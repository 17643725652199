import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { FilterPanelTabValues } from 'app/src/components/GlobalFilters/constants/filterPanelTabOptions';
import { SectionComponents } from './sectionComponents';
import { DataFieldFormat } from '../renderers/DataFieldRenderer';
import { IconRendererIcons } from '../renderers/IconRenderer';

export type CompanyAppStructure = {
  fiscalYearStartingMonth: number;
  pages: StructuredPage[];
};

export type StructuredPage = {
  browserTabTitle: string;
  firstComponentFullWidth?: boolean;
  globalFiltersVisible: boolean;
  globalFiltersStructure?: GlobalFiltersStructure;
  isVisible: boolean;
  key: string;
  navStructure: TopLevelNavStructure | LowerLevelNavStructure;
  components: Array<StructuredComponent>;
};

export type GlobalFiltersStructure = {
  accountStatSummaryConfig: AccountStatSummaryItem[];
  defaultAppliedFilter: string;
  panel: FilterPanelStructure;
  quickAccessBarFilterItems: FilterItemType[];
};

export enum AccountStatSummaryItem {
  totalAccounts = 'totalAccounts',
  totalAccountsWithoutClosedOpps = 'totalAccountsWithoutClosedOpps',
  totalAccountsWithClosedOpps = 'totalAccountsWithClosedOpps',
  totalIndustries = 'totalIndustries',
  avgWinfluence = 'avgWinfluence',
}

export type FilterPanelStructure = {
  showTabs: FilterPanelTabValues[];
  segmentBuilderSections: SegmentBuilderSection[];
};

export type SegmentBuilderSection = {
  priority: number;
  label: string;
  filters: FilterItemType[];
};

export type FilterItemType = {
  type: FilterItemOption;
  field: FilterIndices;
  title: string;
  tooltip?: string;
  removeValues?: any[];
};

export enum FilterItemOption {
  searchableDropdown = 'searchableDropdown',
  checkboxDropdown = 'checkboxDropdown',
  quickFiltersSelect = 'quickFiltersSelect',
  ranger = 'ranger',
  dollarRanger = 'dollarRanger',
  hundredPointScaleRanger = 'hundredPointScaleRanger',
  checkboxes = 'checkboxes',
  radio = 'radio',
  calendar = 'calendar',
}

export type TopLevelNavStructure = {
  urlParent: null; // if null, it's a top level nav link
  urlPaths: UrlPath[];
  navbarLabel: string;
  navbarSecondaryLabel: string;
};
export type LowerLevelNavStructure = {
  urlParent: string;
  urlPaths: UrlPath[];
};

export type UrlPath = {
  param: string;
  path: string;
};

export type StructuredComponent =
  | FullComponentType
  | TabComponentType
  | GridComponentType;

export type FullComponentType = {
  componentType: ComponentType.fullWidth;
  component: StructuredFullComponent;
  isHeader?: boolean;
};

export type TabComponentType = {
  componentType: ComponentType.tab;
  tabComponents: StructuredTabComponent[];
};

export type GridComponentType = {
  componentType: ComponentType.partWidth;
  subComponents: StructuredSubComponent[];
};

export type StructuredFullComponent = {
  key: SectionComponents;
  data: ComponentDataType;
};

export enum ComponentType {
  fullWidth = 'fullWidth',
  tab = 'tab',
  partWidth = 'partWidth',
}

export type StructuredTabComponent = {
  title: string;
  hash: string;
  components: Array<GridComponentType | FullComponentType>;
};

export type StructuredSubComponent = {
  key: SectionComponents;
  gridWidth: number;
  data: ComponentDataType;
};

export type ComponentDataType = {
  title?: string;
  subTitle?: string;
  tooltip?: string;
  accountsHeader?: string;
  signalTypeHeader?: string;
  averageWinfluenceHeader?: string;
  changeHeader?: string;
  goalHeader?: string;
  actualHeader?: string;
};

export enum TableColumnDisplay {
  Button = 'button',
  Static = 'static',
  NonData = 'nonData',
  Expander = 'expander',
}

export enum ButtonActionType {
  Navigate = 'navigate',
}

export type ButtonActionData = {
  type: ButtonActionType.Navigate;
  data: {
    routeToPush: string;
  };
};

export enum TableLegend {
  none = 'none',
  customerStatus = 'customerStatus',
}

type BaseTableColumnType = {
  accessor: string; // defined based on source of data
  header: string;
  headerTooltip: string;
  isNumeric?: boolean; // only needed if column is sortable
  isSortable: boolean;
};

export type TableColumnType = BaseTableColumnType &
  (
    | {
        leftIcon?: IconRendererIcons;
        dataFieldFormat: DataFieldFormat;
        displayType: TableColumnDisplay.Static;
        truncateAt?: number;
      }
    | {
        actionData: ButtonActionData;
        displayType: TableColumnDisplay.Button;
        dataFieldFormat: DataFieldFormat;
        leftIcon?: IconRendererIcons;
        iconAccessor?: string;
        truncateAt?: number;
      }
    | {
        displayType: TableColumnDisplay.NonData;
        leftIcon?: IconRendererIcons;
        value: string;
      }
    | {
        displayType: TableColumnDisplay.Expander;
        subcomponent: TableSubcomponent;
      }
  );

export type TableSubcomponent = {
  dataSource: string; // build enum
  title: {
    fields: string[]; // build enum
    dataFieldFormat: DataFieldFormat;
  };
  rows: TableSubcomponentRow[];
};

export type TableSubcomponentRow = {
  label: string;
  dataField: string;
  dataFieldFormat: DataFieldFormat;
};

export enum TableComponentDataSource {
  accounts = 'accountsWithoutPredictions',
}

export type TableComponentProps = {
  analyticsAttr: string;
  columns: TableColumnType[];
  dataSource: TableComponentDataSource;
  defaultSort: string;
  legendType: TableLegend;
  tablePerPage: number;
  tableSubTitle: string;
  tableTitle: string;
  tableTooltip: string;
};
