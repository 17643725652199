import { useEffect, useState } from 'react';
import { ApiGetAggregateAccountsDataQuery } from 'shared/graphql/generatedApiTypes';
import { Text } from '@chakra-ui/react';
import { useFirebase } from '../../../Firebase';
import { merzGlobalFiltersStructure } from 'app/src/constants/merzGlobalFiltersStructure';
import { crownpeakGlobalFiltersStructure } from 'app/src/constants/crownpeakGlobalFiltersStructure';
import { accountStatSummaryItemRenderer } from 'app/src/renderers/accountStatSummaryItemRenderer';

export type AccountsStatsProps = {
  accountsData: NonNullable<
    ApiGetAggregateAccountsDataQuery['aggregateAccountsData']
  > | null;
};

type RenderItemsType = {
  addOnText: string;
  count?: string | number;
  forceShow?: boolean;
  typeFunction: (count: any) => string;
};

const AccountsStats = ({ accountsData }: AccountsStatsProps) => {
  const [renderItems, setRenderItems] = useState<Array<RenderItemsType | null>>(
    [],
  );
  const { klearlyUser } = useFirebase()!;
  const companyName = klearlyUser?.companyName;
  const globalFiltersStructure =
    companyName === 'merz'
      ? merzGlobalFiltersStructure
      : crownpeakGlobalFiltersStructure;
  const { accountStatSummaryConfig } = globalFiltersStructure;

  useEffect(() => {
    if (accountsData) {
      const renderItemsToSet: Array<RenderItemsType | null> =
        accountStatSummaryConfig.map((stat) =>
          accountStatSummaryItemRenderer(stat, accountsData),
        );
      setRenderItems(renderItemsToSet);
    }
  }, [accountsData, accountStatSummaryConfig]);

  return (
    <>
      {renderItems.map((item, index) =>
        !item
          ? null
          : ((item.count as number) > 0 || item.forceShow) && (
              <Text
                key={index}
                color={'brand.gray-600'}
                fontSize={['sm', null, null, 'md', null]}
              >
                {`${item.count ? `${item.count} ` : ''}${
                  item.typeFunction ? item.typeFunction(item.count) : ''
                }${item.addOnText}`}
              </Text>
            ),
      )}
    </>
  );
};

export default AccountsStats;
