import {OptionsType} from 'shared/types/coreTypes.d';
import {SingleAccountSignalsTimePeriod} from '../types/SingleAccountSignals';

export const singleAccountSignalHistoryTimeOptions: OptionsType[] = [
  {
    label: 'Current Qtr',
    value: SingleAccountSignalsTimePeriod.currentQtr,
  },
  {
    label: 'Previous Qtr',
    value: SingleAccountSignalsTimePeriod.previousQtr,
  },
  {
    label: 'Last 5 Qtrs',
    value: SingleAccountSignalsTimePeriod.last5Qtrs,
  },
];
