import {
  HOME_HEADERS,
  HOME_RECOMMENDATIONS_LIST,
} from 'app/src/constants/routes';
import {
  RecommendationTableIndices,
  RecommendedActionsTableText,
  SectionHeaderIndices,
} from '../types/homeTypes';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

export const useRecommendationsTableAppDefinitions =
  (): RecommendedActionsTableText => {
    const pageId = HOME_RECOMMENDATIONS_LIST.name;
    const {
      text: sectionHeaderText,
      subtext: sectionHeaderSubtext,
      tooltip: sectionHeaderTooltip,
    } = getAppDefinitionsItem(
      HOME_HEADERS.name,
      SectionHeaderIndices.RECOMMENDED_ACTIONS,
    ) as TextDefinitionType;
    const { text: averageWinfluenceText, tooltip: averageWinfluenceTooltip } =
      getAppDefinitionsItem(
        pageId,
        RecommendationTableIndices.AVERAGE_WINFLUENCE_HEADER,
      ) as TextDefinitionType;
    const { text: changeHeaderText, tooltip: changeHeaderTooltip } =
      getAppDefinitionsItem(
        pageId,
        RecommendationTableIndices.CHANGE_HEADER,
      ) as TextDefinitionType;
    const { text: accountsHeaderText, tooltip: accountsHeaderTooltip } =
      getAppDefinitionsItem(
        pageId,
        RecommendationTableIndices.ACCOUNTS_HEADER,
      ) as TextDefinitionType;
    const { text: signalTypeHeaderText, tooltip: signalTypeHeaderTooltip } =
      getAppDefinitionsItem(
        pageId,
        RecommendationTableIndices.SIGNAL_TYPE_HEADER,
      ) as TextDefinitionType;
    const { text: goalHeaderText, tooltip: goalHeaderTooltip } =
      getAppDefinitionsItem(
        pageId,
        RecommendationTableIndices.GOAL_HEADER,
      ) as TextDefinitionType;
    const { text: actionHeaderText, tooltip: actionHeaderTooltip } =
      getAppDefinitionsItem(
        pageId,
        RecommendationTableIndices.ACTUAL_HEADER,
      ) as TextDefinitionType;

    return {
      accountsHeaderText,
      accountsHeaderTooltip,
      actionHeaderText,
      actionHeaderTooltip,
      averageWinfluenceText,
      averageWinfluenceTooltip,
      changeHeaderText,
      changeHeaderTooltip,
      goalHeaderText,
      goalHeaderTooltip,
      sectionHeaderSubtext: sectionHeaderSubtext ?? '',
      sectionHeaderText,
      sectionHeaderTooltip,
      signalTypeHeaderText,
      signalTypeHeaderTooltip,
    };
  };
