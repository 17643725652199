import { useFirebase } from '../../Firebase';
import { useFirestoreQuery } from 'shared/hooks/firestoreHook';
import { appDefinitionsDataVar } from 'shared/graphql';
import { useEffect } from 'react';
import { AppChildrenRestDataType } from '../types/appTypes';
import { WithLoading } from './WithLoading';

export const AppDefinitions = ({
  children,
  ...rest
}: AppChildrenRestDataType) => {
  const firebase = useFirebase();

  const { data: definitionsData, status: definitionsStatus } =
    useFirestoreQuery(firebase!.appDefinitionsCollection);
  const isDefinitionsLoading =
    definitionsStatus === 'loading' && !definitionsData;
  if (!isDefinitionsLoading) {
    appDefinitionsDataVar(definitionsData);
  }

  useEffect(() => {
    if (definitionsData) {
      appDefinitionsDataVar(definitionsData);
    }
  }, [definitionsData]);

  return (
    <WithLoading isLoading={isDefinitionsLoading} {...rest}>
      {children}
    </WithLoading>
  );
};
