import classnames from 'classnames';
import * as ROUTES from 'app/src/constants/routes';
import { BrandBar, Logo } from '../../../../shared/components/Core';
import { NavigationAuth } from './components/NavigationAuth';
import { Box } from '@chakra-ui/react';

export type NavigationProps = {
  showAdminOrSuperUserRoute: boolean;
};

const NavigationBar = ({
  showAdminOrSuperUserRoute = false,
}: NavigationProps) => {
  const headerClasses = classnames({
    'c-header': true,
  });

  return (
    <>
      <BrandBar />
      <header className={headerClasses}>
        <div className={'c-header__container'}>
          <Box
            as={'a'}
            href={ROUTES.HOME}
            className={'c-header__logo h-pointer'}
          >
            <Logo />
          </Box>
          <NavigationAuth
            showAdminOrSuperUserRoute={showAdminOrSuperUserRoute}
          />
        </div>
      </header>
    </>
  );
};

export default NavigationBar;
