import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Navigation from '../../Navigation';
import { Footer } from 'shared/components/Core';
import { Login } from '../../Login';
import AggregateAccountsDataContextProvider from 'app/src/context/AggregateAccountsDataContext';
import * as ROUTES from 'app/src/constants/routes';
import { ApolloClient, ApolloProvider, HttpLink } from '@apollo/client';
import fetch from 'cross-fetch';
import { onError } from '@apollo/client/link/error';
import _map from 'lodash/map';
import apolloClientHelper from 'shared/graphql/apolloClientHelper';
import { cache } from 'shared/graphql';
import { AppDefinitions } from 'app/src/components/App/components/AppDefinitions';
import { theme } from 'shared/theming';
import { ChakraProvider } from '@chakra-ui/react';
import { FirebaseContext } from 'app/src/components/Firebase';
import { FirebaseType } from 'app/src/components/Firebase/firebase';
import { appStructure } from '../structures/appStructure';
import { RootPage } from 'app/src/components/RootPage/RootPage';
import { StructuredPage } from 'shared/types/companyAppStructureTypes';
import { AppNavigationalStructureContextProvider } from 'app/src/context/AppNavigationalStructureContext';
import { firebase } from '../FirebaseProvider';

export const App = () => {
  const apolloClient = () => {
    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_SERVER}/graphql`,
      fetch,
    });
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      // setup errors
      const errors: Array<string> = [];
      _map(graphQLErrors, (error) => errors.push(error.message));
      if (networkError) {
        errors.push(
          'A network error has occurred. Please check out your connection.',
        );
      }
    });
    return new ApolloClient({
      ...apolloClientHelper,
      cache,
      link: errorLink.concat(httpLink),
    });
  };

  const client = apolloClient();
  const structure = appStructure;

  return (
    <ChakraProvider theme={theme} portalZIndex={12000}>
      {firebase && (
        <FirebaseContext.Provider value={firebase as unknown as FirebaseType}>
          <ApolloProvider client={client}>
            <AppDefinitions>
              <Router>
                <AppNavigationalStructureContextProvider
                  topLevelStructure={structure}
                >
                  <AggregateAccountsDataContextProvider>
                    <Navigation showAdminOrSuperUserRoute={false} />
                    <Switch>
                      <Route component={Login} exact path={ROUTES.LOG_IN} />
                      {structure.pages?.map((page) =>
                        page.isVisible ? (
                          <Route
                            key={page.key}
                            exact
                            path={buildPath(page.navStructure)}
                            render={() => (
                              <RootPage pageKey={page.key} key={page.key} />
                            )}
                          />
                        ) : null,
                      )}
                      <Route render={() => <Redirect to={ROUTES.HOME} />} />
                    </Switch>
                  </AggregateAccountsDataContextProvider>
                </AppNavigationalStructureContextProvider>
                <Footer />
              </Router>
            </AppDefinitions>
          </ApolloProvider>
        </FirebaseContext.Provider>
      )}
    </ChakraProvider>
  );
};

const buildPath = (navStructure: StructuredPage['navStructure']): string => {
  // no parent means it's a top level link
  if (!navStructure.urlParent && navStructure.urlPaths[0]) {
    return navStructure.urlPaths[0].path;
  }
  let newPath = navStructure.urlParent;
  navStructure.urlPaths.forEach(
    (path) => (newPath += `${path.param}${path.path}`),
  );
  return newPath ?? '';
};
