import { useContext } from 'react';
import _isEqual from 'lodash/isEqual';
import {
  AccordionPanelWrapper,
  Button,
  Container,
  Inline,
  Txt,
} from '../../../../../../../shared/components/Core';
import { adaptDictAsString } from '../../../helpers/adaptFilterForQuery';
import { getInitialApolloState } from 'app/src/apollo/rootReactiveVariables';
import { AggregateAccountsDataContext } from 'app/src/context/AggregateAccountsDataContext';
import { AccountStatsSummaryBar } from '../../filterBar/AccountStatsSummaryBar';
import { FilterBarDivider } from '../../filterBar/FilterBarDivider';
import { globalFilterAppDefinitions } from '../../../helpers/globalFilterAppDefinitions';
import { Accordion, Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { SaveCurrentSelectionAccordion } from './SaveCurrentSelectionAccordion';
import {
  FilterItemOption,
  SegmentBuilderSection,
} from 'shared/types/companyAppStructureTypes';
import { FilterItem } from '../../filterItems/FilterItem';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { getFilterNumberSelected } from '../../../helpers/getFilterNumberSelected';
import { useFirebase } from '../../../../Firebase';
import { merzGlobalFiltersStructure } from 'app/src/constants/merzGlobalFiltersStructure';
import { crownpeakGlobalFiltersStructure } from 'app/src/constants/crownpeakGlobalFiltersStructure';

type BuildASegmentTabProps = {
  segmentBuilderSections: SegmentBuilderSection[];
  showingSaveSelection: boolean;
};

export const BuildASegmentTab = ({
  segmentBuilderSections,
  showingSaveSelection,
}: BuildASegmentTabProps) => {
  const { accountGroupStats, globalFilter, resetFilter } = useContext(
    AggregateAccountsDataContext,
  );

  const appDefinitions = globalFilterAppDefinitions.getBuildASegmentTabText();

  const { klearlyUser } = useFirebase()!;
  const companyName = klearlyUser?.companyName;
  const globalFiltersStructure =
    companyName === 'merz'
      ? merzGlobalFiltersStructure
      : crownpeakGlobalFiltersStructure;
  const apolloState = getInitialApolloState(
    globalFiltersStructure.defaultAppliedFilter,
  );

  // Need to apply adaptDict to clones of these so that we don't overwrite them

  const resetToDefaultDisabled = _isEqual(
    adaptDictAsString({ ...globalFilter }),
    adaptDictAsString({ ...apolloState.globalFilter }),
  );

  const getTitle = (section: SegmentBuilderSection) => {
    if (section.label === 'Accounts') {
      return 'Accounts';
    }
    return `${section.label} (${getFilterNumberSelected(
      globalFilter,
      section,
    )} Applied)`;
  };

  return (
    <Container>
      <div
        className={'c-form c-modal__body filter-bar-wrapper__panel-tab-border'}
      >
        <div className={'filter-bar-wrapper__sticky-header-panel'}>
          <Flex justify={'space-between'}>
            <AccountStatsSummaryBar />
            <Button
              isDisabled={resetToDefaultDisabled}
              onClick={resetFilter}
              text={appDefinitions.resetFilters.text}
            />
          </Flex>
          <div
            className={
              'h-flex h-flex-wrap filter-bar-wrapper__panel-bar-spacer'
            }
          >
            {accountGroupStats.map((stat, idx) => (
              <Inline className={'h-color-gray-600 h-mr-xs'} key={idx}>
                <Text fontWeight={'bold'}>{`${stat.title}:`}</Text>
                &nbsp;
                <Txt>{`${stat.value}`}</Txt>
              </Inline>
            ))}
          </div>
          <FilterBarDivider />
        </div>
        <Accordion allowMultiple allowToggle _focus={{ boxShadow: 'none' }}>
          {showingSaveSelection && (
            <SaveCurrentSelectionAccordion
              analyticsAttr={'panel-save-selection-accordion'}
            />
          )}
          {segmentBuilderSections
            .sort((a, b) => (a.priority > b.priority ? 1 : -1))
            .map((section) => (
              <AccordionPanelWrapper
                key={section.label}
                analyticsAttr={cleanAnalyticsStringList([
                  'panel',
                  section.label,
                  'accordion',
                ])}
                render={() => (
                  <SimpleGrid columns={[2, null, null, null, null]}>
                    {section.filters.map((item) =>
                      item.type === FilterItemOption.checkboxDropdown ? (
                        <Box
                          gridColumn={['span 2', null, null, 'span 1', null]}
                          key={item.field}
                        >
                          <FilterItem filterItem={item} itemSource={'panel'} />
                        </Box>
                      ) : (
                        <Box gridColumn={'span 2'} key={item.field}>
                          <FilterItem filterItem={item} itemSource={'panel'} />
                        </Box>
                      ),
                    )}
                  </SimpleGrid>
                )}
                title={getTitle(section)}
              />
            ))}
        </Accordion>
      </div>
    </Container>
  );
};
