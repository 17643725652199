import { useMemo } from 'react';
import { Table } from 'shared/components/Core';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Granularity,
  SignalSectionUnitType,
} from '../../../types/SingleAccountSignals';
import {
  SINGLE_ACCOUNT_SIGNAL_INBOUND_COLOR,
  SINGLE_ACCOUNT_SIGNAL_OUTBOUND_COLOR,
} from 'app/src/components/Account/constants/singleAccountSignalColors';
import { ApiSignal } from 'shared/graphql/generatedApiTypes';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

type SingleAccountSignalDetailsTableProps = {
  currentGranularity: Granularity;
  currentUnitType: SignalSectionUnitType;
  tableData: ApiSignal[];
};

export const SingleAccountSignalDetailsTable = ({
  currentGranularity,
  currentUnitType,
  tableData,
}: SingleAccountSignalDetailsTableProps) => {
  const mappedData = useMemo(() => {
    return tableData.map((tableItem) =>
      Object.values(tableItem).reduce((accum, item) => {
        //If "Granularity" = "Daily" display each row in the expanded table as a distinct, atomic signal and not an aggregation
        const existingSignalTypeItem =
          currentGranularity !== Granularity.daily &&
          accum.find(
            (row) =>
              row.type === item.type &&
              row.typeDetail === item.typeDetail &&
              row.topicDetail === item.topicDetail &&
              row.topic === item.topic &&
              row.groupKey === item.groupKey,
          );
        if (!existingSignalTypeItem && item.type && item.quantity) {
          accum.push({
            type: item.type,
            typeDetail: item.typeDetail ?? null,
            quantity: item.quantity,
            topic: item.topic,
            topicDetail: item.topicDetail,
            groupKey: item.groupKey,
            direction: item.direction,
          });
        } else if (existingSignalTypeItem) {
          existingSignalTypeItem.quantity += item.quantity;
        }
        return accum;
      }, [] as Partial<ApiSignal>[]),
    );
  }, [tableData, currentGranularity]);

  const columns = useMemo(
    () => [
      {
        Header: 'Activity Details',
        columns: [
          {
            Header: 'Type',
            Cell: ({ row, value }) => {
              return (
                <Flex align={'center'}>
                  {row.original.direction === 'response' ? (
                    <Box
                      bg={SINGLE_ACCOUNT_SIGNAL_INBOUND_COLOR}
                      borderRadius={6}
                      w={3}
                      h={3}
                      mr={1}
                    />
                  ) : (
                    <Box
                      bg={SINGLE_ACCOUNT_SIGNAL_OUTBOUND_COLOR}
                      borderRadius={6}
                      w={3}
                      h={3}
                      mr={1}
                    />
                  )}
                  {cleanStringCase(value)}
                </Flex>
              );
            },
            accessor: 'type',
          },
          {
            Header: 'Type Detail',
            Cell: ({ value }) => cleanStringCase(value),
            accessor: 'typeDetail',
          },
          {
            Header: 'Topic',
            Cell: ({ value }) => cleanStringCase(value),
            accessor: 'topic',
          },
          {
            Header: 'Topic Detail',
            Cell: ({ value }) => cleanStringCase(value),
            accessor: 'topicDetail',
          },
          {
            Header: 'Order Number',
            Cell: ({ value }) => cleanStringCase(value),
            accessor: 'groupKey',
          },
          {
            Header:
              currentUnitType === SignalSectionUnitType.quantity
                ? 'Total Qty'
                : 'Total Count',
            accessor: 'quantity',
            Cell: ({ value }) => value.toLocaleString(),
            isNumeric: true,
          },
        ],
      },
    ],
    [currentUnitType],
  );

  const unitTypeString =
    currentUnitType === SignalSectionUnitType.quantity ? 'Qty' : 'Count';

  return (
    <Accordion allowToggle allowMultiple>
      {mappedData.map((row, index) => (
        <AccordionItem key={index}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Flex flex='1' textAlign='left' justify={'space-between'}>
                    <Flex align={'center'}>
                      {isExpanded ? (
                        <ChevronDownIcon boxSize={6} />
                      ) : (
                        <ChevronRightIcon boxSize={6} />
                      )}
                      <Text color={'brand.black'}>
                        {`${tableData[index].occurredAt}`}
                      </Text>
                    </Flex>
                    <Text>{`Total ${unitTypeString}: ${row
                      .reduce((accum, item) => {
                        return accum + item.quantity;
                      }, 0)
                      .toLocaleString()}`}</Text>
                    <Text>{`Inbound ${unitTypeString}: ${row
                      .reduce((accum, item) => {
                        if (item.direction === 'response') {
                          return accum + item.quantity;
                        }
                        return accum;
                      }, 0)
                      .toLocaleString()}`}</Text>
                    <Text>{`Outbound ${unitTypeString}: ${row
                      .reduce((accum, item) => {
                        if (item.direction === 'activity') {
                          return accum + item.quantity;
                        }
                        return accum;
                      }, 0)
                      .toLocaleString()}`}</Text>
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Table columns={columns} data={row} perPage={20} />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};
