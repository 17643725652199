import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { GetAccountsWithQueryStringDocument } from 'shared/graphql/generatedApiTypes';
import AsyncSelect from 'react-select/async';
import {
  addControlComponentDataAcceptance,
  addInputComponentDataAcceptance,
  addOptionComponentDataAcceptance,
} from 'app/src/helpers/analyticsHelpers';
import _debounce from 'lodash/debounce';
import _size from 'lodash/size';
import * as ROUTES from '../../../constants/routes';
import { cleanAccountSelectOptions } from 'app/src/helpers/cleanAccountSelectOptions';
import { getAccountSearchStyles } from 'app/src/components/Navigation/helpers/getAccountSearchStyles';

type AccountSearchProps = {
  accountClicked: () => void;
};

export const AccountSearch = ({ accountClicked }: AccountSearchProps) => {
  const history = useHistory();
  const apolloClient = useApolloClient();
  const _handleLoadOptions = (inputValue, callback) => {
    apolloClient
      .query({
        query: GetAccountsWithQueryStringDocument,
        variables: { queryString: inputValue },
      })
      .then((res) =>
        callback(cleanAccountSelectOptions(res?.data?.accountsForSearch)),
      )
      .catch(() => callback(null));
  };

  return (
    // @ts-ignore
    <AsyncSelect
      cacheOptions
      components={{
        Control: addControlComponentDataAcceptance(
          'account-search-nav-bar-select-control',
        ),
        Input: addInputComponentDataAcceptance(
          'account-search-nav-bar-select-input',
        ),
        Option: addOptionComponentDataAcceptance(
          'account-search-nav-bar-select-option',
        ),
      }}
      escapeClearsValue={true}
      inputId={'account-search-nav-bar-react-select'}
      loadOptions={_debounce(_handleLoadOptions, 500, {
        leading: false,
        trailing: true,
      })}
      noOptionsMessage={({ inputValue }) =>
        _size(inputValue) > 0 ? 'No options' : 'Start typing'
      }
      onChange={(val) => {
        if (val) {
          history.push(`${ROUTES.ACCOUNT[0]}/${val.value}`);
          return accountClicked();
        }
      }}
      placeholder={'Account Search...'}
      styles={getAccountSearchStyles()}
      value={''}
    />
  );
};
