import { SAV_RECOMMENDATIONS_LIST } from 'app/src/constants/routes';

import { Container } from '../../../../../../../shared/components/Core';

import SectionHeader from '../../../../../../../shared/components/Core/SectionHeader';
import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { Stack } from '@chakra-ui/react';
import { RecommendationsBody } from './RecommendationsBody';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { HEADER_INDEX } from 'app/src/constants/headerIndex';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

export type RecommendationsProps = {
  accountInfo: ApiGetAccountQuery['account'];
  sectionVisibilityToggles: {
    setShowingAllActions: (newVal: boolean) => void;
    showingAllActions: boolean;
    setShowingPrimaryActions: (newVal: boolean) => void;
    showingPrimaryActions: boolean;
  };
};

const Recommendations = ({
  accountInfo,
  sectionVisibilityToggles,
}: RecommendationsProps) => {
  const {
    subtext: recommendationsHeaderSubText,
    text: recommendationsHeaderText,
  } = getAppDefinitionsItem(
    SAV_RECOMMENDATIONS_LIST.name,
    HEADER_INDEX,
  ) as TextDefinitionType;

  return (
    <Container ph={'xl'} pv={'lg'} width={'full'}>
      <Stack spacing={4}>
        <SectionHeader
          subtitle={recommendationsHeaderSubText}
          title={recommendationsHeaderText}
        />
        <RecommendationsBody
          accountInfo={accountInfo}
          sectionVisibilityToggles={sectionVisibilityToggles}
        />
      </Stack>
    </Container>
  );
};

export default Recommendations;
