import { SectionComponents } from 'shared/types/sectionComponents';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import {
  ComponentDataType,
  ComponentType,
  GridComponentType,
  StructuredComponent,
  TabComponentType,
  TableComponentProps,
} from 'shared/types/companyAppStructureTypes';
import { Orders } from 'app/src/components/Account/components/sav/overview/accountSummary/Orders';
import { HomeRecommendationsTable } from 'app/src/components/Home/components/HomeRecommendationsTable';
import { EngagementStatus } from 'app/src/components/Home/components/EngagementStatus';
import { SingleAccountQuickStat } from 'app/src/components/Account/components/sav/overview/accountSummary/singleAccountQuickStat/SingleAccountQuickStat';
import { MultipleAccountsCutsAndList } from 'app/src/components/Account/components/mav/MultipleAccountsCutsAndList';
import { LabsAccordions } from 'app/src/components/Lab/components/LabsAccordions';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { SankeyChart } from 'app/src/components/Plan/components/SankeyChart';
import { useHistory, useLocation } from 'react-router-dom';
import { EngagementAnalysis } from 'app/src/components/Account/components/sav/overview/EngagementAnalysis';
import { AbstractedTableSection } from 'app/src/components/TableSection/components/AbstractedTableSection';
import { SingleAccountSignalHistorySection } from 'app/src/components/Account/components/sav/singleAccountSignalHistory/SingleAccountSignalHistorySection';
import { EngagementSummary } from 'app/src/components/Account/components/sav/overview/EngagementSummary';
import { RecommendationsTab } from 'app/src/components/Account/components/sav/recommendations/RecommendationsTab';
import { EngagementLineChartWrapper } from 'app/src/components/Home/components/EngagementLineChartWrapper';
import { LabCardDetail } from 'app/src/components/Lab/components/LabCardDetail';
import { AccountHeaderAlternate } from 'app/src/components/Account/components/sav/AccountHeaderAlternate';
import { Card, Container } from '../components/Core';
import { InsightsFeed } from 'app/src/components/InsightsFeed/components/InsightsFeed';
import { GaugeChart } from 'app/src/components/GaugeChart/components/GaugeChart';
import { Winfluence } from 'app/src/components/Account/components/sav/overview/accountSummary/Winfluence';
import { useEffect, useState } from 'react';
import { AccountHeader } from 'app/src/components/Account/components/sav/header/AccountHeader';
import { SingleAccountQuickStatProps } from '../types/quickStatFieldOptions';

export const StructuredComponentRenderer = (
  component: StructuredComponent,
  isMock?: boolean,
) => {
  if (component.componentType === ComponentType.fullWidth) {
    return ComponentRenderer(component.component.key, component.component.data);
  } else if (component.componentType === ComponentType.partWidth) {
    return <GridComponent component={component} />;
  } else if (component.componentType === ComponentType.tab) {
    return <TabComponent component={component} isMock={!!isMock} />;
  }
};

const ComponentRenderer = (
  componentKey: SectionComponents,
  componentData: ComponentDataType,
) => {
  switch (componentKey) {
    case SectionComponents.EngagementStatus:
      return <EngagementStatus />;
    case SectionComponents.AggregateSignalHistory:
      return <EngagementLineChartWrapper />;
    case SectionComponents.RecommendedActionsTable:
      return <HomeRecommendationsTable />;
    case SectionComponents.PossibleAccountScoreSankey:
      return <SankeyChart />;
    case SectionComponents.SingleAccountRecommendations:
      return <RecommendationsTab />;
    case SectionComponents.SingleAccountSignalHistory:
      return (
        <SingleAccountSignalHistorySection structureData={componentData} />
      );
    case SectionComponents.SingleAccountEngagementAnalysis:
      return <EngagementAnalysis />;
    case SectionComponents.SingleAccountEngagementSummary:
      return <EngagementSummary />;
    case SectionComponents.WinfluenceAndPrimaryActionFilterableList:
      return <MultipleAccountsCutsAndList />;
    case SectionComponents.Labs:
      return <LabsAccordions />;
    case SectionComponents.LabsDetail:
      return <LabCardDetail />;
    case SectionComponents.Orders:
      return <Orders />;
    case SectionComponents.Winfluence:
      return <Winfluence />;
    case SectionComponents.SingleAccountHeader:
      return <AccountHeader />;
    case SectionComponents.SingleAccountHeaderAlternate:
      return <AccountHeaderAlternate />;
    case SectionComponents.SingleAccountQuickStat:
      return (
        <SingleAccountQuickStat
          {...(componentData as SingleAccountQuickStatProps)}
        />
      );
    case SectionComponents.InsightsFeed:
      return <InsightsFeed />;
    case SectionComponents.TableSection:
      return (
        <AbstractedTableSection {...(componentData as TableComponentProps)} />
      );
    case SectionComponents.GaugeChart:
      return <GaugeChart />;
  }
};

const GridComponent = ({ component }: { component: GridComponentType }) => {
  return (
    <Box mb={4}>
      <SimpleGrid columns={[60, null, null, null, 120]}>
        {component.subComponents.map((subcomponent, index) => (
          <Box
            key={`${subcomponent.key}-${index}`}
            gridColumn={`span ${subcomponent.gridWidth}`}
            mt={[4, null, null, null, 0]}
            px={2}
          >
            {ComponentRenderer(subcomponent.key, subcomponent.data)}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

const TabComponent = ({
  component,
  isMock = false,
}: {
  component: TabComponentType;
  isMock?: boolean;
}) => {
  const { pathname, hash } = useLocation();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);

  // manage tab changes based on the URL hash - only if it's not a mock though
  useEffect(() => {
    let tabIndices: { [key: string]: number } = {};
    let defaultTab = '';
    component.tabComponents.forEach((tab, index) => {
      if (index === 0) {
        defaultTab = tab.hash;
      }
      tabIndices[tab.hash] = index;
    });
    if (tabIndices[hash] !== undefined) {
      setTabIndex(tabIndices[hash]);
    } else {
      setTabIndex(tabIndices[defaultTab]);
      if (!isMock) {
        history.replace(`${pathname}${defaultTab}`);
      }
    }
  }, [pathname, hash, history, component.tabComponents, isMock]);

  return (
    <Container ph={'xl'} pv={'md'} width={'full'}>
      <Card>
        <Tabs index={tabIndex} isFitted isLazy variant={'enclosed-colored'}>
          <TabList>
            {component.tabComponents.map((tab) => (
              <Tab onClick={() => history.replace(`${pathname}${tab.hash}`)}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {component.tabComponents.map((tab) => (
              <TabPanel p={6}>
                {tab.components.map((tabComponent) =>
                  StructuredComponentRenderer(tabComponent),
                )}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Card>
    </Container>
  );
};
