import {LabCardApiResponse, LabsHeroDisplay} from '../types/labApiTypes';
import {LabFirestoreValuesType} from '../types/firestoreValuesTypes';
import {useFirebase} from '../../Firebase';
import {useFirestoreQuery} from 'shared/hooks/firestoreHook';
import classnames from 'classnames';
import {Container, IconBadge, Tooltip} from '../../../../../shared/components/Core';
import _size from 'lodash/size';
import LabCardComments from './LabCardComments';
import {LabChart} from './LabCharts';
import IfComment from '../../../../../shared/components/Core/Icon/if-comments';
import {Stack} from '@chakra-ui/react';

type LabCardPageContentProps = {
  labFromApi: LabCardApiResponse;
  tableValue: LabFirestoreValuesType;
};

export const LabCardPageContent = ({
  labFromApi,
  tableValue,
}: LabCardPageContentProps) => {
  const firebase = useFirebase();
  const { data } = useFirestoreQuery(
    firebase!.getFirestoreCollection('feedback').doc(labFromApi.id),
  );
  const comments = data?.comments || [];
  const chartSectionClassNames = classnames({
    'h-flex h-ph-lg h-pb-lg': true,
  });

  return (
    <Container pv={'lg'}>
      <Stack spacing={4}>
        <div className={'h-flex h-flex-justify-content-end h-mr-lg'}>
          <Tooltip content={''} hideIcon={true}>
            <IconBadge
              iconComponent={<IfComment boxSize={6} />}
              text={_size(comments).toString()}
            />
          </Tooltip>
        </div>
        <div className={chartSectionClassNames}>
          <div className={'h-height-full h-width-full'}>
            {labFromApi && (
              <LabChart
                columns={labFromApi.columns}
                context={labFromApi.hero.context}
                displayType={labFromApi.chartType.default}
                isAnswerTypePercent={
                  labFromApi.hero.displayType === LabsHeroDisplay.percent
                }
                isChartClickable={false}
                isDollars={
                  labFromApi.hero.displayType === LabsHeroDisplay.currency
                }
                isSmallerViewport={true}
                monoColor={tableValue.monoColor}
                rows={labFromApi.rows}
                sortOrder={'asc'}
                totalNumber={labFromApi.hero.value}
              />
            )}
          </div>
        </div>
        <div className={'h-pointer h-flex h-width-full'}>
          <div
            className={'h-width-full h-mr-lg h-ml-lg'}
            style={{ height: '450px' }}
          >
            <LabCardComments id={labFromApi.id} />
          </div>
        </div>
      </Stack>
    </Container>
  );
};
