import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { Stack } from '@chakra-ui/react';
import {
  ApiAccountGroupType,
  ApiGetAccountQuery,
  GetAccountGroupDocument,
  useGetAccountGroupsByAccountQuery,
  useGetAccountQuery,
  useGetAllAccountGroupsQuery,
  useUpdateAccountGroupAccountsMutation,
} from 'shared/graphql/generatedApiTypes';
import {
  ENGAGEMENT_STATUS_FILTER_ITEMS,
  FilterIndices,
} from '../../../GlobalFilters/types/filterTypes';
import {
  Button,
  Card,
  Checkbox,
  Icon,
  Inline,
  Loading,
  Modal,
  Tooltip,
  Txt,
} from '../../../../../../shared/components/Core';
import { ACCOUNT_FILTER_BAR } from 'app/src/constants/routes';
import { formatDistanceToNowStrict } from 'date-fns';
import { useApolloClient } from '@apollo/client';
import { groupAccountGroups } from '../../../GlobalFilters/helpers/groupAccountGroups';
import { useFirebase } from '../../../Firebase';
import { mapAccountListsToExistingLists } from '../../helpers/mapAccountListsToExistingLists';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { KlearlyUserType } from '../../../Firebase/firebase';
import { updateAccountGroupAccountsCache } from '../../../GlobalFilters/helpers/updateAccountGroupCache';
import { EditIcon } from '@chakra-ui/icons';
import _sortBy from 'lodash/sortBy';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { useParams } from 'react-router-dom';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';
import {
  cleanStringCase,
  numberFormatter,
  pluralizeDaysString,
} from 'shared/helpers/formatHelpers';

type AccountHeaderProps = {
  groupData?: {
    description: string;
  };
  isGroup?: boolean;
  isLoggedInUsersGroup?: boolean;
  showAddToListButton?: boolean;
};

type State = {
  showUpdateDescriptorsModal: boolean;
  showUpdateAccountsModal: boolean;
  selectedDescriptorsUpdateAccountGroup: AccountHeaderProps['groupData'];
  selectedUpdateAccountsAccountGroup: ApiGetAccountQuery['account'] | undefined;
};

const initialState: State = {
  showUpdateAccountsModal: false,
  showUpdateDescriptorsModal: false,
  selectedDescriptorsUpdateAccountGroup: undefined,
  selectedUpdateAccountsAccountGroup: undefined,
};

export const AccountHeaderAlternate = ({
  groupData = undefined,
  isGroup = false,
  isLoggedInUsersGroup = false,
  showAddToListButton = true,
}: AccountHeaderProps) => {
  const [
    { showUpdateAccountsModal, selectedUpdateAccountsAccountGroup },
    setState,
  ] = useState<State>(initialState);
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const { data } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });

  const accountInfo = data?.account;

  const {
    accountName,
    annualRevenue,
    employeeCount,
    engagementStatus,
    industry,
    lastInboundSignal,
    lastOutboundSignal,
    openOpportunityCount,
  } = { ...accountInfo };

  const showExtraItems =
    lastOutboundSignal || lastInboundSignal || engagementStatus;
  // setup definitions
  const pageId = ACCOUNT_FILTER_BAR.name;
  const { text: engagementStatusText } = getAppDefinitionsItem(
    pageId,
    ENGAGEMENT_STATUS_FILTER_ITEMS.index,
  ) as TextDefinitionType;
  const { text: lastInboundSignalDateText } = getAppDefinitionsItem(
    pageId,
    FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER,
  ) as TextDefinitionType;
  const { text: lastOutboundSignalDateText } = getAppDefinitionsItem(
    pageId,
    FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER,
  ) as TextDefinitionType;

  // setup responsive aspects
  const isLargerScreen = useMediaQuery({ minWidth: 957 });
  const wrapperClassnames = classnames({
    'c-inline': isLargerScreen,
    'c-inline--grid': isLargerScreen,
    'h-flex-align-items-center': isLargerScreen,
    'h-flex-justify-content-between': isLargerScreen,
    'h-grid-column-gap-lg': isLargerScreen,
    'h-grid-justify-items-start': isLargerScreen,
    'h-pb-lg': isLargerScreen,
  });

  return (
    <Card minH={30} mb={0}>
      <div className={wrapperClassnames}>
        <div>
          <Stack spacing={4}>
            <Inline alignItems={'center'} gap={'xs'}>
              {isGroup &&
              groupData?.description &&
              groupData?.description.length > 0 ? (
                <Tooltip
                  analyticsAttr={`${accountName} Header`}
                  content={<Txt>{groupData.description}</Txt>}
                >
                  <Txt
                    as={'h1'}
                    className={'h-color-bold'}
                    size={'2xl'}
                    weight={'bold'}
                  >
                    {accountName}
                  </Txt>
                </Tooltip>
              ) : (
                <Txt
                  as={'h1'}
                  className={'h-color-bold'}
                  size={'2xl'}
                  weight={'bold'}
                >
                  {accountName}
                </Txt>
              )}
              {isGroup && isLoggedInUsersGroup && (
                <div
                  analytics-attr={`${accountName}-edit-button`}
                  aria-label={'edit'}
                  className={'h-pointer'}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      selectedDescriptorsUpdateAccountGroup: groupData,
                      showUpdateDescriptorsModal: true,
                    }));
                  }}
                >
                  <Icon name={'if-edit'} />
                </div>
              )}
            </Inline>
            <Inline className={'status-wrapper'} gap={'md'}>
              <Inline gap={'2xs'}>
                <Txt theme={'muted'} weight={'bold'}>
                  {'Open Opportunities: '}
                </Txt>
                <Txt theme={'muted'}>
                  {openOpportunityCount?.toString() ?? ''}
                </Txt>
              </Inline>
              <Inline gap={'2xs'}>
                <Txt theme={'muted'} weight={'bold'}>
                  {'Industry: '}
                </Txt>
                <Txt theme={'muted'}>{cleanStringCase(industry) || '-'}</Txt>
              </Inline>
              <Inline gap={'2xs'}>
                <Txt theme={'muted'} weight={'bold'}>
                  {'Revenue: '}
                </Txt>
                <Txt theme={'muted'}>{numberFormatter(annualRevenue)}</Txt>
              </Inline>
              <Inline gap={'2xs'}>
                <Txt theme={'muted'} weight={'bold'}>
                  {'Size: '}
                </Txt>
                <Txt theme={'muted'}>
                  {numberFormatter(employeeCount, false)}
                </Txt>
              </Inline>
            </Inline>
            {showExtraItems ? (
              <Inline className={'status-wrapper'} gap={'md'}>
                {lastOutboundSignal && lastOutboundSignal.occurredAt && (
                  <Inline gap={'2xs'}>
                    <Txt theme={'muted'} weight={'bold'}>
                      {`${lastOutboundSignalDateText}: `}
                    </Txt>
                    <Txt theme={'muted'}>
                      {`${cleanStringCase(
                        lastOutboundSignal?.type,
                      )} ${cleanStringCase(
                        lastOutboundSignal?.action,
                      )} ${formatDaysAgoWithCommaSeparation(
                        new Date(lastOutboundSignal.occurredAt),
                      )}`}
                    </Txt>
                  </Inline>
                )}
                {lastInboundSignal && lastInboundSignal.occurredAt && (
                  <Inline gap={'2xs'}>
                    <Txt theme={'muted'} weight={'bold'}>
                      {`${lastInboundSignalDateText}: `}
                    </Txt>
                    <Txt theme={'muted'}>
                      {`${cleanStringCase(
                        lastInboundSignal?.type,
                      )} ${cleanStringCase(
                        lastInboundSignal?.action,
                      )} ${formatDaysAgoWithCommaSeparation(
                        new Date(lastInboundSignal.occurredAt),
                      )}`}
                    </Txt>
                  </Inline>
                )}
                {engagementStatus && (
                  <Inline gap={'2xs'}>
                    <Txt theme={'muted'} weight={'bold'}>
                      {`${engagementStatusText}: `}
                    </Txt>
                    <Txt theme={'muted'}>
                      {/*TODO - temporary fix due to enum generation*/}
                      {cleanStringCase(engagementStatus as unknown as string)}
                    </Txt>
                  </Inline>
                )}
              </Inline>
            ) : null}
          </Stack>
        </div>
        {showAddToListButton && (
          <Button
            analyticsAttr={cleanAnalyticsStringList([
              accountName,
              `Add to List`,
            ])}
            className={isLargerScreen ? '' : 'h-mv-md'}
            extraAnalyticsAttr={'SAV Add to List'}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                selectedUpdateAccountsAccountGroup: accountInfo,
                showUpdateAccountsModal: true,
              }));
            }}
            rightIcon={<EditIcon />}
            text={'Manage Lists'}
            fontWeight={'bold'}
            variant={'active'}
          />
        )}
      </div>
      {showUpdateAccountsModal && selectedUpdateAccountsAccountGroup && (
        <UpdateAccountListGroupModal
          accountName={selectedUpdateAccountsAccountGroup.accountName}
          id={selectedUpdateAccountsAccountGroup.id}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              showUpdateAccountsModal: false,
            }))
          }
          showModal={showUpdateAccountsModal}
        />
      )}
    </Card>
  );
};

function formatDaysAgoWithCommaSeparation(date: Date) {
  const daysAgo = formatDistanceToNowStrict(date, { unit: 'day' });
  const [daysAgoValue] = daysAgo.split(' ');
  const formattedDaysAgo = daysAgoValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedDaysAgo} ${pluralizeDaysString(daysAgoValue)} ago`;
}

type UpdateAccountListGroupModalProps = {
  accountName?: NonNullable<ApiGetAccountQuery['account']>['accountName'];
  id: NonNullable<ApiGetAccountQuery['account']>['id'];
  onClose: () => void;
  showModal: boolean;
};

const UpdateAccountListGroupModal = ({
  accountName = '',
  id,
  onClose = () => {},
  showModal = false,
}: UpdateAccountListGroupModalProps) => {
  const apolloClient = useApolloClient();
  // setup app definitions
  const pageId = 'SingleAccountView';
  const { subtext } = getAppDefinitionsItem(
    pageId,
    'add_to_list_text',
  ) as TextDefinitionType;
  const { text: emptyText } = getAppDefinitionsItem(
    pageId,
    'add_to_list_empty_text',
  ) as TextDefinitionType;
  // setup mutations
  const [updateAccountGroupDescriptors, { loading, error }] =
    useUpdateAccountGroupAccountsMutation(updateAccountGroupAccountsCache);
  // get all account groups
  const {
    data: allAccountGroupsData,
    loading: allAccountGroupsLoading,
    refetch: refetchGroups,
  } = useGetAllAccountGroupsQuery();

  const {
    data: accountListsForAccount,
    loading: accountListsForAccountLoading,
    refetch: refetchAccountListsForAccount,
  } = useGetAccountGroupsByAccountQuery({
    variables: {
      accountId: id,
      accountGroupType: ApiAccountGroupType.List,
    },
  });

  const accountGroups = allAccountGroupsData?.accountGroups || [];
  const firebase = useFirebase();
  const klearlyUser = firebase!.klearlyUser || {};
  const { lists: filteredLists } = groupAccountGroups({
    accountGroups,
    klearlyUser: klearlyUser as KlearlyUserType,
    showOnlyLoggedInUsersGroups: true,
  });
  const lists = _sortBy(filteredLists, ['title']);
  const mapToChecked = mapAccountListsToExistingLists({
    allLists: filteredLists,
    accountLists: accountListsForAccount?.accountGroupsForAccount,
  });

  const isPageLoading =
    loading || allAccountGroupsLoading || accountListsForAccountLoading;
  const _onChange = async (checked, item) => {
    const groupWithAccounts = await apolloClient.query({
      query: GetAccountGroupDocument,
      variables: {
        id: item.id,
      },
    });
    const accountIds = groupWithAccounts.data.accountGroup.accounts.map(
      (acct) => acct.id,
    );
    const newAccountIds = checked
      ? _concat(accountIds, id)
      : // eslint-disable-next-line
        _filter(accountIds, (accountId) => accountId != id);
    // trigger update
    await updateAccountGroupDescriptors({
      variables: {
        input: { accountIds: newAccountIds, id: item.id },
      },
    });
    await Promise.all([refetchGroups(), refetchAccountListsForAccount()]);
  };
  const title = `Manage Lists for "${accountName}"`;

  return showModal ? (
    <Modal
      ariaLabel={'Manage List Modal'}
      body={
        isPageLoading ? (
          <Loading dotColor={'color'} overlay={'none'} />
        ) : (
          <form
            analytics-attr={cleanAnalyticsStringList([
              'add',
              accountName,
              'to list form',
            ])}
            className={'c-form'}
          >
            {error && (
              <div className={'c-text-field--error'}>
                <div
                  className={'c-text-field__message h-mb-md'}
                  style={{ position: 'relative' }}
                >
                  <div className={'c-text h-text-size-md h-text-align-left'}>
                    <b>{'Error updating account group lists, try again!'}</b>
                    {error}
                  </div>
                </div>
              </div>
            )}
            {!lists.length && <Txt>{emptyText}</Txt>}
            {lists.map((item) => (
              <Stack spacing={6} key={item?.id}>
                <Checkbox
                  analyticsAttr={cleanAnalyticsStringList([
                    accountName,
                    'add to account',
                    item?.id,
                  ])}
                  checked={item?.id ? mapToChecked[item.id] : false}
                  onChange={(event) => _onChange(event.target.checked, item)}
                  text={item?.title ?? ''}
                />
              </Stack>
            ))}
          </form>
        )
      }
      close={isPageLoading ? () => {} : () => onClose()}
      header={
        <div className={'h-pv-2xl'}>
          <Txt size={'lg'} text={title} weight={'bold'} />
          {!!subtext?.length && (
            <Txt as={'i'} className={'h-mt-md'} size={'md'} text={subtext} />
          )}
        </div>
      }
    />
  ) : null;
};
