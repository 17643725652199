export const EngagementLineChartYLabel = ({ x, y, height, stroke, text }) => (
  <text
    x={x}
    y={height / 2 + y}
    transform={`rotate(270 ${x} ${height / 2 + y})`}
    textAnchor='middle'
    stroke={stroke}
  >
    {text}
  </text>
);
