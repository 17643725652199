import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { buildGoogleMapsLink } from '../../../helpers/buildGoogleMapsLink';
import { getFormattedEmployeeCount } from '../../../helpers/getFormattedEmployeeCount';
import { AccountHeaderContent } from './AccountHeaderContent';
import { AccountHeaderError } from './AccountHeaderError';
import { useFirebase } from 'app/src/components/Firebase';
import { formatDateString } from 'shared/helpers/formatHelpers';

export const AccountHeader = () => {
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const { data, error } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });

  const firebase = useFirebase()!;
  const dataProcessedAt = firebase.klearlyUser?.company?.dataProcessedAt
    ? formatDateString(
        new Date(firebase.klearlyUser.company.dataProcessedAt),
        'MMM dd, yyyy',
      )
    : 'N/A';

  const accountInfo = data?.account;

  if (error) {
    return (
      <AccountHeaderError
        error={`Error fetching account data for account ${selectedAccountId}`}
      />
    );
  }

  const googleMapsLink = buildGoogleMapsLink({
    addressStreet: accountInfo?.addressStreet,
    addressCity: accountInfo?.addressCity,
    addressRegion: accountInfo?.addressRegion,
    addressZipCode: accountInfo?.addressZipCode,
  });

  const formattedEmployeeCount = getFormattedEmployeeCount(
    accountInfo?.employeeCount,
  );

  return (
    <AccountHeaderContent
      accountInfo={accountInfo}
      dataProcessedAt={dataProcessedAt}
      formattedEmployeeCount={formattedEmployeeCount}
      googleMapsLink={googleMapsLink}
    />
  );
};
