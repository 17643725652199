export enum SingleAccountSignalsTimePeriod {
  'previousQtr' = 'previousQtr',
  'currentQtr' = 'currentQtr',
  'last5Qtrs' = 'last5Qtrs',
}

export enum Granularity {
  quarterly = 'quarterly',
  monthly = 'monthly',
  daily = 'daily',
}

export enum SignalSectionUnitType {
  count = 'count',
  quantity = 'quantity',
}

export type SingleAccountSignalItem = {
  inbound: number;
  occurredAt: string;
  originalDate: string;
  outbound: number;
};
