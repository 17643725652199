import { differenceInCalendarDays } from 'date-fns';

export const getXeominVolumeStringAndWidths = ({
  currentQtrXeominTotal,
  endDate,
  prevQtrXeominTotal,
}: {
  currentQtrXeominTotal?: number;
  endDate: string;
  prevQtrXeominTotal?: number;
}) => {
  if (currentQtrXeominTotal === undefined || prevQtrXeominTotal === undefined) {
    return {
      xeominVolumeString: '',
      prevVolumeWidth: 0,
      currentVolumeWidth: 0,
    };
  }

  const xeominComparison =
    currentQtrXeominTotal > prevQtrXeominTotal
      ? 'ahead of'
      : currentQtrXeominTotal === prevQtrXeominTotal
      ? 'the same as'
      : 'trailing';

  const daysUntilEndOfQtr =
    differenceInCalendarDays(new Date(endDate), new Date()) + 1;

  const daysString =
    daysUntilEndOfQtr === 0
      ? 'There are no days left in the quarter'
      : daysUntilEndOfQtr === 1
      ? 'There is still 1 day left in the quarter.'
      : `There are still ${daysUntilEndOfQtr} days left in the quarter.`;

  const prevVolumeWidth =
    currentQtrXeominTotal >= prevQtrXeominTotal
      ? (prevQtrXeominTotal / currentQtrXeominTotal) * 150
      : 150;
  const currentVolumeWidth =
    prevQtrXeominTotal >= currentQtrXeominTotal
      ? (currentQtrXeominTotal / prevQtrXeominTotal) * 150
      : 150;

  const difference = Math.abs(currentQtrXeominTotal - prevQtrXeominTotal);
  const byString =
    difference === 0 ? '.' : ` by ${difference.toLocaleString()}.`;

  const xeominVolumeString = `Xeomin purchase qty is ${xeominComparison} the previous quarter${byString} ${daysString}`;

  return { xeominVolumeString, prevVolumeWidth, currentVolumeWidth };
};
