import { SAV_RECOMMENDATIONS_LIST } from 'app/src/constants/routes';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { RecommendationsToggleShowHideButton } from './RecommendationsToggleShowHideButton';
import { useImpactValuesTable } from './useImpactValuesTable';
import { GetImpactValuesType } from '../../../types/impactValuesTypes';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

export const PrimaryActionSection = ({
  accountInfo,
  tableHeader,
  sectionIsShowing,
  setSectionIsShowing,
}: GetImpactValuesType) => {
  const { priorityImpactLabel: impactLabel } = useImpactValuesTable(
    accountInfo,
    tableHeader,
  );

  const { text, subtext } = getAppDefinitionsItem(
    SAV_RECOMMENDATIONS_LIST.name,
    'priority_signal_header',
  ) as TextDefinitionType;
  const primaryActionNlgNarrative =
    accountInfo?.narratives?.primaryAction ??
    'No actions are recommended currently for this account.';

  return (
    <Stack spacing={4}>
      <Flex alignItems={'center'} justify={'space-between'}>
        <Flex alignItems={'center'}>
          <Flex
            align={'center'}
            borderColor={'brand.white'}
            borderRadius={'50%'}
            boxShadow={'0 1px 1px rgba(0, 0, 0, 0.25)'}
            justify={'center'}
            bg={'brand.blue'}
            height={10}
            minW={10}
            mr={6}
          >
            <Text color={'brand.white'} fontWeight={'extrabold'}>
              {impactLabel}
            </Text>
          </Flex>
          <Stack>
            <Text fontWeight={'bold'}>{text}</Text>
            <Text>{subtext}</Text>
          </Stack>
        </Flex>
        <RecommendationsToggleShowHideButton
          accountId={accountInfo?.id ?? 0}
          isShowing={sectionIsShowing}
          sectionType={'primary'}
          setIsShowing={setSectionIsShowing}
        />
      </Flex>
      {sectionIsShowing && (
        <>
          <Box
            p={4}
            style={{
              backgroundColor: 'var(--global-bg)',
              borderTop:
                '1px solid var(--row-border-color, var(--global-border-color-dim))',
            }}
          >
            <Text fontWeight={'bold'}>Recommendation</Text>
          </Box>
          <Box p={4}>
            <Text fontWeight={'bold'}>{primaryActionNlgNarrative}</Text>
          </Box>
        </>
      )}
    </Stack>
  );
};
