import { useContext, useEffect, useState } from 'react';
import * as ROUTES from 'app/src/constants/routes';
import classnames from 'classnames';
import { useDefinedMediaQuery } from 'shared/hooks/mediaQueryHook';
import { Avatar, Link } from '../../../../../shared/components/Core';
import { AccountSearch } from 'app/src/components/Navigation/components/AccountSearch';
import { NavigationProps } from '../index';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { CompanyAppStructure } from 'shared/types/companyAppStructureTypes';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { homePageKey } from 'shared/constants/homePageKey';
import { isTopLevelNavLink } from 'shared/helpers/isTopLevelNavLink';
import { useHistory } from 'react-router-dom';
import { getAvatarName } from 'app/src/components/Navigation/helpers/getAvatarName';

export const NavigationAuth = ({
  showAdminOrSuperUserRoute = false,
}: NavigationProps) => {
  const history = useHistory();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [topLevelLinks, setTopLevelLinks] = useState<
    CompanyAppStructure['pages']
  >([]);

  const { structure } = useContext(AppNavigationalStructureContext);

  useEffect(() => {
    if (structure) {
      setTopLevelLinks(
        structure.pages.filter(
          (page) =>
            page.isVisible &&
            isTopLevelNavLink(page.navStructure) &&
            page.key !== homePageKey,
        ),
      );
    }
  }, [structure]);

  const adminRoute: string = ROUTES.ADMIN[0];
  const mobileMenuIconClasses = classnames({
    'c-header__menu-icon': true,
    'is-active': showMobileMenu,
  });
  const { isSmallerViewport } = useDefinedMediaQuery();
  const toggleMobileMenu = (value: boolean) => {
    const bodyStyle = document.body.style;
    setShowMobileMenu((previousValue) => {
      const menuVisibleValue = previousValue ? 'auto' : 'hidden';
      bodyStyle.overflowY = isSmallerViewport ? menuVisibleValue : 'auto';
      return value;
    });
  };

  const demoUser = {
    firstName: 'Demo',
    lastName: 'User',
    email: 'demo@demo.com',
    company: {
      canSubmitCredentials: false,
      dataProcessedAt: new Date(),
      crmTypes: [],
    },
    companyName: 'demo',
    id: 0,
    isKlearlyAdmin: false,
    isAdmin: false,
    disabled: false,
  };

  const avatarName = getAvatarName(demoUser);

  return (
    <div className={'c-header__nav'}>
      <button
        analytics-attr={'mobile-menu-btn'}
        aria-controls={'menu'}
        aria-expanded={showMobileMenu}
        aria-haspopup={'true'}
        aria-label={'navigation menu'}
        className={mobileMenuIconClasses}
        onClick={() => toggleMobileMenu(!showMobileMenu)}
      >
        <div className={'c-menu-icon'}>
          <span className={'c-menu-icon__line'} />
        </div>
      </button>
      <nav className={'c-header__menu'}>
        {topLevelLinks.map(
          (page) =>
            isTopLevelNavLink(page.navStructure) && (
              <Link
                analytics-attr={`${page.navStructure.navbarLabel}-nav-link`}
                key={page.key}
                onClick={() => toggleMobileMenu(false)}
                secondaryText={page.navStructure.navbarSecondaryLabel}
                text={page.navStructure.navbarLabel}
                to={page.navStructure.urlPaths[0].path}
              />
            ),
        )}
        {showAdminOrSuperUserRoute && (
          <Link
            analytics-attr={'admin-nav-link'}
            onClick={() => toggleMobileMenu(false)}
            text={'Admin'}
            to={adminRoute}
          />
        )}
        <div
          className={
            'c-header__actions c-header__actions--full-height h-flex h-flex-justify-content-end'
          }
          style={{ width: '50%' }}
        >
          <div className={'c-header__action'} style={{ width: '40%' }}>
            <AccountSearch accountClicked={() => toggleMobileMenu(false)} />
          </div>
          <Menu>
            {({}) => (
              <>
                <MenuButton>
                  <Flex align={'center'}>
                    <Avatar ml={4} text={avatarName} />
                    <ChevronDownIcon ml={2} fontSize={20} />
                  </Flex>
                </MenuButton>
                <MenuList pt={4} pr={4}>
                  <Flex align={'center'}>
                    <Avatar ml={4} text={avatarName} />
                    <Box ml={2}>
                      <Text
                        fontWeight={'bold'}
                      >{`${demoUser?.firstName} ${demoUser?.lastName} `}</Text>
                      <Text>{demoUser?.email}</Text>
                    </Box>
                  </Flex>
                  {demoUser?.isKlearlyAdmin && (
                    <>
                      <Text ml={4} mt={2} fontWeight={'bold'}>
                        Currently viewing:{' '}
                        <Text fontWeight={'normal'} ml={2}>
                          {demoUser.companyName}
                        </Text>
                      </Text>
                    </>
                  )}
                  <MenuItem
                    analytics-attr={'sign-out-nav-btn'}
                    bg={'brand.gray-200'}
                    borderRadius={8}
                    color={'brand.black'}
                    _hover={{ bg: 'brand.crimson', color: 'brand.white' }}
                    m={4}
                    maxW={24}
                    onClick={() => history.push('/login')}
                  >
                    <Text fontSize={'lg'} textAlign={'center'}>
                      Sign Out
                    </Text>
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </div>
      </nav>
    </div>
  );
};
