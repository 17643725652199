import {ApiAccountCustomerStatus} from 'shared/graphql/generatedApiTypes';
import {DottedGrayCircle} from '../../components/Core/Icon/dottedGrayCircle';
import {StatusCircleGradient} from '../../components/Core/Icon/statusCircleGradient';
import {GreenCircleIcon} from '../../components/Core/GreenCircleIcon';

export const getStatusCircleIcon = (value: ApiAccountCustomerStatus) => {
  switch (value) {
    case ApiAccountCustomerStatus.Customer:
      return <GreenCircleIcon data-testid={'green-circle-icon'} />;
    case ApiAccountCustomerStatus.PastCustomer:
      return <DottedGrayCircle data-testid={'dotted-gray-circle-icon'} />;
    case ApiAccountCustomerStatus.Prospect:
      return <StatusCircleGradient data-testid={'circle-gradient-icon'} />;
    default:
      return null;
  }
};
