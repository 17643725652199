import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _size from 'lodash/size';
import { MAV_PRIORITY_SIGNAL_RECOMMENDATIONS_LIST } from 'app/src/constants/routes';
import { Button, Card, Inline } from '../../../../../../shared/components/Core';
import AccountsTable from './AccountsTable';
import ClosedAccounts from './ClosedAccounts';
import SectionHeader from '../../../../../../shared/components/Core/SectionHeader';
import { FilterIndices } from '../../../GlobalFilters/types/filterTypes';
import { ApolloError } from '@apollo/client';
import { AddIcon, DownloadIcon } from '@chakra-ui/icons';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';
import { cleanStringCase, numberFormatter } from 'shared/helpers/formatHelpers';

type AccountsDataType = Array<{
  accountName: string;
  annualRevenue: number;
  crmAccountId: string;
  employeeCount: number;
  goalValues: Array<{
    isPriority: boolean;
    lift: number;
  }>;
  id: number;
}>;

type AccountsProps = {
  accountsData: AccountsDataType;
  dataError?: ApolloError;
  isFilterApplied: boolean;
};

const Accounts = ({
  accountsData = [],
  dataError = undefined,
  isFilterApplied = false,
}: AccountsProps) => {
  const [isListCreationModalShowing, setIsListCreationModalShowing] =
    useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([]);

  const csvLinkRef = useRef<{ link: { click: () => void } }>(null);

  const { subtext: headerSubtext, text: headerText } = getAppDefinitionsItem(
    MAV_PRIORITY_SIGNAL_RECOMMENDATIONS_LIST.name,
    'accounts_header',
  ) as TextDefinitionType;

  const areButtonsDisabled = _size(selectedRowIds) === 0;
  const csvFilenamePrefix = new Date()
    .toLocaleDateString('en-US')
    .replace('/', '')
    .replace('/', '');
  const csvHeaders = [
    { key: 'accountCRMId', label: 'CRM Account ID' },
    { key: 'accountName', label: 'Account Name' },
    {
      key: FilterIndices.CURRENT_WIN_PROBABILITY,
      label: 'Current Account Score',
    },
    {
      key: `primary_${FilterIndices.LIFT}`,
      label: 'Primary Action Winfluence',
    },
    { key: `all_${FilterIndices.LIFT}`, label: 'All Actions Winfluence' },
    { key: FilterIndices.INDUSTRY, label: 'Industry' },
    { key: FilterIndices.REVENUES_FILTER, label: 'Revenue' },
    { key: FilterIndices.EMPLOYEE_COUNT_FILTER, label: 'Size' },
  ];
  const csvData = areButtonsDisabled
    ? []
    : _map(selectedRowIds, (accountId) => {
        const selectedAccount = _find(accountsData, ['id', accountId]);
        const nonPriority = _find(selectedAccount?.goalValues, {
          isPriority: false,
        })?.lift;
        const priority = _find(selectedAccount?.goalValues, {
          isPriority: true,
        })?.lift;
        return {
          accountCRMId: selectedAccount?.['crmAccountId'],
          accountName: selectedAccount?.['accountName'],
          [FilterIndices.CURRENT_WIN_PROBABILITY]:
            selectedAccount?.[FilterIndices.CURRENT_WIN_PROBABILITY],
          [`primary_${FilterIndices.LIFT}`]: priority,
          [`all_${FilterIndices.LIFT}`]: nonPriority,
          [FilterIndices.INDUSTRY]: selectedAccount?.[FilterIndices.INDUSTRY]
            ? cleanStringCase(selectedAccount?.[FilterIndices.INDUSTRY])
            : '-',
          [FilterIndices.REVENUES_FILTER]: numberFormatter(
            selectedAccount?.[FilterIndices.REVENUES_FILTER],
            true,
          ),
          [FilterIndices.EMPLOYEE_COUNT_FILTER]: numberFormatter(
            selectedAccount?.[FilterIndices.EMPLOYEE_COUNT_FILTER],
            false,
          ),
        };
      });
  return (
    <>
      <SectionHeader
        addHeaderLeftMargin
        rightComponent={
          <Inline gap={'md'}>
            <CSVLink
              className={'hidden'}
              data={csvData}
              filename={`${csvFilenamePrefix}_accounts.csv`}
              headers={csvHeaders}
              ref={csvLinkRef}
              target={'_blank'}
            />
            <Button
              analyticsAttr={'create-list'}
              fontProps={{ fontSize: 'sm' }}
              isDisabled={areButtonsDisabled}
              leftIcon={<AddIcon />}
              onClick={() => setIsListCreationModalShowing(true)}
              text={'Create List'}
              variant={'active'}
            />
            <Button
              analyticsAttr={'Export Account Data'}
              fontProps={{ fontSize: 'sm' }}
              isDisabled={areButtonsDisabled}
              leftIcon={<DownloadIcon />}
              onClick={() => csvLinkRef?.current?.link?.click()}
              text={'Export Account Data'}
              variant={'mutedOutline'}
            />
          </Inline>
        }
        showBackgroundColor={false}
        showFilterIcon={isFilterApplied}
        subtitle={headerSubtext}
        title={headerText}
      />
      <AccountsTable
        accounts={dataError ? [] : accountsData}
        isListCreationModalShowing={isListCreationModalShowing}
        selectedRowIds={selectedRowIds}
        setIsListCreationModalShowing={setIsListCreationModalShowing}
        setSelectedRowIds={(rowIds) => setSelectedRowIds(rowIds)}
      />
    </>
  );
};

export const AccountsWithClosedOpps = ({
  accountsWithClosedOpps,
}: {
  accountsWithClosedOpps: Array<{}>;
}) => {
  return !!accountsWithClosedOpps.length ? (
    <Card minH={30}>
      <ClosedAccounts
        accounts={accountsWithClosedOpps}
        title={'Accounts w/Closed Opps'}
      />
    </Card>
  ) : null;
};

export const AccountsWithoutSignals = ({
  accountsWithoutSignals,
}: {
  accountsWithoutSignals: Array<{}>;
}) => {
  return !!accountsWithoutSignals.length ? (
    <Card minH={30}>
      <ClosedAccounts
        accounts={accountsWithoutSignals}
        title={'Accounts w/o Signals'}
      />
    </Card>
  ) : null;
};

export default Accounts;
