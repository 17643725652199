import {CalendarRangePreviousFilterItem} from './CalendarRangePreviousFilterItem';
import {CalendarRangeBetweenFilterItem} from './CalendarRangeBetweenFilterItem';
import {CalendarRangeFilterItemProps, CalendarRangeState,} from '../../../types/calendarTypes';
import {RadioInput} from '../../../../../../../shared/components/Core';
import {OptionsType} from 'shared/types/coreTypes.d';
import {useInput, UseInputHookReturnType, UseInputHookValueStringType,} from 'shared/hooks/inputHook';
import {calendarRangeStateOptions} from '../../../constants/calendarFilterItemOptions';
import classnames from 'classnames';
import {useEffect} from 'react';

type InputBetweenPreviousTypes = {
  setValue: (value: UseInputHookValueStringType) => void;
  value: CalendarRangeState;
};

export const CalendarRangeWrapper = (props: CalendarRangeFilterItemProps) => {
  const {
    bind: bindState,
    setValue,
    value: radioState,
  } = useInput(CalendarRangeState.BETWEEN, {
    defaultValue: CalendarRangeState.BETWEEN,
    id: 'state',
    name: 'state',
    options: calendarRangeStateOptions,
  }) as UseInputHookReturnType & InputBetweenPreviousTypes;

  const rangeWrapperClassnames = classnames({
    'calendar-range-wrapper': true,
    'calendar-range-wrapper__between':
      radioState === CalendarRangeState.BETWEEN,
  });

  // force the initial animated accordion render to calculate with the "between" height and then immediately queue up
  // defaulting it to previous on mount. This allows the max scroll height to be the full calendar height
  useEffect(() => {
    setTimeout(() => setValue(CalendarRangeState.PREVIOUS), 0);
  }, [setValue]);

  return (
    <div className={rangeWrapperClassnames}>
      <RadioInput
        {...bindState}
        analytics-attr={'between-or-previous-calendar-radio-button'}
        className={'h-pb-none'}
        horizontal
        options={bindState.options as OptionsType[]}
        value={(radioState as string) ?? CalendarRangeState.PREVIOUS}
      />
      {(radioState as string) === CalendarRangeState.BETWEEN ? (
        <CalendarRangeBetweenFilterItem {...props} />
      ) : (
        <CalendarRangePreviousFilterItem {...props} />
      )}
    </div>
  );
};
