import {
  StructuredPage,
  TopLevelNavStructure,
} from 'shared/types/companyAppStructureTypes';

export const isTopLevelNavLink = (
  pageNavStructure: StructuredPage['navStructure'],
): pageNavStructure is TopLevelNavStructure => {
  return (
    (pageNavStructure as TopLevelNavStructure).urlParent === null ||
    (pageNavStructure as TopLevelNavStructure).urlParent === undefined ||
    (pageNavStructure as TopLevelNavStructure).urlParent === ''
  );
};
