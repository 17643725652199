import {
  CompanyAppStructure,
  ComponentType,
} from 'shared/types/companyAppStructureTypes';
import { SectionComponents } from 'shared/types/sectionComponents';

export const appStructure: CompanyAppStructure = {
  fiscalYearStartingMonth: 0,
  pages: [
    {
      key: 'signals',
      firstComponentFullWidth: false,
      globalFiltersVisible: false,
      components: [
        {
          componentType: ComponentType.fullWidth,
          component: {
            key: SectionComponents.EngagementStatus,
            data: {},
          },
        },
        {
          componentType: ComponentType.fullWidth,
          component: {
            key: SectionComponents.AggregateSignalHistory,
            data: {},
          },
        },
        // {
        //   componentType: ComponentType.fullWidth,
        //   component: {
        //     key: SectionComponents.RecommendedActionsTable,
        //     data: {},
        //   },
        // },
      ],
      browserTabTitle: 'Home',
      isVisible: true,
      navStructure: {
        urlParent: null,
        urlPaths: [{ path: '/', param: '' }],
        navbarLabel: '',
        navbarSecondaryLabel: '',
      },
    },
  ],
};
