import {
  ApiAggregateString,
  ApiGetAggregateAccountsDataWithoutEngagementStatusQuery,
} from 'shared/graphql/generatedApiTypes';
import { EngagementStatusItemType } from '../types/homeTypes';
import { HOME_ENGAGEMENT_STATUS } from 'app/src/constants/routes';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

const convertEngagementStatusNameToLabel = (name: string) => {
  switch (name) {
    case 'pending':
      return 'not_engaged';
    case 'active':
      return 'recently_engaged';
    case 'lapsing':
      return 'previously_engaged';
    case 'lapsed':
      return 'previously_engaged';
    case 'inactive':
      return 'previously_engaged';
    default:
      return '';
  }
};

export const mapEngagementStatusDataToChart = (
  aggregateAccountsQuery:
    | ApiGetAggregateAccountsDataWithoutEngagementStatusQuery
    | undefined,
): EngagementStatusItemType[] => {
  if (
    !aggregateAccountsQuery ||
    !aggregateAccountsQuery.aggregateAccountsData ||
    !aggregateAccountsQuery.aggregateAccountsData.engagementStatus?.length
  ) {
    return [];
  }

  // build the initial array to handle the sort
  const sortedEngagementStatusArr = [
    {
      label: 'recently_engaged',
      x: 'active',
      y: 0,
      maxYValue: 0,
      tooltip: '',
    },
    {
      label: 'previously_engaged',
      x: 'lapsing',
      y: 0,
      maxYValue: 0,
      tooltip: '',
    },
    {
      label: 'previously_engaged',
      x: 'lapsed',
      y: 0,
      maxYValue: 0,
      tooltip: '',
    },
    {
      label: 'previously_engaged',
      x: 'inactive',
      y: 0,
      maxYValue: 0,
      tooltip: '',
    },
    {
      label: 'not_engaged',
      x: 'pending',
      y: 0,
      maxYValue: 0,
      tooltip: '',
    },
  ];

  aggregateAccountsQuery.aggregateAccountsData.engagementStatus.forEach(
    (item) => {
      if (!item) return;
      // can now cast because we did a null check above
      const { name, count } = item as NonNullable<ApiAggregateString>;
      const { tooltip } = getAppDefinitionsItem(
        HOME_ENGAGEMENT_STATUS.name,
        name,
      ) as TextDefinitionType;
      const correctedItem = {
        label: convertEngagementStatusNameToLabel(name),
        x: name,
        y: count,
        maxYValue: count,
        tooltip: tooltip ?? '',
      };
      const indexToInsert = sortedEngagementStatusArr.findIndex(
        (item) => item.x === correctedItem.x,
      );
      sortedEngagementStatusArr[indexToInsert] = correctedItem;
    },
  );
  return sortedEngagementStatusArr;
};
