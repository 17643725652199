import {makeVar} from '@apollo/client';
import {FilterDictType} from '../components/GlobalFilters/types/filterDict';
import {FilterIndices} from '../components/GlobalFilters/types/filterTypes';
import {LabCardApiResponse} from '../components/Lab/types/labApiTypes';
import {filterStringFromBackendToDict} from '../components/GlobalFilters/helpers/filterDictAndFilterStringHelpers';
import {OptionsType} from 'shared/types/coreTypes.d';

export type InitialApolloState = {
  appliedFiltersHeight: number;
  appliedFiltersWidth: number;
  filterItemsLoading: boolean;
  filterPanelHeight: number;
  globalFilter: (FilterDictType & { accounts: OptionsType[] }) | null;
  loadedLabs: { [key: string]: LabCardApiResponse };
  showingFilterPanel: boolean;
  token: string;
};

// default global state outside of components
export const initialApolloState: InitialApolloState = {
  appliedFiltersHeight: 0,
  appliedFiltersWidth: 0,
  filterItemsLoading: false,
  filterPanelHeight: 0,
  globalFilter: null,
  loadedLabs: {},
  showingFilterPanel: false,
  token: '',
};

// accessible from inside components so that we can pass company-specific structure
export const getInitialApolloState = (defaultFilter: string) => {
  return {
    ...initialApolloState,
    globalFilter: {
      ...filterStringFromBackendToDict(defaultFilter),
      [FilterIndices.ACCOUNTS_FILTER_TYPE]:
        FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
      accounts: [],
    },
  };
};

export const appliedFiltersHeightVar = makeVar<
  InitialApolloState['appliedFiltersHeight']
>(initialApolloState.appliedFiltersHeight);
export const appliedFiltersWidthVar = makeVar<
  InitialApolloState['appliedFiltersWidth']
>(initialApolloState.appliedFiltersWidth);
export const globalFilterVar = makeVar<InitialApolloState['globalFilter']>(
  initialApolloState.globalFilter,
);
export const filterItemsLoadingVar = makeVar<
  InitialApolloState['filterItemsLoading']
>(initialApolloState.filterItemsLoading);
export const filterPanelHeightVar = makeVar<
  InitialApolloState['filterPanelHeight']
>(initialApolloState.filterPanelHeight);
export const loadedLabsVar = makeVar<InitialApolloState['loadedLabs']>(
  initialApolloState.loadedLabs,
);
export const showingFilterPanelVar = makeVar<
  InitialApolloState['showingFilterPanel']
>(initialApolloState.showingFilterPanel);
export const tokenVar = makeVar<InitialApolloState['token']>(
  initialApolloState.token,
);
