import { StructuredComponentRenderer } from '../../../../shared/renderers/ComponentRenderer';
import { useContext, useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { globalFilterVar } from '../../apollo/rootReactiveVariables';
import { AppNavigationalStructureContext } from '../../context/AppNavigationalStructureContext';
import { Box } from '@chakra-ui/react';
import { PageWrapper } from './PageWrapper';
import { merzGlobalFiltersStructure } from '../../constants/merzGlobalFiltersStructure';
import { useFirebase } from '../Firebase';
import { crownpeakGlobalFiltersStructure } from '../../constants/crownpeakGlobalFiltersStructure';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';

export const RootPage = ({ pageKey }: { pageKey: string }) => {
  useReactiveVar(globalFilterVar);
  const { structure } = useContext(AppNavigationalStructureContext);
  const selectedPage = structure?.pages.find((page) => pageKey === page.key);
  const components = useMemo(() => selectedPage?.components, [selectedPage]);
  const globalFiltersVisible = !!selectedPage?.globalFiltersVisible;
  // const globalFiltersStructure = selectedPage?.globalFiltersStructure;
  const { klearlyUser } = useFirebase()!;
  const companyName = klearlyUser?.companyName;
  const globalFiltersStructure =
    companyName === 'merz'
      ? merzGlobalFiltersStructure
      : crownpeakGlobalFiltersStructure;
  const browserTabTitle = selectedPage?.browserTabTitle;
  const firstComponentFullWidth = selectedPage?.firstComponentFullWidth;

  useEffect(() => {
    if (browserTabTitle) {
      updateAppHeaders(browserTabTitle);
    }
  }, [browserTabTitle]);

  return firstComponentFullWidth && components ? (
    <>
      {StructuredComponentRenderer(components[0])}
      <PageWrapper
        globalFiltersVisible={globalFiltersVisible}
        globalFiltersStructure={globalFiltersStructure}
      >
        {components?.map(
          (component, index) =>
            index > 0 && (
              <Box key={index}>{StructuredComponentRenderer(component)}</Box>
            ),
        )}
      </PageWrapper>
    </>
  ) : (
    <PageWrapper
      globalFiltersVisible={globalFiltersVisible}
      globalFiltersStructure={globalFiltersStructure}
    >
      {components?.map((component, index) => (
        <Box key={index}>{StructuredComponentRenderer(component)}</Box>
      ))}
    </PageWrapper>
  );
};
