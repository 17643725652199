import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { BindMinMaxOptionsType } from '../../../types/minToMaxFilterItemProps';
import { RangeSliderThumb, Text } from '@chakra-ui/react';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';

export type RangeThumbProps = AnalyticsAttrType & {
  index: number;
  initialFilterItem: BindMinMaxOptionsType;
  isHundredPointScale: boolean;
  numberOfTicks: number;
  value: number;
};

export const RangeThumb = ({
  analyticsAttr,
  index,
  value,
  numberOfTicks,
  isHundredPointScale,
  initialFilterItem,
}: RangeThumbProps) => (
  <RangeSliderThumb
    analytics-attr={cleanAnalyticsStringList([analyticsAttr])}
    bg={'brand.lightBlue'}
    borderColor={'brand.gray-600'}
    borderWidth={1}
    boxSize={10}
    color={'brand.white'}
    index={index}
    key={index}
    mt={-10}
  >
    <Text fontSize={'xs'}>
      {value === numberOfTicks
        ? isHundredPointScale
          ? '100'
          : 'Max'
        : initialFilterItem[value] && initialFilterItem[value].label}
    </Text>
  </RangeSliderThumb>
);
