export const getAccountSearchStyles = () => {
  return {
    dropdownIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => null,
    menu: (styles) => ({
      ...styles,
      fontSize: '13px',
      textAlign: 'left',
    }),
    container: (styles) => ({
      ...styles,
      zIndex: 1,
    }),
  };
};
