import { ChangeEvent, CSSProperties, useContext } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import _size from 'lodash/size';

import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';

import { Tooltip, Txt } from './index';
import {
  AnalyticsAttrType,
  ClassNameType,
  OptionsType,
} from 'shared/types/coreTypes.d';
import Checkbox from './Checkbox';
import { AggregateAccountsDataContext } from 'app/src/context/AggregateAccountsDataContext';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { Text } from '@chakra-ui/react';

export type OnChangeSelectChangeEventType = ChangeEvent<HTMLSelectElement>;

type CustomSelectType = AnalyticsAttrType &
  ClassNameType & {
    disabled?: boolean;
    extraFieldSetStyles?: CSSProperties;
    filterIndex: FilterIndices;
    id?: string;
    isFilterBar?: boolean;
    label?: string;
    labelTooltip?: string;
    options?: Array<OptionsType>;
  };

const MultiSelectCheckbox = ({
  analyticsAttr = undefined,
  className = undefined,
  disabled,
  extraFieldSetStyles = {},
  filterIndex,
  id = 'select',
  isFilterBar = false,
  label,
  labelTooltip,
  options,
}: CustomSelectType) => {
  const { globalFilter, updateFilter } = useContext(
    AggregateAccountsDataContext,
  );

  const fieldClass = classnames({
    'c-text-field': true,
    'h-m-md': true,
    'c-text-field--disabled': disabled,
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });

  const selectAnalyticsAttr = cleanAnalyticsStringList([
    `analytics attr`,
    analyticsAttr,
    'select-control',
  ]);
  const selectClassNames = classnames({
    'c-text-field__input': true,
    [selectAnalyticsAttr]: _size(selectAnalyticsAttr) > 0,
  });

  const handleChange = (newValue: any) => {
    updateFilter({
      index: filterIndex,
      value: newValue,
    });
  };
  if (!globalFilter) {
    return null;
  }
  return (
    <fieldset
      className={fieldClass}
      disabled={disabled}
      style={{ ...extraFieldSetStyles }}
    >
      <label className={'c-text-field__wrapper'} htmlFor={id}>
        <div className={'c-text-field__label'} style={{ width: '200px' }}>
          {labelTooltip && labelTooltip.length > 0 ? (
            <Tooltip
              analyticsAttr={cleanAnalyticsStringList([
                analyticsAttr,
                'tooltip',
              ])}
              content={labelTooltip}
            >
              <Text fontWeight={'bold'}>{label}</Text>
            </Tooltip>
          ) : (
            <Txt size={'md'} weight={'bold'}>
              {label}
            </Txt>
          )}
        </div>
        <div className={'c-text-field__input-block'}>
          {disabled && <div className={'h-disabled-overlay'} />}
          {options && isFilterBar ? (
            <div className={'h-relative'}>
              <Select
                className={selectClassNames}
                id={id}
                isDisabled={disabled}
                isMulti={true}
                name={id}
                options={options}
                styles={{
                  container: (styles) => ({ ...styles, display: 'none' }),
                }}
                value={null}
              />
              <div className={'c-grid c-grid--cols c-grid--cols-2'}>
                {options.map((option) => {
                  const checked =
                    globalFilter[filterIndex] &&
                    !!globalFilter[filterIndex].find(
                      (selected) => selected === option.value,
                    );
                  return (
                    <Checkbox
                      checked={checked}
                      key={option.value}
                      onChange={() =>
                        checked
                          ? handleChange(
                              globalFilter[filterIndex].filter(
                                (opt) => opt !== option.value,
                              ),
                            )
                          : globalFilter[filterIndex]
                          ? handleChange([
                              ...globalFilter[filterIndex],
                              option.value,
                            ])
                          : handleChange([option.value])
                      }
                      text={option.label}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </label>
    </fieldset>
  );
};

export default MultiSelectCheckbox;
