import { add } from 'date-fns';
import { getFormattedAddressString } from 'app/src/components/Account/helpers/getFormattedAddressString';
import {
  cleanStringCase,
  formatAsPercentage,
  formatDateString,
  numberFormatter,
  truncateString,
} from 'shared/helpers/formatHelpers';
import { getTimezoneOffset } from 'shared/helpers/dateTimeHelpers';

export enum DataFieldFormat {
  PERCENTAGE = 'percentage',
  TEXT = 'text',
  UNFORMATTEDTEXT = 'unformattedText',
  FINANCIAL = 'financial',
  DATE = 'date',
  ADDRESS_LINE2 = 'address',
  NUMBER_WITH_SUFFIX = 'number',
  NUMBER_TO_TENTHS = 'numberToTenths',
  NUMBER_TO_HUNDREDTHS = 'numberToHundredths',
  NUMBER_TO_THOUSANDTHS = 'numberToThousandths',
}

export const DataFieldRenderer = (
  value: any,
  format: DataFieldFormat,
  truncateAt?: number,
): string => {
  if (value === undefined || value === null || value === 'none') {
    return '';
  }
  switch (format) {
    case DataFieldFormat.TEXT:
      return truncateAt
        ? truncateString(cleanStringCase(value.toLocaleString()), truncateAt)
        : cleanStringCase(value.toLocaleString());
    case DataFieldFormat.UNFORMATTEDTEXT:
      return truncateAt
        ? truncateString(value.toString(), truncateAt)
        : value.toString();
    case DataFieldFormat.DATE:
      if (value.occurredAt) {
        const parsedDate = new Date(value.occurredAt);
        const localDate = add(parsedDate, { minutes: getTimezoneOffset() });
        return formatDateString(localDate.toDateString(), 'PP');
      }
      return formatDateString(new Date(value).toDateString(), 'PP');
    case DataFieldFormat.FINANCIAL:
      return numberFormatter(value);
    case DataFieldFormat.PERCENTAGE:
      return formatAsPercentage(value.toString(), { precision: 0 });
    case DataFieldFormat.ADDRESS_LINE2:
      return getFormattedAddressString(value);
    case DataFieldFormat.NUMBER_WITH_SUFFIX:
      return numberFormatter(value, false);
    case DataFieldFormat.NUMBER_TO_TENTHS:
      return Number(parseFloat(value.toFixed(1))).toLocaleString();
    case DataFieldFormat.NUMBER_TO_HUNDREDTHS:
      return Number(parseFloat(value.toFixed(2))).toLocaleString();
    case DataFieldFormat.NUMBER_TO_THOUSANDTHS:
      return Number(parseFloat(value.toFixed(3))).toLocaleString();
    default:
      return value.toString();
  }
};
