import {createContext, useEffect, useState} from 'react';
import {CompanyAppStructure} from 'shared/types/companyAppStructureTypes';

export const AppNavigationalStructureContext = createContext<{
  structure: CompanyAppStructure | null;
}>({
  structure: null,
});

export const AppNavigationalStructureContextProvider = ({
  children,
  topLevelStructure,
}) => {
  const [structure, setStructure] = useState<any | null>(null);

  useEffect(() => {
    setStructure(topLevelStructure);
  }, [topLevelStructure]);
  return (
    <AppNavigationalStructureContext.Provider
      value={{
        structure,
      }}
    >
      {children}
    </AppNavigationalStructureContext.Provider>
  );
};
