import {
  add,
  addMinutes,
  addMonths,
  differenceInCalendarDays,
  differenceInMonths,
  getDayOfYear,
  getDaysInMonth,
  getDaysInYear,
  isBefore,
  sub,
} from 'date-fns';
import {
  Granularity,
  SignalSectionUnitType,
  SingleAccountSignalItem,
  SingleAccountSignalsTimePeriod,
} from '../types/SingleAccountSignals';
import { SignalDirections, SignalSources } from '../../Home/types/homeTypes';
import { SingleAccountSignalReducerState } from '../reducers/singleAccountSignalReducer';
import { formatDateString } from 'shared/helpers/formatHelpers';
import { getTimezoneOffset } from 'shared/helpers/dateTimeHelpers';

export const mapDropdownTimeInputToDates = (
  timeInput: SingleAccountSignalsTimePeriod,
  fiscalYearStartingMonth: number,
): { startDate: string; endDate: string } => {
  const quarters = buildQuarters(fiscalYearStartingMonth);

  switch (timeInput) {
    case SingleAccountSignalsTimePeriod.currentQtr:
      return calculateCurrentQuarter(quarters);
    case SingleAccountSignalsTimePeriod.previousQtr:
      return calculatePreviousQuarter(quarters);
    case SingleAccountSignalsTimePeriod.last5Qtrs:
      return calculateLast5Quarters(quarters);
  }
};

export const calculateCurrentQuarter = (quarters: {
  [key: number]: number[];
}) => {
  const { currentDay, firstDayOfQuarter, lastDayOfQuarter } =
    getBaseQuarterCalcValues(quarters);
  const startDateDiff = currentDay - firstDayOfQuarter;
  const endDateDiff = lastDayOfQuarter - currentDay;
  return {
    startDate: formatDateString(sub(new Date(), { days: startDateDiff })),
    endDate: formatDateString(add(new Date(), { days: endDateDiff })),
  };
};

export const calculatePreviousQuarter = (quarters: {
  [key: number]: number[];
}) => {
  const { currentDay, currentYear, currentQuarterKey } =
    getBaseQuarterCalcValues(quarters);
  const pastQuarterKey =
    parseInt(currentQuarterKey) - 1 < 1 ? 4 : parseInt(currentQuarterKey) - 1;
  const pastQuarterValues = quarters[pastQuarterKey];
  const firstDayOfQuarter = getDayOfYear(
    new Date(currentYear, pastQuarterValues[0], 1, 0, 0, 0),
  );
  const lastDayOfQuarter = getDayOfYear(
    new Date(
      currentYear,
      pastQuarterValues[2],
      getDaysInMonth(new Date(currentYear, pastQuarterValues[2])),
      0,
      0,
      0,
    ),
  );
  const startDateDiff = currentDay - firstDayOfQuarter;
  const endDateDiff = lastDayOfQuarter - currentDay;
  return {
    startDate: formatDateString(sub(new Date(), { days: startDateDiff })),
    endDate: formatDateString(add(new Date(), { days: endDateDiff })),
  };
};

export const calculateLast5Quarters = (quarters: {
  [key: number]: number[];
}) => {
  const { currentDay, currentYear, firstDayOfQuarter, lastDayOfQuarter } =
    getBaseQuarterCalcValues(quarters);
  const daysInThisYear = getDaysInYear(new Date(currentYear, 0));
  const startDateDiff = daysInThisYear + currentDay - firstDayOfQuarter;
  const endDateDiff = lastDayOfQuarter - currentDay;
  return {
    startDate: formatDateString(
      sub(new Date(), { days: startDateDiff }).toDateString(),
    ),
    endDate: formatDateString(
      add(new Date(), {
        days: endDateDiff,
        years: endDateDiff < 0 ? 1 : 0,
      }).toDateString(),
    ),
  };
};

export const buildQuarters = (fiscalYearStartingMonth: number) => {
  // set it to january (0) if it's outside month boundaries
  if (fiscalYearStartingMonth < 0 || fiscalYearStartingMonth > 11) {
    fiscalYearStartingMonth = 0;
  }
  return {
    1: [
      fiscalYearStartingMonth,
      fiscalYearStartingMonth + 1 > 11
        ? fiscalYearStartingMonth - 11
        : fiscalYearStartingMonth + 1,
      fiscalYearStartingMonth + 2 > 11
        ? fiscalYearStartingMonth - 10
        : fiscalYearStartingMonth + 2,
    ],
    2: [
      fiscalYearStartingMonth + 3 > 11
        ? fiscalYearStartingMonth - 9
        : fiscalYearStartingMonth + 3,
      fiscalYearStartingMonth + 4 > 11
        ? fiscalYearStartingMonth - 8
        : fiscalYearStartingMonth + 4,
      fiscalYearStartingMonth + 5 > 11
        ? fiscalYearStartingMonth - 7
        : fiscalYearStartingMonth + 5,
    ],
    3: [
      fiscalYearStartingMonth + 6 > 11
        ? fiscalYearStartingMonth - 6
        : fiscalYearStartingMonth + 6,
      fiscalYearStartingMonth + 7 > 11
        ? fiscalYearStartingMonth - 5
        : fiscalYearStartingMonth + 7,
      fiscalYearStartingMonth + 8 > 11
        ? fiscalYearStartingMonth - 4
        : fiscalYearStartingMonth + 8,
    ],
    4: [
      fiscalYearStartingMonth + 9 > 11
        ? fiscalYearStartingMonth - 3
        : fiscalYearStartingMonth + 9,
      fiscalYearStartingMonth + 10 > 11
        ? fiscalYearStartingMonth - 2
        : fiscalYearStartingMonth + 10,
      fiscalYearStartingMonth + 11 > 11
        ? fiscalYearStartingMonth - 1
        : fiscalYearStartingMonth + 11,
    ],
  };
};

export const getBaseQuarterCalcValues = (quarters: {
  [key: number]: number[];
}) => {
  const currentDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes() + getTimezoneOffset(),
  );
  const currentDay = getDayOfYear(currentDate);
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentQuarterKey = Object.keys(quarters).find(
    (quarter) =>
      quarters[quarter].find((value) => value === currentMonth) !== undefined,
  )!;
  const currentQuarterValues = quarters[currentQuarterKey];
  const firstDayOfQuarter = getDayOfYear(
    new Date(
      currentYear,
      parseInt(currentQuarterValues[0]),
      1,
      0,
      getTimezoneOffset(),
      0,
    ),
  );

  const lastDayOfQuarter = getDayOfYear(
    new Date(
      currentYear,
      parseInt(currentQuarterValues[2]),
      getDaysInMonth(new Date(currentYear, parseInt(currentQuarterValues[2]))),
      0,
      getTimezoneOffset(),
      0,
    ),
  );
  return {
    currentDay,
    currentYear,
    currentQuarterKey,
    firstDayOfQuarter,
    lastDayOfQuarter,
  };
};
const transformFullDataToAggregatedData = ({
  fullData,
  currentDirection,
  currentTypeDetail,
  currentType,
  currentSource,
  currentGranularity,
  currentStartDate,
  currentEndDate,
  currentUnitType,
  fiscalYearStartingMonth,
}: SingleAccountSignalReducerState) => {
  if (!currentStartDate || !currentEndDate) {
    return [];
  }

  const countedData = fullData ? [...fullData] : [];
  // always filter out typeDetail === return for now
  let filteredOutReturnData = countedData.filter((item) => {
    if (item) {
      return item.typeDetail !== 'return';
    }
    return item;
  });

  // if we're just using the count, then set all the quantities to 1
  if (currentUnitType === SignalSectionUnitType.count) {
    filteredOutReturnData = filteredOutReturnData.map((item) => {
      if (item) {
        return {
          ...item,
          quantity: 1,
        };
      }
      return item;
    });
  }

  // filter out direction, source, type, type detail, and dates outside of the range
  const filteredData = filteredOutReturnData.filter((item) => {
    let usingThisItem = true;
    if (
      (currentDirection === SignalDirections.Inbound &&
        item?.direction !== 'response') ||
      (currentDirection === SignalDirections.Outbound &&
        item?.direction !== 'activity')
    ) {
      usingThisItem = false;
    }
    if (currentSource !== SignalSources.All && currentSource !== item?.source) {
      usingThisItem = false;
    }
    if (currentType && currentType !== item?.type) {
      usingThisItem = false;
    }
    if (currentTypeDetail && currentTypeDetail !== item?.typeDetail) {
      usingThisItem = false;
    }
    if (
      currentStartDate &&
      isBefore(new Date(currentEndDate), new Date(item?.occurredAt))
    ) {
      usingThisItem = false;
    }
    if (
      currentEndDate &&
      isBefore(new Date(item?.occurredAt), new Date(currentStartDate))
    ) {
      usingThisItem = false;
    }
    return usingThisItem;
  });
  let aggregatedData = {};

  const currentStartAsDate = addMinutes(
    new Date(currentStartDate),
    new Date().getTimezoneOffset(),
  );
  const currentEndAsDate = addMinutes(
    new Date(currentEndDate),
    new Date().getTimezoneOffset(),
  );

  // aggregate it by granularity
  switch (currentGranularity) {
    case Granularity.daily:
      aggregatedData = getDailyAggregatedData(
        currentEndAsDate,
        currentStartAsDate,
        filteredData,
      );
      break;
    case Granularity.monthly:
      aggregatedData = getMonthlyAggregatedData(
        currentEndAsDate,
        currentStartAsDate,
        filteredData,
      );
      break;
    case Granularity.quarterly:
      // build the quarters object first
      aggregatedData = getQuarterlyAggregatedData(
        filteredData,
        fiscalYearStartingMonth,
        currentEndAsDate,
        currentStartAsDate,
      );
      break;
  }

  return aggregatedData;
};
const getDailyAggregatedData = (
  currentEndAsDate,
  currentStartAsDate,
  filteredData,
) => {
  let dayMap: { [key: string]: any[] } = {};
  // get the current start and end dates
  const difference = differenceInCalendarDays(
    currentEndAsDate,
    currentStartAsDate,
  );
  for (let i = 0; i <= difference; i++) {
    const currentDate = add(currentStartAsDate, {
      days: i,
    });
    const formattedDate = formatDateString(currentDate, 'MM/dd/yy');
    dayMap[formattedDate] = [];
  }

  return filteredData
    ? filteredData?.reduce((accum, item) => {
        if (!item || !item.occurredAt) {
          return accum;
        }
        if (accum[formatDateString(item.occurredAt, 'MM/dd/yy')]) {
          accum[formatDateString(item.occurredAt, 'MM/dd/yy')].push(item);
        }
        return accum;
      }, dayMap as any)
    : null;
};
const getMonthlyAggregatedData = (
  currentEndAsDate,
  currentStartAsDate,
  filteredData,
) => {
  let monthMap: { [key: string]: any[] } = {};
  const numberToShow =
    differenceInMonths(currentEndAsDate, currentStartAsDate) + 1;
  for (let i = 0; i < numberToShow; i++) {
    const newDate = addMonths(currentStartAsDate, i);
    monthMap[`${newDate.getMonth()}-${newDate.getFullYear()}`] = [];
  }
  return filteredData
    ? filteredData?.reduce((accum, item) => {
        if (!item || !item.occurredAt) {
          return accum;
        }
        const occurredAtAsDate = new Date(item.occurredAt);
        const occurredAtMonth = occurredAtAsDate.getMonth();
        const occurredAtYear = occurredAtAsDate.getFullYear();
        const monthKey = Object.keys(monthMap).find((key) => {
          const [monthToMatch, yearToMatch] = key.split('-');
          return (
            monthToMatch === occurredAtMonth.toString() &&
            yearToMatch === occurredAtYear.toString()
          );
        });
        if (monthKey) {
          accum[monthKey].push(item);
        }
        return accum;
      }, monthMap as any)
    : null;
};
const getQuarterlyAggregatedData = (
  filteredData,
  fiscalYearStartingMonth,
  currentEndAsDate,
  currentStartAsDate,
) => {
  const quarters = buildQuarters(fiscalYearStartingMonth);
  const numberToShow = Math.round(
    (differenceInMonths(currentEndAsDate, currentStartAsDate) + 1) / 3,
  );
  let quarterMap: { [key: string]: any[] } = {};
  for (let i = 0; i < numberToShow; i++) {
    const newDate = addMonths(currentStartAsDate, i * 3);
    const foundQuarter = Object.values(quarters).find((quarter) => {
      return quarter.includes(newDate.getMonth());
    });
    const monthString = foundQuarter
      ? foundQuarter.map((item) => item + 1).join(',')
      : '';
    quarterMap[`${monthString}-${addMonths(newDate, 2).getFullYear()}`] = [];
  }
  return filteredData
    ? filteredData?.reduce((accum, item) => {
        if (!item || !item.occurredAt) {
          return accum;
        }
        const occurredAtAsDate = new Date(item.occurredAt);
        const month = occurredAtAsDate.getMonth() + 1;
        const year = occurredAtAsDate.getFullYear();
        const foundQuarter = Object.keys(quarterMap).find((key) => {
          const monthsAndYear = key.split('-');
          const monthsToMatch = monthsAndYear[0].split(',');
          const yearToMatch = monthsAndYear[1];
          return (
            monthsToMatch.find(
              (foundMonth) => parseInt(foundMonth) === month,
            ) !== undefined && parseInt(yearToMatch) === year
          );
        });
        if (foundQuarter) {
          accum[foundQuarter].push(item);
        }
        return accum;
      }, quarterMap as any)
    : null;
};
export const transformAggregatedDataToChartData = (
  state: SingleAccountSignalReducerState,
): SingleAccountSignalItem[] => {
  const aggregatedData = transformFullDataToAggregatedData(state);
  const { currentGranularity } = state;
  const dataAsChartItems = Object.keys(aggregatedData).map((key) => {
    if (currentGranularity === Granularity.quarterly) {
      return buildQuarterlyChartItem(aggregatedData, key);
    }
    if (currentGranularity === Granularity.monthly) {
      return buildMonthlyChartItem(aggregatedData, key);
    }
    return buildDailyChartItem(aggregatedData, key);
  });

  return sortBuiltData(dataAsChartItems, currentGranularity);
};
const buildDailyChartItem = (aggregatedData, key): SingleAccountSignalItem => {
  const returnValue = {
    inbound: 0,
    outbound: 0,
    occurredAt: key,
    originalDate: key,
    data: aggregatedData[key],
  };
  aggregatedData[key].forEach((item) => {
    if (item.direction === 'activity') {
      returnValue.outbound += item.quantity;
    } else {
      returnValue.inbound += item.quantity;
    }
    returnValue.originalDate = item.occurredAt;
  });
  return returnValue;
};
const buildMonthlyChartItem = (
  aggregatedData,
  key,
): SingleAccountSignalItem => {
  const splitKey = key.split('-');
  const occurredAtAsString = parseInt(splitKey[0]) + 1 + '/' + splitKey[1];
  const dateString = [parseInt(splitKey[0]) + 1, splitKey[1]].join('-1-');
  const returnValue = {
    inbound: 0,
    outbound: 0,
    occurredAt: occurredAtAsString,
    originalDate: dateString,
    data: aggregatedData[key],
  };
  aggregatedData[key].forEach((item) => {
    if (item.direction === 'activity') {
      returnValue.outbound += item.quantity;
    } else {
      returnValue.inbound += item.quantity;
    }
  });
  return returnValue;
};
const buildQuarterlyChartItem = (
  aggregatedData,
  key,
): SingleAccountSignalItem => {
  const occurredAtString = getQuarterlyOccurredAtAsString(key);
  const splitKey = key.split('-');
  const dateString = splitKey[0].split(',')[0] + '-1-' + splitKey[1];
  if (!aggregatedData[key].length) {
    return {
      inbound: 0,
      outbound: 0,
      occurredAt: occurredAtString,
      originalDate: dateString,
    };
  }
  const returnValue = {
    inbound: 0,
    outbound: 0,
    occurredAt: occurredAtString,
    originalDate: dateString,
  };
  aggregatedData[key].forEach((item) => {
    if (item.direction === 'activity') {
      returnValue.outbound += item.quantity;
    } else {
      returnValue.inbound += item.quantity;
    }
  });
  return returnValue;
};
const sortBuiltData = (
  aggregatedData: SingleAccountSignalItem[],
  currentGranularity: Granularity,
): SingleAccountSignalItem[] => {
  return aggregatedData.sort((a, b) => {
    const previousYear = new Date().getFullYear() - 1;
    if (currentGranularity === Granularity.quarterly) {
      if (
        a.occurredAt.includes(previousYear.toString()) &&
        isBefore(new Date(a.originalDate), new Date(b.originalDate))
      ) {
        return -1;
      }
      return 1;
    }
    if (currentGranularity === Granularity.monthly) {
      const splitAKey = a.occurredAt.split('/');
      const splitBKey = b.occurredAt.split('/');
      if (
        parseInt(splitAKey[0]) < parseInt(splitBKey[0]) &&
        parseInt(splitAKey[1]) < parseInt(splitBKey[1])
      ) {
        return -1;
      }
    }
    return 1;
  });
};
export const transformAggregatedDataToTableData = (
  state: SingleAccountSignalReducerState,
) => {
  const aggregatedData = transformFullDataToAggregatedData(state);
  const { currentGranularity } = state;
  let rows: any[] = [];
  Object.keys(aggregatedData).forEach((key) => {
    if (aggregatedData[key].length) {
      if (currentGranularity === Granularity.quarterly) {
        const quarterlyReturnValue = buildQuarterlyTableData(
          aggregatedData,
          key,
        );
        rows.push(quarterlyReturnValue);
      }
      if (currentGranularity === Granularity.monthly) {
        const monthlyReturnValue = buildMonthlyTableData(aggregatedData, key);
        rows.push(monthlyReturnValue);
      }
      if (currentGranularity === Granularity.daily) {
        const dailyReturnValue = buildDailyTableData(aggregatedData, key);
        rows.push(dailyReturnValue);
      }
    }
  });
  return sortBuiltData(rows, currentGranularity);
};
const buildDailyTableData = (aggregatedData, key) => {
  return {
    inbound: 0,
    outbound: 0,
    ...aggregatedData[key],
    occurredAt: key,
  };
};
const buildMonthlyTableData = (aggregatedData, key) => {
  const splitKey = key.split('-');
  const occurredAtAsString = parseInt(splitKey[0]) + 1 + '/' + splitKey[1];
  return {
    inbound: 0,
    outbound: 0,
    ...aggregatedData[key],
    occurredAt: occurredAtAsString,
  };
};
const buildQuarterlyTableData = (aggregatedData, key) => {
  return {
    inbound: 0,
    outbound: 0,
    ...aggregatedData[key],
    occurredAt: getQuarterlyOccurredAtAsString(key),
  };
};
const getQuarterlyOccurredAtAsString = (key: string) => {
  const splitKey = key.split('-');
  const splitMonthYear = splitKey[0].split(',');
  const startMonth = formatDateString(
    new Date(0, parseInt(splitMonthYear[0]) - 1),
    'MMM',
  );
  const endMonth = formatDateString(
    new Date(0, parseInt(splitMonthYear[2]) - 1),
    'MMM',
  );
  return startMonth + '-' + endMonth + ' ' + splitKey[1];
};
