import { createContext, useContext } from 'react';
import { FirebaseType } from './firebase';

const FirebaseContext = createContext<FirebaseType | null>(null);

export function useFirebase() {
  return useContext(FirebaseContext);
}

export default FirebaseContext;
