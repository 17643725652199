import {useReactiveVar} from '@apollo/client';
import {showingFilterPanelVar} from '../../apollo/rootReactiveVariables';
import {useEffect, useRef} from 'react';
import {MainFilterBar} from './components/filterBar/MainFilterBar';
import {AccountStatsSummaryBar} from './components/filterBar/AccountStatsSummaryBar';
import {FilterBarDivider} from './components/filterBar/FilterBarDivider';
import {FilterStatsSummaryBar} from './components/filterBar/FilterStatsSummaryBar';
import {FilterPanel} from './components/filterBar/FilterPanel';
import {useGetAllAccountGroupsQuery} from 'shared/graphql/generatedApiTypes';
import {
    addScrollableSelector,
    clearQueueScrollLocks,
    disablePageScroll,
    enablePageScroll,
    removeScrollableSelector,
} from 'scroll-lock';
import {GlobalFiltersStructure} from 'shared/types/companyAppStructureTypes';

export const GlobalFilters = ({
  globalFiltersStructure,
}: {
  globalFiltersStructure: GlobalFiltersStructure;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const showingPanel = useReactiveVar(showingFilterPanelVar);

  // kick off this query so that it caches
  useGetAllAccountGroupsQuery();

  // lock or unlock the scroll when panel shows
  useEffect(() => {
    if (showingPanel && scrollRef.current) {
      disablePageScroll(scrollRef.current);
      addScrollableSelector(scrollRef.current);
    } else if (scrollRef.current) {
      enablePageScroll(scrollRef.current);
      removeScrollableSelector(scrollRef.current);
    }
    return () => clearQueueScrollLocks();
  }, [showingPanel]);

  const { panel, quickAccessBarFilterItems } = globalFiltersStructure;
  const panelHasTabs = !!panel.showTabs.length;

  return (
    <>
      <MainFilterBar
        quickAccessFilterBarItems={quickAccessBarFilterItems}
        showMoreFiltersButton={panelHasTabs}
      />
      {panelHasTabs && (
        <>
          <div className={'filter-bar-wrapper__sticky-header'}>
            <AccountStatsSummaryBar />
            <FilterBarDivider />
            <FilterStatsSummaryBar />
          </div>
          <div ref={scrollRef}>
            <FilterPanel panel={panel} />
          </div>
        </>
      )}
    </>
  );
};
