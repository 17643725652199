import { zIndexValues } from 'shared/constants/zIndexValues';

export const getAccountsFilterItemStyles = (
  itemType: 'panel' | 'bar' | 'wideMenu',
  isMenuOpen: boolean,
  isFilterApplied: boolean,
) => {
  const sharedStyles = {
    control: (styles) => ({
      ...styles,
      borderWidth: 0,
      cursor: 'text',
    }),
    dropdownIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' }),
  };

  if (itemType === 'panel') {
    return {
      ...sharedStyles,
      container: (styles) => ({
        ...styles,
        padding: 0,
        width: '200px',
        zIndex: zIndexValues.disabledOverlay,
      }),
      menu: (styles) => ({
        ...styles,
        fontSize: '13px',
      }),
    };
  }
  return {
    ...sharedStyles,
    container: (styles) => ({
      ...styles,
      padding: 0,
      border: isFilterApplied
        ? '2px solid var(--color-blue)'
        : '2px solid var(--color-gray-300)',
      zIndex: zIndexValues.disabledOverlay,
      width: '200px',
    }),
    menu: (styles) => ({
      ...styles,
      height: '300px',
      paddingLeft: '200px',
      width: '400px',
      fontSize: '13px',
    }),
  };
};
