import { ApiAccountField } from 'shared/graphql/generatedApiTypes';

export const mapColumnNamesToSorts = (columnName: string) => {
  if (columnName.includes('xeominAverageQuantity')) {
    return ApiAccountField.XeominAverageQuantity;
  }
  if (columnName.includes('xeominPurchaseTrendShort')) {
    return ApiAccountField.XeominPurchaseTrendShort;
  }
  if (columnName.includes('xeominSpendFraction')) {
    return ApiAccountField.XeominSpendFraction;
  }
  if (columnName.includes('customerStatus')) {
    return ApiAccountField.CustomerStatus;
  }
  if (columnName.includes('xeominPredictionSummary')) {
    return ApiAccountField.XeominPredictionSummary;
  }

  return ApiAccountField.AccountName;
};
