import {
  filterIndexToString,
  FilterIndices,
  RANK_TICKS,
  RECOMMENDED_ACTIONS_FILTER_ITEMS,
  WINFLUENCE_DROPDOWN_FILTER_ITEMS,
} from 'app/src/components/GlobalFilters/types/filterTypes';
import _size from 'lodash/size';
import {
  cleanStringCase,
  formatDateString,
  numberFormatter,
  pluralizeIndustriesString,
} from 'shared/helpers/formatHelpers';
import _isArray from 'lodash/isArray';
import _join from 'lodash/join';
import _map from 'lodash/map';
import { FilterDictType } from 'app/src/components/GlobalFilters/types/filterDict';
import _omit from 'lodash/omit';
import _includes from 'lodash/includes';

function returnMinMaxFilterValue(
  value: { min: number; max: number },
  key: string,
) {
  const minString = numberFormatter(
    value?.min,
    key === FilterIndices.REVENUES_FILTER,
  );
  const maxString = value?.max
    ? ` - ${numberFormatter(value?.max, key === FilterIndices.REVENUES_FILTER)}`
    : '+';
  return `${minString}${maxString}`;
}

function returnStringFilterValue(
  value: { min: number; max: number } | Array<any> | string,
  key: string,
) {
  if (key === FilterIndices.ACCOUNTS_FILTER) {
    return _size(value).toLocaleString();
  }
  if (key === RECOMMENDED_ACTIONS_FILTER_ITEMS.index) {
    return cleanStringCase(value as string);
  }
  if (key === FilterIndices.ENGAGEMENT_INTENSITY) {
    const noneIndex =
      _isArray(value) && value.findIndex((item) => item === 'none');
    if (noneIndex !== undefined && noneIndex !== false && noneIndex > -1) {
      value[noneIndex] = 'No Responses';
    }
  }
  if (key === FilterIndices.OUTREACH_INTENSITY) {
    const noneIndex =
      _isArray(value) && value.findIndex((item) => item === 'none');
    if (noneIndex !== undefined && noneIndex !== false && noneIndex > -1) {
      value[noneIndex] = 'No Activities';
    }
  }
  return _join(
    _map(value, (v) => cleanStringCase(v)),
    ', ',
  );
}

function accountGroupStatsItemTitleValue(value, key) {
  let title = filterIndexToString[key];
  if (key === FilterIndices.INDUSTRY) {
    title = pluralizeIndustriesString(_size(value));
  }
  let val = returnStringFilterValue(value, key);
  if (
    _includes(
      [
        FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER,
        FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER,
      ],
      key,
    )
  ) {
    // beat race condition where values are removed slightly before keys
    if (value) {
      val = `${formatDateString(
        `${value?.min}T00:00:00`,
        'PP',
      )} - ${formatDateString(`${value?.max}T00:00:00`, 'PP')}`;
    }
  } else if (value?.min || value?.max) {
    val = returnMinMaxFilterValue(value, key);
  } else if (key === WINFLUENCE_DROPDOWN_FILTER_ITEMS.index) {
    if (_isArray(value)) {
      val = _map(value, (v) => RANK_TICKS[v]).join(', ');
    } else {
      val = RANK_TICKS[value];
    }
  } else if (
    key === FilterIndices.ACCOUNTS_FULL_FILTER ||
    key === FilterIndices.ACCOUNTS_FILTER
  ) {
    val = value.length.toString();
  } else if (key === FilterIndices.ENGAGEMENT_INTENSITY && val === 'none') {
  }
  return {
    title,
    value: val,
  };
}

export function getAccountGroupStats(
  filterDict: FilterDictType | undefined | null,
) {
  const stats = _map(
    _omit(filterDict, FilterIndices.ACCOUNTS_FILTER_TYPE),
    (value, key) => accountGroupStatsItemTitleValue(value, key),
    // Ensure accounts show first in the list
  )
    .sort((a) => (a.title === 'Accounts' ? -1 : 1))
    .filter((stat) => stat.value !== '' && stat.value !== '0 - 100');

  // Remove duplicate account values due to both accounts & accountIds key
  if (
    stats.length >= 2 &&
    stats[0].title === 'Accounts' &&
    stats[1].title === 'Accounts'
  ) {
    stats.shift();
  }

  // Remove accounts if there are 0
  if (stats.length && stats[0].title === 'Accounts' && stats[0].value === '0') {
    stats.shift();
  }

  return stats;
}
