import classnames from 'classnames';
import {ReactNode} from 'react';
import {allSizeOptionsLiteral, ClassNameType, FullSizeType, HeightType,} from 'shared/types/coreTypes.d';
import {GlobalFilters} from 'app/src/components/GlobalFilters';
import {useReactiveVar} from '@apollo/client';
import {appliedFiltersHeightVar} from 'app/src/apollo/rootReactiveVariables';
import {GlobalFiltersStructure} from '../../types/companyAppStructureTypes';

type ContainerProps = ClassNameType & {
  children?: ReactNode;
  globalFiltersStructure?: GlobalFiltersStructure;
  height?: HeightType;
  ph?: allSizeOptionsLiteral;
  pv?: allSizeOptionsLiteral;
  showFilters?: boolean;
  width?: FullSizeType;
};

function Container({
  children,
  className,
  globalFiltersStructure,
  height,
  ph,
  pv,
  showFilters = false,
  width,
}: ContainerProps) {
  const containerClasses = classnames({
    'c-container': true,
    [`c-container--width-${width}`]: width,
    [`h-height-${height}`]: height,
    [`h-ph-${ph}`]: ph,
    [`h-pv-${pv}`]: pv,
    [`${className}`]: className,
  });

  const appliedFiltersBarHeight = useReactiveVar(appliedFiltersHeightVar);

  return showFilters ? (
    <>
      {globalFiltersStructure && (
        <GlobalFilters globalFiltersStructure={globalFiltersStructure} />
      )}
      <div
        className={containerClasses}
        style={{ top: appliedFiltersBarHeight - 30 }}
      >
        {children}
      </div>
    </>
  ) : (
    <div className={containerClasses}>{children}</div>
  );
}

export default Container;
