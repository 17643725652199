import { ChangeEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import _size from 'lodash/size';
import Icon from './Icon';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import {
  AnalyticsAttrType,
  ClassNameType,
  InputAutoCompleteType,
  InputTypeType,
} from 'shared/types/coreTypes.d';

export type OnChangeInputChangeEventType = ChangeEvent<HTMLInputElement>;

type InputType = AnalyticsAttrType &
  ClassNameType & {
    autoComplete?: InputAutoCompleteType;
    disabled?: boolean;
    error?: string;
    hideMessage?: boolean;
    id?: string;
    label?: string;
    message?: string;
    name?: string;
    onChange?: (event: OnChangeInputChangeEventType) => void;
    placeholder?: string;
    required?: boolean;
    success?: string;
    type?: InputTypeType;
    value?: string | number;
  };

function Input({
  analyticsAttr = undefined,
  autoComplete = 'off',
  className = undefined,
  disabled = false,
  error,
  hideMessage = false,
  id = uuidv4(),
  label,
  message,
  name,
  onChange,
  placeholder,
  required,
  success,
  type = 'text',
  value,
}: InputType) {
  const [showPassword, setShowPassword] = useState(false);
  const fieldClass = classnames({
    'c-text-field': true,
    'c-text-field--disabled': disabled,
    'c-text-field--error': !!error,
    'c-text-field--success': !!success,
    'h-pb-none': hideMessage,
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });
  return (
    <fieldset className={fieldClass} disabled={disabled}>
      <label className={'c-text-field__wrapper'} htmlFor={id}>
        {label && (
          <div className={'c-text-field__label'}>
            <span className={'c-text h-text-size-md'}>
              {label}
              {required && (
                <sup
                  style={{
                    color: 'var(--color-red)',
                  }}
                >
                  {' *'}
                </sup>
              )}
            </span>
          </div>
        )}
        <div className={'c-text-field__input-block'}>
          <input
            analytics-attr={cleanAnalyticsStringList([analyticsAttr, 'input'])}
            autoComplete={autoComplete}
            className={'c-text-field__input'}
            id={id}
            min={0}
            name={name || id}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            type={type === 'password' && showPassword ? 'text' : type}
            value={value}
          />
          {type === 'password' && (
            <span
              onClick={() => setShowPassword(!showPassword)}
              className={'c-text-field__icon h-pointer'}
            >
              <Icon name={showPassword ? 'if-eye-slash' : 'if-eye'} />
            </span>
          )}
        </div>
      </label>
      {message && _size(message) > 0 && (
        <div className={'c-text-field__message h-text-align-left'}>
          <div className={'c-text h-text-size-2xs'}>{message}</div>
        </div>
      )}
      {success ||
        (error && (
          <div className={'c-text-field__message'}>
            {error && (
              <div className={'c-text h-text-size-2xs'}>
                <b>Error! </b>
                {error}
              </div>
            )}
            {success && (
              <div className={'c-text h-text-size-2xs'}>
                <b>Success! </b>
                {Array.isArray(success)
                  ? success.map((msg) => <p key={msg}>{msg}</p>)
                  : success}
              </div>
            )}
          </div>
        ))}
    </fieldset>
  );
}

export default Input;
