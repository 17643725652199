import {ReactNode} from 'react';
import {Container} from '../../../../shared/components/Core';
import {GlobalFiltersStructure} from 'shared/types/companyAppStructureTypes';

type PageWrapperProps = {
  children: ReactNode;
  globalFiltersStructure: GlobalFiltersStructure;
  globalFiltersVisible: boolean;
};

export const PageWrapper = ({
  children,
  globalFiltersStructure,
  globalFiltersVisible,
}: PageWrapperProps) => {
  return (
    <Container
      globalFiltersStructure={globalFiltersStructure}
      pv={'xl'}
      ph={'xl'}
      showFilters={globalFiltersVisible}
      width={'full'}
    >
      {children}
    </Container>
  );
};
