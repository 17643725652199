import {Inline} from '../../../../../../shared/components/Core';
import AccountsStats from './AccountsStats';
import {useContext} from 'react';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import {useDefinedMediaQuery} from 'shared/hooks/mediaQueryHook';
import {globalFilterAppDefinitions} from '../../helpers/globalFilterAppDefinitions';
import {Box, Text} from '@chakra-ui/react';

export const AccountStatsSummaryBar = () => {
  const { data, loading } = useContext(AggregateAccountsDataContext);

  const accountStatsData = data?.aggregateAccountsData ?? null;
  const { isSmallerViewport } = useDefinedMediaQuery();
  const textSize = isSmallerViewport ? 'sm' : 'md';

  const appDefinitions = globalFilterAppDefinitions.getAccountStatsSummaryText();

  return (
    <Box bg={'brand.white'}>
      <div className={'c-header__container'}>
        <Inline
          alignItems={'center'}
          className={'h-bg-white'}
          display={'grid'}
          gap={'xs'}
          height={'auto'}
        >
          <Text
            color={'brand.gray-600'}
            fontSize={textSize}
            fontWeight={'bold'}
          >
            {appDefinitions.topBarSummaryLabel.text}{' '}
          </Text>
          {loading ? (
            <Text fontSize={textSize}>
              {appDefinitions.topBarSummaryLoading.text}
            </Text>
          ) : (
            accountStatsData && (
              <AccountsStats accountsData={accountStatsData} />
            )
          )}
        </Inline>
      </div>
    </Box>
  );
};
