import _map from 'lodash/map';
import _maxBy from 'lodash/maxBy';
import _size from 'lodash/size';
import { SectionHeaderIndices } from '../types/homeTypes';
import { Card } from '../../../../../shared/components/Core';
import { useGetAggregateAccountsDataWithoutEngagementStatusQuery } from 'shared/graphql/generatedApiTypes';
import { HOME_HEADERS } from 'app/src/constants/routes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { EngagementItem } from './EngagementItem';
import { mapEngagementStatusDataToChart } from '../helpers/engagementStatusHelpers';
import SectionHeader from '../../../../../shared/components/Core/SectionHeader';
import { useReactiveVar } from '@apollo/client';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import { Box, Flex } from '@chakra-ui/react';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

export const EngagementStatus = () => {
  useReactiveVar(globalFilterVar);

  const { data, loading } =
    useGetAggregateAccountsDataWithoutEngagementStatusQuery({
      ...defaultQueryFetchPolicy,
      notifyOnNetworkStatusChange: true,
    });

  const mappedData = mapEngagementStatusDataToChart(data);
  const maxYValue = _maxBy(mappedData, 'y')?.y || 100;

  const {
    text: sectionHeaderText,
    subtext: sectionHeaderSubtext,
    tooltip: sectionHeaderTooltip,
  } = getAppDefinitionsItem(
    HOME_HEADERS.name,
    SectionHeaderIndices.ENGAGEMENT_STATUS,
  ) as TextDefinitionType;

  return (
    <Card isLoading={loading}>
      <Box analytics-attr={'engagement-status'} minH={'380px'} pb={8}>
        <SectionHeader
          title={sectionHeaderText}
          subtitle={sectionHeaderSubtext}
          tooltip={sectionHeaderTooltip}
          showBackgroundColor={false}
          addHeaderLeftMargin
        />
        <Flex justify={'space-between'} flexWrap={'wrap'} px={6}>
          {_size(data) > 0 &&
            !loading &&
            _map(mappedData, (d, idx) => (
              <EngagementItem {...d} key={idx} maxYValue={maxYValue} />
            ))}
        </Flex>
      </Box>
    </Card>
  );
};
