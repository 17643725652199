import {Icon, Inline, Input, Tooltip} from '../../../../../../../shared/components/Core';
import {useInput, UseInputHookReturnType, UseInputHookStringValueTypes,} from 'shared/hooks/inputHook';
import {ApiAccountGroup} from 'shared/graphql/generatedApiTypes';
import {globalFilterAppDefinitions} from '../../../helpers/globalFilterAppDefinitions';
import {Text} from '@chakra-ui/react';

type EditGroupTitleAndDescriptionProps = {
  error: string | undefined;
  group: ApiAccountGroup;
  onCancel: () => void;
  onChange: (input) => void;
};

export const EditGroupTitleAndDescription = ({
  error,
  group,
  onCancel,
  onChange,
}: EditGroupTitleAndDescriptionProps) => {
  const appDefinitions =
    globalFilterAppDefinitions.getSegmentSaveAccordionText();

  const { bind: bindAccountGroupTitle, value: accountGroupTitle } = useInput(
    group.title ?? '',
    {
      label: appDefinitions.segmentSaveNameLabel.text,
      placeholder: appDefinitions.segmentSaveNamePlaceholder.text,
    },
  ) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const { bind: bindAccountGroupDescription, value: accountGroupDescription } =
    useInput(group.description ?? '', {
      label: appDefinitions.segmentSaveDescriptionLabel.text,
      placeholder: appDefinitions.segmentSaveDescriptionPlaceholder.text,
    }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  return (
    <>
      {!!error && (
        <Text m={6} color={'brand.crimson'}>
          {error}
        </Text>
      )}
      <Inline alignItems={'center'}>
        <div>
          <Input {...bindAccountGroupTitle} />
          <Input {...bindAccountGroupDescription} />
        </div>
        <div
          analytics-attr={'save-group-title-description'}
          onClick={() =>
            onChange({
              id: group.id,
              title: accountGroupTitle,
              description: accountGroupDescription,
            })
          }
        >
          <Tooltip content={'Save Changes'} hideIcon={true}>
            <Icon
              name={'io-check'}
              size={'lg'}
              className={'h-m-lg h-pointer'}
            />
          </Tooltip>
        </div>
        <div
          analytics-attr={'cancel-group-title-description'}
          onClick={onCancel}
        >
          <Tooltip content={'Cancel Changes'} hideIcon={true}>
            <Icon
              name={'if-trash'}
              size={'lg'}
              className={'h-m-lg h-pointer'}
            />
          </Tooltip>
        </div>
      </Inline>
    </>
  );
};
