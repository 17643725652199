import {
  FilterItemOption,
  FilterItemType,
} from 'shared/types/companyAppStructureTypes';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import CheckboxSelectFilterItem from './CheckboxSelectFilterItem';
import { useContext } from 'react';
import { AggregateAccountsDataContext } from 'app/src/context/AggregateAccountsDataContext';
import AccountsFilterItem from './AccountsFilterItem';
import { RangerFilterItem } from './rangerFilterItem/RangerFilterItem';
import {
  createAggregateMinMaxFromItems,
  createRangeMinMaxFromItems,
} from '../../helpers/rangerFilterHelpers';
import CalendarRangeFilterItem from './CalendarFilterItem';
import CheckboxFilterItem from './CheckboxFilterItem';
import RadioInputFilterItem from './RadioInputFilterItem';

export const FilterItem = ({
  filterItem,
  itemSource,
}: {
  filterItem: FilterItemType;
  itemSource?: 'panel' | 'bar' | 'wideMenu';
}) => {
  const { fullAggregateData, globalFilter } = useContext(
    AggregateAccountsDataContext,
  );

  const analyticsAttr = cleanAnalyticsStringList([filterItem.title, 'filter']);

  switch (filterItem.type) {
    case FilterItemOption.checkboxDropdown:
      return (
        <CheckboxSelectFilterItem
          analytics-attr={analyticsAttr}
          initialItems={
            (fullAggregateData?.aggregateAccountsData &&
              fullAggregateData.aggregateAccountsData[filterItem.field]) ??
            []
          }
          filterIndex={filterItem.field}
          itemSource={itemSource}
          placeholder={
            itemSource === 'panel'
              ? `${filterItem.title} (${
                  globalFilter && globalFilter[filterItem.field]
                    ? globalFilter[filterItem.field].length
                    : 0
                } selected)`
              : filterItem.title
          }
          tableAccessor={'name'}
          width={itemSource === 'panel' ? '400px' : undefined}
        />
      );
    case FilterItemOption.searchableDropdown:
      if (filterItem.field === 'accountIds') {
        return (
          <AccountsFilterItem itemType={itemSource ? itemSource : 'panel'} />
        );
      }
      return null;
    case FilterItemOption.ranger:
      return (
        <RangerFilterItem
          analyticsAttr={analyticsAttr}
          filterIndex={filterItem.field}
          initialFilterItem={
            fullAggregateData?.aggregateAccountsData
              ? createRangeMinMaxFromItems(
                  fullAggregateData.aggregateAccountsData[filterItem.field],
                  false,
                )
              : []
          }
          isDollars={false}
          isHundredPointScale={false}
          labelString={filterItem.title}
          labelStringTooltip={filterItem.tooltip ?? ''}
        />
      );
    case FilterItemOption.hundredPointScaleRanger:
      return (
        <RangerFilterItem
          analyticsAttr={analyticsAttr}
          filterIndex={filterItem.field}
          initialFilterItem={
            fullAggregateData?.aggregateAccountsData
              ? createAggregateMinMaxFromItems(
                  fullAggregateData.aggregateAccountsData[filterItem.field],
                )
              : []
          }
          isDollars={false}
          isHundredPointScale
          labelString={filterItem.title}
          labelStringTooltip={filterItem.tooltip ?? ''}
        />
      );
    case FilterItemOption.dollarRanger:
      return (
        <RangerFilterItem
          analyticsAttr={analyticsAttr}
          filterIndex={filterItem.field}
          initialFilterItem={
            fullAggregateData?.aggregateAccountsData
              ? createRangeMinMaxFromItems(
                  fullAggregateData.aggregateAccountsData[filterItem.field],
                  true,
                )
              : []
          }
          isDollars
          isHundredPointScale={false}
          labelString={filterItem.title}
          labelStringTooltip={filterItem.tooltip ?? ''}
        />
      );
    case FilterItemOption.calendar:
      return (
        <CalendarRangeFilterItem
          analytics-attr={analyticsAttr}
          filterIndex={filterItem.field}
          label={filterItem.title}
        />
      );
    case FilterItemOption.checkboxes:
      return (
        <CheckboxFilterItem
          analyticsAttr={analyticsAttr}
          initialItems={
            fullAggregateData?.aggregateAccountsData
              ? fullAggregateData.aggregateAccountsData[filterItem.field]
              : []
          }
          filterIndex={filterItem.field}
          label={filterItem.title}
          labelStringTooltip={filterItem.tooltip}
          tableAccessor={'name'}
        />
      );
    case FilterItemOption.radio:
      return (
        <RadioInputFilterItem
          analyticsAttr={analyticsAttr}
          defaultValue={'all'}
          initialItems={
            fullAggregateData?.aggregateAccountsData
              ? fullAggregateData.aggregateAccountsData[filterItem.field]
              : []
          }
          filterIndex={filterItem.field}
          label={filterItem.title}
          labelStringTooltip={filterItem.tooltip}
          tableAccessor={'name'}
        />
      );
    default:
      return null;
  }
};
