import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { useApolloClient } from '@apollo/client';
import _map from 'lodash/map';
import _size from 'lodash/size';
import { showingFilterPanelVar } from 'app/src/apollo/rootReactiveVariables';
import {
  ApiGetAllAccountGroupsQuery,
  GetAccountGroupDocument,
  GetAccountsWithFilterVarDocument,
  useGetAllAccountGroupsQuery,
  useUpdateAccountGroupDescriptorsMutation,
  useUpdateAccountGroupIsHiddenMutation,
} from 'shared/graphql/generatedApiTypes';
import { groupAccountGroups } from '../../../helpers/groupAccountGroups';
import { EditGroupTitleAndDescription } from './EditGroupTitleAndDescription';
import { UPDATE_ACCOUNT_GROUP_ACCOUNTS } from 'shared/graphql/gqlMutations';
import {
  Button,
  Icon,
  Inline,
  Loading,
  Table,
} from '../../../../../../../shared/components/Core';
import { filterStringFromBackendToDict } from '../../../helpers/filterDictAndFilterStringHelpers';
import {
  updateAccountGroupDescriptorsCache,
  updateAccountGroupIsHiddenCache,
} from '../../../helpers/updateAccountGroupCache';
import { useFirebase } from '../../../../Firebase';
import { FilterIndices } from '../../../types/filterTypes';
import { FilterDictType } from '../../../types/filterDict';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { KlearlyUserType } from '../../../../Firebase/firebase';
import { AggregateAccountsDataContext } from 'app/src/context/AggregateAccountsDataContext';
import { OptionsType } from 'shared/types/coreTypes.d';
import { Box, ButtonGroup } from '@chakra-ui/react';
import {
  DeleteIcon,
  NotAllowedIcon,
  RepeatClockIcon,
  ViewIcon,
} from '@chakra-ui/icons';
import { formatDateForTable } from 'shared/helpers/formatHelpers';

export type GroupTableProps = {
  columns?: Column<object>[];
  data: NonNullable<ApiGetAllAccountGroupsQuery['accountGroups']>;
  index?: string;
  segmentOrListType: 'segment' | 'list';
};

type GroupTableState = {
  errorMessage: string;
  isConfirmingDeleteRowId: number | null;
  isEditingRowId: number | null;
  isRefreshing: boolean;
};

const initialState: GroupTableState = {
  errorMessage: '',
  isConfirmingDeleteRowId: null,
  isEditingRowId: null,
  isRefreshing: false,
};

const GroupTable = ({
  columns = undefined,
  data = [],
  index = undefined,
  segmentOrListType,
}: GroupTableProps) => {
  const apolloClient = useApolloClient();
  const { overrideFilter } = useContext(AggregateAccountsDataContext);
  const [updateAccountGroupDescriptors, { loading }] =
    useUpdateAccountGroupDescriptorsMutation(
      updateAccountGroupDescriptorsCache,
    );

  const [updateAccountGroupIsHidden, { loading: deletionLoading }] =
    useUpdateAccountGroupIsHiddenMutation(updateAccountGroupIsHiddenCache);

  const [
    { errorMessage, isConfirmingDeleteRowId, isEditingRowId, isRefreshing },
    setState,
  ] = useState<GroupTableState>(initialState);
  const isSegment = segmentOrListType === 'segment';
  const firebase = useFirebase();
  const klearlyUser: KlearlyUserType | null = firebase!.klearlyUser || null;
  const { data: groupsData } = useGetAllAccountGroupsQuery();

  const handleRefreshAndApply = useCallback(
    async (group) => {
      let filterToApply: FilterDictType & { accounts: OptionsType[] };
      const groupWithAccounts = await apolloClient.query({
        query: GetAccountGroupDocument,
        variables: {
          id: group.id,
        },
      });

      if (isSegment) {
        const filterDict = filterStringFromBackendToDict(group.filterString);
        if (filterDict.accountIds) {
          filterDict.accounts =
            groupWithAccounts.data.accountGroup.accounts.map((acct) => ({
              value: acct.id.toString(),
              label: acct.accountName,
            }));
        }
        filterToApply = filterDict;
      } else {
        const { accounts } = groupWithAccounts.data.accountGroup;
        filterToApply = {
          accountFilterType: FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
          accountIds: accounts.map((acct) => acct.id),
          accounts: accounts.map((acct) => ({
            value: acct.id.toString(),
            label: acct.accountName,
          })),
        };
      }
      if (isSegment) {
        setState((prevState) => ({ ...prevState, isRefreshing: true }));
        try {
          const res = await apolloClient.query({
            query: GetAccountsWithFilterVarDocument,
            variables: { filterString: group.filterString },
          });
          await apolloClient.mutate({
            mutation: UPDATE_ACCOUNT_GROUP_ACCOUNTS,
            variables: {
              input: {
                accountIds: _map(res.data.accountsWithPredictions, 'id'),
                id: group.id,
              },
            },
          });
          setState((prevState) => ({ ...prevState, isRefreshing: false }));
        } catch (err) {
          setState((prevState) => ({
            ...prevState,
            isRefreshing: false,
            errorMessage: err as string,
          }));
        }
      }
      overrideFilter(filterToApply);
      showingFilterPanelVar(false);
    },
    [apolloClient, isSegment, overrideFilter],
  );

  const handleDelete = useCallback(
    async (group) => {
      try {
        setState((prevState) => ({
          ...prevState,
          errorMessage: '',
          isConfirmingDeleteRowId: null,
        }));
        await updateAccountGroupIsHidden({
          variables: {
            input: {
              id: group.id,
              isHidden: true,
            },
          },
        });
      } catch (err) {
        setState((prevState) => ({
          ...prevState,
          errorMessage: 'Error removing group, please try again.',
        }));
      }
    },
    [updateAccountGroupIsHidden],
  );

  const handleUpdateDescriptors = useCallback(
    async (input: { id: number; title: string; description: string }) => {
      const { groups: accountSegments, lists: accountLists } =
        groupAccountGroups({
          accountGroups: groupsData?.accountGroups,
          klearlyUser,
        });
      const accountsToSearch =
        segmentOrListType === 'segment' ? accountSegments : accountLists;
      if (
        accountsToSearch?.find(
          (group) =>
            group?.title === input.title.trim() && group.id !== input.id,
        )
      ) {
        setState((prevState) => ({
          ...prevState,
          errorMessage:
            'An account group already exists with this name. Please try another.',
        }));
      } else {
        try {
          setState((prevState) => ({ ...prevState, errorMessage: '' }));
          await updateAccountGroupDescriptors({
            variables: {
              input: {
                ...input,
                title: input.title.trim(),
                description: input.description.trim(),
              },
            },
          });
          setState((prevState) => ({ ...prevState, isEditingRowId: null }));
        } catch (err) {
          setState((prevState) => ({
            ...prevState,
            errorMessage: 'Error updating group, please try again.',
          }));
        }
      }
    },
    [
      updateAccountGroupDescriptors,
      groupsData?.accountGroups,
      klearlyUser,
      segmentOrListType,
    ],
  );

  // on ummount of component, we want to clear any in progress actions
  useEffect(() => {
    return () => {
      setState(initialState);
    };
  }, []);

  // setup table columns and data
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Groups/Lists',
        columns: [
          {
            Cell: ({ row, value }) => {
              return row.original.id !== isEditingRowId ? (
                <div style={{ maxWidth: '30vw' }}>
                  <Inline>
                    {value}
                    {row.original.isLoggedInUsers &&
                    !isConfirmingDeleteRowId ? (
                      <div
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            isEditingRowId: row.original.id,
                          }));
                        }}
                      >
                        <Icon
                          name={'if-edit'}
                          size={'md'}
                          className={'h-ml-xs h-pointer'}
                        />
                      </div>
                    ) : (
                      <Box minW={5} />
                    )}
                  </Inline>
                  <br />
                  <em>{row.original?.description}</em>
                </div>
              ) : (
                <EditGroupTitleAndDescription
                  error={errorMessage}
                  group={row.original}
                  onChange={(input) => handleUpdateDescriptors(input)}
                  onCancel={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isEditingRowId: null,
                    }))
                  }
                />
              );
            },
            Header: 'Name',
            accessor: 'title',
            analyticsAttrIndex: 'title',
          },
          {
            Header: 'Created By',
            accessor: (originalRow) =>
              `${originalRow.companyUser.firstName} ${originalRow.companyUser.lastName}`,
            analyticsAttrIndex: 'title',
          },
          {
            Cell: ({ row, value }) =>
              `${value.toLocaleString()} (${formatDateForTable(
                row.original.accountsUpdatedAt,
              )})`,
            Header: 'Total Accounts',
            accessor: (originalRow) => originalRow.accountCount,
            analyticsAttrIndex: 'title',
          },
          {
            Cell: ({ row }) => (
              <ButtonGroup>
                {isConfirmingDeleteRowId !== row.original.id && (
                  <Button
                    analyticsAttr={cleanAnalyticsStringList([
                      index,
                      'refresh',
                      row.original.id,
                    ])}
                    aria-label={'refresh'}
                    className={'h-pointer'}
                    disabled={
                      !!isEditingRowId ||
                      !!(
                        isConfirmingDeleteRowId &&
                        isConfirmingDeleteRowId !== row.original.id
                      )
                    }
                    onClick={() => handleRefreshAndApply(row.original)}
                    size={'sm'}
                    leftIcon={isSegment ? <RepeatClockIcon /> : <ViewIcon />}
                    variant={'actionOutline'}
                    text={isSegment ? 'Refresh & Apply' : 'View Accounts'}
                  />
                )}
                {isConfirmingDeleteRowId === row.original.id && (
                  <Button
                    analyticsAttr={cleanAnalyticsStringList([
                      index,
                      'cancel delete',
                      row.original.id,
                    ])}
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isConfirmingDeleteRowId: null,
                      }))
                    }
                    leftIcon={<NotAllowedIcon />}
                    size={'sm'}
                    text={'Cancel'}
                    variant={'activeOutline'}
                  />
                )}
                {row.original.isLoggedInUsers && (
                  <Button
                    analyticsAttr={cleanAnalyticsStringList([
                      index,
                      'delete',
                      row.original.id,
                    ])}
                    aria-label={'delete'}
                    className={'h-pointer'}
                    isDisabled={
                      !!isEditingRowId ||
                      !!(
                        isConfirmingDeleteRowId &&
                        isConfirmingDeleteRowId !== row.original.id
                      )
                    }
                    onClick={() => {
                      isConfirmingDeleteRowId === row.original.id
                        ? handleDelete(row.original)
                        : setState((prevState) => ({
                            ...prevState,
                            isConfirmingDeleteRowId: row.original.id,
                          }));
                    }}
                    text={
                      isConfirmingDeleteRowId === row.original.id
                        ? 'Confirm'
                        : 'Delete'
                    }
                    size={'sm'}
                    variant={
                      isConfirmingDeleteRowId ? 'active' : 'activeOutline'
                    }
                    leftIcon={<DeleteIcon />}
                  />
                )}
              </ButtonGroup>
            ),
            Header: 'Actions',
          },
        ],
      },
    ],
    [
      errorMessage,
      index,
      isEditingRowId,
      isSegment,
      isConfirmingDeleteRowId,
      handleDelete,
      handleRefreshAndApply,
      handleUpdateDescriptors,
    ],
  );
  const tableData = useMemo(() => {
    return data || [];
  }, [data]);
  // if no data, render nothing
  if (_size(data) === 0) {
    return null;
  }

  return (
    <>
      <Table
        analyticsAttr={index}
        columns={columns || tableColumns}
        //@ts-ignore
        data={tableData && Object.keys(tableData) ? tableData : []}
        initialDefaultSortByColumnId={'title'}
        perPage={30}
        tableVPadding={'sm'}
      />
      {(isRefreshing || loading || deletionLoading) && (
        <Loading dotColor={'color'} text={'Loading...'} />
      )}
    </>
  );
};

export default GroupTable;
