export enum FilterIndices {
  ACCOUNTS_FILTER_TYPE_INCLUSIVE = 'and',
  ACCOUNTS_FILTER_TYPE_EXCLUSIVE = 'or',
  ACCOUNTS_FILTER_TYPE = 'accountFilterType',
  ACCOUNTS_FILTER = 'accountIds',
  ACCOUNTS_FULL_FILTER = 'accounts',
  ACCOUNT_NAME = 'accountName',
  CONTACT_COUNT = 'contactCount',
  COUNTRY = 'country',
  CURRENT_ACCOUNT_SCORE = 'current_account_score',
  CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE = 'currentAccountScore',
  CURRENT_WIN_PROBABILITY = 'currentValue',
  EMPLOYEE_COUNT_FILTER = 'employeeCount',
  ENGAGEMENT_INTENSITY = 'signalResponseIntensity',
  ENGAGEMENT_STATUS = 'engagementStatus',
  INDUSTRY = 'industry',
  LAST_INBOUND_SIGNAL_DATE_FILTER = 'lastInboundSignalDate',
  LAST_OUTBOUND_SIGNAL_DATE_FILTER = 'lastOutboundSignalDate',
  LIFT = 'lift',
  OUTREACH_INTENSITY = 'signalActivityIntensity',
  RANK = 'winfluenceRank',
  RECOMMENDED_ACTION = 'recommendedAction',
  REVENUES_FILTER = 'revenue',
  SIGNAL_TYPE_FILTER = 'signalType',
  SIZES_FILTER = 'size',
  WINFLUENCE = 'lift',
  WINFLUENCE_ACTUAL = 'winfluence',
  XEOMIN_PREDICTION_SUMMARY = 'xeominPredictionSummary',
}

export enum FilterStrings {
  CURRENT_ACCOUNT_SCORE = 'Current Account Score',
  COUNTRY = 'Country',
  INDUSTRY = 'Industry',
  LAST_INBOUND_SIGNAL_DATE = 'Last Inbound Signal Date',
  LAST_OUTBOUND_SIGNAL_DATE = 'Last Outbound Signal Date',
  RECOMMENDED_ACTION = 'Recommended Action',
  ENGAGEMENT_STATUS = 'Engagement Status',
  ENGAGEMENT_INTENSITY = 'Engagement Intensity',
  OUTREACH_INTENSITY = 'Outreach Intensity',
  REVENUE = 'Revenue',
  SIGNAL_TYPE = 'Signal Type',
  SIZE = 'Size',
  WINFLUENCE = 'Winfluence',
  RANK = 'Rank',
  XEOMIN_PREDICTION_SUMMARY = 'Xeomin Prediction Summary',
}

type FilterItemsType = {
  accessor: string;
  index: string;
};
export const INDUSTRY_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'industry',
};
export const COUNTRY_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'country',
};
export const ENGAGEMENT_STATUS_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'engagementStatus',
};
export const WINFLUENCE_DROPDOWN_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'winfluence',
};
export const RECOMMENDED_ACTIONS_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'recommendedAction',
};
export const SIGNAL_TYPES_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'signalType',
};
export const RANK_TICKS: Array<string> = [
  'None',
  'Very Low',
  'Low',
  'Moderate',
  'High',
  'Very High',
];
export const filterIndexToString: {
  [x: string]: string;
} = {
  [FilterIndices.ACCOUNTS_FILTER]: 'Accounts',
  [FilterIndices.ACCOUNTS_FULL_FILTER]: 'Accounts',
  [COUNTRY_FILTER_ITEMS.index]: FilterStrings.COUNTRY,
  [FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE]: 'Account Scores',
  [FilterIndices.INDUSTRY]: FilterStrings.INDUSTRY,
  [FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER]:
    FilterStrings.LAST_INBOUND_SIGNAL_DATE,
  [FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER]:
    FilterStrings.LAST_OUTBOUND_SIGNAL_DATE,
  [RECOMMENDED_ACTIONS_FILTER_ITEMS.index]: FilterStrings.RECOMMENDED_ACTION,
  [ENGAGEMENT_STATUS_FILTER_ITEMS.index]: FilterStrings.ENGAGEMENT_STATUS,
  [FilterIndices.ENGAGEMENT_INTENSITY]: FilterStrings.ENGAGEMENT_INTENSITY,
  [FilterIndices.OUTREACH_INTENSITY]: FilterStrings.OUTREACH_INTENSITY,
  [FilterIndices.REVENUES_FILTER]: FilterStrings.REVENUE,
  [FilterIndices.SIZES_FILTER]: FilterStrings.SIZE,
  [SIGNAL_TYPES_FILTER_ITEMS.index]: FilterStrings.SIGNAL_TYPE,
  [FilterIndices.WINFLUENCE]: FilterStrings.WINFLUENCE,
  [WINFLUENCE_DROPDOWN_FILTER_ITEMS.index]: FilterStrings.WINFLUENCE,
  [FilterIndices.RANK]: FilterStrings.WINFLUENCE,
  [FilterIndices.XEOMIN_PREDICTION_SUMMARY]:
    FilterStrings.XEOMIN_PREDICTION_SUMMARY,
};
