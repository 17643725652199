import { useReactiveVar } from '@apollo/client';
import { loadedLabsVar, tokenVar } from '../apollo/rootReactiveVariables';
import { useEffect, useState } from 'react';

export const useFetchLabCardData = (
  labCardId: string,
  body?: { accountId: number },
) => {
  const token = useReactiveVar(tokenVar);
  const loadedLabs = useReactiveVar(loadedLabsVar);
  const data = body?.accountId
    ? loadedLabs[`${labCardId}-${body.accountId}`]
    : loadedLabs[labCardId];
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (!data && token && labCardId) {
        setError('');
        setLoading(true);
        try {
          const res = body
            ? await fetch(
                `${process.env.REACT_APP_SERVER}/api/labs/cards/${labCardId}/query`,
                {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(body),
                },
              )
            : await fetch(
                `${process.env.REACT_APP_SERVER}/api/labs/cards/${labCardId}`,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                },
              );
          const jsonRes = await res.json();
          setLoading(false);
          // if we had an account ID, cache under that
          if (body?.accountId) {
            loadedLabsVar({
              ...loadedLabsVar(),
              [`${labCardId}-${body.accountId}`]: jsonRes,
            });
          } else {
            loadedLabsVar({ ...loadedLabsVar(), [labCardId]: jsonRes });
          }
        } catch (err) {
          setError('Error loading labs data. Please refresh and try again.');
          setLoading(false);
        }
      }
    })();
  }, [labCardId, token, data, body]);

  return {
    data,
    loading,
    error,
  };
};
