import {Button, Inline} from '../../../../../../shared/components/Core';
import {appliedFiltersWidthVar, showingFilterPanelVar,} from 'app/src/apollo/rootReactiveVariables';
import {useContext, useEffect, useRef} from 'react';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import {useReactiveVar} from '@apollo/client';
import {useDefinedMediaQuery} from 'shared/hooks/mediaQueryHook';
import {TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons';
import {FilterItemType} from 'shared/types/companyAppStructureTypes';
import {FilterItem} from '../filterItems/FilterItem';
import {Box} from '@chakra-ui/react';

type MainFilterBarProps = {
  quickAccessFilterBarItems: FilterItemType[];
  showMoreFiltersButton: boolean;
};

export const MainFilterBar = ({
  quickAccessFilterBarItems,
  showMoreFiltersButton,
}: MainFilterBarProps) => {
  const showingPanel = useReactiveVar(showingFilterPanelVar);
  const { isLargerScreen } = useDefinedMediaQuery();
  const appliedFiltersBarRef = useRef<HTMLDivElement>(null);

  const { globalFilter } = useContext(AggregateAccountsDataContext);

  // Set the width of this bar in order to adjust the panel width when things take > 1 line
  useEffect(() => {
    if (appliedFiltersBarRef.current) {
      appliedFiltersWidthVar(appliedFiltersBarRef.current.clientWidth);
    }
  }, [globalFilter, appliedFiltersBarRef.current?.clientWidth]);

  return (
    <Box bg={'brand.white'} pt={1}>
      <div
        className={'c-header__container'}
        ref={appliedFiltersBarRef}
        style={{ paddingBottom: '8px' }}
      >
        <Inline gap={'sm'} display={'grid'}>
          {quickAccessFilterBarItems.map((item, index) =>
            index < 1 ? (
              <FilterItem
                filterItem={item}
                itemSource={'wideMenu'}
                key={index}
              />
            ) : index < 2 ? (
              <FilterItem filterItem={item} itemSource={'bar'} key={index} />
            ) : isLargerScreen ? (
              <FilterItem filterItem={item} itemSource={'bar'} key={index} />
            ) : null,
          )}
        </Inline>
        {showMoreFiltersButton && (
          <Button
            analyticsAttr={'more-filters-button'}
            className={showingPanel ? 'filter-bar-wrapper__more-button' : ''}
            fontProps={{ fontSize: 'sm' }}
            onClick={() => showingFilterPanelVar(!showingPanel)}
            py={'sm'}
            rightIcon={showingPanel ? <TriangleUpIcon /> : <TriangleDownIcon />}
            text={isLargerScreen ? 'More Filters' : 'More'}
            variant={'activeOutline'}
          />
        )}
      </div>
    </Box>
  );
};
