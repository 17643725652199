import {ReactNode, RefObject} from 'react';
import ReactToPrint from 'react-to-print';
import {copyLinkToClipboard, handleFeedbackFavoriteForLabCard, triggerEmail,} from '../helpers/labCardInteractions';
import {useFirestoreQuery} from 'shared/hooks/firestoreHook';
import {useFirebase} from '../../Firebase';
import {Icon, Inline, Tooltip} from '../../../../../shared/components/Core';
import {useToast} from '@chakra-ui/react';

type LabCardLikeFavoriteIconsType = {
  id: string;
  middleComponent?: ReactNode;
};

type LabCardShareIconsType = {
  componentRef: RefObject<HTMLElement>;
  customContentIdPrefix: string;
  customURLPrefix?: string;
  id: string;
  question: string;
};

const LabCardShareIcons = ({
  componentRef,
  customContentIdPrefix = '',
  customURLPrefix = undefined,
  id = '',
  question = '',
}: LabCardShareIconsType) => {
  // setup ReactToPrint items
  const cleanedId = `${customContentIdPrefix}${id}`;
  const data =
    (document.getElementById(cleanedId)?.childNodes[0] as HTMLElement) ||
    document.getElementById(cleanedId);
  const badgeWrapper = data?.getElementsByClassName(
    'c-badge-wrapper',
  )[0] as HTMLElement;
  const commentsWrapper = document.getElementById('comments-wrapper');
  const toast = useToast();
  return (
    <Inline alignItems={'center'} gap={'md'} justifyContent={'between'}>
      <div
        className={'h-pointer'}
        onClick={() =>
          copyLinkToClipboard(
            toast,
            customURLPrefix ? `${customURLPrefix}/${id}` : undefined,
          )
        }
      >
        <Tooltip content={'Copy to clipboard!'} hideIcon={true}>
          <Icon color={'black'} name={'io-link'} size={'md'} />
        </Tooltip>
      </div>
      <ReactToPrint
        content={() => componentRef.current}
        documentTitle={`Klearly-Insight-${cleanedId}.pdf`}
        onAfterPrint={() => {
          if (commentsWrapper && commentsWrapper.style) {
            commentsWrapper.style.removeProperty('display');
          }
          if (badgeWrapper && badgeWrapper.style) {
            badgeWrapper.style.removeProperty('display');
          }
        }}
        onBeforeGetContent={() => {
          // trigger toast message
          toast({
            title: `Download started`,
            status: 'success',
            position: 'top',
          });
          // download page as pdf
          if (badgeWrapper && badgeWrapper.style) {
            badgeWrapper.style.display = 'none';
          }
          if (commentsWrapper && commentsWrapper.style) {
            commentsWrapper.style.display = 'none';
          }
        }}
        trigger={() => (
          <div className={'h-pointer'}>
            <Tooltip content={'Download!'} hideIcon={true}>
              <Icon color={'black'} name={'io-download'} size={'lg'} />
            </Tooltip>
          </div>
        )}
      />
      <div
        className={'h-pointer'}
        onClick={() =>
          triggerEmail(
            toast,
            question,
            customURLPrefix ? `${customURLPrefix}/${id}` : undefined,
          )
        }
      >
        <Tooltip content={'Share via email!'} hideIcon={true}>
          <Icon color={'black'} name={'io-email'} size={'lg'} />
        </Tooltip>
      </div>
    </Inline>
  );
};

const LabCardLikeFavoriteIcons = ({
  id = '',
  middleComponent = undefined,
}: LabCardLikeFavoriteIconsType) => {
  // setup firebase items
  const firebase = useFirebase();
  const firebaseUserUID: string = firebase!.auth.currentUser.uid;
  const favoriteProps = useFirestoreQuery(
    firebase!.getFirestoreCollection('favorite').doc(firebaseUserUID),
  );
  const feedbackProps = useFirestoreQuery(
    firebase!.getFirestoreCollection('feedback').doc(id),
  );
  const toggleFavoriteFlag = (id, firebaseUserUID, isFavorited) =>
    firebase!.toggleFavoriteFlag(id, firebaseUserUID, isFavorited);
  const toggleFeedbackLikeFlag = (id, firebaseUserUID, isLiked) =>
    firebase!.toggleFeedbackLikeFlag(id, firebaseUserUID, isLiked);
  // clean props
  const { isFavorited, isLiked } = handleFeedbackFavoriteForLabCard(
    favoriteProps,
    feedbackProps,
    firebaseUserUID,
    id,
  );
  // setup props
  const favoriteActiveColor = 'orange';
  const likeActiveColor = 'crimson';
  const favoriteBGColor = isFavorited ? favoriteActiveColor : 'white';
  const likeBGColor = isLiked ? likeActiveColor : 'white';
  return (
    <Inline
      alignItems={'center'}
      gap={'md'}
      justifyContent={middleComponent ? 'between' : 'center'}
    >
      <div
        className={'h-pointer'}
        onClick={() => toggleFeedbackLikeFlag(id, firebaseUserUID, isLiked)}
      >
        <Tooltip content={'Like!'} hideIcon={true}>
          <Icon bgColor={likeBGColor} name={'if-like'} size={'lg'} />
        </Tooltip>
      </div>
      {middleComponent}
      <div
        className={'h-pointer'}
        onClick={() => toggleFavoriteFlag(id, firebaseUserUID, isFavorited)}
      >
        <Tooltip content={'Favorite!'} hideIcon={true}>
          <Icon bgColor={favoriteBGColor} name={'if-favorite'} size={'lg'} />
        </Tooltip>
      </div>
    </Inline>
  );
};

export { LabCardLikeFavoriteIcons, LabCardShareIcons };
