import { Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const LocationLink = ({
  googleMapsLink,
  labelText,
}: {
  googleMapsLink: string | null;
  labelText: string;
}) => {
  return googleMapsLink ? (
    <Text
      _hover={{ cursor: 'pointer' }}
      analytics-attr={`single-account-external-map-link`}
      as={'a'}
      color={'brand.gray-400'}
      fontSize={'sm'}
      fontWeight={'bold'}
      href={googleMapsLink}
      mr={2}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      {labelText}
      <ExternalLinkIcon ml={2} fontSize={'2xl'} />
    </Text>
  ) : (
    <Text fontWeight={'bold'} color={'brand.gray-400'} fontSize={'sm'}>
      {labelText}
    </Text>
  );
};
