import {useContext, useEffect, useState} from 'react';
import {AnalyticsAttrType} from 'shared/types/coreTypes.d';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import {FilterIndices} from '../../types/filterTypes';
import {sortSelectFilterOptions} from '../../helpers/sortSelectFilterOptions';
import {getFilterItemConsts} from '../../helpers/getFilterItemConsts';
import {useGetAggregateAccountsDataWithFilterVarQuery} from 'shared/graphql/generatedApiTypes';
import {adaptDictAsString} from '../../helpers/adaptFilterForQuery';
import _omit from 'lodash/omit';
import Select, {components} from 'react-select';
import {Icon, Inline} from '../../../../../../shared/components/Core';
import {
    getCheckboxSelectFilterItemStyles,
    getSelectedOptionsInitialState,
} from '../../helpers/checkboxSelectFilterItem';

export type CheckboxSelectFilterItemProps = AnalyticsAttrType & {
  filterIndex: FilterIndices;
  itemSource?: 'panel' | 'bar' | 'wideMenu';
  initialItems: any[];
  placeholder?: string;
  removeValues?: any[];
  tableAccessor: string;
  width?: string;
};

const CheckboxSelectFilterItem = ({
  analyticsAttr = '',
  filterIndex,
  initialItems,
  itemSource,
  placeholder,
  removeValues,
  tableAccessor,
  width = '200px',
}: CheckboxSelectFilterItemProps) => {
  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
    globalFilter,
    updateFilter,
  } = useContext(AggregateAccountsDataContext);

  const {
    data: filterItemAggregatedAccountsData,
    loading: filterItemAggregatedAccountsDataLoading,
  } = useGetAggregateAccountsDataWithFilterVarQuery({
    variables: {
      filterString: adaptDictAsString(_omit(globalFilter, filterIndex)) ?? '',
    },
  });

  const { aggregatedItemsAccountData, areFiltersApplied, isSelectedDisabled } =
    getFilterItemConsts({
      filterIndex,
      filterItemAggregatedAccountsData,
      filterItemAggregatedAccountsDataLoading,
      globalFilter,
      initialAggregatedAccountsData,
      initialAggregatedAccountsDataLoading,
    });

  const itemsSorted = sortSelectFilterOptions({
    aggregatedItemsAccountData,
    areFiltersApplied,
    filterIndex,
    initialItems,
    removeValues,
    tableAccessor,
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    getSelectedOptionsInitialState({
      filterIndex,
      filterItemAggregatedAccountsData,
      globalFilter,
      tableAccessor,
    }),
  );

  useEffect(() => {
    if (globalFilter && globalFilter[filterIndex]) {
      setSelectedOptions(globalFilter[filterIndex]);
    } else {
      setSelectedOptions([]);
    }
  }, [globalFilter, filterIndex, setSelectedOptions]);

  const addFilterLocally = (itemValue) => {
    if (selectedOptions.find((existingItem) => itemValue === existingItem)) {
      setSelectedOptions((prevState) =>
        prevState.filter((existingItem) => itemValue !== existingItem),
      );
    } else {
      setSelectedOptions((prevState) => [...prevState, itemValue]);
    }
  };

  const handleMenuClose = () => {
    updateFilter({
      index: filterIndex,
      value: selectedOptions,
    });
  };

  const getIconName = (value: string) =>
    selectedOptions.find((existingItem) => value === existingItem)
      ? 'checkbox-checked'
      : 'checkbox';

  // A reference for this https://julietonyekaoha.medium.com/customizing-reusable-react-select-menu-options-components-7642190caa73
  // https://github.com/esotericjules/custom-react-select-menu-component/blob/master/src/custom-select-menu/custom-select.jsx
  const Option = (props) => (
    <components.Option
      {...props}
      analytics-attr={`${analyticsAttr}-${props.data.value}`}
    >
      <Inline>
        <span className={'h-flex h-flex-align-items-center'}>
          <span className={'flag-icon-wrapper'}>
            <Icon
              color={'blue'}
              name={getIconName(props.data.value)}
              size={'md'}
            />
          </span>
          {props.data.countryCode && (
            <img
              alt={props.data.countryCode}
              className={'h-mh-sm h-height-full flag-icon'}
              src={
                require(`../../../../assets/images/flags/${props.data.countryCode.toLowerCase()}.svg`)
                  ?.default
              }
            />
          )}
          <span className={'h-ml-md'}>{props.data.label}</span>
        </span>
      </Inline>
    </components.Option>
  );

  return (
    <Select
      analytics-attr={`${analyticsAttr}-checkbox-select`}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      components={{ Option }}
      isSearchable={true}
      isDisabled={isSelectedDisabled}
      isMulti={true}
      onChange={(selectedOptions, { action }) => {
        action === 'select-option' &&
          addFilterLocally(selectedOptions[0].value);
      }}
      onInputChange={(newValue, { action }) => {
        action === 'menu-close' && handleMenuClose();
      }}
      options={itemsSorted}
      placeholder={placeholder}
      styles={getCheckboxSelectFilterItemStyles(
        selectedOptions,
        width,
        itemSource,
      )}
      value={null}
    />
  );
};

export default CheckboxSelectFilterItem;
