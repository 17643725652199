import {
  ButtonActionType,
  TableColumnDisplay,
  TableColumnType,
} from 'shared/types/companyAppStructureTypes';
import _size from 'lodash/size';
import Button from '../../../../../shared/components/Core/Button';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { Tooltip } from '../../../../../shared/components/Core';
import {
  DataFieldFormat,
  DataFieldRenderer,
} from 'shared/renderers/DataFieldRenderer';
import { IconRenderer } from 'shared/renderers/IconRenderer';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

export const getTableCell = (column: TableColumnType) => {
  switch (column.displayType) {
    case TableColumnDisplay.Button:
      return ({ row, value }) => {
        const iconValue = column.iconAccessor
          ? row.original[column.iconAccessor]
          : null;
        switch (column.actionData.type) {
          case ButtonActionType.Navigate:
            return value && _size(value) > 0 ? (
              <Button
                analyticsAttr={`${column.header}-${value}`}
                asLink
                rel={'noopener noreferrer'}
                href={`${column.actionData.data.routeToPush}/${row.original.id}`}
                target={'_blank'}
                leftIcon={
                  column.leftIcon && iconValue ? (
                    <Tooltip content={cleanStringCase(iconValue)} hideIcon>
                      {IconRenderer({
                        iconName: column.leftIcon,
                        iconColor: 'brand.blue',
                        value: iconValue,
                      })}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
                maxW={72}
                text={
                  column.truncateAt
                    ? DataFieldRenderer(
                        value,
                        column.dataFieldFormat,
                        column.truncateAt,
                      )
                    : DataFieldRenderer(value, column.dataFieldFormat)
                }
                fontProps={{
                  color: 'brand.lightBlue',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                }}
                variant={'transparent'}
              />
            ) : (
              '-'
            );
        }
      };
    case TableColumnDisplay.Static:
      return ({ row, value }) =>
        column.leftIcon ? (
          <Flex align={'center'}>
            <Box mr={1}>
              {IconRenderer({
                iconName: column.leftIcon,
                iconColor: 'brand.black',
                value,
              })}
            </Box>
            {DataFieldRenderer(
              value,
              column.dataFieldFormat,
              column.truncateAt,
            )}
          </Flex>
        ) : (
          DataFieldRenderer(value, column.dataFieldFormat, column.truncateAt)
        );
    case TableColumnDisplay.NonData:
      return () =>
        column.leftIcon ? (
          <Flex align={'center'}>
            {IconRenderer({
              iconName: column.leftIcon,
              iconColor: 'brand.black',
              value: column.value,
            })}
            {DataFieldRenderer(column.value, DataFieldFormat.UNFORMATTEDTEXT)}
          </Flex>
        ) : (
          DataFieldRenderer(column.value, DataFieldFormat.UNFORMATTEDTEXT)
        );
    case TableColumnDisplay.Expander:
      return ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <ChevronDownIcon fontSize={'4xl'} minW={3} />
          ) : (
            <ChevronRightIcon fontSize={'4xl'} minW={3} />
          )}
        </span>
      );
  }
};
