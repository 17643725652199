import { ReactNode, useCallback, useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Tooltip } from '../../../../../shared/components/Core';
import {
  TableColumnDisplay,
  TableColumnType,
} from 'shared/types/companyAppStructureTypes';
import { getTableCell } from '../helpers/getTableCell';
import { ApiPageInfo } from 'shared/graphql/generatedApiTypes';
import { AnalyticsAttrType, OptionsType } from 'shared/types/coreTypes.d';
import { ServerPaginatedTable } from './ServerPaginatedTable';
import {
  DataFieldFormat,
  DataFieldRenderer,
} from 'shared/renderers/DataFieldRenderer';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';

type AbstractedTableProps = AnalyticsAttrType & {
  columns: TableColumnType[];
  data: any;
  defaultSort: string;
  error: string | null;
  fetchData: (p: { pageIndex: any; pageSize: any }) => void;
  legend: ReactNode | null;
  pageInfo: ApiPageInfo;
};

export const AbstractedTable = ({
  analyticsAttr,
  columns,
  data = [],
  defaultSort,
  error,
  legend,
  pageInfo,
  fetchData,
}: AbstractedTableProps) => {
  const renderRowSubComponent = useCallback(
    ({ row }) => {
      // there can only be one of these
      const subcomponentStructure = columns.find(
        (column) => column.displayType === TableColumnDisplay.Expander,
      );

      if (!subcomponentStructure) {
        return null;
      }

      //@ts-ignore: we know this is here if it's an expander column
      const { subcomponent } = subcomponentStructure;
      let titleValue;
      if (
        subcomponent.title.dataFieldFormat === DataFieldFormat.ADDRESS_LINE2
      ) {
        titleValue = {
          addressCity: row.original.addressCity,
          addressRegion: row.original.addressRegion,
          addressZipCode: row.original.addressZipCode,
        };
      } else {
        titleValue = row.original[subcomponent.title.fields[0]];
      }

      return (
        <Box ml={8}>
          <Text fontWeight={'bold'} mb={2}>
            {DataFieldRenderer(titleValue, subcomponent.title.dataFieldFormat)}
          </Text>
          {subcomponent.rows.map((subRow) => (
            <Flex key={subRow.label}>
              <Text>{subRow.label} &nbsp;</Text>
              <Text fontWeight={'bold'}>
                {row.original[subRow.dataField]
                  ? DataFieldRenderer(
                      row.original[subRow.dataField],
                      subRow.dataFieldFormat,
                    )
                  : '-'}
              </Text>
            </Flex>
          ))}
        </Box>
      );
    },
    [columns],
  );

  // setup table columns and data
  const tableColumns = useMemo(
    () =>
      columns.map((column, index) => ({
        Header: 'All Accounts',
        columns: [
          {
            Cell: getTableCell(column),
            Header: column.headerTooltip ? (
              <Tooltip content={column.headerTooltip}>
                <Text>{column.header}</Text>
              </Tooltip>
            ) : (
              column.header
            ),
            accessor: column.accessor,
            analyticsAttrIndex: column.accessor,
            id: column.accessor + index,
            isNumeric: column.isNumeric,
            disableSortBy: !column.isSortable,
          },
        ],
      })),
    [columns],
  );

  const sortableColumnOptions = columns.reduce((accum, current) => {
    if (current.isSortable) {
      accum.push({
        label: current.header ?? '',
        value: current.accessor,
        analyticsAttr: cleanAnalyticsStringList([
          current.header,
          'sort select',
        ]),
      });
    }
    return accum;
  }, [] as OptionsType[]);

  return (
    <Box p={8}>
      <ServerPaginatedTable
        analyticsAttr={analyticsAttr}
        columns={tableColumns}
        data={data}
        defaultSort={defaultSort}
        error={error}
        fetchData={fetchData}
        legend={legend}
        pageInfo={pageInfo}
        renderRowSubComponent={renderRowSubComponent}
        sortableColumnOptions={sortableColumnOptions}
      />
    </Box>
  );
};
