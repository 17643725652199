export enum VolumePredictionLevel {
  AboutTheSame = 'ABOUT_THE_SAME',
  Higher = 'HIGHER',
  Lower = 'LOWER',
  MuchHigher = 'MUCH_HIGHER',
  MuchLower = 'MUCH_LOWER',
  SlightlyHigher = 'SLIGHTLY_HIGHER',
  SlightlyLower = 'SLIGHTLY_LOWER',
}

export enum RiskPredictionLevel {
  LeaningLikely = 'LEANING_LIKELY',
  LeaningUnlikely = 'LEANING_UNLIKELY',
  Likely = 'LIKELY',
  Tossup = 'TOSSUP',
  Unlikely = 'UNLIKELY',
  VeryLikely = 'VERY_LIKELY',
  VeryUnlikely = 'VERY_UNLIKELY',
}

export enum AcquisitionPredictionLevel {
  ModerateBet = 'MODERATE_BET',
  StrongBet = 'STRONG_BET',
  WeakBet = 'WEAK_BET',
}

export const volumeBins = {
  1: VolumePredictionLevel.MuchLower,
  2: VolumePredictionLevel.Lower,
  3: VolumePredictionLevel.SlightlyLower,
  4: VolumePredictionLevel.AboutTheSame,
  5: VolumePredictionLevel.SlightlyHigher,
  6: VolumePredictionLevel.Higher,
  7: VolumePredictionLevel.MuchHigher,
};

export const riskBins = {
  1: RiskPredictionLevel.VeryUnlikely,
  2: RiskPredictionLevel.Unlikely,
  3: RiskPredictionLevel.LeaningUnlikely,
  4: RiskPredictionLevel.Tossup,
  5: RiskPredictionLevel.LeaningLikely,
  6: RiskPredictionLevel.Likely,
  7: RiskPredictionLevel.VeryLikely,
};

export const acquisitionBins = {
  1: AcquisitionPredictionLevel.WeakBet,
  2: AcquisitionPredictionLevel.ModerateBet,
  3: AcquisitionPredictionLevel.StrongBet,
};
