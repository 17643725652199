import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import { WinfluenceScore } from '../../shared/WinfluenceScore';
import { getWinfluencePriorityDetails } from '../../../../helpers/getWinfluencePriorityDetails';
import { WinfluenceChangeText } from './WinfluenceChangeText';
import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';

export const Winfluence = () => {
  const { id: selectedAccountId } = useParams<{ id: string }>();

  const { data } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });

  const accountInfo = data?.account;

  const { title, nonPriorityLift, priorityLift, subText } =
    getWinfluencePriorityDetails({ accountInfo });

  return (
    <Box>
      <Flex align={'center'} justify={'center'}>
        <Text>WINFLUENCE</Text>
        <Badge
          bg={'brand.green'}
          color={'brand.white'}
          ml={2}
          p={1}
          variant={'standard'}
        >
          Retention
        </Badge>
      </Flex>
      <Text ml={8} mt={6}>
        TODAY
      </Text>
      <Flex align={'center'}>
        <Text
          ml={4}
          mr={4}
          color={'brand.black'}
          fontSize={'xl'}
          fontWeight={'bold'}
        >
          {title}
        </Text>
        <WinfluenceScore
          nonPriorityScore={nonPriorityLift}
          winfluenceScore={priorityLift}
        />
        <Text ml={4}>{subText}</Text>
      </Flex>
      <Text mt={6} ml={6} mb={4}>
        CHANGE VS LAST WEEK
      </Text>
      <WinfluenceChangeText />
    </Box>
  );
};
