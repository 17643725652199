import { ReactNode, useEffect, useRef, useState } from 'react';

// Calculates the max height needed to animate to when using react-spring
// Optionally takes a content parameter to trigger resize if that content height changes
export const useAnimationHeightCalc = (content?: ReactNode) => {
  const animationRef = useRef<HTMLDivElement>(null);
  const [contentMaxHeight, setContentMaxHeight] = useState<number>(0);

  useEffect(() => {
    const calcContentMaxHeight = () => {
      if (animationRef && animationRef.current) {
        setContentMaxHeight(animationRef.current.scrollHeight);
      }
    };
    calcContentMaxHeight();
    window.addEventListener('resize', () => calcContentMaxHeight());
    return () =>
      window.removeEventListener('resize', () => calcContentMaxHeight());
  }, [contentMaxHeight, animationRef, content]);

  return {
    animationRef,
    contentMaxHeight,
  };
};
