import { useState } from 'react';
import { FetchResult, useApolloClient } from '@apollo/client';
import _size from 'lodash/size';
import classnames from 'classnames';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { ADD_ACCOUNT_GROUP } from 'shared/graphql/gqlMutations';
import {
  Button,
  Inline,
  Input,
  Modal,
  Txt,
} from '../../../../../../shared/components/Core';
import { useGetAllAccountGroupsQuery } from 'shared/graphql/generatedApiTypes';
import { groupAccountGroups } from '../../../GlobalFilters/helpers/groupAccountGroups';
import { KlearlyUserType } from '../../../Firebase/firebase';
import { useFirebase } from '../../../Firebase';
import { useToast } from '@chakra-ui/react';
import { pluralizeAccountsString } from 'shared/helpers/formatHelpers';

type CreateAccountGroupListModalProps = {
  onClose: () => void;
  onSubmitSuccessfully: (res: FetchResult<any>) => void;
  selectedRowIds: string[];
};

const CreateAccountGroupListModal = ({
  onClose = () => {},
  onSubmitSuccessfully = () => {},
  selectedRowIds = [],
}: CreateAccountGroupListModalProps) => {
  const apolloClient = useApolloClient();
  const toast = useToast();
  const [creatingNewAccountGroup, setCreatingNewAccountGroup] =
    useState<boolean>(false);
  const [errorCreatingAccountGroup, setErrorCreatingAccountGroup] = useState<
    string | undefined
  >(undefined);
  // setup input state items
  const { bind: bindAccountGroupTitle, value: accountGroupTitle } = useInput(
    '',
    {
      label: 'Name',
      placeholder: 'Name',
    },
  ) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const { bind: bindAccountGroupDescription, value: accountGroupDescription } =
    useInput('', {
      label: 'Description',
      placeholder: 'Description',
    }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const isModalSubmitButtonDisabled = _size(accountGroupTitle) === 0;
  const shouldPreventSubmit =
    creatingNewAccountGroup || isModalSubmitButtonDisabled;
  const firebase = useFirebase();
  const klearlyUser: KlearlyUserType | null = firebase!.klearlyUser || null;
  const { data, refetch } = useGetAllAccountGroupsQuery();
  const { lists: accountLists } = groupAccountGroups({
    accountGroups: data?.accountGroups,
    klearlyUser,
  });

  const _onSubmit = async () => {
    setCreatingNewAccountGroup(true);
    setErrorCreatingAccountGroup(undefined);
    if (_size(selectedRowIds) === 0) {
      setCreatingNewAccountGroup(false);
      setErrorCreatingAccountGroup(
        'Please select one or more accounts from the accounts listed below before saving your static list.',
      );
    }
    const newAccountGroup = {
      accountIds: selectedRowIds,
      description: accountGroupDescription.trim(),
      filterString: null,
      title: accountGroupTitle.trim(),
    };
    if (accountLists?.find((segment) => segment?.title === accountGroupTitle)) {
      setErrorCreatingAccountGroup(
        'An account segment already exists with this name. Please try another.',
      );
      setCreatingNewAccountGroup(false);
    } else {
      try {
        const res = await apolloClient.mutate({
          mutation: ADD_ACCOUNT_GROUP,
          variables: { input: newAccountGroup },
        });
        await refetch();
        setCreatingNewAccountGroup(false);
        toast({
          title: `List successfully created`,
          status: 'success',
          position: 'top',
        });
        onSubmitSuccessfully(res);
      } catch (err) {
        setCreatingNewAccountGroup(false);
        if (
          (err as any).graphQLErrors &&
          (err as any).graphQLErrors.length > 0
        ) {
          setErrorCreatingAccountGroup((err as any).graphQLErrors[0].message);
        } else {
          setErrorCreatingAccountGroup((err as any).message);
        }
      }
    }
  };

  const statsClasses = classnames({
    'h-pb-lg': true,
    'h-pt-sm': errorCreatingAccountGroup,
  });
  // render
  return (
    <Modal
      ariaLabel={'Add Account Group Modal'}
      body={
        <form className={'c-form'} onSubmit={() => _onSubmit()}>
          {errorCreatingAccountGroup && (
            <div className={'c-text-field--error h-border-bottom'}>
              <div
                className={'c-text-field__message h-mb-md'}
                style={{ position: 'relative' }}
              >
                <div
                  className={
                    'c-text h-text-size-md h-text-align-left h-color-red'
                  }
                >
                  {`Error creating group: ${errorCreatingAccountGroup}`}
                </div>
              </div>
            </div>
          )}
          <div className={statsClasses}>
            <Txt>
              {`You are about to save ${_size(
                selectedRowIds,
              ).toLocaleString()} ${pluralizeAccountsString(
                _size(selectedRowIds),
              )} to your list.`}
            </Txt>
          </div>
          <Input {...bindAccountGroupTitle} className={'h-pb-sm'} />
          <Input {...bindAccountGroupDescription} className={'h-pb-sm'} />
        </form>
      }
      close={creatingNewAccountGroup ? () => {} : () => onClose()}
      footer={
        <>
          <Button
            className={'h-mr-lg'}
            variant={'activeOutline'}
            onClick={onClose}
            text={'Cancel'}
          />
          <Button
            onClick={shouldPreventSubmit ? () => {} : () => _onSubmit()}
            isDisabled={isModalSubmitButtonDisabled}
            isLoading={!isModalSubmitButtonDisabled && creatingNewAccountGroup}
            text={'Submit'}
            type={'submit'}
          />
        </>
      }
      header={
        <Inline justifyContent={'between'} pv={'2xl'}>
          <Txt
            size={'lg'}
            text={'Create Static Account List'}
            weight={'bold'}
          />
        </Inline>
      }
      modalWidth={'45%'}
      submit={shouldPreventSubmit ? () => {} : () => _onSubmit()}
    />
  );
};

export default CreateAccountGroupListModal;
