import { CSSTransition } from 'react-transition-group';
import {
  PANEL_ANIMATION_DURATION,
  panelTransitionStyles,
} from '../../constants/panelAnimation';
import {
  appliedFiltersWidthVar,
  showingFilterPanelVar,
} from 'app/src/apollo/rootReactiveVariables';
import { Icon } from '../../../../../../shared/components/Core';
import { SegmentedControl } from '../../../../../../shared/components/Core/SegmentedControl';
import { FilterPanelTabValues } from '../../constants/filterPanelTabOptions';
import { useAnimationHeightCalc } from 'app/src/hooks/useAnimationHeightCalc';
import { useReactiveVar } from '@apollo/client';
import { useState } from 'react';
import { TabSwitch } from '../tabs/TabSwitch';
import { useWindowSize } from 'app/src/hooks/useWindowSize';
import { FilterPanelStructure } from 'shared/types/companyAppStructureTypes';
import { OptionsType } from 'shared/types/coreTypes.d';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

export const FilterPanel = ({ panel }: { panel: FilterPanelStructure }) => {
  const { animationRef } = useAnimationHeightCalc();
  const showingPanel = useReactiveVar(showingFilterPanelVar);
  const [selectedPanelTab, setSelectedPanelTab] =
    useState<FilterPanelTabValues>(
      panel.showTabs.length ? panel.showTabs[0] : FilterPanelTabValues.BUILD,
    );

  // Make sure that panel is centered
  const appliedFiltersWidth = useReactiveVar(appliedFiltersWidthVar);
  const { width } = useWindowSize();
  const panelLeft = (width - appliedFiltersWidth) / 2;

  const filterPanelTabOptions: OptionsType[] = panel.showTabs.length
    ? panel.showTabs.map((tab) => ({
        value: tab,
        label: cleanStringCase(tab),
        analyticsAttr: `filter-panel-tab=${tab}`,
      }))
    : [];

  return (
    <>
      {showingPanel && <div className={'c-modal-backdrop'} />}
      <CSSTransition
        in={showingPanel}
        timeout={PANEL_ANIMATION_DURATION}
        unmountOnExit
        mountOnEnter
      >
        {(transitionState) => (
          <div
            className={'h-p-xl filter-bar-wrapper__panel'}
            ref={animationRef}
            style={{
              ...panelTransitionStyles[transitionState],
              left: panelLeft,
              width: appliedFiltersWidth,
            }}
          >
            <span
              className={'h-grid filter-bar-wrapper__panel-x'}
              onClick={() => showingFilterPanelVar(false)}
            >
              <Icon hoverColor={'red'} name={'io-x'} size={'lg'} />
            </span>
            <div className={'h-flex h-flex-justify-content-center'}>
              <SegmentedControl
                asTabs
                onChange={(newTab) =>
                  setSelectedPanelTab(newTab as FilterPanelTabValues)
                }
                segments={filterPanelTabOptions}
                selectedSegmentValue={selectedPanelTab}
              />
            </div>
            <div className={'filter-bar-wrapper__panel-tab-wrapper'}>
              <TabSwitch
                selectedPanelTab={selectedPanelTab}
                segmentBuilderSections={panel.segmentBuilderSections}
                showingSaveSelection={
                  !!filterPanelTabOptions.find(
                    (tab) => tab.value === FilterPanelTabValues.TEAM_SEGMENTS,
                  )
                }
              />
            </div>
          </div>
        )}
      </CSSTransition>
    </>
  );
};
