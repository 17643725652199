import { useGetAllAccountGroupsQuery } from 'shared/graphql/generatedApiTypes';
import { SegmentsOrLists } from './SegmentsOrLists';
import { groupAccountGroups } from '../../../helpers/groupAccountGroups';
import { useFirebase } from '../../../../Firebase';
import { SegmentsAndListsIndices } from '../../../constants/segmentsAndListsIndices';
import { globalFilterAppDefinitions } from '../../../helpers/globalFilterAppDefinitions';

export const TeamSegmentsTab = () => {
  const { data, loading } = useGetAllAccountGroupsQuery();
  const firebase = useFirebase();
  const klearlyUser = firebase!.klearlyUser ?? null;
  const accountSegments = data?.accountGroups ?? [];
  const { groups } = groupAccountGroups({
    accountGroups: accountSegments,
    klearlyUser,
  });

  const appDefinitions = globalFilterAppDefinitions.getTeamSegmentsTabText();

  return (
    <div
      key={SegmentsAndListsIndices.SEGMENTS_INDEX}
      label={SegmentsAndListsIndices.SEGMENTS_INDEX}
    >
      <SegmentsOrLists
        data={groups ?? []}
        emptyListString={appDefinitions.empty.text}
        index={SegmentsAndListsIndices.SEGMENTS_INDEX}
        loading={loading}
        segmentOrListType={'segment'}
        subtext={appDefinitions.tabTitle.subtext}
        title={appDefinitions.tabTitle.text}
      />
    </div>
  );
};
