import {
  ApiGetAccountGroupsByAccountQuery,
  ApiGetAllAccountGroupsQuery,
} from 'shared/graphql/generatedApiTypes';

type MapAccountListsToExistingListsProps = {
  allLists: ApiGetAllAccountGroupsQuery['accountGroups'];
  accountLists: ApiGetAccountGroupsByAccountQuery['accountGroupsForAccount'];
};

type MapAccountListsToExistingListsReturn = { [key: number]: boolean };

export const mapAccountListsToExistingLists = ({
  allLists,
  accountLists,
}: MapAccountListsToExistingListsProps): MapAccountListsToExistingListsReturn => {
  const mapToReturn: MapAccountListsToExistingListsReturn = {};

  // build an object that determines whether the list should be checked or not
  allLists?.forEach((list) => {
    if (
      accountLists?.find((accountList) => accountList?.id === list?.id) &&
      list?.id !== undefined
    ) {
      mapToReturn[list.id] = true;
    } else if (list?.id !== undefined) {
      mapToReturn[list.id] = false;
    }
  });

  return mapToReturn;
};
