import { useMemo, useState } from 'react';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _round from 'lodash/round';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';

import { ACCOUNT, ACCOUNT_LIST } from 'app/src/constants/routes';

import {
  Button,
  IndeterminateCheckbox,
  Table,
  Tooltip,
} from '../../../../../../shared/components/Core';
import CreateAccountGroupListModal from './CreateAccountListGroupModal';
import { FilterIndices } from '../../../GlobalFilters/types/filterTypes';
import { WinfluenceScore } from '../sav/shared/WinfluenceScore';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '@chakra-ui/react';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';
import { cleanStringCase, numberFormatter } from 'shared/helpers/formatHelpers';

type AccountsTableProps = {
  accounts: Array<{}>;
  isListCreationModalShowing: boolean;
  selectedRowIds: Array<string>;
  setIsListCreationModalShowing: (newVal: boolean) => void;
  setSelectedRowIds: (ids: Array<string>) => void;
};

const AccountsTable = ({
  accounts = [],
  isListCreationModalShowing,
  selectedRowIds = [],
  setIsListCreationModalShowing,
  setSelectedRowIds = () => {},
}: AccountsTableProps) => {
  const pageId = ACCOUNT_LIST.name;
  const { text: accountScoreText, tooltip: accountScoreTooltip } =
    getAppDefinitionsItem(
      pageId,
      FilterIndices.CURRENT_WIN_PROBABILITY,
    ) as TextDefinitionType;
  const { text: winfluenceText, tooltip: winfluenceTooltip } =
    getAppDefinitionsItem(pageId, FilterIndices.LIFT) as TextDefinitionType;

  // use a boolean flag to determine if we've just saved & therefore want to reset all the selected rows in the table
  const [hasJustSaved, setHasJustSaved] = useState(false);

  // setup table columns and data
  const tableColumns = useMemo(
    () => [
      {
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            analyticsAttr={`Accounts ${row.original.id}`}
            extraAnalyticsAttr={'Accounts'}
          />
        ),
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            {...getToggleAllRowsSelectedProps()}
            analyticsAttr={'Accounts All Accounts'}
          />
        ),
        accessor: 'id',
        analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
        disableSortBy: true,
      },
      {
        Header: 'All Accounts',
        columns: [
          {
            Cell: ({ row, value }) =>
              value && _size(value) > 0 ? (
                <Button
                  analyticsAttr={`Accounts ${value}`}
                  rightIcon={<ChevronRightIcon boxSize={5} mt={1} />}
                  asLink
                  rel={'noopener noreferrer'}
                  href={`${ACCOUNT[0]}/${row.original.id}`}
                  target={'_blank'}
                  text={value}
                  fontProps={{ color: 'brand.black', fontWeight: 'normal' }}
                  variant={'transparent'}
                />
              ) : (
                '-'
              ),
            Header: 'Account Name',
            accessor: FilterIndices.ACCOUNT_NAME,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
          {
            Cell: ({ value }) => (value ? _round(value) : ''),
            Header: (
              <Tooltip
                analyticsAttr={accountScoreText}
                content={<Text fontSize={'sm'}>{accountScoreTooltip}</Text>}
                text={accountScoreText}
              />
            ),
            accessor: FilterIndices.CURRENT_WIN_PROBABILITY,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
          {
            Cell: ({ row }) => (
              <WinfluenceScore
                nonPriorityScore={row.original.rankAll || 0}
                winfluenceScore={row.original.rankPriority || 0}
              />
            ),
            Header: (
              <Tooltip
                analyticsAttr={winfluenceText}
                content={<Text fontSize={'sm'}>{winfluenceTooltip}</Text>}
                text={winfluenceText}
              />
            ),
            accessor: (originalRow) =>
              _find(originalRow.goalValues, {
                isPriority: true,
              })?.lift,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
            id: FilterIndices.LIFT,
          },
          {
            Cell: ({ value }) => (value ? cleanStringCase(value) : '-'),
            Header: 'Industry',
            accessor: FilterIndices.INDUSTRY,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
          {
            Cell: ({ value }) => numberFormatter(value, true),
            Header: 'Revenue',
            accessor: FilterIndices.REVENUES_FILTER,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
          {
            Cell: ({ value }) => numberFormatter(value, false),
            Header: 'Size',
            accessor: FilterIndices.EMPLOYEE_COUNT_FILTER,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
        ],
      },
    ],
    [accountScoreText, accountScoreTooltip, winfluenceText, winfluenceTooltip],
  );

  return (
    <div className={'h-p-lg'}>
      <Table
        analyticsAttr={'Accounts'}
        columns={tableColumns}
        data={accounts}
        hasJustSaved={hasJustSaved}
        initialDefaultSortByColumnId={'accountName'}
        initialSelectedRows={selectedRowIds.reduce(
          (accum, next) => ({ ...accum, [next]: true }),
          {},
        )}
        perPage={30}
        setHasJustSaved={setHasJustSaved}
        setSelectedRows={(rowIds) => {
          if (
            !_isEqual(_sortBy(rowIds), _sortBy(selectedRowIds)) &&
            !hasJustSaved
          ) {
            setSelectedRowIds(rowIds);
          }
        }}
      />
      {isListCreationModalShowing && (
        <CreateAccountGroupListModal
          onClose={() => setIsListCreationModalShowing(false)}
          onSubmitSuccessfully={() => {
            setHasJustSaved(true);
            setIsListCreationModalShowing(false);
          }}
          selectedRowIds={selectedRowIds}
        />
      )}
    </div>
  );
};

export default AccountsTable;
