import { useMemo, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import _startCase from 'lodash/startCase';

import {
  IndeterminateCheckbox,
  Table,
  Tooltip,
  Txt,
} from '../../../../../../shared/components/Core';

import SectionHeader from '../../../../../../shared/components/Core/SectionHeader';
import { MAV_PRIORITY_SIGNAL_RECOMMENDATIONS_LIST } from 'app/src/constants/routes';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

const PAUSE_INDEX = 'pause';
const PURSUE_INDEX = 'pursue';
const SIGNAL_INDEX = 'signal_type';
const PRIORITY_SIGNAL_HEADER_INDEX = 'priority_signal_header';

type PrioritySignalRecommendationsType = {
  data: Array<{}>;
  onSignalSelection: (signals: Array<string>) => void;
};

const PrioritySignalRecommendations = ({
  data = [],
  onSignalSelection = () => {},
}: PrioritySignalRecommendationsType) => {
  // setup state
  const [selectedRowSignals, setSelectedRowSignals] = useState<Array<string>>(
    [],
  );
  // setup static text
  const pageId = MAV_PRIORITY_SIGNAL_RECOMMENDATIONS_LIST.name;
  const { text: signalText, tooltip: signalTooltip } = getAppDefinitionsItem(
    pageId,
    SIGNAL_INDEX,
  ) as TextDefinitionType;
  const { text: pursueText, tooltip: pursueTooltip } = getAppDefinitionsItem(
    pageId,
    PURSUE_INDEX,
  ) as TextDefinitionType;
  const { text: pauseText, tooltip: pauseTooltip } = getAppDefinitionsItem(
    pageId,
    PAUSE_INDEX,
  ) as TextDefinitionType;
  const { subtext: headerSubtext, text: headerText } = getAppDefinitionsItem(
    pageId,
    PRIORITY_SIGNAL_HEADER_INDEX,
  ) as TextDefinitionType;
  // setup table columns and data
  const tableColumns = useMemo(
    () => [
      {
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            analyticsAttr={cleanAnalyticsStringList([
              'Primary Actions',
              row.original[SIGNAL_INDEX].value,
            ])}
          />
        ),
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            {...getToggleAllRowsSelectedProps()}
            analyticsAttr={cleanAnalyticsStringList([
              'Primary Actions all actions',
            ])}
          />
        ),
        accessor: 'id',
        analyticsAttrIndex: SIGNAL_INDEX,
        disableSortBy: true,
      },
      {
        Header: 'Priority Signal',
        columns: [
          {
            Cell: ({ value }) => _startCase(value),
            Header: (
              <Tooltip
                analyticsAttr={signalText}
                content={<Txt>{signalTooltip}</Txt>}
                text={signalText}
              />
            ),
            accessor: SIGNAL_INDEX,
            analyticsAttrIndex: SIGNAL_INDEX,
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: 'Total Accounts',
            accessor: 'accounts',
            analyticsAttrIndex: SIGNAL_INDEX,
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: (
              <Tooltip
                analyticsAttr={pursueText}
                content={<Txt>{pursueTooltip}</Txt>}
                text={pursueText}
              />
            ),
            accessor: PURSUE_INDEX,
            analyticsAttrIndex: SIGNAL_INDEX,
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: (
              <Tooltip
                analyticsAttr={pauseText}
                content={<Txt>{pauseTooltip}</Txt>}
                text={pauseText}
              />
            ),
            accessor: PAUSE_INDEX,
            analyticsAttrIndex: SIGNAL_INDEX,
          },
        ],
      },
    ],
    [
      pauseText,
      pauseTooltip,
      pursueText,
      pursueTooltip,
      signalText,
      signalTooltip,
    ],
  );
  const tableData = useMemo(() => {
    return data;
  }, [data]);

  return (
    <>
      <SectionHeader
        addHeaderLeftMargin
        showBackgroundColor={false}
        subtitle={headerSubtext}
        title={headerText}
      />
      <div className={'h-p-lg'}>
        <Table
          analyticsAttr={'Primary Actions'}
          columns={tableColumns}
          data={tableData}
          initialDefaultSortByColumnDesc={true}
          initialDefaultSortByColumnId={'accounts'}
          selectedRowIndex={SIGNAL_INDEX}
          setSelectedRows={(signalTypes) => {
            if (!_isEqual(_sortBy(signalTypes), _sortBy(selectedRowSignals))) {
              setSelectedRowSignals(signalTypes);
              onSignalSelection(signalTypes);
            }
          }}
        />
      </div>
    </>
  );
};

export default PrioritySignalRecommendations;
