import _size from 'lodash/size';
import _omit from 'lodash/omit';
import { FilterIndices } from '../types/filterTypes';
import {
  ApiGetAggregateAccountsDataQuery,
  ApiGetAggregateAccountsDataWithFilterVarQuery,
} from 'shared/graphql/generatedApiTypes';
import { GlobalFilterReducerState } from '../reducers/globalFilterReducer';
import { adaptDictAsDict } from './adaptFilterForQuery';
import { FilterDictType } from '../types/filterDict';

type GetFilterItemConstsParams = {
  filterIndex: FilterIndices;
  filterItemAggregatedAccountsData:
    | ApiGetAggregateAccountsDataWithFilterVarQuery
    | undefined;
  filterItemAggregatedAccountsDataLoading: boolean;
  globalFilter: GlobalFilterReducerState;
  initialAggregatedAccountsData: ApiGetAggregateAccountsDataQuery | undefined;
  initialAggregatedAccountsDataLoading: boolean;
};

export const getFilterItemConsts = ({
  filterIndex,
  filterItemAggregatedAccountsData,
  filterItemAggregatedAccountsDataLoading,
  globalFilter,
  initialAggregatedAccountsDataLoading,
}: GetFilterItemConstsParams) => {
  const cleanedFilterDict = _omit(
    adaptDictAsDict(globalFilter as FilterDictType),
    [FilterIndices.ACCOUNTS_FILTER_TYPE, filterIndex],
  );

  const aggregatedItemsAccountData =
    filterItemAggregatedAccountsData?.aggregateAccountsData;

  const isSelectedDisabled =
    initialAggregatedAccountsDataLoading ||
    filterItemAggregatedAccountsDataLoading;
  const areFiltersApplied = _size(cleanedFilterDict) > 0;
  return {
    aggregatedItemsAccountData,
    areFiltersApplied,
    isSelectedDisabled,
  };
};
