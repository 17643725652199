import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { RefObject } from 'react';
import { OnChangeInputChangeEventType } from './Input';
import { Checkbox } from '@chakra-ui/react';

type CheckboxType = {
  analyticsAttr?: string;
  checked?: boolean;
  id?: string;
  isDisabled?: boolean;
  onChange?: (event: OnChangeInputChangeEventType) => void;
  resolvedRef?: RefObject<HTMLInputElement> | null;
  text?: string;
};

const StyledCheckbox = ({
  analyticsAttr = undefined,
  checked,
  id,
  isDisabled,
  onChange,
  resolvedRef = null,
  text,
}: CheckboxType) => {
  // setup consts
  let analyticsStr = cleanAnalyticsStringList([analyticsAttr, 'checkbox']);

  return (
    <Checkbox
      analytics-attr={analyticsStr}
      aria-label={text}
      colorScheme={'red'}
      isChecked={checked}
      id={id}
      isDisabled={!!isDisabled}
      onChange={onChange}
      resolvedref={resolvedRef}
    >
      {!!text && text}
    </Checkbox>
  );
};

export default StyledCheckbox;
