import {createIcon} from '@chakra-ui/icon';

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 12 12',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='12'
      width='12'
      viewBox='0 0 12 12'
    >
      <title>filter tool</title>
      <g
        strokeWidth='1'
        strokeLinejoin='round'
        fill='none'
        stroke='#ffffff'
        strokeLinecap='round'
        className='nc-icon-wrapper'
      >
        <polygon points='0.5 0.5 11.5 0.5 7.5 6.5 7.5 11.5 4.5 11.5 4.5 6.5 0.5 0.5' />
      </g>
    </svg>
  ),
});
