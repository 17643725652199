// import { useGetMessageFeedQuery } from '../../../Apollo/generatedApiTypes';
import {Card, Loading} from '../../../../../shared/components/Core';
import {Box} from '@chakra-ui/react';

export const InsightsFeed = () => {
  //  const { id } = useParams<{ id: string }>();

  // const { data } = useGetMessageFeedQuery({
  //   ...defaultQueryFetchPolicy,
  //   variables: { accountId: parseInt(id) },
  // });

  const data = { messageFeed: [] };

  return (
    <Card>
      {data?.messageFeed?.map((insight, index) =>
        insight ? (
          <Box key={index}>
            {/*<InsightItem*/}
            {/*  categories={insight.categories}*/}
            {/*  date={formatDistance(new Date(insight.timestamp), new Date(), {*/}
            {/*    addSuffix: true,*/}
            {/*    includeSeconds: true,*/}
            {/*  })}*/}
            {/*  isLast={*/}
            {/*    !!(data.messageFeed && index === data.messageFeed.length - 1)*/}
            {/*  }*/}
            {/*  label={insight.label}*/}
            {/*  message={insight.message}*/}
            {/*/>*/}
          </Box>
        ) : (
          <Loading />
        ),
      )}
    </Card>
  );
};
