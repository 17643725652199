import { KlearlyUserType } from '../../Firebase/firebase';

export const getAvatarName = (klearlyUser: NonNullable<KlearlyUserType>) => {
  let avatarName = '';
  if (klearlyUser.firstName) {
    avatarName += klearlyUser.firstName.trim();
  }
  if (klearlyUser.lastName) {
    if (avatarName) {
      avatarName += ` ${klearlyUser.lastName.trim()}`;
    } else avatarName = klearlyUser.lastName.trim();
  }
  return avatarName;
};
