import {ReactNode} from 'react';
import {OptionsType} from 'shared/types/coreTypes.d';

type SegmentedControlProps = {
  asTabs?: boolean; // use this to create an implementation with underlines/spacing
  onChange: (newVal: string) => void;
  segments: OptionsType[];
  selectedSegmentValue: string;
};

// Full segmented control structure
export const SegmentedControl = ({
  asTabs = false,
  onChange,
  segments,
  selectedSegmentValue,
}: SegmentedControlProps) => (
  <ul className={asTabs ? 'segmented-control-tabs' : 'segmented-control'}>
    {segments.map((segment) => (
      <SegmentedControlItem
        analyticsAttr={segment.analyticsAttr ?? ''}
        asTabs={asTabs}
        key={segment.value}
        onChange={() => onChange(segment.value)}
        checked={segment.value === selectedSegmentValue}
      >
        {segment.label}
      </SegmentedControlItem>
    ))}
  </ul>
);

type SegmentedControlItemProps = {
  analyticsAttr: string;
  asTabs: boolean;
  checked: boolean;
  children: ReactNode;
  onChange: () => void;
};

// Each individual "radio button"
const SegmentedControlItem = ({
  analyticsAttr,
  asTabs,
  checked,
  children,
  onChange,
}: SegmentedControlItemProps) => (
  <li
    analytics-attr={analyticsAttr}
    className={
      (asTabs ? 'segmented-control-item-tab' : 'segmented-control-item') +
      (checked ? '-active' : '')
    }
    onClick={onChange}
  >
    {children}
  </li>
);
