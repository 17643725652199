import {
  ACCOUNT_FILTER_BAR,
  GLOBAL_FILTER_PANEL,
  GROUPS_VIEW,
} from 'app/src/constants/routes';
import { FilterAppDefinitions } from '../constants/globalFilterAppDefinitions';
import {
  ENGAGEMENT_STATUS_FILTER_ITEMS,
  FilterIndices,
} from '../types/filterTypes';
import { SegmentsAndListsIndices } from '../constants/segmentsAndListsIndices';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

class GlobalFilterAppDefinitions {
  appDefinitions: { [key: string]: TextDefinitionType };

  constructor() {
    this.appDefinitions = {};
  }

  getAccountStatsSummaryText() {
    return {
      topBarSummaryLabel: this.getAppDefinitions(
        FilterAppDefinitions.topBarSummaryLabel,
        GLOBAL_FILTER_PANEL.name,
      ),
      topBarSummaryLoading: this.getAppDefinitions(
        FilterAppDefinitions.topBarSummaryLoading,
        GLOBAL_FILTER_PANEL.name,
      ),
    };
  }

  getBuildASegmentTabText() {
    return {
      resetFilters: this.getAppDefinitions(
        FilterAppDefinitions.resetFiltersText,
        GLOBAL_FILTER_PANEL.name,
      ),
    };
  }

  getMainFilterBarText() {
    return {
      winfluence: this.getAppDefinitions(
        FilterIndices.WINFLUENCE,
        ACCOUNT_FILTER_BAR.name,
      ),
      engagementStatus: this.getAppDefinitions(
        ENGAGEMENT_STATUS_FILTER_ITEMS.index,
        ACCOUNT_FILTER_BAR.name,
      ),
    };
  }

  getSegmentSaveAccordionText() {
    return {
      accordionLabel: this.getAppDefinitions(
        FilterAppDefinitions.segmentSaveAccordionLabel,
        GLOBAL_FILTER_PANEL.name,
      ),
      segmentSaveNameLabel: this.getAppDefinitions(
        FilterAppDefinitions.segmentSaveNameLabel,
        GLOBAL_FILTER_PANEL.name,
      ),
      segmentSaveNamePlaceholder: this.getAppDefinitions(
        FilterAppDefinitions.segmentSaveNamePlaceholder,
        GLOBAL_FILTER_PANEL.name,
      ),
      segmentSaveDescriptionLabel: this.getAppDefinitions(
        FilterAppDefinitions.segmentSaveDescriptionLabel,
        GLOBAL_FILTER_PANEL.name,
      ),
      segmentSaveDescriptionPlaceholder: this.getAppDefinitions(
        FilterAppDefinitions.segmentSaveDescriptionPlaceholder,
        GLOBAL_FILTER_PANEL.name,
      ),
      segmentSaveDuplicateError: this.getAppDefinitions(
        FilterAppDefinitions.segmentSaveDuplicateError,
        GLOBAL_FILTER_PANEL.name,
      ),
    };
  }

  getTeamListsTabText() {
    return {
      empty: this.getAppDefinitions(
        SegmentsAndListsIndices.EMPTY_LISTS_INDEX,
        GROUPS_VIEW.name,
      ),
      tabTitle: this.getAppDefinitions(
        SegmentsAndListsIndices.LISTS_INDEX,
        GROUPS_VIEW.name,
      ),
    };
  }

  getTeamSegmentsTabText() {
    return {
      empty: this.getAppDefinitions(
        SegmentsAndListsIndices.EMPTY_SEGMENTS_INDEX,
        GROUPS_VIEW.name,
      ),
      tabTitle: this.getAppDefinitions(
        SegmentsAndListsIndices.SEGMENTS_INDEX,
        GROUPS_VIEW.name,
      ),
    };
  }

  private getAppDefinitions(textIndex: string, pageId: string) {
    if (this.appDefinitions[textIndex]) {
      return this.appDefinitions[textIndex];
    } else {
      const newText = getAppDefinitionsItem(
        pageId,
        textIndex,
      ) as TextDefinitionType;
      this.appDefinitions[textIndex] = newText;
      return newText;
    }
  }
}

export const globalFilterAppDefinitions = new GlobalFilterAppDefinitions();
