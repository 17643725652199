import { zIndexValues } from 'shared/constants/zIndexValues';
import { ApiGetAggregateAccountsDataWithFilterVarQuery } from 'shared/graphql/generatedApiTypes';
import { CheckboxSelectFilterItemProps } from '../components/filterItems/CheckboxSelectFilterItem';
import { GlobalFilterReducerState } from '../reducers/globalFilterReducer';

export const getCheckboxSelectFilterItemStyles = (
  selectedOptions: string[],
  width: string,
  itemSource?: 'panel' | 'bar' | 'wideMenu',
) => {
  return {
    container: (styles) => ({
      ...styles,
      border: !!selectedOptions.length
        ? '2px solid var(--color-blue)'
        : '2px solid var(--color-gray-300)',
      borderRadius: '6px',
      marginBottom: '4px',
      padding: 0,
      width,
    }),
    control: (styles) => ({
      ...styles,
      paddingTop: '4px',
      paddingBottom: '4px',
      cursor: 'pointer',
    }),
    menu: (styles) => ({
      ...styles,
      width: itemSource === 'wideMenu' ? '400px' : '200px',
      zIndex: zIndexValues.selectDefault,
    }),
    valueContainer: (styles) => ({
      ...styles,
      flexWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: itemSource === 'wideMenu' ? '400px' : '200px',
    }),
  };
};

type GetSelectedOptionsInitialStateParams = {
  filterItemAggregatedAccountsData:
    | ApiGetAggregateAccountsDataWithFilterVarQuery
    | undefined;
  filterIndex: CheckboxSelectFilterItemProps['filterIndex'];
  globalFilter: GlobalFilterReducerState;
  tableAccessor: CheckboxSelectFilterItemProps['tableAccessor'];
};

export const getSelectedOptionsInitialState = ({
  filterItemAggregatedAccountsData,
  filterIndex,
  globalFilter,
  tableAccessor,
}: GetSelectedOptionsInitialStateParams) =>
  filterItemAggregatedAccountsData?.aggregateAccountsData && globalFilter
    ? filterItemAggregatedAccountsData.aggregateAccountsData[filterIndex]
        .filter(
          (option) =>
            globalFilter[filterIndex] &&
            globalFilter[filterIndex].find(
              (item) => item === option[tableAccessor],
            ),
        )
        .map((option) => option[tableAccessor])
    : [];
