// winfluence rank should always be between 0-5
import _round from 'lodash/round';
import { RANK_TICKS } from 'app/src/components/GlobalFilters/types/filterTypes';

export function convertWinfluenceRankToText(
  avgWinfluence: number | undefined,
): string {
  return avgWinfluence && _round(avgWinfluence as number) >= 0
    ? RANK_TICKS[_round(avgWinfluence as number)] + ' '
    : '';
}
