import {useContext, useEffect, useMemo, useState} from 'react';
import {AnalyticsAttrType} from 'shared/types/coreTypes.d';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import {FilterIndices} from '../../../types/filterTypes';
import {BindMinMaxOptionsType} from '../../../types/minToMaxFilterItemProps';
import {Box, Flex, RangeSlider, RangeSliderFilledTrack, RangeSliderTrack, SimpleGrid, Text,} from '@chakra-ui/react';
import {createRangerBars, getLabelAddOn,} from '../../../helpers/rangerFilterHelpers';
import {Button, Tooltip} from '../../../../../../../shared/components/Core';
import {RangeThumb} from './RangeThumb';
import {RangerBarAndLabel} from './RangerBarAndLabel';

type RangerFilterItemProps = AnalyticsAttrType & {
  filterIndex: FilterIndices;
  initialFilterItem: BindMinMaxOptionsType;
  isDollars: boolean;
  isHundredPointScale: boolean;
  labelString: string;
  labelStringTooltip: string;
};

type RangerFilterItemState = {
  isApplied: boolean;
  currentValueIndices: number[];
  displayedValueIndices: number[];
};

export const RangerFilterItem = ({
  analyticsAttr = undefined,
  filterIndex,
  initialFilterItem = [],
  isDollars,
  isHundredPointScale,
  labelString = '',
  labelStringTooltip = '',
}: RangerFilterItemProps) => {
  const {
    data: initialAggregatedAccountsData,
    globalFilter,
    updateFilter,
  } = useContext(AggregateAccountsDataContext);

  const aggregatedInitialData = useMemo(() => {
    return initialAggregatedAccountsData?.aggregateAccountsData
      ? initialAggregatedAccountsData?.aggregateAccountsData[filterIndex]
      : [];
  }, [initialAggregatedAccountsData, filterIndex]);

  const numberOfTicks = aggregatedInitialData?.length ?? 0;

  const initialMinValue =
    globalFilter?.[filterIndex]?.min !== undefined
      ? initialFilterItem.findIndex(
          (item) => item.index === globalFilter?.[filterIndex].min,
        )
      : 0;
  const initialMaxValue =
    globalFilter?.[filterIndex]?.max !== undefined
      ? initialFilterItem.findIndex(
          (item) => item.index === globalFilter?.[filterIndex].max,
        )
      : numberOfTicks;

  const initialState: RangerFilterItemState = {
    isApplied: false,
    currentValueIndices: [initialMinValue, initialMaxValue],
    displayedValueIndices: [initialMinValue, initialMaxValue],
  };

  const [{ isApplied, currentValueIndices, displayedValueIndices }, setState] =
    useState<RangerFilterItemState>(initialState);

  const numberOfItemsInRange = aggregatedInitialData.map((d, idx) =>
    idx === aggregatedInitialData.length - 1
      ? d?.eq! + d?.gt!
      : d?.eq! + d?.gt! - aggregatedInitialData[idx + 1]?.gt! || 0,
  );

  const bars = useMemo(
    () => ({
      values: createRangerBars(numberOfItemsInRange),
      selectedBarsIndex: currentValueIndices,
    }),
    [currentValueIndices, numberOfItemsInRange],
  );

  const label = useMemo(() => {
    const combinedRangeLabel = getLabelAddOn(
      aggregatedInitialData,
      [
        (initialFilterItem[currentValueIndices[0]] &&
          initialFilterItem[currentValueIndices[0]].index) ??
          0,
        (initialFilterItem[currentValueIndices[1]] &&
          initialFilterItem[currentValueIndices[1]].index) ??
          'Max',
      ],
      isDollars,
    );
    if (!globalFilter || (globalFilter && !globalFilter[filterIndex])) {
      return `${labelString} Range (No filter applied - all accounts)`;
    }
    return `${labelString} Range${combinedRangeLabel}`;
  }, [
    aggregatedInitialData,
    currentValueIndices,
    filterIndex,
    globalFilter,
    initialFilterItem,
    isDollars,
    labelString,
  ]);

  useEffect(() => {
    let cleanedValue: { min?: string | number; max?: string | number } = {};
    const currentlyHasMin =
      (currentValueIndices[0] || currentValueIndices[0] === 0) &&
      initialFilterItem[currentValueIndices[0]];
    const currentlyHasMax =
      currentValueIndices[1] &&
      currentValueIndices[1] !== numberOfTicks &&
      initialFilterItem[currentValueIndices[1]];
    if (currentlyHasMin) {
      cleanedValue = {
        min: initialFilterItem[currentValueIndices[0]].index ?? undefined,
      };
      if (currentlyHasMax) {
        cleanedValue.max =
          initialFilterItem[currentValueIndices[1]].index ?? undefined;
      }
    }
    if (Object.keys(cleanedValue).length && isApplied) {
      updateFilter({ index: filterIndex, value: cleanedValue });
    }
    //updateFilter causes infinite rerenders
    //eslint-disable-next-line
  }, [currentValueIndices, filterIndex]);

  return (
    <Box h={64} mt={4} key={filterIndex}>
      {label && (
        <Flex justify={'center'} align={'center'}>
          {labelStringTooltip ? (
            <Tooltip content={labelStringTooltip}>
              <Text fontWeight={'bold'} textAlign={'center'}>
                {label}
              </Text>
            </Tooltip>
          ) : (
            <Text fontWeight={'bold'} textAlign={'center'}>
              {label}
            </Text>
          )}
          <Button
            h={8}
            isDisabled={!globalFilter || !globalFilter[filterIndex]}
            ml={2}
            onClick={() => {
              setState(initialState);
              updateFilter({ index: filterIndex, value: undefined });
            }}
            text={'Clear Filter'}
          />
        </Flex>
      )}
      <Box h={60} mt={20}>
        <RangeSlider
          defaultValue={displayedValueIndices}
          max={isHundredPointScale ? numberOfTicks - 1 : numberOfTicks}
          min={0}
          minStepsBetweenThumbs={1}
          onChange={(newVal) =>
            setState((prevState) => ({
              ...prevState,
              displayedValueIndices: newVal,
            }))
          }
          onChangeEnd={(newVal) =>
            setState((prevState) => ({
              ...prevState,
              isApplied: true,
              currentValueIndices: newVal,
            }))
          }
          step={1}
          value={displayedValueIndices}
          w={'96%'}
        >
          <RangeSliderTrack
            bg='brand.gray-200'
            borderColor={'brand.gray-400'}
            borderWidth={0.5}
            h={2}
            mt={-10}
          >
            <RangeSliderFilledTrack bg='brand.gradient' />
          </RangeSliderTrack>
          {displayedValueIndices.map((value, index) => (
            <RangeThumb
              analyticsAttr={analyticsAttr}
              key={index}
              index={index}
              initialFilterItem={initialFilterItem}
              isHundredPointScale={isHundredPointScale}
              numberOfTicks={numberOfTicks}
              value={value}
            />
          ))}
          <SimpleGrid
            columns={isHundredPointScale ? numberOfTicks : numberOfTicks + 1}
            textAlign={'center'}
            w={'108%'}
            ml={-6}
          >
            {initialFilterItem.map((item, index) => (
              <Box w={'100%'} key={index}>
                {bars.values && bars.values[index] && (
                  <RangerBarAndLabel
                    bars={bars}
                    currentValueIndices={currentValueIndices}
                    index={index}
                    label={item?.label}
                  />
                )}
              </Box>
            ))}
            {!isHundredPointScale &&
              bars.values &&
              bars.values[bars.values.length - 1] && (
                <Box
                  mt={3}
                  transform={`translateY(${
                    bars.values[bars.values.length - 1].height
                  })`}
                >
                  <Text
                    fontSize={'xs'}
                    mt={1}
                    transform={
                      bars.values[bars.values.length - 1].height
                        ? `translateY(-${
                            bars.values[bars.values.length - 1].height
                          })`
                        : undefined
                    }
                  >
                    Max
                  </Text>
                </Box>
              )}
          </SimpleGrid>
        </RangeSlider>
      </Box>
    </Box>
  );
};
