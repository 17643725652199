import { Stack, Text } from '@chakra-ui/react';

export const AccountHeaderItem = ({
  labelText,
  itemValue,
}: {
  labelText: string;
  itemValue: string;
}) => {
  return (
    <Stack spacing={1} data-cy={'account-header'}>
      <Text color={'brand.gray-400'} fontSize={'sm'} fontWeight={'bold'}>
        {labelText}
      </Text>
      <Text>{itemValue}</Text>
    </Stack>
  );
};
