import _map from 'lodash/map';
import _find from 'lodash/find';
import _isNumber from 'lodash/isNumber';
import _size from 'lodash/size';
import {
  FilterIndices,
  RANK_TICKS,
  WINFLUENCE_DROPDOWN_FILTER_ITEMS,
} from '../types/filterTypes';
import _findKey from 'lodash/findKey';
import countries from '../../../constants/countries';
import {
  ApiAggregateAccountsFragment,
  ApiEngagementStatus,
  ApiSignalIntensity,
  ApiXeominPredictionSummary,
  Maybe,
} from 'shared/graphql/generatedApiTypes';
import {
  cleanStringCase,
  pluralizeAccountsString,
} from 'shared/helpers/formatHelpers';

export type SortSelectFilterOptionsParams = {
  aggregatedItemsAccountData: Maybe<ApiAggregateAccountsFragment>;
  areFiltersApplied: boolean;
  filterIndex: FilterIndices;
  initialItems: any[];
  removeValues?: any[];
  tableAccessor: string;
};

export const sortSelectFilterOptions = ({
  aggregatedItemsAccountData,
  areFiltersApplied,
  filterIndex,
  initialItems,
  removeValues,
  tableAccessor,
}: SortSelectFilterOptionsParams) => {
  let itemsSorted = _map(initialItems, (i) => {
    // first, build the label add on if other filters are applied (# of # accounts)
    let labelAddOn = '';
    let totalAccounts: number | number[] = [];
    if (areFiltersApplied && aggregatedItemsAccountData) {
      const accountIdsForInitialItem: number | Array<number> =
        _find(initialItems, [[tableAccessor], i[tableAccessor]])?.count ||
        _find(initialItems, [[tableAccessor], i[tableAccessor]])?.accountIds ||
        [];
      totalAccounts = _isNumber(accountIdsForInitialItem)
        ? accountIdsForInitialItem
        : _size(accountIdsForInitialItem);
      labelAddOn = ` of ${totalAccounts.toLocaleString()}`;
    }

    // Then, build the label itself based on the count prop or array of account IDs
    let label = getStaticTextForLabel(filterIndex, i, tableAccessor);
    const accountIdsForItem = aggregatedItemsAccountData
      ? _find(aggregatedItemsAccountData[filterIndex], [
          [tableAccessor],
          i[tableAccessor],
        ])?.count ||
        _find(aggregatedItemsAccountData[filterIndex], [
          [tableAccessor],
          i[tableAccessor],
        ])?.accountIds ||
        []
      : [];

    // Use the count or total count to correctly pluralize the account string
    const count = _isNumber(accountIdsForItem)
      ? accountIdsForItem
      : _size(accountIdsForItem);
    const totalCount = _isNumber(totalAccounts)
      ? totalAccounts
      : _size(totalAccounts);
    const accountsStrings = ` ${pluralizeAccountsString(
      totalAccounts ? totalCount : count,
    )}`;

    // build the label & add the country code to use for flag lookup if needed
    label += ` (${count.toLocaleString()}${labelAddOn}${accountsStrings})`;
    let countryCode: string | undefined = undefined;
    if (filterIndex === FilterIndices.COUNTRY) {
      countryCode = _findKey(countries, (item) => item === i[tableAccessor]);
    }
    return {
      accountsCount: i.count || i.accountIds,
      analyticsAttr: i[tableAccessor],
      count,
      countryCode,
      label,
      value: i[tableAccessor],
    };
  });

  // if we have a defined order, use that
  if (orderToSortBy[filterIndex]) {
    const sortOrder = orderToSortBy[filterIndex];
    itemsSorted = itemsSorted.sort(
      (a, b) => sortOrder.indexOf(a.value) - sortOrder.indexOf(b.value),
    );
    // otherwise, just alphabetize
  } else {
    itemsSorted = itemsSorted.sort((a, b) => (a.label > b.label ? 1 : -1));
  }
  //filter out any remove values
  if (removeValues) {
    return itemsSorted.filter(
      (item) => !removeValues.find((value) => value === item.value),
    );
  }
  return itemsSorted;
};

const orderToSortBy = {
  [FilterIndices.ENGAGEMENT_STATUS]: [
    ApiEngagementStatus.Active.toLowerCase(),
    ApiEngagementStatus.Lapsing.toLowerCase(),
    ApiEngagementStatus.Lapsed.toLowerCase(),
    ApiEngagementStatus.Inactive.toLowerCase(),
    ApiEngagementStatus.Pending.toLowerCase(),
  ],
  [FilterIndices.ENGAGEMENT_INTENSITY]: [
    ApiSignalIntensity.WellAboveAverage.toLowerCase(),
    ApiSignalIntensity.AboveAverage.toLowerCase(),
    ApiSignalIntensity.Average.toLowerCase(),
    ApiSignalIntensity.BelowAverage.toLowerCase(),
    ApiSignalIntensity.WellBelowAverage.toLowerCase(),
    ApiSignalIntensity.None.toLowerCase(),
  ],
  [FilterIndices.OUTREACH_INTENSITY]: [
    ApiSignalIntensity.WellAboveAverage.toLowerCase(),
    ApiSignalIntensity.AboveAverage.toLowerCase(),
    ApiSignalIntensity.Average.toLowerCase(),
    ApiSignalIntensity.BelowAverage.toLowerCase(),
    ApiSignalIntensity.WellBelowAverage.toLowerCase(),
    ApiSignalIntensity.None.toLowerCase(),
  ],
  [FilterIndices.WINFLUENCE_ACTUAL]: ['5', '4', '3', '2', '1', '0'],
  [FilterIndices.XEOMIN_PREDICTION_SUMMARY]: [
    ApiXeominPredictionSummary.HighProspect.toLowerCase(),
    ApiXeominPredictionSummary.MediumProspect.toLowerCase(),
    ApiXeominPredictionSummary.LowProspect.toLowerCase(),
    ApiXeominPredictionSummary.HighCrossSell.toLowerCase(),
    ApiXeominPredictionSummary.MediumCrossSell.toLowerCase(),
    ApiXeominPredictionSummary.LowCrossSell.toLowerCase(),
    ApiXeominPredictionSummary.Retain.toLowerCase(),
    ApiXeominPredictionSummary.UpSell.toLowerCase(),
    ApiXeominPredictionSummary.LikelyReorder.toLowerCase(),
    ApiXeominPredictionSummary.SomewhatLikelyReorder.toLowerCase(),
    ApiXeominPredictionSummary.UnlikelyReorder.toLowerCase(),
  ],
};

// Account for the winfluence conversion of number to string, also for ApiSignalIntensity enum "None"
const getStaticTextForLabel = (
  filterIndex: FilterIndices,
  i: any,
  tableAccessor: string,
) => {
  if (filterIndex === WINFLUENCE_DROPDOWN_FILTER_ITEMS.index) {
    return RANK_TICKS[parseInt(i[tableAccessor])];
  }
  if (
    filterIndex === FilterIndices.OUTREACH_INTENSITY &&
    i[tableAccessor] === 'none'
  ) {
    return 'No Activities';
  }
  if (
    filterIndex === FilterIndices.ENGAGEMENT_INTENSITY &&
    i[tableAccessor] === 'none'
  ) {
    return 'No Responses';
  }
  return cleanStringCase(i[tableAccessor]);
};
