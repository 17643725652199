import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  /**
   * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: any;
};

export type ApiAccount = {
  accountName: Maybe<Scalars['String']>;
  addressCity: Maybe<Scalars['String']>;
  addressRegion: Maybe<Scalars['String']>;
  addressStreet: Maybe<Scalars['String']>;
  addressZipCode: Maybe<Scalars['String']>;
  annualRevenue: Maybe<Scalars['String']>;
  contactCount: Maybe<Scalars['Int']>;
  crmAccountId: Scalars['String'];
  currentAccountScore: Maybe<Scalars['Int']>;
  currentValue: Maybe<Scalars['Float']>;
  /** @deprecated Deprecated. Please use currentValue */
  currentWinProbability: Maybe<Scalars['Float']>;
  customerStatus: Maybe<ApiAccountCustomerStatus>;
  employeeCount: Maybe<Scalars['String']>;
  engagementStatus: Maybe<ApiEngagementStatus>;
  expectedValue: Maybe<ApiPredictionExpected>;
  /** @deprecated Deprecated. Please use expectedValue */
  expectedWinProbability: Maybe<ApiPredictionExpected>;
  firstClosedOpportunityAt: Maybe<Scalars['DateTime']>;
  goalValues: Maybe<Array<Maybe<ApiPredictionGoal>>>;
  /** @deprecated Deprecated. Please use goalValues */
  goalWinProbabilities: Maybe<Array<Maybe<ApiPredictionGoal>>>;
  id: Scalars['Int'];
  industry: Maybe<Scalars['String']>;
  lastInboundSignal: Maybe<ApiSignal>;
  lastOutboundSignal: Maybe<ApiSignal>;
  lostOpportunityCount: Scalars['Int'];
  narratives: Maybe<ApiNarrative>;
  openOpportunityCount: Scalars['Int'];
  predictionCalculatedForName: Maybe<Scalars['String']>;
  predictionCalculatedForType: Maybe<ApiPredictionForType>;
  predictionLevel: Maybe<Scalars['Int']>;
  rankAll: Maybe<Scalars['Int']>;
  rankPriority: Maybe<Scalars['Int']>;
  signalActivityIntensity: Maybe<ApiSignalIntensity>;
  signalResponseIntensity: Maybe<ApiSignalIntensity>;
  wonOpportunityCount: Scalars['Int'];
  xeominAverageQuantity: Maybe<Scalars['Float']>;
  xeominPredictionSummary: Maybe<ApiXeominPredictionSummary>;
  xeominPurchaseTrendLong: Maybe<Scalars['String']>;
  xeominPurchaseTrendShort: Maybe<ApiXeominPurchaseTrend>;
  xeominSpendFraction: Maybe<Scalars['Float']>;
};

export enum ApiAccountCountGroupBy {
  CurrentAccountScoreBucket = 'CURRENT_ACCOUNT_SCORE_BUCKET',
  PossibleAccountScoreBucket = 'POSSIBLE_ACCOUNT_SCORE_BUCKET',
  SignalType = 'SIGNAL_TYPE',
}

export type ApiAccountCountGrouping = {
  accountCount: Maybe<Scalars['Int']>;
  currentAccountScoreBucket: Maybe<ApiAccountScoreBucket>;
  possibleAccountScoreBucket: Maybe<ApiAccountScoreBucket>;
  signalType: Maybe<Scalars['String']>;
};

export enum ApiAccountCustomerStatus {
  Customer = 'CUSTOMER',
  PastCustomer = 'PAST_CUSTOMER',
  Prospect = 'PROSPECT',
}

export enum ApiAccountField {
  AccountName = 'ACCOUNT_NAME',
  CustomerStatus = 'CUSTOMER_STATUS',
  XeominAverageQuantity = 'XEOMIN_AVERAGE_QUANTITY',
  XeominPredictionSummary = 'XEOMIN_PREDICTION_SUMMARY',
  XeominPurchaseTrendLong = 'XEOMIN_PURCHASE_TREND_LONG',
  XeominPurchaseTrendShort = 'XEOMIN_PURCHASE_TREND_SHORT',
  XeominSpendFraction = 'XEOMIN_SPEND_FRACTION',
}

export type ApiAccountForSearchPayload = {
  contactCount: Scalars['Int'];
  crmAccountId: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ApiAccountGroup = {
  accountCount: Maybe<Scalars['Int']>;
  accounts: Array<Maybe<ApiAccount>>;
  accountsUpdatedAt: Maybe<Scalars['NaiveDateTime']>;
  /** @deprecated Please use "user". */
  companyUser: ApiUser;
  createdAt: Scalars['NaiveDateTime'];
  description: Maybe<Scalars['String']>;
  filterString: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['NaiveDateTime'];
  user: ApiUser;
};

export type ApiAccountGroupPayload = {
  accountGroup: Maybe<ApiAccountGroup>;
};

export enum ApiAccountGroupType {
  All = 'ALL',
  List = 'LIST',
  Segment = 'SEGMENT',
}

export type ApiAccountPredictions = {
  currentPredictions: Maybe<Array<Maybe<ApiCurrentPrediction>>>;
  dateFormat: Scalars['String'];
  expectedPredictions: Maybe<Array<Maybe<ApiExpectedPrediction>>>;
  goalPredictions: Maybe<Array<Maybe<ApiGoalPrediction>>>;
};

export enum ApiAccountScoreBucket {
  Likely = 'LIKELY',
  Tossup = 'TOSSUP',
  Unlikely = 'UNLIKELY',
  VeryLikely = 'VERY_LIKELY',
  VeryUnlikely = 'VERY_UNLIKELY',
}

/** Input object for sorting an account list */
export type ApiAccountSortItem = {
  direction: ApiSort;
  field: ApiAccountField;
};

/** Input object for adding an account group */
export type ApiAddAccountGroupInput = {
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  description: Maybe<Scalars['String']>;
  filterString: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ApiAddUserError = {
  email: Scalars['String'];
  message: Scalars['String'];
};

export type ApiAddUsersPayload = {
  errors: Array<Maybe<ApiAddUserError>>;
  users: Array<Maybe<ApiUser>>;
};

export type ApiAggregateAccountsData = {
  accountsForSearch: Array<Maybe<ApiAccountForSearchPayload>>;
  accountsWithClosedOpps: Scalars['Int'];
  avgWinfluence: Scalars['Float'];
  country: Array<Maybe<ApiAggregateString>>;
  currentAccountScore: Array<Maybe<ApiAggregateValueCounts>>;
  engagementStatus: Array<Maybe<ApiAggregateString>>;
  industry: Array<Maybe<ApiAggregateString>>;
  recommendedAction: Array<Maybe<ApiAggregateString>>;
  revenue: Array<Maybe<ApiAggregateValueCounts>>;
  signalActivityIntensity: Array<Maybe<ApiAggregateString>>;
  signalResponseIntensity: Array<Maybe<ApiAggregateString>>;
  signalType: Array<Maybe<ApiAggregateString>>;
  size: Array<Maybe<ApiAggregateValueCounts>>;
  totalAccounts: Scalars['Int'];
  totalIndustryCount: Scalars['Int'];
  winfluence: Array<Maybe<ApiAggregateString>>;
  xeominPredictionSummary: Array<Maybe<ApiAggregateString>>;
};

export type ApiAggregateString = {
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type ApiAggregateValueCounts = {
  eq: Scalars['Int'];
  gt: Scalars['Int'];
  lt: Scalars['Int'];
  value: Scalars['Int'];
};

export type ApiCompany = {
  canSubmitCredentials: Scalars['Boolean'];
  companyName: Scalars['String'];
  crmTypes: Maybe<Array<Maybe<Scalars['String']>>>;
  dataProcessedAt: Maybe<Scalars['NaiveDateTime']>;
  id: Scalars['Int'];
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
};

/** Input object for company */
export type ApiCompanyInput = {
  /** Name of Can Submit Credentials */
  canSubmitCredentials: Maybe<Scalars['Boolean']>;
  /** Name for the company */
  companyName: Scalars['String'];
  /** Name of CRM Types */
  crmTypes: Array<Maybe<Scalars['String']>>;
};

/** Input object for company update */
export type ApiCompanyInputUpdate = {
  /** Name of Can Submit Credentials */
  canSubmitCredentials: Maybe<Scalars['Boolean']>;
  /** Name for the company */
  companyName: Scalars['String'];
  /** Name of CRM Types */
  crmTypes: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** CRM Type Enum */
export enum ApiCrmTypeEnum {
  Hubspot = 'HUBSPOT',
  Marketo = 'MARKETO',
  Salesforce = 'SALESFORCE',
}

export type ApiCurrentPrediction = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  value: Scalars['Float'];
  /** @deprecated Deprecated. Please use value */
  winProbability: Scalars['Float'];
};

export type ApiDataReport = {
  data: Maybe<ApiDataReportData>;
  description: Maybe<Scalars['String']>;
  queryText: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ApiDataReportData = {
  columns: Maybe<Array<Maybe<Scalars['String']>>>;
  rows: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

export enum ApiEngagementStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Lapsed = 'LAPSED',
  Lapsing = 'LAPSING',
  Pending = 'PENDING',
}

export type ApiExpectedPrediction = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  valueHigh: Scalars['Float'];
  valueLow: Scalars['Float'];
  valueMiddle: Scalars['Float'];
  /** @deprecated Deprecated. Please use valueHigh */
  winProbabilityHigh: Scalars['Float'];
  /** @deprecated Deprecated. Please use valueLow */
  winProbabilityLow: Scalars['Float'];
  /** @deprecated Deprecated. Please use valueMiddle */
  winProbabilityMiddle: Scalars['Float'];
};

export type ApiGoalPrediction = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  isPriority: Scalars['Boolean'];
  value: Scalars['Float'];
  /** @deprecated Deprecated. Please use value */
  winProbability: Scalars['Float'];
};

export type ApiGoogleSecret = {
  message: Scalars['String'];
};

/** Input object for google secrets */
export type ApiGoogleSecretInput = {
  /** Company for the credentials */
  companyName: Scalars['String'];
  /** CRM contact person's email */
  contactPersonEmail: Maybe<Scalars['String']>;
  /** CRM contact person's name */
  contactPersonName: Maybe<Scalars['String']>;
  /** CRM Type */
  crmType: ApiCrmTypeEnum;
  /** CRM user's email */
  email: Scalars['String'];
  /** Marketo Client ID */
  marketoClientId: Maybe<Scalars['String']>;
  /** Marketo Client Secret */
  marketoClientSecret: Maybe<Scalars['String']>;
  /** Marketo Endpoint Base URL */
  marketoEndpointBaseUrl: Maybe<Scalars['String']>;
  /** CRM user's password */
  password: Scalars['String'];
};

export type ApiIndustry = {
  name: Scalars['String'];
};

/** Enqueue object for internal ETL */
export type ApiInternalEtlInput = {
  /** The company to do internal ETL on */
  company: Scalars['String'];
  /** The earliest date to get data for. Format YYYY-0M-0D */
  dateFrom: Scalars['String'];
  /** The tables (optional) to do internal ETL for */
  tables: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Schedule object for ML prediction */
export type ApiMlPredictionInput = {
  /** Optional account ids to limit to */
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The company to do ML prediction on */
  company: Scalars['String'];
  /** The start date for ML prediction */
  dateFrom: Scalars['String'];
  /** The end date for ML prediction */
  dateTo: Scalars['String'];
};

export type ApiNarrative = {
  primaryAction: Scalars['String'];
};

export type ApiObanJob = {
  args: Scalars['String'];
  attempt: Scalars['Int'];
  attemptedAt: Scalars['DateTime'];
  breakdown: Maybe<Scalars['String']>;
  completedAt: Scalars['DateTime'];
  errors: Scalars['String'];
  executionTimeMs: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  insertedAt: Scalars['DateTime'];
  rows: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['DateTime'];
  state: Scalars['String'];
  worker: Scalars['String'];
};

export type ApiPageInfo = {
  totalEntries: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ApiPaginatedAccounts = {
  accounts: Array<Maybe<ApiAccount>>;
  pageInfo: ApiPageInfo;
};

/** Enqueue object for entire ETL pipeline */
export type ApiPipelineInput = {
  /** The company to do internal ETL on */
  company: Scalars['String'];
  /** The upper bound date. Format YYYY-0M-0D. Defaults to today if not provided */
  dateFrom: Maybe<Scalars['String']>;
  /** The lower bound date. Format YYYY-0M-0D. Defaults to today if not provided */
  dateTo: Maybe<Scalars['String']>;
};

export type ApiPredictionExpected = {
  high: Scalars['Float'];
  low: Scalars['Float'];
  middle: Scalars['Float'];
};

export enum ApiPredictionForType {
  Acquisition = 'ACQUISITION',
  Risk = 'RISK',
  Volume = 'VOLUME',
}

export type ApiPredictionGoal = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  crmAccountId: Scalars['String'];
  id: Scalars['Int'];
  isPriority: Scalars['Boolean'];
  lift: Maybe<Scalars['Float']>;
  /** @deprecated Deprecated. Please use value */
  probability: Maybe<Scalars['Float']>;
  signalPredictions: Array<Maybe<ApiSignalPrediction>>;
  value: Maybe<Scalars['Float']>;
};

export type ApiRecommendedAction = {
  accountCount: Scalars['Int'];
  action: Scalars['String'];
  actualCount: Scalars['Int'];
  averageRank: Scalars['Float'];
  goalCount: Scalars['Int'];
  signalType: Scalars['String'];
};

export type ApiRootMutationType = {
  addAccountGroup: Maybe<ApiAccountGroupPayload>;
  addCompany: Maybe<ApiCompany>;
  addUser: Maybe<ApiUser>;
  addUsers: Maybe<ApiAddUsersPayload>;
  createGoogleSecretVersion: Maybe<ApiGoogleSecret>;
  enqueueEtlPipeline: Maybe<ApiObanJob>;
  enqueueInternalEtl: Maybe<ApiObanJob>;
  enqueueMlPrediction: Maybe<ApiObanJob>;
  updateAccountGroupAccounts: Maybe<ApiAccountGroupPayload>;
  updateAccountGroupDescriptors: Maybe<ApiAccountGroupPayload>;
  updateAccountGroupFilter: Maybe<ApiAccountGroupPayload>;
  updateAccountGroupIsHidden: Maybe<ApiAccountGroupPayload>;
  updateCompany: Maybe<ApiCompany>;
  updateUser: Maybe<ApiUser>;
  updateUserCompany: Maybe<ApiUser>;
};

export type ApiRootMutationTypeAddAccountGroupArgs = {
  input: ApiAddAccountGroupInput;
};

export type ApiRootMutationTypeAddCompanyArgs = {
  company: ApiCompanyInput;
};

export type ApiRootMutationTypeAddUserArgs = {
  user: ApiUserInput;
};

export type ApiRootMutationTypeAddUsersArgs = {
  users: Array<Maybe<ApiUserInput>>;
};

export type ApiRootMutationTypeCreateGoogleSecretVersionArgs = {
  googleSecret: ApiGoogleSecretInput;
};

export type ApiRootMutationTypeEnqueueEtlPipelineArgs = {
  pipelineInput: ApiPipelineInput;
};

export type ApiRootMutationTypeEnqueueInternalEtlArgs = {
  internalEtlInput: ApiInternalEtlInput;
};

export type ApiRootMutationTypeEnqueueMlPredictionArgs = {
  mlPredictionInput: ApiMlPredictionInput;
};

export type ApiRootMutationTypeUpdateAccountGroupAccountsArgs = {
  input: ApiUpdateAccountGroupAccountsInput;
};

export type ApiRootMutationTypeUpdateAccountGroupDescriptorsArgs = {
  input: ApiUpdateAccountGroupDescriptorsInput;
};

export type ApiRootMutationTypeUpdateAccountGroupFilterArgs = {
  input: ApiUpdateAccountGroupFilterInput;
};

export type ApiRootMutationTypeUpdateAccountGroupIsHiddenArgs = {
  input: ApiUpdateAccountGroupIsHiddenInput;
};

export type ApiRootMutationTypeUpdateCompanyArgs = {
  company: ApiCompanyInputUpdate;
};

export type ApiRootMutationTypeUpdateUserArgs = {
  user: ApiUserInputUpdate;
};

export type ApiRootMutationTypeUpdateUserCompanyArgs = {
  companyName: Scalars['String'];
};

export type ApiRootQueryType = {
  /** Account & prediction data for a single account */
  account: Maybe<ApiAccount>;
  /** The account count given the set of group by fields */
  accountCountByGrouping: Maybe<Array<Maybe<ApiAccountCountGrouping>>>;
  /** A single account group by id */
  accountGroup: Maybe<ApiAccountGroup>;
  /** All account groups for a company */
  accountGroups: Maybe<Array<Maybe<ApiAccountGroup>>>;
  /** Account groups for the given account */
  accountGroupsForAccount: Maybe<Array<Maybe<ApiAccountGroup>>>;
  /** Predictions for a given account and a given timeline */
  accountPredictions: Maybe<ApiAccountPredictions>;
  /** Returns all signals for the given account id for a given start and end date */
  accountSignals: Maybe<Array<Maybe<ApiSignal>>>;
  /** Multiple accounts without prediction data */
  accounts: Maybe<ApiPaginatedAccounts>;
  /** Accounts returned when searching for one */
  accountsForSearch: Array<Maybe<ApiAccountForSearchPayload>>;
  /** Multiple accounts with prediction data */
  accountsWithPredictions: Maybe<Array<Maybe<ApiAccount>>>;
  /** Aggregate accounts data */
  aggregateAccountsData: Maybe<ApiAggregateAccountsData>;
  /** Companies aka Klearly's customers */
  companies: Maybe<Array<Maybe<ApiCompany>>>;
  /** Users who can log into Klearly */
  companyUsers: Maybe<Array<Maybe<ApiUser>>>;
  dataAssessment: Maybe<Array<Maybe<ApiDataReport>>>;
  /** Client-only field that contains the current filter string to apply */
  filterString: Maybe<Scalars['String']>;
  /** Client-only field that contains the current filter string without engagement status */
  filterStringWithoutEngagementStatus: Maybe<Scalars['String']>;
  /** All industries for the current user's company */
  industries: Maybe<Array<Maybe<ApiIndustry>>>;
  /** Information about logged in user */
  me: Maybe<ApiUser>;
  /** List of recommended actions and corresponding signal actuals */
  recommendedActions: Maybe<Array<Maybe<ApiRecommendedAction>>>;
  /** Returns signal history for the given dates grouped by source and direction */
  signalHistory: Maybe<ApiSignalHistory>;
  /** Returns signal history for the given account id, start and dates, and grouping parameters. By default the results are grouped by signal date, source, and direction */
  signalHistoryForAccount: Maybe<ApiSignalHistoryForAccount>;
};

export type ApiRootQueryTypeAccountArgs = {
  id: Scalars['Int'];
};

export type ApiRootQueryTypeAccountCountByGroupingArgs = {
  filterString: Maybe<Scalars['String']>;
  groupBy: Maybe<Array<Maybe<ApiAccountCountGroupBy>>>;
};

export type ApiRootQueryTypeAccountGroupArgs = {
  id: Scalars['Int'];
};

export type ApiRootQueryTypeAccountGroupsArgs = {
  filterHidden?: Maybe<Scalars['Boolean']>;
};

export type ApiRootQueryTypeAccountGroupsForAccountArgs = {
  accountGroupType?: Maybe<ApiAccountGroupType>;
  accountId: Scalars['Int'];
};

export type ApiRootQueryTypeAccountPredictionsArgs = {
  accountId: Scalars['Int'];
  timeRange: ApiTimeRange;
};

export type ApiRootQueryTypeAccountSignalsArgs = {
  accountId: Scalars['Int'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type ApiRootQueryTypeAccountsArgs = {
  filterString?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<ApiAccountSortItem>>>;
};

export type ApiRootQueryTypeAccountsForSearchArgs = {
  limitToOpenAccountsWithPredictions?: Maybe<Scalars['Boolean']>;
  query: Scalars['String'];
};

export type ApiRootQueryTypeAccountsWithPredictionsArgs = {
  filterString: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeAggregateAccountsDataArgs = {
  filterString: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeDataAssessmentArgs = {
  company: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeRecommendedActionsArgs = {
  filterString: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeSignalHistoryArgs = {
  filterString: Maybe<Scalars['String']>;
  timeRange: ApiTimeRange;
};

export type ApiRootQueryTypeSignalHistoryForAccountArgs = {
  accountId: Scalars['Int'];
  endDate: Scalars['Date'];
  groupBy: Array<Maybe<ApiSignalGroupByType>>;
  startDate: Scalars['Date'];
};

export type ApiSignal = {
  action: Scalars['String'];
  crmAccountId: Scalars['String'];
  direction: Scalars['String'];
  eventId: Scalars['Int'];
  groupKey: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  insertedAt: Scalars['DateTime'];
  occurredAt: Maybe<Scalars['DateTime']>;
  quantity: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  topic: Maybe<Scalars['String']>;
  topicDetail: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeDetail: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Maybe<Scalars['Float']>;
};

export type ApiSignalDetailsForAccountItem = {
  count: Scalars['Int'];
  signalDate: Maybe<Scalars['Date']>;
  signalSource: Maybe<Scalars['String']>;
  signalTopic: Maybe<Scalars['String']>;
  signalTopicDetail: Maybe<Scalars['String']>;
  signalType: Maybe<Scalars['String']>;
  signalTypeDetail: Maybe<Scalars['String']>;
};

export enum ApiSignalGroupByType {
  SignalTopic = 'SIGNAL_TOPIC',
  SignalTopicDetail = 'SIGNAL_TOPIC_DETAIL',
  SignalType = 'SIGNAL_TYPE',
  SignalTypeDetail = 'SIGNAL_TYPE_DETAIL',
}

export type ApiSignalHistory = {
  allActivity: ApiSignalHistoryCategory;
  allAll: ApiSignalHistoryCategory;
  allResponse: ApiSignalHistoryCategory;
  dateFormat: Scalars['String'];
  marketingActivity: ApiSignalHistoryCategory;
  marketingAll: ApiSignalHistoryCategory;
  marketingResponse: ApiSignalHistoryCategory;
  salesActivity: ApiSignalHistoryCategory;
  salesAll: ApiSignalHistoryCategory;
  salesResponse: ApiSignalHistoryCategory;
};

export type ApiSignalHistoryCategory = {
  narratives: Array<Maybe<Scalars['String']>>;
  signalHistoryItems: Array<Maybe<ApiSignalHistoryItem>>;
};

export type ApiSignalHistoryForAccount = {
  allActivity: ApiSignalHistoryForAccountItem;
  allAll: ApiSignalHistoryForAccountItem;
  allResponse: ApiSignalHistoryForAccountItem;
  dateFormat: Scalars['String'];
  marketingActivity: ApiSignalHistoryForAccountItem;
  marketingAll: ApiSignalHistoryForAccountItem;
  marketingResponse: ApiSignalHistoryForAccountItem;
  salesActivity: ApiSignalHistoryForAccountItem;
  salesAll: ApiSignalHistoryForAccountItem;
  salesResponse: ApiSignalHistoryForAccountItem;
};

export type ApiSignalHistoryForAccountItem = {
  signalDetails: Array<Maybe<ApiSignalDetailsForAccountItem>>;
  signalTotalByDate: Array<Maybe<ApiSignalTotalForAccountByDate>>;
};

export type ApiSignalHistoryItem = {
  accountCount: Scalars['Int'];
  endDate: Scalars['Date'];
  signalCount: Scalars['Int'];
  startDate: Scalars['Date'];
};

export enum ApiSignalIntensity {
  AboveAverage = 'ABOVE_AVERAGE',
  Average = 'AVERAGE',
  BelowAverage = 'BELOW_AVERAGE',
  None = 'NONE',
  WellAboveAverage = 'WELL_ABOVE_AVERAGE',
  WellBelowAverage = 'WELL_BELOW_AVERAGE',
}

export type ApiSignalPrediction = {
  calculatedAtDate: Scalars['Date'];
  calculatedForDate: Scalars['Date'];
  crmAccountId: Scalars['String'];
  expectedHigh: Maybe<Scalars['Int']>;
  expectedLow: Maybe<Scalars['Int']>;
  expectedMiddle: Maybe<Scalars['Int']>;
  goal: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  recommendation: Scalars['String'];
  signalType: Maybe<Scalars['String']>;
};

export type ApiSignalTotalForAccountByDate = {
  date: Scalars['Date'];
  total: Scalars['Int'];
};

export enum ApiSort {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ApiTimeRange = {
  daysFuture: Scalars['Int'];
  daysPast: Scalars['Int'];
  intervalDays: Scalars['Int'];
};

/** Input object for updating the accounts associated to the account group */
export type ApiUpdateAccountGroupAccountsInput = {
  /** The associated account ids */
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The account group id */
  id: Scalars['Int'];
};

/** Input object for updating the account group's title and description */
export type ApiUpdateAccountGroupDescriptorsInput = {
  /** Description of the account group */
  description: Scalars['String'];
  /** The account group id */
  id: Scalars['Int'];
  /** Title of the account group */
  title: Scalars['String'];
};

/** Input object for updating an account group filter and the corresponding accounts */
export type ApiUpdateAccountGroupFilterInput = {
  /** The associated account ids */
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The filter string */
  filterString: Scalars['String'];
  /** The account group id */
  id: Scalars['Int'];
};

/** Input object for updating the account group's is_hidden field */
export type ApiUpdateAccountGroupIsHiddenInput = {
  /** The account group id */
  id: Scalars['Int'];
  /** Value of is hidden */
  isHidden: Scalars['Boolean'];
};

export type ApiUser = {
  authUserId: Scalars['String'];
  company: Maybe<ApiCompany>;
  companyName: Scalars['String'];
  disabled: Scalars['Boolean'];
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdmin: Maybe<Scalars['Boolean']>;
  isKlearlyAdmin: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** Input object for user */
export type ApiUserInput = {
  /** Company name for the company the user is associated to */
  companyName: Scalars['String'];
  /** If the user disabled */
  disabled: Maybe<Scalars['Boolean']>;
  /** Email of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName: Scalars['String'];
  /** If the user is an admin */
  isAdmin: Maybe<Scalars['Boolean']>;
  /** If the user is a Klearly admin */
  isKlearlyAdmin: Maybe<Scalars['Boolean']>;
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Role of the user */
  role: Maybe<Scalars['String']>;
  /** Send email */
  sendEmail: Maybe<Scalars['Boolean']>;
};

/** Input object for user update */
export type ApiUserInputUpdate = {
  /** Company name for the company the user is associated to */
  companyName: Scalars['String'];
  /** If the user disabled */
  disabled: Maybe<Scalars['Boolean']>;
  /** Email of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName: Maybe<Scalars['String']>;
  /** If the user is an admin */
  isAdmin: Maybe<Scalars['Boolean']>;
  /** If the user is a Klearly admin */
  isKlearlyAdmin: Maybe<Scalars['Boolean']>;
  /** Last name of the user */
  lastName: Maybe<Scalars['String']>;
  /** Role of the user */
  role: Maybe<Scalars['String']>;
};

export enum ApiXeominPredictionSummary {
  HighCrossSell = 'HIGH_CROSS_SELL',
  HighProspect = 'HIGH_PROSPECT',
  LikelyReorder = 'LIKELY_REORDER',
  LowCrossSell = 'LOW_CROSS_SELL',
  LowProspect = 'LOW_PROSPECT',
  MediumCrossSell = 'MEDIUM_CROSS_SELL',
  MediumProspect = 'MEDIUM_PROSPECT',
  Retain = 'RETAIN',
  SomewhatLikelyReorder = 'SOMEWHAT_LIKELY_REORDER',
  UnlikelyReorder = 'UNLIKELY_REORDER',
  UpSell = 'UP_SELL',
}

export enum ApiXeominPurchaseTrend {
  FirstRecentSale = 'FIRST_RECENT_SALE',
  NeverPurchased = 'NEVER_PURCHASED',
  NewAccount = 'NEW_ACCOUNT',
  NoRecentSale = 'NO_RECENT_SALE',
  Steady = 'STEADY',
  TrendingDown = 'TRENDING_DOWN',
  TrendingFlat = 'TRENDING_FLAT',
  TrendingUp = 'TRENDING_UP',
}

export type ApiAddAccountGroupMutationVariables = Exact<{
  input: ApiAddAccountGroupInput;
}>;

export type ApiAddAccountGroupMutation = {
  addAccountGroup: Maybe<{ accountGroup: Maybe<Pick<ApiAccountGroup, 'id'>> }>;
};

export type ApiCreateGoogleSecretVersionMutationVariables = Exact<{
  googleSecret: ApiGoogleSecretInput;
}>;

export type ApiCreateGoogleSecretVersionMutation = {
  createGoogleSecretVersion: Maybe<Pick<ApiGoogleSecret, 'message'>>;
};

export type ApiCreateNewCompanyMutationVariables = Exact<{
  company: ApiCompanyInput;
}>;

export type ApiCreateNewCompanyMutation = {
  addCompany: Maybe<
    Pick<
      ApiCompany,
      'id' | 'companyName' | 'canSubmitCredentials' | 'insertedAt' | 'updatedAt'
    > & { CRMTypes: ApiCompany['crmTypes'] }
  >;
};

export type ApiCreateNewUserMutationVariables = Exact<{
  user: ApiUserInput;
}>;

export type ApiCreateNewUserMutation = {
  addUser: Maybe<Pick<ApiUser, 'email' | 'firstName' | 'lastName'>>;
};

export type ApiCreateNewUsersMutationVariables = Exact<{
  users: Array<Maybe<ApiUserInput>> | Maybe<ApiUserInput>;
}>;

export type ApiCreateNewUsersMutation = {
  addUsers: Maybe<{
    users: Array<
      Maybe<Pick<ApiUser, 'firstName' | 'lastName' | 'email' | 'companyName'>>
    >;
    errors: Array<Maybe<Pick<ApiAddUserError, 'email' | 'message'>>>;
  }>;
};

export type ApiUpdateAccountGroupAccountsMutationVariables = Exact<{
  input: ApiUpdateAccountGroupAccountsInput;
}>;

export type ApiUpdateAccountGroupAccountsMutation = {
  updateAccountGroupAccounts: Maybe<{
    accountGroup: Maybe<Pick<ApiAccountGroup, 'title' | 'id'>>;
  }>;
};

export type ApiUpdateAccountGroupDescriptorsMutationVariables = Exact<{
  input: ApiUpdateAccountGroupDescriptorsInput;
}>;

export type ApiUpdateAccountGroupDescriptorsMutation = {
  updateAccountGroupDescriptors: Maybe<{
    accountGroup: Maybe<Pick<ApiAccountGroup, 'title' | 'id'>>;
  }>;
};

export type ApiUpdateAccountGroupIsHiddenMutationVariables = Exact<{
  input: ApiUpdateAccountGroupIsHiddenInput;
}>;

export type ApiUpdateAccountGroupIsHiddenMutation = {
  updateAccountGroupIsHidden: Maybe<{
    accountGroup: Maybe<Pick<ApiAccountGroup, 'title' | 'id'>>;
  }>;
};

export type ApiUpdateCompanyMutationVariables = Exact<{
  company: ApiCompanyInputUpdate;
}>;

export type ApiUpdateCompanyMutation = {
  updateCompany: Maybe<
    Pick<ApiCompany, 'companyName' | 'canSubmitCredentials'> & {
      CRMTypes: ApiCompany['crmTypes'];
    }
  >;
};

export type ApiUpdateCurrentUserCompanyMutationVariables = Exact<{
  companyName: Scalars['String'];
}>;

export type ApiUpdateCurrentUserCompanyMutation = {
  updateUserCompany: Maybe<Pick<ApiUser, 'companyName'>>;
};

export type ApiUpdateUserMutationVariables = Exact<{
  user: ApiUserInputUpdate;
}>;

export type ApiUpdateUserMutation = {
  updateUser: Maybe<
    Pick<
      ApiUser,
      | 'companyName'
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'isAdmin'
      | 'disabled'
    >
  >;
};

export type ApiGetAccountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ApiGetAccountQuery = {
  account: Maybe<
    Pick<
      ApiAccount,
      | 'id'
      | 'accountName'
      | 'addressCity'
      | 'addressRegion'
      | 'addressStreet'
      | 'addressZipCode'
      | 'annualRevenue'
      | 'crmAccountId'
      | 'currentValue'
      | 'customerStatus'
      | 'employeeCount'
      | 'engagementStatus'
      | 'firstClosedOpportunityAt'
      | 'industry'
      | 'lostOpportunityCount'
      | 'openOpportunityCount'
      | 'predictionCalculatedForName'
      | 'predictionCalculatedForType'
      | 'predictionLevel'
      | 'rankAll'
      | 'rankPriority'
      | 'signalResponseIntensity'
      | 'wonOpportunityCount'
      | 'xeominPredictionSummary'
    > & {
      expectedValue: Maybe<
        Pick<ApiPredictionExpected, 'low' | 'middle' | 'high'>
      >;
      goalValues: Maybe<
        Array<
          Maybe<
            Pick<
              ApiPredictionGoal,
              'calculatedAt' | 'calculatedFor' | 'isPriority' | 'lift' | 'value'
            > & {
              signalPredictions: Array<
                Maybe<
                  Pick<
                    ApiSignalPrediction,
                    'expectedLow' | 'expectedHigh' | 'goal' | 'recommendation'
                  > & { signal_type: ApiSignalPrediction['signalType'] }
                >
              >;
            }
          >
        >
      >;
      lastInboundSignal: Maybe<
        Pick<ApiSignal, 'action' | 'occurredAt' | 'type'>
      >;
      lastOutboundSignal: Maybe<
        Pick<ApiSignal, 'action' | 'occurredAt' | 'type'>
      >;
      narratives: Maybe<Pick<ApiNarrative, 'primaryAction'>>;
    }
  >;
};

export type ApiGetAccountsListQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<ApiAccountSortItem>> | Maybe<ApiAccountSortItem>>;
}>;

export type ApiGetAccountsListQuery = Pick<ApiRootQueryType, 'filterString'> & {
  accounts: Maybe<{
    accounts: Array<
      Maybe<
        Pick<
          ApiAccount,
          | 'id'
          | 'crmAccountId'
          | 'accountName'
          | 'addressCity'
          | 'addressRegion'
          | 'addressZipCode'
          | 'industry'
          | 'employeeCount'
          | 'annualRevenue'
          | 'customerStatus'
          | 'xeominAverageQuantity'
          | 'xeominPredictionSummary'
          | 'xeominPurchaseTrendShort'
          | 'xeominSpendFraction'
        >
      >
    >;
    pageInfo: Pick<ApiPageInfo, 'totalEntries' | 'totalPages'>;
  }>;
};

export type ApiGetAccountWithIncludeDirectivesQueryVariables = Exact<{
  id: Scalars['Int'];
  currentValue?: Scalars['Boolean'];
  engagementStatus?: Scalars['Boolean'];
  firstClosedOpportunityAt?: Scalars['Boolean'];
  lastInboundSignal?: Scalars['Boolean'];
  lastOutboundSignal?: Scalars['Boolean'];
  lostOpportunityCount?: Scalars['Boolean'];
  openOpportunityCount?: Scalars['Boolean'];
  signalActivityIntensity?: Scalars['Boolean'];
  signalResponseIntensity?: Scalars['Boolean'];
  wonOpportunityCount?: Scalars['Boolean'];
  xeominAverageQuantity?: Scalars['Boolean'];
  xeominPurchaseTrendLong?: Scalars['Boolean'];
  xeominPurchaseTrendShort?: Scalars['Boolean'];
  xeominSpendFraction?: Scalars['Boolean'];
}>;

export type ApiGetAccountWithIncludeDirectivesQuery = {
  account: Maybe<
    MakeMaybe<
      Pick<
        ApiAccount,
        | 'id'
        | 'currentValue'
        | 'engagementStatus'
        | 'firstClosedOpportunityAt'
        | 'lostOpportunityCount'
        | 'openOpportunityCount'
        | 'signalActivityIntensity'
        | 'signalResponseIntensity'
        | 'wonOpportunityCount'
        | 'xeominAverageQuantity'
        | 'xeominPurchaseTrendLong'
        | 'xeominPurchaseTrendShort'
        | 'xeominSpendFraction'
      >,
      | 'currentValue'
      | 'engagementStatus'
      | 'firstClosedOpportunityAt'
      | 'lostOpportunityCount'
      | 'openOpportunityCount'
      | 'signalActivityIntensity'
      | 'signalResponseIntensity'
      | 'wonOpportunityCount'
      | 'xeominAverageQuantity'
      | 'xeominPurchaseTrendLong'
      | 'xeominPurchaseTrendShort'
      | 'xeominSpendFraction'
    > & {
      lastInboundSignal?: Maybe<Pick<ApiSignal, 'occurredAt'>>;
      lastOutboundSignal?: Maybe<Pick<ApiSignal, 'occurredAt'>>;
    }
  >;
};

export type ApiGetAccountCountByGroupingQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  groupBy: Maybe<
    Array<Maybe<ApiAccountCountGroupBy>> | Maybe<ApiAccountCountGroupBy>
  >;
}>;

export type ApiGetAccountCountByGroupingQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & {
  accountCountByGrouping: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccountCountGrouping,
          | 'accountCount'
          | 'currentAccountScoreBucket'
          | 'possibleAccountScoreBucket'
          | 'signalType'
        >
      >
    >
  >;
};

export type ApiGetAccountGroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ApiGetAccountGroupQuery = {
  accountGroup: Maybe<
    Pick<
      ApiAccountGroup,
      | 'id'
      | 'title'
      | 'description'
      | 'createdAt'
      | 'accountsUpdatedAt'
      | 'filterString'
    > & {
      accounts: Array<
        Maybe<
          Pick<
            ApiAccount,
            | 'id'
            | 'crmAccountId'
            | 'accountName'
            | 'industry'
            | 'currentValue'
            | 'annualRevenue'
            | 'firstClosedOpportunityAt'
            | 'rankAll'
            | 'rankPriority'
          > & {
            goalValues: Maybe<
              Array<
                Maybe<
                  Pick<ApiPredictionGoal, 'isPriority' | 'lift'> & {
                    signalPredictions: Array<
                      Maybe<
                        Pick<ApiSignalPrediction, 'recommendation'> & {
                          signal_type: ApiSignalPrediction['signalType'];
                        }
                      >
                    >;
                  }
                >
              >
            >;
          }
        >
      >;
      companyUser: Pick<ApiUser, 'id' | 'email' | 'firstName' | 'lastName'>;
    }
  >;
};

export type ApiGetAccountGroupsByAccountQueryVariables = Exact<{
  accountId: Scalars['Int'];
  accountGroupType: Maybe<ApiAccountGroupType>;
}>;

export type ApiGetAccountGroupsByAccountQuery = {
  accountGroupsForAccount: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccountGroup,
          | 'id'
          | 'title'
          | 'description'
          | 'createdAt'
          | 'accountsUpdatedAt'
          | 'filterString'
        >
      >
    >
  >;
};

export type ApiGetAccountPredictionsQueryVariables = Exact<{
  accountId: Scalars['Int'];
  timeRange: ApiTimeRange;
}>;

export type ApiGetAccountPredictionsQuery = {
  accountPredictions: Maybe<
    Pick<ApiAccountPredictions, 'dateFormat'> & {
      currentPredictions: Maybe<
        Array<Maybe<Pick<ApiCurrentPrediction, 'calculatedFor' | 'value'>>>
      >;
      expectedPredictions: Maybe<
        Array<
          Maybe<
            Pick<
              ApiExpectedPrediction,
              'calculatedFor' | 'valueMiddle' | 'valueLow' | 'valueHigh'
            >
          >
        >
      >;
      goalPredictions: Maybe<
        Array<
          Maybe<
            Pick<ApiGoalPrediction, 'calculatedFor' | 'value' | 'isPriority'>
          >
        >
      >;
    }
  >;
};

export type ApiGetAccountSignalsQueryVariables = Exact<{
  accountId: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;

export type ApiGetAccountSignalsQuery = {
  accountSignals: Maybe<
    Array<
      Maybe<
        Pick<
          ApiSignal,
          | 'id'
          | 'crmAccountId'
          | 'type'
          | 'typeDetail'
          | 'action'
          | 'direction'
          | 'source'
          | 'occurredAt'
          | 'topic'
          | 'topicDetail'
          | 'quantity'
          | 'value'
          | 'groupKey'
        >
      >
    >
  >;
};

export type ApiGetAccountsQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetAccountsQuery = Pick<ApiRootQueryType, 'filterString'> & {
  accountsWithPredictions: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccount,
          | 'id'
          | 'crmAccountId'
          | 'accountName'
          | 'industry'
          | 'employeeCount'
          | 'annualRevenue'
          | 'currentValue'
          | 'firstClosedOpportunityAt'
          | 'contactCount'
          | 'rankAll'
          | 'rankPriority'
        > & {
          goalValues: Maybe<
            Array<
              Maybe<
                Pick<ApiPredictionGoal, 'isPriority' | 'lift'> & {
                  signalPredictions: Array<
                    Maybe<
                      Pick<ApiSignalPrediction, 'recommendation'> & {
                        signal_type: ApiSignalPrediction['signalType'];
                      }
                    >
                  >;
                }
              >
            >
          >;
        }
      >
    >
  >;
};

export type ApiGetAccountsWithFilterVarQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetAccountsWithFilterVarQuery = {
  accountsWithPredictions: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccount,
          | 'id'
          | 'crmAccountId'
          | 'accountName'
          | 'industry'
          | 'employeeCount'
          | 'annualRevenue'
          | 'currentValue'
          | 'firstClosedOpportunityAt'
          | 'contactCount'
          | 'rankAll'
          | 'rankPriority'
        > & {
          goalValues: Maybe<
            Array<
              Maybe<
                Pick<ApiPredictionGoal, 'isPriority' | 'lift'> & {
                  signalPredictions: Array<
                    Maybe<
                      Pick<ApiSignalPrediction, 'recommendation'> & {
                        signal_type: ApiSignalPrediction['signalType'];
                      }
                    >
                  >;
                }
              >
            >
          >;
        }
      >
    >
  >;
};

export type ApiGetAccountsWithQueryStringQueryVariables = Exact<{
  queryString: Scalars['String'];
  limit: Maybe<Scalars['Boolean']>;
}>;

export type ApiGetAccountsWithQueryStringQuery = {
  accountsForSearch: Array<
    Maybe<
      Pick<ApiAccountForSearchPayload, 'id' | 'crmAccountId'> & {
        accountName: ApiAccountForSearchPayload['name'];
      }
    >
  >;
};

export type ApiAggregateAccountsFragment = Pick<
  ApiAggregateAccountsData,
  | 'totalAccounts'
  | 'accountsWithClosedOpps'
  | 'avgWinfluence'
  | 'totalIndustryCount'
> & {
  currentAccountScore: Array<
    Maybe<Pick<ApiAggregateValueCounts, 'value' | 'lt' | 'gt' | 'eq'>>
  >;
  winfluence: Array<Maybe<Pick<ApiAggregateString, 'name' | 'count'>>>;
  revenue: Array<
    Maybe<Pick<ApiAggregateValueCounts, 'value' | 'lt' | 'gt' | 'eq'>>
  >;
  size: Array<
    Maybe<Pick<ApiAggregateValueCounts, 'value' | 'lt' | 'gt' | 'eq'>>
  >;
  country: Array<Maybe<Pick<ApiAggregateString, 'name' | 'count'>>>;
  industry: Array<Maybe<Pick<ApiAggregateString, 'name' | 'count'>>>;
  signalType: Array<Maybe<Pick<ApiAggregateString, 'name' | 'count'>>>;
  recommendedAction: Array<Maybe<Pick<ApiAggregateString, 'name' | 'count'>>>;
  accountsForSearch: Array<
    Maybe<
      Pick<ApiAccountForSearchPayload, 'id' | 'contactCount'> & {
        accountName: ApiAccountForSearchPayload['name'];
      }
    >
  >;
  engagementStatus: Array<Maybe<Pick<ApiAggregateString, 'name' | 'count'>>>;
  signalActivityIntensity: Array<
    Maybe<Pick<ApiAggregateString, 'name' | 'count'>>
  >;
  signalResponseIntensity: Array<
    Maybe<Pick<ApiAggregateString, 'name' | 'count'>>
  >;
  xeominPredictionSummary: Array<
    Maybe<Pick<ApiAggregateString, 'name' | 'count'>>
  >;
};

export type ApiGetAggregateAccountsDataQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetAggregateAccountsDataQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & { aggregateAccountsData: Maybe<ApiAggregateAccountsFragment> };

export type ApiGetAggregateAccountsDataWithFilterVarQueryVariables = Exact<{
  filterString: Scalars['String'];
}>;

export type ApiGetAggregateAccountsDataWithFilterVarQuery = {
  aggregateAccountsData: Maybe<ApiAggregateAccountsFragment>;
};

export type ApiGetAggregateAccountsDataWithoutEngagementStatusQueryVariables =
  Exact<{
    filterString: Maybe<Scalars['String']>;
  }>;

export type ApiGetAggregateAccountsDataWithoutEngagementStatusQuery = Pick<
  ApiRootQueryType,
  'filterStringWithoutEngagementStatus'
> & { aggregateAccountsData: Maybe<ApiAggregateAccountsFragment> };

export type ApiGetAllAccountGroupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApiGetAllAccountGroupsQuery = {
  accountGroups: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccountGroup,
          | 'id'
          | 'title'
          | 'description'
          | 'filterString'
          | 'createdAt'
          | 'accountsUpdatedAt'
          | 'isHidden'
          | 'accountCount'
        > & {
          companyUser: Pick<ApiUser, 'id' | 'email' | 'firstName' | 'lastName'>;
        }
      >
    >
  >;
};

export type ApiGetAllCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetAllCompaniesQuery = {
  companies: Maybe<
    Array<
      Maybe<
        Pick<
          ApiCompany,
          'id' | 'companyName' | 'canSubmitCredentials' | 'crmTypes'
        >
      >
    >
  >;
};

export type ApiGetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetAllUsersQuery = {
  companyUsers: Maybe<
    Array<
      Maybe<
        Pick<
          ApiUser,
          | 'id'
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'companyName'
          | 'isKlearlyAdmin'
          | 'isAdmin'
          | 'disabled'
          | 'role'
        >
      >
    >
  >;
};

export type ApiGetDataAssessmentPerCompanyQueryVariables = Exact<{
  company: Scalars['String'];
}>;

export type ApiGetDataAssessmentPerCompanyQuery = {
  dataAssessment: Maybe<
    Array<
      Maybe<
        Pick<ApiDataReport, 'title' | 'description' | 'queryText'> & {
          data: Maybe<Pick<ApiDataReportData, 'columns' | 'rows'>>;
        }
      >
    >
  >;
};

export type ApiGetKlearlyUserQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetKlearlyUserQuery = {
  me: Maybe<
    Pick<
      ApiUser,
      | 'id'
      | 'firstName'
      | 'lastName'
      | 'companyName'
      | 'email'
      | 'isAdmin'
      | 'isKlearlyAdmin'
      | 'disabled'
    > & {
      company: Maybe<
        Pick<
          ApiCompany,
          'canSubmitCredentials' | 'crmTypes' | 'dataProcessedAt'
        >
      >;
    }
  >;
};

export type ApiSignalHistoryItemsFragment = Pick<
  ApiSignalHistoryCategory,
  'narratives'
> & {
  signalHistoryItems: Array<
    Maybe<
      Pick<
        ApiSignalHistoryItem,
        'startDate' | 'endDate' | 'accountCount' | 'signalCount'
      >
    >
  >;
};

export type ApiGetSignalHistoryQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  timeRange: ApiTimeRange;
}>;

export type ApiGetSignalHistoryQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & {
  signalHistory: Maybe<
    Pick<ApiSignalHistory, 'dateFormat'> & {
      allAll: ApiSignalHistoryItemsFragment;
      allResponse: ApiSignalHistoryItemsFragment;
      allActivity: ApiSignalHistoryItemsFragment;
      marketingAll: ApiSignalHistoryItemsFragment;
      marketingResponse: ApiSignalHistoryItemsFragment;
      marketingActivity: ApiSignalHistoryItemsFragment;
      salesAll: ApiSignalHistoryItemsFragment;
      salesResponse: ApiSignalHistoryItemsFragment;
      salesActivity: ApiSignalHistoryItemsFragment;
    }
  >;
};

export type ApiGetRecommendedActionsQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetRecommendedActionsQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & {
  recommendedActions: Maybe<
    Array<
      Maybe<
        Pick<
          ApiRecommendedAction,
          | 'signalType'
          | 'action'
          | 'accountCount'
          | 'goalCount'
          | 'actualCount'
          | 'averageRank'
        >
      >
    >
  >;
};

export type ApiSignalHistoryFragment = {
  signalDetails: Array<
    Maybe<
      Pick<
        ApiSignalDetailsForAccountItem,
        | 'count'
        | 'signalDate'
        | 'signalSource'
        | 'signalTopicDetail'
        | 'signalType'
        | 'signalTypeDetail'
      >
    >
  >;
  signalTotalByDate: Array<
    Maybe<Pick<ApiSignalTotalForAccountByDate, 'date' | 'total'>>
  >;
};

export type ApiGetSignalHistoryForAccountQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  accountId: Scalars['Int'];
  groupBy: Array<Maybe<ApiSignalGroupByType>> | Maybe<ApiSignalGroupByType>;
}>;

export type ApiGetSignalHistoryForAccountQuery = {
  signalHistoryForAccount: Maybe<{
    allAll: ApiSignalHistoryFragment;
    allActivity: ApiSignalHistoryFragment;
    allResponse: ApiSignalHistoryFragment;
    marketingAll: ApiSignalHistoryFragment;
    marketingActivity: ApiSignalHistoryFragment;
    marketingResponse: ApiSignalHistoryFragment;
    salesAll: ApiSignalHistoryFragment;
    salesActivity: ApiSignalHistoryFragment;
    salesResponse: ApiSignalHistoryFragment;
  }>;
};

export const AggregateAccountsFragmentDoc = gql`
  fragment aggregateAccounts on AggregateAccountsData {
    currentAccountScore {
      value
      lt
      gt
      eq
    }
    winfluence {
      name
      count
    }
    revenue {
      value
      lt
      gt
      eq
    }
    size {
      value
      lt
      gt
      eq
    }
    country {
      name
      count
    }
    industry {
      name
      count
    }
    signalType {
      name
      count
    }
    recommendedAction {
      name
      count
    }
    totalAccounts
    accountsWithClosedOpps
    avgWinfluence
    totalAccounts
    totalIndustryCount
    accountsForSearch {
      id
      accountName: name
      contactCount
    }
    engagementStatus {
      name
      count
    }
    signalActivityIntensity {
      name
      count
    }
    signalResponseIntensity {
      name
      count
    }
    xeominPredictionSummary {
      name
      count
    }
  }
`;
export const SignalHistoryItemsFragmentDoc = gql`
  fragment signalHistoryItems on SignalHistoryCategory {
    signalHistoryItems {
      startDate
      endDate
      accountCount
      signalCount
    }
    narratives
  }
`;
export const SignalHistoryFragmentDoc = gql`
  fragment signalHistory on SignalHistoryForAccountItem {
    signalDetails {
      count
      signalDate
      signalSource
      signalTopicDetail
      signalType
      signalTypeDetail
    }
    signalTotalByDate {
      date
      total
    }
  }
`;
export const AddAccountGroupDocument = gql`
  mutation AddAccountGroup($input: AddAccountGroupInput!) {
    addAccountGroup(input: $input) {
      accountGroup {
        id
      }
    }
  }
`;
export type ApiAddAccountGroupMutationFn = Apollo.MutationFunction<
  ApiAddAccountGroupMutation,
  ApiAddAccountGroupMutationVariables
>;

/**
 * __useAddAccountGroupMutation__
 *
 * To run a mutation, you first call `useAddAccountGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountGroupMutation, { data, loading, error }] = useAddAccountGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAccountGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiAddAccountGroupMutation,
    ApiAddAccountGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiAddAccountGroupMutation,
    ApiAddAccountGroupMutationVariables
  >(AddAccountGroupDocument, options);
}
export type AddAccountGroupMutationHookResult = ReturnType<
  typeof useAddAccountGroupMutation
>;
export type AddAccountGroupMutationResult =
  Apollo.MutationResult<ApiAddAccountGroupMutation>;
export type AddAccountGroupMutationOptions = Apollo.BaseMutationOptions<
  ApiAddAccountGroupMutation,
  ApiAddAccountGroupMutationVariables
>;
export const CreateGoogleSecretVersionDocument = gql`
  mutation CreateGoogleSecretVersion($googleSecret: GoogleSecretInput!) {
    createGoogleSecretVersion(googleSecret: $googleSecret) {
      message
    }
  }
`;
export type ApiCreateGoogleSecretVersionMutationFn = Apollo.MutationFunction<
  ApiCreateGoogleSecretVersionMutation,
  ApiCreateGoogleSecretVersionMutationVariables
>;

/**
 * __useCreateGoogleSecretVersionMutation__
 *
 * To run a mutation, you first call `useCreateGoogleSecretVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoogleSecretVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoogleSecretVersionMutation, { data, loading, error }] = useCreateGoogleSecretVersionMutation({
 *   variables: {
 *      googleSecret: // value for 'googleSecret'
 *   },
 * });
 */
export function useCreateGoogleSecretVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateGoogleSecretVersionMutation,
    ApiCreateGoogleSecretVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateGoogleSecretVersionMutation,
    ApiCreateGoogleSecretVersionMutationVariables
  >(CreateGoogleSecretVersionDocument, options);
}
export type CreateGoogleSecretVersionMutationHookResult = ReturnType<
  typeof useCreateGoogleSecretVersionMutation
>;
export type CreateGoogleSecretVersionMutationResult =
  Apollo.MutationResult<ApiCreateGoogleSecretVersionMutation>;
export type CreateGoogleSecretVersionMutationOptions =
  Apollo.BaseMutationOptions<
    ApiCreateGoogleSecretVersionMutation,
    ApiCreateGoogleSecretVersionMutationVariables
  >;
export const CreateNewCompanyDocument = gql`
  mutation CreateNewCompany($company: CompanyInput!) {
    addCompany(company: $company) {
      id
      companyName
      canSubmitCredentials
      CRMTypes: crmTypes
      insertedAt
      updatedAt
    }
  }
`;
export type ApiCreateNewCompanyMutationFn = Apollo.MutationFunction<
  ApiCreateNewCompanyMutation,
  ApiCreateNewCompanyMutationVariables
>;

/**
 * __useCreateNewCompanyMutation__
 *
 * To run a mutation, you first call `useCreateNewCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCompanyMutation, { data, loading, error }] = useCreateNewCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreateNewCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateNewCompanyMutation,
    ApiCreateNewCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateNewCompanyMutation,
    ApiCreateNewCompanyMutationVariables
  >(CreateNewCompanyDocument, options);
}
export type CreateNewCompanyMutationHookResult = ReturnType<
  typeof useCreateNewCompanyMutation
>;
export type CreateNewCompanyMutationResult =
  Apollo.MutationResult<ApiCreateNewCompanyMutation>;
export type CreateNewCompanyMutationOptions = Apollo.BaseMutationOptions<
  ApiCreateNewCompanyMutation,
  ApiCreateNewCompanyMutationVariables
>;
export const CreateNewUserDocument = gql`
  mutation CreateNewUser($user: UserInput!) {
    addUser(user: $user) {
      email
      firstName
      lastName
    }
  }
`;
export type ApiCreateNewUserMutationFn = Apollo.MutationFunction<
  ApiCreateNewUserMutation,
  ApiCreateNewUserMutationVariables
>;

/**
 * __useCreateNewUserMutation__
 *
 * To run a mutation, you first call `useCreateNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUserMutation, { data, loading, error }] = useCreateNewUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateNewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateNewUserMutation,
    ApiCreateNewUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateNewUserMutation,
    ApiCreateNewUserMutationVariables
  >(CreateNewUserDocument, options);
}
export type CreateNewUserMutationHookResult = ReturnType<
  typeof useCreateNewUserMutation
>;
export type CreateNewUserMutationResult =
  Apollo.MutationResult<ApiCreateNewUserMutation>;
export type CreateNewUserMutationOptions = Apollo.BaseMutationOptions<
  ApiCreateNewUserMutation,
  ApiCreateNewUserMutationVariables
>;
export const CreateNewUsersDocument = gql`
  mutation CreateNewUsers($users: [UserInput]!) {
    addUsers(users: $users) {
      users {
        firstName
        lastName
        email
        companyName
      }
      errors {
        email
        message
      }
    }
  }
`;
export type ApiCreateNewUsersMutationFn = Apollo.MutationFunction<
  ApiCreateNewUsersMutation,
  ApiCreateNewUsersMutationVariables
>;

/**
 * __useCreateNewUsersMutation__
 *
 * To run a mutation, you first call `useCreateNewUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUsersMutation, { data, loading, error }] = useCreateNewUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useCreateNewUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateNewUsersMutation,
    ApiCreateNewUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateNewUsersMutation,
    ApiCreateNewUsersMutationVariables
  >(CreateNewUsersDocument, options);
}
export type CreateNewUsersMutationHookResult = ReturnType<
  typeof useCreateNewUsersMutation
>;
export type CreateNewUsersMutationResult =
  Apollo.MutationResult<ApiCreateNewUsersMutation>;
export type CreateNewUsersMutationOptions = Apollo.BaseMutationOptions<
  ApiCreateNewUsersMutation,
  ApiCreateNewUsersMutationVariables
>;
export const UpdateAccountGroupAccountsDocument = gql`
  mutation UpdateAccountGroupAccounts(
    $input: UpdateAccountGroupAccountsInput!
  ) {
    updateAccountGroupAccounts(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;
export type ApiUpdateAccountGroupAccountsMutationFn = Apollo.MutationFunction<
  ApiUpdateAccountGroupAccountsMutation,
  ApiUpdateAccountGroupAccountsMutationVariables
>;

/**
 * __useUpdateAccountGroupAccountsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountGroupAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountGroupAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountGroupAccountsMutation, { data, loading, error }] = useUpdateAccountGroupAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountGroupAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateAccountGroupAccountsMutation,
    ApiUpdateAccountGroupAccountsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateAccountGroupAccountsMutation,
    ApiUpdateAccountGroupAccountsMutationVariables
  >(UpdateAccountGroupAccountsDocument, options);
}
export type UpdateAccountGroupAccountsMutationHookResult = ReturnType<
  typeof useUpdateAccountGroupAccountsMutation
>;
export type UpdateAccountGroupAccountsMutationResult =
  Apollo.MutationResult<ApiUpdateAccountGroupAccountsMutation>;
export type UpdateAccountGroupAccountsMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateAccountGroupAccountsMutation,
    ApiUpdateAccountGroupAccountsMutationVariables
  >;
export const UpdateAccountGroupDescriptorsDocument = gql`
  mutation UpdateAccountGroupDescriptors(
    $input: UpdateAccountGroupDescriptorsInput!
  ) {
    updateAccountGroupDescriptors(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;
export type ApiUpdateAccountGroupDescriptorsMutationFn =
  Apollo.MutationFunction<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >;

/**
 * __useUpdateAccountGroupDescriptorsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountGroupDescriptorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountGroupDescriptorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountGroupDescriptorsMutation, { data, loading, error }] = useUpdateAccountGroupDescriptorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountGroupDescriptorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >(UpdateAccountGroupDescriptorsDocument, options);
}
export type UpdateAccountGroupDescriptorsMutationHookResult = ReturnType<
  typeof useUpdateAccountGroupDescriptorsMutation
>;
export type UpdateAccountGroupDescriptorsMutationResult =
  Apollo.MutationResult<ApiUpdateAccountGroupDescriptorsMutation>;
export type UpdateAccountGroupDescriptorsMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >;
export const UpdateAccountGroupIsHiddenDocument = gql`
  mutation UpdateAccountGroupIsHidden(
    $input: UpdateAccountGroupIsHiddenInput!
  ) {
    updateAccountGroupIsHidden(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;
export type ApiUpdateAccountGroupIsHiddenMutationFn = Apollo.MutationFunction<
  ApiUpdateAccountGroupIsHiddenMutation,
  ApiUpdateAccountGroupIsHiddenMutationVariables
>;

/**
 * __useUpdateAccountGroupIsHiddenMutation__
 *
 * To run a mutation, you first call `useUpdateAccountGroupIsHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountGroupIsHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountGroupIsHiddenMutation, { data, loading, error }] = useUpdateAccountGroupIsHiddenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountGroupIsHiddenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateAccountGroupIsHiddenMutation,
    ApiUpdateAccountGroupIsHiddenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateAccountGroupIsHiddenMutation,
    ApiUpdateAccountGroupIsHiddenMutationVariables
  >(UpdateAccountGroupIsHiddenDocument, options);
}
export type UpdateAccountGroupIsHiddenMutationHookResult = ReturnType<
  typeof useUpdateAccountGroupIsHiddenMutation
>;
export type UpdateAccountGroupIsHiddenMutationResult =
  Apollo.MutationResult<ApiUpdateAccountGroupIsHiddenMutation>;
export type UpdateAccountGroupIsHiddenMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateAccountGroupIsHiddenMutation,
    ApiUpdateAccountGroupIsHiddenMutationVariables
  >;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($company: CompanyInputUpdate!) {
    updateCompany(company: $company) {
      companyName
      canSubmitCredentials
      CRMTypes: crmTypes
    }
  }
`;
export type ApiUpdateCompanyMutationFn = Apollo.MutationFunction<
  ApiUpdateCompanyMutation,
  ApiUpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateCompanyMutation,
    ApiUpdateCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateCompanyMutation,
    ApiUpdateCompanyMutationVariables
  >(UpdateCompanyDocument, options);
}
export type UpdateCompanyMutationHookResult = ReturnType<
  typeof useUpdateCompanyMutation
>;
export type UpdateCompanyMutationResult =
  Apollo.MutationResult<ApiUpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  ApiUpdateCompanyMutation,
  ApiUpdateCompanyMutationVariables
>;
export const UpdateCurrentUserCompanyDocument = gql`
  mutation UpdateCurrentUserCompany($companyName: String!) {
    updateUserCompany(companyName: $companyName) {
      companyName
    }
  }
`;
export type ApiUpdateCurrentUserCompanyMutationFn = Apollo.MutationFunction<
  ApiUpdateCurrentUserCompanyMutation,
  ApiUpdateCurrentUserCompanyMutationVariables
>;

/**
 * __useUpdateCurrentUserCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserCompanyMutation, { data, loading, error }] = useUpdateCurrentUserCompanyMutation({
 *   variables: {
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useUpdateCurrentUserCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateCurrentUserCompanyMutation,
    ApiUpdateCurrentUserCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateCurrentUserCompanyMutation,
    ApiUpdateCurrentUserCompanyMutationVariables
  >(UpdateCurrentUserCompanyDocument, options);
}
export type UpdateCurrentUserCompanyMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserCompanyMutation
>;
export type UpdateCurrentUserCompanyMutationResult =
  Apollo.MutationResult<ApiUpdateCurrentUserCompanyMutation>;
export type UpdateCurrentUserCompanyMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateCurrentUserCompanyMutation,
    ApiUpdateCurrentUserCompanyMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($user: UserInputUpdate!) {
    updateUser(user: $user) {
      companyName
      email
      firstName
      lastName
      isAdmin
      disabled
    }
  }
`;
export type ApiUpdateUserMutationFn = Apollo.MutationFunction<
  ApiUpdateUserMutation,
  ApiUpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateUserMutation,
    ApiUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateUserMutation,
    ApiUpdateUserMutationVariables
  >(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<ApiUpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  ApiUpdateUserMutation,
  ApiUpdateUserMutationVariables
>;
export const GetAccountDocument = gql`
  query GetAccount($id: Int!) {
    account(id: $id) {
      id
      accountName
      addressCity
      addressRegion
      addressStreet
      addressZipCode
      annualRevenue
      crmAccountId
      currentValue
      customerStatus
      employeeCount
      engagementStatus
      expectedValue {
        low
        middle
        high
      }
      firstClosedOpportunityAt
      goalValues {
        calculatedAt
        calculatedFor
        isPriority
        lift
        value
        signalPredictions {
          expectedLow
          expectedHigh
          goal
          recommendation
          signal_type: signalType
        }
      }
      industry
      lastInboundSignal {
        action
        occurredAt
        type
      }
      lastOutboundSignal {
        action
        occurredAt
        type
      }
      lostOpportunityCount
      narratives {
        primaryAction
      }
      openOpportunityCount
      predictionCalculatedForName
      predictionCalculatedForType
      predictionLevel
      rankAll
      rankPriority
      signalResponseIntensity
      wonOpportunityCount
      xeominPredictionSummary
    }
  }
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountQuery,
    ApiGetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiGetAccountQuery, ApiGetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountQuery,
    ApiGetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiGetAccountQuery, ApiGetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountLazyQuery
>;
export type GetAccountQueryResult = Apollo.QueryResult<
  ApiGetAccountQuery,
  ApiGetAccountQueryVariables
>;
export function refetchGetAccountQuery(
  variables?: ApiGetAccountQueryVariables,
) {
  return { query: GetAccountDocument, variables: variables };
}
export const GetAccountsListDocument = gql`
  query GetAccountsList(
    $filterString: String
    $limit: Int
    $page: Int
    $sort: [AccountSortItem] = []
  ) {
    filterString @client @export(as: "filterString")
    accounts(
      filterString: $filterString
      limit: $limit
      page: $page
      sort: $sort
    ) {
      accounts {
        id
        crmAccountId
        accountName
        addressCity
        addressRegion
        addressZipCode
        industry
        employeeCount
        annualRevenue
        customerStatus
        xeominAverageQuantity
        xeominPredictionSummary
        xeominPurchaseTrendShort
        xeominSpendFraction
      }
      pageInfo {
        totalEntries
        totalPages
      }
    }
  }
`;

/**
 * __useGetAccountsListQuery__
 *
 * To run a query within a React component, call `useGetAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsListQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >(GetAccountsListDocument, options);
}
export function useGetAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >(GetAccountsListDocument, options);
}
export type GetAccountsListQueryHookResult = ReturnType<
  typeof useGetAccountsListQuery
>;
export type GetAccountsListLazyQueryHookResult = ReturnType<
  typeof useGetAccountsListLazyQuery
>;
export type GetAccountsListQueryResult = Apollo.QueryResult<
  ApiGetAccountsListQuery,
  ApiGetAccountsListQueryVariables
>;
export function refetchGetAccountsListQuery(
  variables?: ApiGetAccountsListQueryVariables,
) {
  return { query: GetAccountsListDocument, variables: variables };
}
export const GetAccountWithIncludeDirectivesDocument = gql`
  query GetAccountWithIncludeDirectives(
    $id: Int!
    $currentValue: Boolean! = false
    $engagementStatus: Boolean! = false
    $firstClosedOpportunityAt: Boolean! = false
    $lastInboundSignal: Boolean! = false
    $lastOutboundSignal: Boolean! = false
    $lostOpportunityCount: Boolean! = false
    $openOpportunityCount: Boolean! = false
    $signalActivityIntensity: Boolean! = false
    $signalResponseIntensity: Boolean! = false
    $wonOpportunityCount: Boolean! = false
    $xeominAverageQuantity: Boolean! = false
    $xeominPurchaseTrendLong: Boolean! = false
    $xeominPurchaseTrendShort: Boolean! = false
    $xeominSpendFraction: Boolean! = false
  ) {
    account(id: $id) {
      id
      currentValue @include(if: $currentValue)
      engagementStatus @include(if: $engagementStatus)
      firstClosedOpportunityAt @include(if: $firstClosedOpportunityAt)
      lastInboundSignal @include(if: $lastInboundSignal) {
        occurredAt
      }
      lastOutboundSignal @include(if: $lastOutboundSignal) {
        occurredAt
      }
      lostOpportunityCount @include(if: $lostOpportunityCount)
      openOpportunityCount @include(if: $openOpportunityCount)
      signalActivityIntensity @include(if: $signalActivityIntensity)
      signalResponseIntensity @include(if: $signalResponseIntensity)
      wonOpportunityCount @include(if: $wonOpportunityCount)
      xeominAverageQuantity @include(if: $xeominAverageQuantity)
      xeominPurchaseTrendLong @include(if: $xeominPurchaseTrendLong)
      xeominPurchaseTrendShort @include(if: $xeominPurchaseTrendShort)
      xeominSpendFraction @include(if: $xeominSpendFraction)
    }
  }
`;

/**
 * __useGetAccountWithIncludeDirectivesQuery__
 *
 * To run a query within a React component, call `useGetAccountWithIncludeDirectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountWithIncludeDirectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountWithIncludeDirectivesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currentValue: // value for 'currentValue'
 *      engagementStatus: // value for 'engagementStatus'
 *      firstClosedOpportunityAt: // value for 'firstClosedOpportunityAt'
 *      lastInboundSignal: // value for 'lastInboundSignal'
 *      lastOutboundSignal: // value for 'lastOutboundSignal'
 *      lostOpportunityCount: // value for 'lostOpportunityCount'
 *      openOpportunityCount: // value for 'openOpportunityCount'
 *      signalActivityIntensity: // value for 'signalActivityIntensity'
 *      signalResponseIntensity: // value for 'signalResponseIntensity'
 *      wonOpportunityCount: // value for 'wonOpportunityCount'
 *      xeominAverageQuantity: // value for 'xeominAverageQuantity'
 *      xeominPurchaseTrendLong: // value for 'xeominPurchaseTrendLong'
 *      xeominPurchaseTrendShort: // value for 'xeominPurchaseTrendShort'
 *      xeominSpendFraction: // value for 'xeominSpendFraction'
 *   },
 * });
 */
export function useGetAccountWithIncludeDirectivesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >(GetAccountWithIncludeDirectivesDocument, options);
}
export function useGetAccountWithIncludeDirectivesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >(GetAccountWithIncludeDirectivesDocument, options);
}
export type GetAccountWithIncludeDirectivesQueryHookResult = ReturnType<
  typeof useGetAccountWithIncludeDirectivesQuery
>;
export type GetAccountWithIncludeDirectivesLazyQueryHookResult = ReturnType<
  typeof useGetAccountWithIncludeDirectivesLazyQuery
>;
export type GetAccountWithIncludeDirectivesQueryResult = Apollo.QueryResult<
  ApiGetAccountWithIncludeDirectivesQuery,
  ApiGetAccountWithIncludeDirectivesQueryVariables
>;
export function refetchGetAccountWithIncludeDirectivesQuery(
  variables?: ApiGetAccountWithIncludeDirectivesQueryVariables,
) {
  return {
    query: GetAccountWithIncludeDirectivesDocument,
    variables: variables,
  };
}
export const GetAccountCountByGroupingDocument = gql`
  query GetAccountCountByGrouping(
    $filterString: String
    $groupBy: [AccountCountGroupBy]
  ) {
    filterString @client @export(as: "filterString")
    accountCountByGrouping(filterString: $filterString, groupBy: $groupBy) {
      accountCount
      currentAccountScoreBucket
      possibleAccountScoreBucket
      signalType
    }
  }
`;

/**
 * __useGetAccountCountByGroupingQuery__
 *
 * To run a query within a React component, call `useGetAccountCountByGroupingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCountByGroupingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCountByGroupingQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useGetAccountCountByGroupingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >(GetAccountCountByGroupingDocument, options);
}
export function useGetAccountCountByGroupingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >(GetAccountCountByGroupingDocument, options);
}
export type GetAccountCountByGroupingQueryHookResult = ReturnType<
  typeof useGetAccountCountByGroupingQuery
>;
export type GetAccountCountByGroupingLazyQueryHookResult = ReturnType<
  typeof useGetAccountCountByGroupingLazyQuery
>;
export type GetAccountCountByGroupingQueryResult = Apollo.QueryResult<
  ApiGetAccountCountByGroupingQuery,
  ApiGetAccountCountByGroupingQueryVariables
>;
export function refetchGetAccountCountByGroupingQuery(
  variables?: ApiGetAccountCountByGroupingQueryVariables,
) {
  return { query: GetAccountCountByGroupingDocument, variables: variables };
}
export const GetAccountGroupDocument = gql`
  query GetAccountGroup($id: Int!) {
    accountGroup(id: $id) {
      id
      title
      description
      createdAt
      accountsUpdatedAt
      filterString
      accounts {
        id
        crmAccountId
        accountName
        industry
        currentValue
        annualRevenue
        firstClosedOpportunityAt
        goalValues {
          isPriority
          lift
          signalPredictions {
            recommendation
            signal_type: signalType
          }
        }
        rankAll
        rankPriority
      }
      companyUser {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetAccountGroupQuery__
 *
 * To run a query within a React component, call `useGetAccountGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >(GetAccountGroupDocument, options);
}
export function useGetAccountGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >(GetAccountGroupDocument, options);
}
export type GetAccountGroupQueryHookResult = ReturnType<
  typeof useGetAccountGroupQuery
>;
export type GetAccountGroupLazyQueryHookResult = ReturnType<
  typeof useGetAccountGroupLazyQuery
>;
export type GetAccountGroupQueryResult = Apollo.QueryResult<
  ApiGetAccountGroupQuery,
  ApiGetAccountGroupQueryVariables
>;
export function refetchGetAccountGroupQuery(
  variables?: ApiGetAccountGroupQueryVariables,
) {
  return { query: GetAccountGroupDocument, variables: variables };
}
export const GetAccountGroupsByAccountDocument = gql`
  query GetAccountGroupsByAccount(
    $accountId: Int!
    $accountGroupType: AccountGroupType
  ) {
    accountGroupsForAccount(
      accountId: $accountId
      accountGroupType: $accountGroupType
    ) {
      id
      title
      description
      createdAt
      accountsUpdatedAt
      filterString
    }
  }
`;

/**
 * __useGetAccountGroupsByAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountGroupsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountGroupsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountGroupsByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountGroupType: // value for 'accountGroupType'
 *   },
 * });
 */
export function useGetAccountGroupsByAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >(GetAccountGroupsByAccountDocument, options);
}
export function useGetAccountGroupsByAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >(GetAccountGroupsByAccountDocument, options);
}
export type GetAccountGroupsByAccountQueryHookResult = ReturnType<
  typeof useGetAccountGroupsByAccountQuery
>;
export type GetAccountGroupsByAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountGroupsByAccountLazyQuery
>;
export type GetAccountGroupsByAccountQueryResult = Apollo.QueryResult<
  ApiGetAccountGroupsByAccountQuery,
  ApiGetAccountGroupsByAccountQueryVariables
>;
export function refetchGetAccountGroupsByAccountQuery(
  variables?: ApiGetAccountGroupsByAccountQueryVariables,
) {
  return { query: GetAccountGroupsByAccountDocument, variables: variables };
}
export const GetAccountPredictionsDocument = gql`
  query GetAccountPredictions($accountId: Int!, $timeRange: TimeRange!) {
    accountPredictions(accountId: $accountId, timeRange: $timeRange) {
      currentPredictions {
        calculatedFor
        value
      }
      expectedPredictions {
        calculatedFor
        valueMiddle
        valueLow
        valueHigh
      }
      goalPredictions {
        calculatedFor
        value
        isPriority
      }
      dateFormat
    }
  }
`;

/**
 * __useGetAccountPredictionsQuery__
 *
 * To run a query within a React component, call `useGetAccountPredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPredictionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useGetAccountPredictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >(GetAccountPredictionsDocument, options);
}
export function useGetAccountPredictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >(GetAccountPredictionsDocument, options);
}
export type GetAccountPredictionsQueryHookResult = ReturnType<
  typeof useGetAccountPredictionsQuery
>;
export type GetAccountPredictionsLazyQueryHookResult = ReturnType<
  typeof useGetAccountPredictionsLazyQuery
>;
export type GetAccountPredictionsQueryResult = Apollo.QueryResult<
  ApiGetAccountPredictionsQuery,
  ApiGetAccountPredictionsQueryVariables
>;
export function refetchGetAccountPredictionsQuery(
  variables?: ApiGetAccountPredictionsQueryVariables,
) {
  return { query: GetAccountPredictionsDocument, variables: variables };
}
export const GetAccountSignalsDocument = gql`
  query GetAccountSignals(
    $accountId: Int!
    $startDate: Date!
    $endDate: Date!
  ) {
    accountSignals(
      accountId: $accountId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      crmAccountId
      type
      typeDetail
      action
      direction
      source
      occurredAt
      topic
      topicDetail
      quantity
      value
      groupKey
    }
  }
`;

/**
 * __useGetAccountSignalsQuery__
 *
 * To run a query within a React component, call `useGetAccountSignalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountSignalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountSignalsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAccountSignalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >(GetAccountSignalsDocument, options);
}
export function useGetAccountSignalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >(GetAccountSignalsDocument, options);
}
export type GetAccountSignalsQueryHookResult = ReturnType<
  typeof useGetAccountSignalsQuery
>;
export type GetAccountSignalsLazyQueryHookResult = ReturnType<
  typeof useGetAccountSignalsLazyQuery
>;
export type GetAccountSignalsQueryResult = Apollo.QueryResult<
  ApiGetAccountSignalsQuery,
  ApiGetAccountSignalsQueryVariables
>;
export function refetchGetAccountSignalsQuery(
  variables?: ApiGetAccountSignalsQueryVariables,
) {
  return { query: GetAccountSignalsDocument, variables: variables };
}
export const GetAccountsDocument = gql`
  query GetAccounts($filterString: String) {
    filterString @client @export(as: "filterString")
    accountsWithPredictions(filterString: $filterString) {
      id
      crmAccountId
      accountName
      industry
      employeeCount
      annualRevenue
      currentValue
      firstClosedOpportunityAt
      goalValues {
        isPriority
        lift
        signalPredictions {
          recommendation
          signal_type: signalType
        }
      }
      contactCount
      rankAll
      rankPriority
    }
  }
`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsQuery,
    ApiGetAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiGetAccountsQuery, ApiGetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export function useGetAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsQuery,
    ApiGetAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiGetAccountsQuery, ApiGetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAccountsLazyQuery
>;
export type GetAccountsQueryResult = Apollo.QueryResult<
  ApiGetAccountsQuery,
  ApiGetAccountsQueryVariables
>;
export function refetchGetAccountsQuery(
  variables?: ApiGetAccountsQueryVariables,
) {
  return { query: GetAccountsDocument, variables: variables };
}
export const GetAccountsWithFilterVarDocument = gql`
  query GetAccountsWithFilterVar($filterString: String) {
    accountsWithPredictions(filterString: $filterString) {
      id
      crmAccountId
      accountName
      industry
      employeeCount
      annualRevenue
      currentValue
      firstClosedOpportunityAt
      goalValues {
        isPriority
        lift
        signalPredictions {
          recommendation
          signal_type: signalType
        }
      }
      contactCount
      rankAll
      rankPriority
    }
  }
`;

/**
 * __useGetAccountsWithFilterVarQuery__
 *
 * To run a query within a React component, call `useGetAccountsWithFilterVarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsWithFilterVarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsWithFilterVarQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAccountsWithFilterVarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >(GetAccountsWithFilterVarDocument, options);
}
export function useGetAccountsWithFilterVarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >(GetAccountsWithFilterVarDocument, options);
}
export type GetAccountsWithFilterVarQueryHookResult = ReturnType<
  typeof useGetAccountsWithFilterVarQuery
>;
export type GetAccountsWithFilterVarLazyQueryHookResult = ReturnType<
  typeof useGetAccountsWithFilterVarLazyQuery
>;
export type GetAccountsWithFilterVarQueryResult = Apollo.QueryResult<
  ApiGetAccountsWithFilterVarQuery,
  ApiGetAccountsWithFilterVarQueryVariables
>;
export function refetchGetAccountsWithFilterVarQuery(
  variables?: ApiGetAccountsWithFilterVarQueryVariables,
) {
  return { query: GetAccountsWithFilterVarDocument, variables: variables };
}
export const GetAccountsWithQueryStringDocument = gql`
  query GetAccountsWithQueryString($queryString: String!, $limit: Boolean) {
    accountsForSearch(
      query: $queryString
      limitToOpenAccountsWithPredictions: $limit
    ) {
      id
      accountName: name
      crmAccountId
    }
  }
`;

/**
 * __useGetAccountsWithQueryStringQuery__
 *
 * To run a query within a React component, call `useGetAccountsWithQueryStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsWithQueryStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsWithQueryStringQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAccountsWithQueryStringQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >(GetAccountsWithQueryStringDocument, options);
}
export function useGetAccountsWithQueryStringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >(GetAccountsWithQueryStringDocument, options);
}
export type GetAccountsWithQueryStringQueryHookResult = ReturnType<
  typeof useGetAccountsWithQueryStringQuery
>;
export type GetAccountsWithQueryStringLazyQueryHookResult = ReturnType<
  typeof useGetAccountsWithQueryStringLazyQuery
>;
export type GetAccountsWithQueryStringQueryResult = Apollo.QueryResult<
  ApiGetAccountsWithQueryStringQuery,
  ApiGetAccountsWithQueryStringQueryVariables
>;
export function refetchGetAccountsWithQueryStringQuery(
  variables?: ApiGetAccountsWithQueryStringQueryVariables,
) {
  return { query: GetAccountsWithQueryStringDocument, variables: variables };
}
export const GetAggregateAccountsDataDocument = gql`
  query GetAggregateAccountsData($filterString: String) {
    filterString @client @export(as: "filterString")
    aggregateAccountsData(filterString: $filterString) {
      ...aggregateAccounts
    }
  }
  ${AggregateAccountsFragmentDoc}
`;

/**
 * __useGetAggregateAccountsDataQuery__
 *
 * To run a query within a React component, call `useGetAggregateAccountsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateAccountsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateAccountsDataQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAggregateAccountsDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAggregateAccountsDataQuery,
    ApiGetAggregateAccountsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateAccountsDataQuery,
    ApiGetAggregateAccountsDataQueryVariables
  >(GetAggregateAccountsDataDocument, options);
}
export function useGetAggregateAccountsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateAccountsDataQuery,
    ApiGetAggregateAccountsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateAccountsDataQuery,
    ApiGetAggregateAccountsDataQueryVariables
  >(GetAggregateAccountsDataDocument, options);
}
export type GetAggregateAccountsDataQueryHookResult = ReturnType<
  typeof useGetAggregateAccountsDataQuery
>;
export type GetAggregateAccountsDataLazyQueryHookResult = ReturnType<
  typeof useGetAggregateAccountsDataLazyQuery
>;
export type GetAggregateAccountsDataQueryResult = Apollo.QueryResult<
  ApiGetAggregateAccountsDataQuery,
  ApiGetAggregateAccountsDataQueryVariables
>;
export function refetchGetAggregateAccountsDataQuery(
  variables?: ApiGetAggregateAccountsDataQueryVariables,
) {
  return { query: GetAggregateAccountsDataDocument, variables: variables };
}
export const GetAggregateAccountsDataWithFilterVarDocument = gql`
  query GetAggregateAccountsDataWithFilterVar($filterString: String!) {
    aggregateAccountsData(filterString: $filterString) {
      ...aggregateAccounts
    }
  }
  ${AggregateAccountsFragmentDoc}
`;

/**
 * __useGetAggregateAccountsDataWithFilterVarQuery__
 *
 * To run a query within a React component, call `useGetAggregateAccountsDataWithFilterVarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateAccountsDataWithFilterVarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateAccountsDataWithFilterVarQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAggregateAccountsDataWithFilterVarQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAggregateAccountsDataWithFilterVarQuery,
    ApiGetAggregateAccountsDataWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateAccountsDataWithFilterVarQuery,
    ApiGetAggregateAccountsDataWithFilterVarQueryVariables
  >(GetAggregateAccountsDataWithFilterVarDocument, options);
}
export function useGetAggregateAccountsDataWithFilterVarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateAccountsDataWithFilterVarQuery,
    ApiGetAggregateAccountsDataWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateAccountsDataWithFilterVarQuery,
    ApiGetAggregateAccountsDataWithFilterVarQueryVariables
  >(GetAggregateAccountsDataWithFilterVarDocument, options);
}
export type GetAggregateAccountsDataWithFilterVarQueryHookResult = ReturnType<
  typeof useGetAggregateAccountsDataWithFilterVarQuery
>;
export type GetAggregateAccountsDataWithFilterVarLazyQueryHookResult =
  ReturnType<typeof useGetAggregateAccountsDataWithFilterVarLazyQuery>;
export type GetAggregateAccountsDataWithFilterVarQueryResult =
  Apollo.QueryResult<
    ApiGetAggregateAccountsDataWithFilterVarQuery,
    ApiGetAggregateAccountsDataWithFilterVarQueryVariables
  >;
export function refetchGetAggregateAccountsDataWithFilterVarQuery(
  variables?: ApiGetAggregateAccountsDataWithFilterVarQueryVariables,
) {
  return {
    query: GetAggregateAccountsDataWithFilterVarDocument,
    variables: variables,
  };
}
export const GetAggregateAccountsDataWithoutEngagementStatusDocument = gql`
  query GetAggregateAccountsDataWithoutEngagementStatus($filterString: String) {
    filterStringWithoutEngagementStatus @client @export(as: "filterString")
    aggregateAccountsData(filterString: $filterString) {
      ...aggregateAccounts
    }
  }
  ${AggregateAccountsFragmentDoc}
`;

/**
 * __useGetAggregateAccountsDataWithoutEngagementStatusQuery__
 *
 * To run a query within a React component, call `useGetAggregateAccountsDataWithoutEngagementStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateAccountsDataWithoutEngagementStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateAccountsDataWithoutEngagementStatusQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAggregateAccountsDataWithoutEngagementStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAggregateAccountsDataWithoutEngagementStatusQuery,
    ApiGetAggregateAccountsDataWithoutEngagementStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateAccountsDataWithoutEngagementStatusQuery,
    ApiGetAggregateAccountsDataWithoutEngagementStatusQueryVariables
  >(GetAggregateAccountsDataWithoutEngagementStatusDocument, options);
}
export function useGetAggregateAccountsDataWithoutEngagementStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateAccountsDataWithoutEngagementStatusQuery,
    ApiGetAggregateAccountsDataWithoutEngagementStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateAccountsDataWithoutEngagementStatusQuery,
    ApiGetAggregateAccountsDataWithoutEngagementStatusQueryVariables
  >(GetAggregateAccountsDataWithoutEngagementStatusDocument, options);
}
export type GetAggregateAccountsDataWithoutEngagementStatusQueryHookResult =
  ReturnType<typeof useGetAggregateAccountsDataWithoutEngagementStatusQuery>;
export type GetAggregateAccountsDataWithoutEngagementStatusLazyQueryHookResult =
  ReturnType<
    typeof useGetAggregateAccountsDataWithoutEngagementStatusLazyQuery
  >;
export type GetAggregateAccountsDataWithoutEngagementStatusQueryResult =
  Apollo.QueryResult<
    ApiGetAggregateAccountsDataWithoutEngagementStatusQuery,
    ApiGetAggregateAccountsDataWithoutEngagementStatusQueryVariables
  >;
export function refetchGetAggregateAccountsDataWithoutEngagementStatusQuery(
  variables?: ApiGetAggregateAccountsDataWithoutEngagementStatusQueryVariables,
) {
  return {
    query: GetAggregateAccountsDataWithoutEngagementStatusDocument,
    variables: variables,
  };
}
export const GetAllAccountGroupsDocument = gql`
  query GetAllAccountGroups {
    accountGroups {
      id
      title
      description
      filterString
      createdAt
      accountsUpdatedAt
      companyUser {
        id
        email
        firstName
        lastName
      }
      isHidden
      accountCount
    }
  }
`;

/**
 * __useGetAllAccountGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >(GetAllAccountGroupsDocument, options);
}
export function useGetAllAccountGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >(GetAllAccountGroupsDocument, options);
}
export type GetAllAccountGroupsQueryHookResult = ReturnType<
  typeof useGetAllAccountGroupsQuery
>;
export type GetAllAccountGroupsLazyQueryHookResult = ReturnType<
  typeof useGetAllAccountGroupsLazyQuery
>;
export type GetAllAccountGroupsQueryResult = Apollo.QueryResult<
  ApiGetAllAccountGroupsQuery,
  ApiGetAllAccountGroupsQueryVariables
>;
export function refetchGetAllAccountGroupsQuery(
  variables?: ApiGetAllAccountGroupsQueryVariables,
) {
  return { query: GetAllAccountGroupsDocument, variables: variables };
}
export const GetAllCompaniesDocument = gql`
  query GetAllCompanies {
    companies {
      id
      companyName
      canSubmitCredentials
      crmTypes
    }
  }
`;

/**
 * __useGetAllCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >(GetAllCompaniesDocument, options);
}
export function useGetAllCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >(GetAllCompaniesDocument, options);
}
export type GetAllCompaniesQueryHookResult = ReturnType<
  typeof useGetAllCompaniesQuery
>;
export type GetAllCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetAllCompaniesLazyQuery
>;
export type GetAllCompaniesQueryResult = Apollo.QueryResult<
  ApiGetAllCompaniesQuery,
  ApiGetAllCompaniesQueryVariables
>;
export function refetchGetAllCompaniesQuery(
  variables?: ApiGetAllCompaniesQueryVariables,
) {
  return { query: GetAllCompaniesDocument, variables: variables };
}
export const GetAllUsersDocument = gql`
  query GetAllUsers {
    companyUsers {
      id
      firstName
      lastName
      email
      companyName
      isKlearlyAdmin
      isAdmin
      disabled
      role
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAllUsersQuery,
    ApiGetAllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiGetAllUsersQuery, ApiGetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options,
  );
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAllUsersQuery,
    ApiGetAllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiGetAllUsersQuery, ApiGetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options,
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersLazyQuery
>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  ApiGetAllUsersQuery,
  ApiGetAllUsersQueryVariables
>;
export function refetchGetAllUsersQuery(
  variables?: ApiGetAllUsersQueryVariables,
) {
  return { query: GetAllUsersDocument, variables: variables };
}
export const GetDataAssessmentPerCompanyDocument = gql`
  query GetDataAssessmentPerCompany($company: String!) {
    dataAssessment(company: $company) {
      title
      description
      queryText
      data {
        columns
        rows
      }
    }
  }
`;

/**
 * __useGetDataAssessmentPerCompanyQuery__
 *
 * To run a query within a React component, call `useGetDataAssessmentPerCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataAssessmentPerCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataAssessmentPerCompanyQuery({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useGetDataAssessmentPerCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >(GetDataAssessmentPerCompanyDocument, options);
}
export function useGetDataAssessmentPerCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >(GetDataAssessmentPerCompanyDocument, options);
}
export type GetDataAssessmentPerCompanyQueryHookResult = ReturnType<
  typeof useGetDataAssessmentPerCompanyQuery
>;
export type GetDataAssessmentPerCompanyLazyQueryHookResult = ReturnType<
  typeof useGetDataAssessmentPerCompanyLazyQuery
>;
export type GetDataAssessmentPerCompanyQueryResult = Apollo.QueryResult<
  ApiGetDataAssessmentPerCompanyQuery,
  ApiGetDataAssessmentPerCompanyQueryVariables
>;
export function refetchGetDataAssessmentPerCompanyQuery(
  variables?: ApiGetDataAssessmentPerCompanyQueryVariables,
) {
  return { query: GetDataAssessmentPerCompanyDocument, variables: variables };
}
export const GetKlearlyUserDocument = gql`
  query GetKlearlyUser {
    me {
      id
      firstName
      lastName
      companyName
      email
      isAdmin
      isKlearlyAdmin
      disabled
      company {
        canSubmitCredentials
        crmTypes
        dataProcessedAt
      }
    }
  }
`;

/**
 * __useGetKlearlyUserQuery__
 *
 * To run a query within a React component, call `useGetKlearlyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKlearlyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKlearlyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKlearlyUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >(GetKlearlyUserDocument, options);
}
export function useGetKlearlyUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >(GetKlearlyUserDocument, options);
}
export type GetKlearlyUserQueryHookResult = ReturnType<
  typeof useGetKlearlyUserQuery
>;
export type GetKlearlyUserLazyQueryHookResult = ReturnType<
  typeof useGetKlearlyUserLazyQuery
>;
export type GetKlearlyUserQueryResult = Apollo.QueryResult<
  ApiGetKlearlyUserQuery,
  ApiGetKlearlyUserQueryVariables
>;
export function refetchGetKlearlyUserQuery(
  variables?: ApiGetKlearlyUserQueryVariables,
) {
  return { query: GetKlearlyUserDocument, variables: variables };
}
export const GetSignalHistoryDocument = gql`
  query GetSignalHistory($filterString: String, $timeRange: TimeRange!) {
    filterString @client @export(as: "filterString")
    signalHistory(filterString: $filterString, timeRange: $timeRange) {
      allAll {
        ...signalHistoryItems
      }
      allResponse {
        ...signalHistoryItems
      }
      allActivity {
        ...signalHistoryItems
      }
      marketingAll {
        ...signalHistoryItems
      }
      marketingResponse {
        ...signalHistoryItems
      }
      marketingActivity {
        ...signalHistoryItems
      }
      salesAll {
        ...signalHistoryItems
      }
      salesResponse {
        ...signalHistoryItems
      }
      salesActivity {
        ...signalHistoryItems
      }
      dateFormat
    }
  }
  ${SignalHistoryItemsFragmentDoc}
`;

/**
 * __useGetSignalHistoryQuery__
 *
 * To run a query within a React component, call `useGetSignalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignalHistoryQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useGetSignalHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >(GetSignalHistoryDocument, options);
}
export function useGetSignalHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >(GetSignalHistoryDocument, options);
}
export type GetSignalHistoryQueryHookResult = ReturnType<
  typeof useGetSignalHistoryQuery
>;
export type GetSignalHistoryLazyQueryHookResult = ReturnType<
  typeof useGetSignalHistoryLazyQuery
>;
export type GetSignalHistoryQueryResult = Apollo.QueryResult<
  ApiGetSignalHistoryQuery,
  ApiGetSignalHistoryQueryVariables
>;
export function refetchGetSignalHistoryQuery(
  variables?: ApiGetSignalHistoryQueryVariables,
) {
  return { query: GetSignalHistoryDocument, variables: variables };
}
export const GetRecommendedActionsDocument = gql`
  query GetRecommendedActions($filterString: String) {
    filterString @client @export(as: "filterString")
    recommendedActions(filterString: $filterString) {
      signalType
      action
      accountCount
      goalCount
      actualCount
      averageRank
    }
  }
`;

/**
 * __useGetRecommendedActionsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedActionsQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetRecommendedActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >(GetRecommendedActionsDocument, options);
}
export function useGetRecommendedActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >(GetRecommendedActionsDocument, options);
}
export type GetRecommendedActionsQueryHookResult = ReturnType<
  typeof useGetRecommendedActionsQuery
>;
export type GetRecommendedActionsLazyQueryHookResult = ReturnType<
  typeof useGetRecommendedActionsLazyQuery
>;
export type GetRecommendedActionsQueryResult = Apollo.QueryResult<
  ApiGetRecommendedActionsQuery,
  ApiGetRecommendedActionsQueryVariables
>;
export function refetchGetRecommendedActionsQuery(
  variables?: ApiGetRecommendedActionsQueryVariables,
) {
  return { query: GetRecommendedActionsDocument, variables: variables };
}
export const GetSignalHistoryForAccountDocument = gql`
  query GetSignalHistoryForAccount(
    $startDate: Date!
    $endDate: Date!
    $accountId: Int!
    $groupBy: [SignalGroupByType]!
  ) {
    signalHistoryForAccount(
      accountId: $accountId
      startDate: $startDate
      endDate: $endDate
      groupBy: $groupBy
    ) {
      allAll {
        ...signalHistory
      }
      allActivity {
        ...signalHistory
      }
      allResponse {
        ...signalHistory
      }
      marketingAll {
        ...signalHistory
      }
      marketingActivity {
        ...signalHistory
      }
      marketingResponse {
        ...signalHistory
      }
      salesAll {
        ...signalHistory
      }
      salesActivity {
        ...signalHistory
      }
      salesResponse {
        ...signalHistory
      }
    }
  }
  ${SignalHistoryFragmentDoc}
`;

/**
 * __useGetSignalHistoryForAccountQuery__
 *
 * To run a query within a React component, call `useGetSignalHistoryForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignalHistoryForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignalHistoryForAccountQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      accountId: // value for 'accountId'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useGetSignalHistoryForAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >(GetSignalHistoryForAccountDocument, options);
}
export function useGetSignalHistoryForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >(GetSignalHistoryForAccountDocument, options);
}
export type GetSignalHistoryForAccountQueryHookResult = ReturnType<
  typeof useGetSignalHistoryForAccountQuery
>;
export type GetSignalHistoryForAccountLazyQueryHookResult = ReturnType<
  typeof useGetSignalHistoryForAccountLazyQuery
>;
export type GetSignalHistoryForAccountQueryResult = Apollo.QueryResult<
  ApiGetSignalHistoryForAccountQuery,
  ApiGetSignalHistoryForAccountQueryVariables
>;
export function refetchGetSignalHistoryForAccountQuery(
  variables?: ApiGetSignalHistoryForAccountQueryVariables,
) {
  return { query: GetSignalHistoryForAccountDocument, variables: variables };
}
