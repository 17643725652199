import { Box, Flex, Text } from '@chakra-ui/react';
import { useSignalSourceButtons } from 'app/src/hooks/useSignalSourceButtons';
import { useSignalDirectionButtons } from 'app/src/hooks/useSignalDirectionButtons';

export const EngagementAnalysis = () => {
  const { signalSourceComponent } = useSignalSourceButtons(
    'sav-engagement-analysis',
  );
  const { signalDirectionComponent } = useSignalDirectionButtons(
    'sav-engagement-analysis',
  );
  return (
    <>
      <Text color={'brand.black'} fontWeight={'bold'} fontSize={'lg'}>
        ENGAGEMENT ANALYSIS
      </Text>
      <Flex justify={'space-between'}>
        <Box>{signalSourceComponent}</Box>
        <Box>{signalDirectionComponent}</Box>
      </Flex>
      <Text>BY SIGNAL TYPE</Text>
      <Text>BY INDIVIDUAL SIGNAL</Text>
    </>
  );
};
