import { Card } from '../../../../../shared/components/Core';
import SectionHeader from '../../../../../shared/components/Core/SectionHeader';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { GaugeByPredictionType } from './GaugeByPredictionType';
import { useContext, useEffect, useMemo } from 'react';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { getXeominVolumeStringAndWidths } from '../helpers/getXeominVolumeStringAndWidths';
import { getChartNarratives } from '../helpers/getChartNarratives';
import { getQtrXeominTotal } from '../helpers/getQtrXeominTotal';
import { mapDropdownTimeInputToDates } from '../../Account/helpers/singleAccountSignalHelpers';
import { SingleAccountSignalsTimePeriod } from '../../Account/types/SingleAccountSignals';
import {
  ApiPredictionForType,
  useGetAccountQuery,
  useGetAccountSignalsLazyQuery,
} from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  cleanStringCase,
  formatDateString,
} from 'shared/helpers/formatHelpers';

export const GaugeChart = () => {
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const { structure } = useContext(AppNavigationalStructureContext);
  const fiscalYearStartingMonth = structure?.fiscalYearStartingMonth ?? 0;
  const { data, loading } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });

  const { endDate } = mapDropdownTimeInputToDates(
    SingleAccountSignalsTimePeriod.last5Qtrs,
    fiscalYearStartingMonth,
  );

  const [getPrevQtrAccountSignals, { data: prevQtrAccountSignals }] =
    useGetAccountSignalsLazyQuery();
  const [getCurrentQtrAccountSignals, { data: currentQtrAccountSignals }] =
    useGetAccountSignalsLazyQuery();

  useEffect(() => {
    if (fiscalYearStartingMonth !== undefined) {
      const { startDate, endDate } = mapDropdownTimeInputToDates(
        SingleAccountSignalsTimePeriod.previousQtr,
        fiscalYearStartingMonth,
      );
      getPrevQtrAccountSignals({
        variables: {
          accountId: parseInt(selectedAccountId),
          startDate,
          endDate,
        },
      });
    }
  }, [fiscalYearStartingMonth, getPrevQtrAccountSignals, selectedAccountId]);

  useEffect(() => {
    if (fiscalYearStartingMonth !== undefined) {
      const { startDate, endDate } = mapDropdownTimeInputToDates(
        SingleAccountSignalsTimePeriod.currentQtr,
        fiscalYearStartingMonth,
      );
      getCurrentQtrAccountSignals({
        variables: {
          accountId: parseInt(selectedAccountId),
          startDate,
          endDate,
        },
      });
    }
  }, [
    fiscalYearStartingMonth,
    getPrevQtrAccountSignals,
    selectedAccountId,
    getCurrentQtrAccountSignals,
  ]);

  const prevQtrXeominTotal = useMemo(() => {
    return getQtrXeominTotal(prevQtrAccountSignals);
  }, [prevQtrAccountSignals]);

  const currentQtrXeominTotal = useMemo(() => {
    return getQtrXeominTotal(currentQtrAccountSignals);
  }, [currentQtrAccountSignals]);

  const accountInfo = data?.account;

  const narratives = getChartNarratives(
    accountInfo?.predictionCalculatedForType,
  );

  const { xeominVolumeString, prevVolumeWidth, currentVolumeWidth } =
    getXeominVolumeStringAndWidths({
      currentQtrXeominTotal,
      endDate,
      prevQtrXeominTotal,
    });

  return (
    <Card isLoading={loading}>
      <SectionHeader
        title={'Xeomin Predictions (Current Fiscal Quarter)'}
        addHeaderLeftMargin
        showBackgroundColor={false}
      />
      {accountInfo?.xeominPredictionSummary && (
        <Flex>
          <Text mb={4} ml={8} fontWeight={'normal'}>
            Summary:&nbsp;
          </Text>
          <Text fontWeight={'bold'}>
            {cleanStringCase(accountInfo.xeominPredictionSummary)}
          </Text>
        </Flex>
      )}
      {!accountInfo?.predictionCalculatedForType ||
      !accountInfo?.predictionLevel ||
      !accountInfo.expectedValue ? (
        <Text ml={6}>No predictions available for this account.</Text>
      ) : (
        <SimpleGrid columns={[4, null, null, null, 5]}>
          <Box gridColumn={'span 2'}>
            <GaugeByPredictionType
              predictionType={accountInfo.predictionCalculatedForType}
              predictionLevel={accountInfo.predictionLevel}
            />
          </Box>
          {accountInfo.predictionCalculatedForType ===
            ApiPredictionForType.Volume && (
            <Box gridColumn={'span 1'} ml={4}>
              <SectionHeader
                title={'Xeomin Purchase Qty'}
                titleProps={{ fontSize: 'md' }}
                showBackgroundColor={false}
                addHeaderLeftMargin
              />
              <Flex>
                <Box minW={24} mr={2} textAlign={'right'}>
                  <Text fontWeight={'bold'} mb={2}>
                    So Far
                  </Text>
                  <Text>Last Qtr</Text>
                </Box>
                <Box>
                  <Flex align={'center'} mb={2}>
                    <Box
                      h={6}
                      w={`${currentVolumeWidth}px`}
                      bg={'brand.gray-600'}
                      mr={2}
                    />
                    <Text fontWeight={'bold'}>
                      {currentQtrXeominTotal?.toLocaleString()}
                    </Text>
                  </Flex>
                  <Flex align={'center'}>
                    <Box
                      h={6}
                      w={`${prevVolumeWidth}px`}
                      bg={'brand.gray-400'}
                      mr={2}
                    />
                    <Text>{prevQtrXeominTotal?.toLocaleString()}</Text>
                  </Flex>
                </Box>
              </Flex>
              <Text mt={8} ml={8} maxW={64}>
                {xeominVolumeString}
              </Text>
            </Box>
          )}
          <Box
            mr={8}
            gridColumn={'span 2'}
            mb={8}
            ml={[8, null, null, null, 20]}
          >
            <SectionHeader
              title={'Why you are seeing this chart'}
              titleProps={{ fontSize: 'md' }}
              showBackgroundColor={false}
            />
            <ul
              style={{
                listStyleType: 'disc',
                paddingLeft: '20px',
                fontSize: '18px',
                display: 'inline-block',
                verticalAlign: 'top',
              }}
            >
              <Box gridColumn={'span 1'}>
                {narratives.map((narrative, i) => (
                  <li key={i}>
                    <Text fontSize={'md'}>{narrative}</Text>
                  </li>
                ))}
              </Box>
            </ul>
          </Box>
        </SimpleGrid>
      )}
      {accountInfo?.goalValues?.length &&
        accountInfo.goalValues[0]!.calculatedAt && (
          <Text ml={9} fontSize={'sm'}>
            Predictions were last updated on&nbsp;
            {formatDateString(
              accountInfo.goalValues[0]!.calculatedAt,
              'MMMM dd, yyyy',
            )}
            .
          </Text>
        )}
    </Card>
  );
};
