import {
  ApiAccountScoreBucket,
  ApiGetAccountCountByGroupingQuery,
} from 'shared/graphql/generatedApiTypes';
import { colorsByScoreBucket } from '../constants/sankeyColorMappings';
import { MappedSankeyData } from '../types/mappedSankeyData';
import _startCase from 'lodash/startCase';
import _lowerCase from 'lodash/lowerCase';

export const mapAccountCountToSankey = (
  accountCountData: ApiGetAccountCountByGroupingQuery | undefined,
) => {
  if (!accountCountData?.accountCountByGrouping) {
    return {
      links: [],
      nodes: [],
      colors: [],
      colorGradients: [],
    };
  }

  const nodes = buildNodes(accountCountData);
  const nodesByIndex = buildNodesByIndex(nodes);
  const links = buildLinks(accountCountData, nodesByIndex);
  const colorGradients = buildColorGradients(links, nodesByIndex);

  return {
    nodes: mapNodesToNames(nodes),
    links,
    colors: ['var(--color-gray-200)'],
    colorGradients,
  };
};

const buildNodes = (accountCountData: ApiGetAccountCountByGroupingQuery) => {
  const fromNodes: Array<{ name: string }> = [];
  const toNodes: Array<{ name: string }> = [];

  // only build in the nodes that are actually there
  Object.values(ApiAccountScoreBucket).forEach((value) => {
    if (
      accountCountData.accountCountByGrouping?.find(
        (acct) => acct?.currentAccountScoreBucket === value,
      )
    ) {
      fromNodes.push({ name: `From ${value}` });
    }
    if (
      accountCountData.accountCountByGrouping?.find(
        (acct) => acct?.possibleAccountScoreBucket === value,
      )
    ) {
      toNodes.push({ name: `To ${value}` });
    }
  });

  // sort them in the desired orders
  const fromOrderToSortBy = [
    'From VERY_LIKELY',
    'From LIKELY',
    'From TOSSUP',
    'From UNLIKELY',
    'From VERY_UNLIKELY',
  ];
  const toOrderToSortBy = [
    'To VERY_LIKELY',
    'To LIKELY',
    'To TOSSUP',
    'To UNLIKELY',
    'To VERY_UNLIKELY',
  ];

  const sortedFromNodes = fromNodes.sort(
    (a, b) =>
      fromOrderToSortBy.indexOf(a.name) - fromOrderToSortBy.indexOf(b.name),
  );
  const sortedToNodes = toNodes.sort(
    (a, b) => toOrderToSortBy.indexOf(a.name) - toOrderToSortBy.indexOf(b.name),
  );

  // get all the unique signal types by using a set
  const signalTypeNodes = Array.from(
    new Set(
      accountCountData?.accountCountByGrouping?.map(
        (count) => count?.signalType,
      ),
    ),
  ).map((signalType) => ({ name: signalType ?? '' }));

  return [...signalTypeNodes, ...sortedFromNodes, ...sortedToNodes];
};

const buildNodesByIndex = (nodes: MappedSankeyData['nodes']) => {
  // build a map to look up the indexes by key
  const nodesByIndex: { [key: string]: number } = {};
  nodes.forEach((node, index) => {
    nodesByIndex[node.name] = index;
  });
  return nodesByIndex;
};

const buildLinks = (
  accountCountData: ApiGetAccountCountByGroupingQuery,
  nodesByIndex: { [key: string]: number },
) => {
  const links: MappedSankeyData['links'] = [];
  accountCountData.accountCountByGrouping?.forEach((grouping) => {
    if (grouping) {
      links.push({
        source: nodesByIndex[`From ${grouping.currentAccountScoreBucket!}`],
        target: nodesByIndex[grouping.signalType!],
        value: grouping.accountCount ?? 0,
      });

      links.push({
        source: nodesByIndex[grouping.signalType!],
        target: nodesByIndex[`To ${grouping.possibleAccountScoreBucket}`!],
        value: grouping.accountCount ?? 0,
      });
    }
  });
  return links;
};

const buildColorGradients = (
  links: MappedSankeyData['links'],
  nodesByIndex: { [key: string]: number },
) => {
  return links.map((link) => {
    // default to black in case another option is added unexpectedly
    let selectedColor = '#000';
    if (
      nodesByIndex[`From ${ApiAccountScoreBucket.VeryUnlikely}`] ===
        link.source ||
      nodesByIndex[`To ${ApiAccountScoreBucket.VeryUnlikely}`] === link.target
    ) {
      selectedColor = colorsByScoreBucket[ApiAccountScoreBucket.VeryUnlikely];
    }
    if (
      nodesByIndex[`From ${ApiAccountScoreBucket.Unlikely}`] === link.source ||
      nodesByIndex[`To ${ApiAccountScoreBucket.Unlikely}`] === link.target
    ) {
      selectedColor = colorsByScoreBucket[ApiAccountScoreBucket.Unlikely];
    }
    if (
      nodesByIndex[`From ${ApiAccountScoreBucket.Tossup}`] === link.source ||
      nodesByIndex[`To ${ApiAccountScoreBucket.Tossup}`] === link.target
    ) {
      selectedColor = colorsByScoreBucket[ApiAccountScoreBucket.Tossup];
    }
    if (
      nodesByIndex[`From ${ApiAccountScoreBucket.Likely}`] === link.source ||
      nodesByIndex[`To ${ApiAccountScoreBucket.Likely}`] === link.target
    ) {
      selectedColor = colorsByScoreBucket[ApiAccountScoreBucket.Likely];
    }
    if (
      nodesByIndex[`From ${ApiAccountScoreBucket.VeryLikely}`] ===
        link.source ||
      nodesByIndex[`To ${ApiAccountScoreBucket.VeryLikely}`] === link.target
    ) {
      selectedColor = colorsByScoreBucket[ApiAccountScoreBucket.VeryLikely];
    }
    return {
      source: selectedColor,
      target: selectedColor,
    };
  });
};

const mapNodesToNames = (nodes: MappedSankeyData['nodes']) =>
  nodes.map((node) => ({ name: _startCase(_lowerCase(node.name)) }));
