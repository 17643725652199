import { Badge, Text } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { ApiAccountCustomerStatus } from 'shared/graphql/generatedApiTypes';
import { IconRenderer, IconRendererIcons } from 'shared/renderers/IconRenderer';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

export const CustomerBadge = ({
  customerStatus,
}: {
  customerStatus: ApiAccountCustomerStatus;
}) => {
  return (
    <Badge
      alignItems={'center'}
      bg={'brand.white'}
      borderRadius={6}
      color={'brand.black'}
      display={'flex'}
      justifyContent={'center'}
      maxH={6}
      ml={4}
      px={2}
      textTransform={'capitalize'}
      variant={'standard'}
    >
      {IconRenderer({
        iconName: IconRendererIcons.STATUS_CIRCLE,
        value: customerStatus,
      })}
      <Text ml={1}>{capitalize(cleanStringCase(customerStatus))}</Text>
    </Badge>
  );
};
