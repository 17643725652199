import { Box, Flex, Text } from '@chakra-ui/react';
import { useSignalSourceButtons } from 'app/src/hooks/useSignalSourceButtons';
import { useSignalDirectionButtons } from 'app/src/hooks/useSignalDirectionButtons';
import { useRef } from 'react';
import useComponentSize from '@rehooks/component-size';
import {
  RechartsBase,
  RechartsTooltip,
} from '../../../../../../../shared/components/Core';
import {
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { EngagementLineChartYLabel } from '../../../../Home/components/EngagementLineChartYLabel';
import { formatDateString } from 'shared/helpers/formatHelpers';

const initialData = [
  { date: '2021-04-01', lastInteractions: 411, interactions: 345 },
  { date: '2021-04-08', lastInteractions: 239, interactions: 64 },
  { date: '2021-04-15', lastInteractions: 137, interactions: 245 },
  { date: '2021-04-22', lastInteractions: 116, interactions: 119 },
  { date: '2021-04-29', lastInteractions: 229, interactions: 98 },
  { date: '2021-05-06', lastInteractions: 34, interactions: 43 },
  { date: '2021-05-13', lastInteractions: 53, interactions: 352 },
  { date: '2021-05-20', lastInteractions: 252, interactions: null },
  { date: '2021-05-27', lastInteractions: 179, interactions: null },
  { date: '2021-06-03', lastInteractions: 294, interactions: null },
  { date: '2021-06-10', lastInteractions: 43, interactions: null },
  { date: '2021-06-17', lastInteractions: 441, interactions: null },
  { date: '2021-06-24', lastInteractions: 21, interactions: null },
  { date: '2021-07-01', lastInteractions: 83, interactions: null },
  { date: '2021-07-08', lastInteractions: 67, interactions: null },
  { date: '2021-07-15', lastInteractions: 98, interactions: null },
  { date: '2021-07-22', lastInteractions: 37, interactions: null },
  { date: '2021-07-29', lastInteractions: 265, interactions: null },
  { date: '2021-08-05', lastInteractions: 312, interactions: null },
  { date: '2021-08-12', lastInteractions: 79, interactions: null },
];

export const EngagementSummary = () => {
  const { signalSourceComponent } = useSignalSourceButtons(
    'sav-engagement-summary',
  );
  const { signalDirectionComponent } = useSignalDirectionButtons(
    'sav-engagement-summary',
  );

  const chartWrapperRef = useRef(null);
  const { width } = useComponentSize(chartWrapperRef);

  const renderTooltipContent = (dataPayload) => {
    const { lastInteractions = '', interactions = '', date = '' } = dataPayload;
    const formattedDateRange = `${formatDateString(date, 'MM/dd/yy')}`;
    return (
      <>
        <p
          style={{ color: 'var(--color-black)' }}
        >{`Date: ${formattedDateRange}`}</p>
        {!!interactions && (
          <p
            style={{ color: 'var(--color-blue)' }}
          >{`Interactions: ${interactions}`}</p>
        )}
        <p
          style={{ color: 'var(--color-klearly-blue)' }}
        >{`Comparison Period Interactions: ${lastInteractions}`}</p>
      </>
    );
  };

  return (
    <>
      <Text color={'brand.black'} fontWeight={'bold'} fontSize={'lg'}>
        ENGAGEMENT SUMMARY
      </Text>
      <Flex justify={'space-between'}>
        <Box>{signalSourceComponent}</Box>
        <Box>{signalDirectionComponent}</Box>
      </Flex>
      <Box ref={chartWrapperRef}>
        <RechartsBase height={400} width={width}>
          <ComposedChart
            data={initialData}
            margin={{ top: 20, right: 20, bottom: 5, left: 0 }}
          >
            <Legend verticalAlign='top' height={30} iconType={'circle'} />
            <Line
              name={'This Period'}
              dataKey='interactions'
              stroke={'var(--color-blue)'}
              dot={{ stroke: 'var(--color-blue)', strokeWidth: 5 }}
              strokeWidth={3}
            />
            <Line
              name={'Comparison Period'}
              dataKey='lastInteractions'
              stroke={'var(--color-klearly-blue)'}
              dot={{ stroke: 'var(--color-klearly-blue)', strokeWidth: 5 }}
              strokeWidth={3}
            />
            <XAxis
              dataKey={'date'}
              tickFormatter={(val) => val.toLocaleString()}
              tickMargin={5}
              type={'category'}
            />
            <YAxis
              orientation={'left'}
              domain={[0, 'dataMax+25']}
              label={({ viewBox }) => (
                <EngagementLineChartYLabel {...viewBox} text={'Interactions'} />
              )}
              scale={'linear'}
              tickFormatter={(val) => val.toLocaleString()}
              tickMargin={5}
            />
            <Tooltip
              content={
                <RechartsTooltip
                  lightBackground
                  renderContent={renderTooltipContent}
                />
              }
            />
            <ReferenceLine
              x={'2021-05-13'}
              label={{
                fill: 'var(--color-red)',
                position: 'top',
                value: 'TODAY',
              }}
              stroke={'var(--color-red)'}
              strokeDasharray={'4'}
              strokeWidth={2}
            />
          </ComposedChart>
        </RechartsBase>
      </Box>
    </>
  );
};
