export enum FilterAppDefinitions {
  resetFiltersText = 'resetFiltersButtonText',
  segmentSaveAccordionLabel = 'segmentSaveAccordionLabel',
  segmentSaveDescriptionLabel = 'segmentSaveDescriptionLabel',
  segmentSaveDescriptionPlaceholder = 'segmentSaveDescriptionPlaceholder',
  segmentSaveDuplicateError = 'segmentSaveDuplicateError',
  segmentSaveNameLabel = 'segmentSaveNameLabel',
  segmentSaveNamePlaceholder = 'segmentSaveNamePlaceholder',
  topBarSummaryLoading = 'topBarSummaryLoading',
  topBarSummaryLabel = 'topBarSummaryLabel',
}
