import {FilterPanelTabValues} from '../../constants/filterPanelTabOptions';
import {BuildASegmentTab} from './buildSegmentTab/BuildASegmentTab';
import {RecommendedSegmentsTab} from './segmentsOrListsTab/RecommendedSegmentsTab';
import {TeamListsTab} from './segmentsOrListsTab/TeamListsTab';
import {TeamSegmentsTab} from './segmentsOrListsTab/TeamSegmentsTab';
import {SegmentBuilderSection} from 'shared/types/companyAppStructureTypes';

type TabSwitchProps = {
  segmentBuilderSections: SegmentBuilderSection[];
  selectedPanelTab: FilterPanelTabValues;
  showingSaveSelection: boolean;
};

export const TabSwitch = ({
  segmentBuilderSections,
  selectedPanelTab,
  showingSaveSelection,
}: TabSwitchProps) => {
  switch (selectedPanelTab) {
    case FilterPanelTabValues.BUILD:
      return (
        <BuildASegmentTab
          segmentBuilderSections={segmentBuilderSections}
          showingSaveSelection={showingSaveSelection}
        />
      );
    case FilterPanelTabValues.RECOMMENDED:
      return <RecommendedSegmentsTab />;
    case FilterPanelTabValues.TEAM_LISTS:
      return <TeamListsTab />;
    case FilterPanelTabValues.TEAM_SEGMENTS:
      return <TeamSegmentsTab />;
  }
};
