import {createIcon} from '@chakra-ui/icon';

export const StatusCircleGradient = createIcon({
  displayName: 'StatusCircleGradient',
  viewBox: '0 0 12 12',
  path: (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='6'
        cy='6'
        r='4.5'
        fill='transparent'
        stroke='url(#paint0_linear_694:3168)'
        strokeWidth='3'
      />
      <defs>
        <linearGradient
          id='paint0_linear_694:3168'
          x1='8.27701e-08'
          y1='6.00009'
          x2='12'
          y2='6.00009'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FE282D' />
          <stop offset='1' stopColor='#7E2A98' />
        </linearGradient>
      </defs>
    </svg>
  ),
});
