import { Flex, Stack, Text } from '@chakra-ui/react';
import { Container } from '../../../../../../../shared/components/Core';
import { SAV_ACCOUNT_SCORE_LINE_CHART } from 'app/src/constants/routes';
import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { WinfluenceScore } from '../shared/WinfluenceScore';
import { getWinfluencePriorityDetails } from '../../../helpers/getWinfluencePriorityDetails';
import SectionHeader from '../../../../../../../shared/components/Core/SectionHeader';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

type WinfluenceProps = {
  accountInfo: ApiGetAccountQuery['account'];
};

const Winfluence = ({ accountInfo }: WinfluenceProps) => {
  const { text: winfluenceText, tooltip: winfluenceTooltip } =
    getAppDefinitionsItem(
      SAV_ACCOUNT_SCORE_LINE_CHART.name,
      'lift',
    ) as TextDefinitionType;

  const { title, nonPriorityLift, priorityLift, subText } =
    getWinfluencePriorityDetails({ accountInfo });

  return (
    <Container ph={'xl'} pv={'lg'} width={'full'}>
      <Stack spacing={0}>
        <SectionHeader
          title={winfluenceText}
          tooltip={winfluenceTooltip}
          showBackgroundColor={false}
        />
        <Flex>
          <Text
            as={'h1'}
            color={'brand.black'}
            fontSize={'5xl'}
            fontWeight={'bold'}
            mr={4}
          >
            {title}
          </Text>
          <WinfluenceScore
            nonPriorityScore={nonPriorityLift}
            winfluenceScore={priorityLift}
          />
        </Flex>
        <Text>{subText}</Text>
      </Stack>
    </Container>
  );
};

export { Winfluence };
