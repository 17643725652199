import { Inline, Txt } from '../../../../../shared/components/Core';
import _size from 'lodash/size';
import { LabCardLikeFavoriteIcons, LabCardShareIcons } from './LabCardFeedback';
import { RefObject } from 'react';
import { styleNumber } from 'app/src/components/Lab/helpers/styleNumber';

type LabCardPageHeaderProps = {
  componentRef: RefObject<HTMLElement>;
  context: string | undefined;
  heroNumber?: number;
  id: string;
  question: string;
  totalNumberType: 'number' | 'percent' | 'currency';
};

export const LabCardPageHeader = ({
  componentRef,
  context = undefined,
  heroNumber = 0,
  id = '',
  question = '',
  totalNumberType = 'number',
}: LabCardPageHeaderProps) => (
  <Inline
    alignItems={'center'}
    className={'h-border-bottom'}
    gap={'md'}
    justifyContent={'between'}
    ph={'lg'}
    pv={'lg'}
    width={'full'}
  >
    <div>
      <Txt className={'h-color-black'} size={'lg'} weight={'bold'}>
        {question}
      </Txt>
      <div className={'h-mt-md'}>
        {heroNumber && (
          <Txt className={'h-color-black'} size={'3xl'} weight={'regular'}>
            {styleNumber(
              heroNumber,
              totalNumberType === 'currency',
              totalNumberType === 'percent',
            )}
          </Txt>
        )}
      </div>
      <div className={'h-mt-2xs'}>
        {_size(context) > 0 && <Txt className={'h-color-black'}>{context}</Txt>}
      </div>
    </div>
    <LabCardLikeFavoriteIcons
      id={id}
      middleComponent={
        <LabCardShareIcons
          componentRef={componentRef}
          customContentIdPrefix={'lab-page-'}
          id={id}
          question={question}
        />
      }
    />
  </Inline>
);
