import {ColorListGauge, OpacityScaleGauge} from '../../../../../shared/components/Core/Gauge';
import {getGaugeProps} from '../helpers/getGaugeProps';
import {ApiPredictionForType} from 'shared/graphql/generatedApiTypes';

export const GaugeByPredictionType = ({
  predictionLevel,
  predictionType,
}: {
  predictionLevel: number;
  predictionType: ApiPredictionForType;
}) => {
  const gaugeProps = getGaugeProps({ predictionType, predictionLevel });

  return gaugeProps?.colorList ? (
    <ColorListGauge
      topLabel={gaugeProps?.topLabel ?? ''}
      leftLabel={gaugeProps?.leftLabel ?? ''}
      rightLabel={gaugeProps?.rightLabel ?? ''}
      chartLabel={gaugeProps?.chartLabel ?? ''}
      selectedSectionLabel={gaugeProps?.selectedSectionLabel ?? ''}
      colorList={gaugeProps.colorList}
      selectedSectionIndex={gaugeProps.selectedSectionIndex ?? 0}
    />
  ) : (
    <OpacityScaleGauge
      topLabel={gaugeProps?.topLabel ?? ''}
      leftLabel={gaugeProps?.leftLabel ?? ''}
      rightLabel={gaugeProps?.rightLabel ?? ''}
      chartLabel={gaugeProps?.chartLabel ?? ''}
      selectedSectionLabel={gaugeProps?.selectedSectionLabel ?? ''}
      sectionColor={gaugeProps?.sectionColor ?? 'rgb(41, 151, 252)'}
      numberOfSections={gaugeProps?.numberOfSections ?? 7}
      selectedSectionIndex={gaugeProps?.selectedSectionIndex ?? 0}
    />
  );
};
