import _size from 'lodash/size';
import GroupTable from './GroupTable';
import {Card, Container, Txt} from '../../../../../../../shared/components/Core';
import {ApiGetAllAccountGroupsQuery} from 'shared/graphql/generatedApiTypes';

type SegmentsOrListsProps = {
  data: NonNullable<ApiGetAllAccountGroupsQuery['accountGroups']>;
  emptyListString: string;
  index?: string;
  loading: boolean;
  segmentOrListType: 'segment' | 'list';
  subtext?: string;
  title: string;
};

export const SegmentsOrLists = ({
  data = [],
  emptyListString = '',
  index = undefined,
  loading = false,
  segmentOrListType,
  subtext = '',
  title = '',
}: SegmentsOrListsProps) => (
  <Container width={'full'}>
    <Card isLoading={loading}>
      <div className={'h-border-bottom h-color-bold h-p-lg'}>
        <Txt as={'h1'} className={'h-color-bold'} size={'lg'} weight={'bold'}>
          {title}
        </Txt>
        {subtext && _size(subtext) > 0 && (
          <Txt className={'h-pt-sm'} theme={'muted'}>
            {subtext}
          </Txt>
        )}
      </div>
      {_size(data) === 0 ? (
        <Container ph={'lg'} pv={'lg'}>
          <Txt size={'lg'} weight={'heavy'}>
            {emptyListString}
          </Txt>
        </Container>
      ) : (
        <GroupTable
          data={data ?? []}
          index={index}
          segmentOrListType={segmentOrListType}
        />
      )}
    </Card>
  </Container>
);
