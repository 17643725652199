import { useMemo } from 'react';
import { ApiSignal } from 'shared/graphql/generatedApiTypes';
import { Table } from 'shared/components/Core';
import { Box, Flex, Text } from '@chakra-ui/react';
import { SignalSectionUnitType } from '../../../types/SingleAccountSignals';
import {
  SINGLE_ACCOUNT_SIGNAL_INBOUND_COLOR,
  SINGLE_ACCOUNT_SIGNAL_OUTBOUND_COLOR,
} from '../../../constants/singleAccountSignalColors';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

type SingleAccountSignalSummaryTableProps = {
  currentUnitType: SignalSectionUnitType;
  tableData: ApiSignal[];
};

export const SingleAccountSignalSummaryTable = ({
  currentUnitType,
  tableData,
}: SingleAccountSignalSummaryTableProps) => {
  const mappedData = useMemo(() => {
    return tableData.flat().reduce((accum, item) => {
      // remove the excess data for inbound, outbound, and occurredAt
      const reducedItem = Object.keys(item).reduce((reducedAccum, key) => {
        if (typeof item[key] === 'object') {
          reducedAccum.push(item[key]);
        }
        return reducedAccum;
      }, [] as Array<ApiSignal>);
      reducedItem.forEach((singleItem) => {
        const existingSignalTypeItem = accum.find(
          (row) =>
            row.type === singleItem.type &&
            row.typeDetail === singleItem.typeDetail,
        );
        if (!existingSignalTypeItem && singleItem.type && singleItem.quantity) {
          accum.push({
            type: singleItem.type,
            typeDetail: singleItem.typeDetail ?? null,
            quantity: singleItem.quantity,
            direction: singleItem.direction,
          });
        } else if (existingSignalTypeItem && existingSignalTypeItem.quantity) {
          existingSignalTypeItem.quantity += singleItem.quantity ?? 0;
        }
      });
      return accum;
    }, [] as Array<Partial<ApiSignal>>);
  }, [tableData]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Activity Totals',
        columns: [
          {
            Header: 'Type',
            Cell: ({ row, value }) => {
              return (
                <Flex align={'center'}>
                  {row.original.direction === 'response' ? (
                    <Box
                      bg={SINGLE_ACCOUNT_SIGNAL_INBOUND_COLOR}
                      borderRadius={6}
                      w={3}
                      h={3}
                      mr={1}
                    />
                  ) : (
                    <Box
                      bg={SINGLE_ACCOUNT_SIGNAL_OUTBOUND_COLOR}
                      borderRadius={6}
                      w={3}
                      h={3}
                      mr={1}
                    />
                  )}
                  {cleanStringCase(value)}
                </Flex>
              );
            },
            accessor: 'type',
          },
          {
            Header: 'Signal Detail',
            Cell: ({ value }) => cleanStringCase(value),
            accessor: 'typeDetail',
          },
          {
            Header:
              currentUnitType === SignalSectionUnitType.quantity
                ? 'Total Qty'
                : 'Total Count',
            accessor: 'quantity',
            Cell: ({ value }) => value.toLocaleString(),
            isNumeric: true,
          },
        ],
      },
    ];
  }, [currentUnitType]);

  const unitTypeString =
    currentUnitType === SignalSectionUnitType.quantity ? 'Qty' : 'Count';

  const total = mappedData.reduce((accum, item) => {
    return accum + (item?.quantity ?? 0);
  }, 0);
  const inbound = mappedData.reduce((accum, item) => {
    if (item.direction === 'response') {
      return accum + (item?.quantity ?? 0);
    }
    return accum;
  }, 0);
  const outbound = mappedData.reduce((accum, item) => {
    if (item.direction === 'activity') {
      return accum + (item?.quantity ?? 0);
    }
    return accum;
  }, 0);

  return (
    <>
      <Flex
        flex='1'
        textAlign='left'
        justify={'space-between'}
        fontWeight={'bold'}
        px={8}
      >
        <Text>{`Total ${unitTypeString}: ${total.toLocaleString()}`}</Text>
        <Text>{`Inbound ${unitTypeString}: ${inbound.toLocaleString()}`}</Text>
        <Text>{`Outbound ${unitTypeString}: ${outbound.toLocaleString()}`}</Text>
      </Flex>
      <Table columns={columns} data={mappedData} perPage={20} />
    </>
  );
};
