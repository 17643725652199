import { SAV_RECOMMENDATIONS_LIST } from 'app/src/constants/routes';
import { PrimaryActionSection } from './PrimaryActionSection';
import { recommendationsTableAccessors } from '../../../constants/recommendationsTableAccessors';
import { RecommendationsProps } from './Recommendations';
import { MaxImpact } from './MaxImpactSection';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

export const RecommendationsBody = ({
  accountInfo,
  sectionVisibilityToggles,
}: RecommendationsProps) => {
  // setup static text
  const pageId = SAV_RECOMMENDATIONS_LIST.name;
  const { text: expectedRecommendedText, tooltip: expectedRecommendedTooltip } =
    getAppDefinitionsItem(
      pageId,
      recommendationsTableAccessors.expectedRecommended,
    ) as TextDefinitionType;
  const { text: recommendationText, tooltip: recommendationTooltip } =
    getAppDefinitionsItem(
      pageId,
      recommendationsTableAccessors.recommendation,
    ) as TextDefinitionType;
  const { text: signalTypeText, tooltip: signalTypeTooltip } =
    getAppDefinitionsItem(
      pageId,
      recommendationsTableAccessors.signalType,
    ) as TextDefinitionType;
  const tableHeaders = {
    expectedRecommendedText,
    expectedRecommendedTooltip,
    recommendationText,
    recommendationTooltip,
    signalTypeText,
    signalTypeTooltip,
  };

  //get section visibility toggles
  const {
    setShowingAllActions,
    showingAllActions,
    setShowingPrimaryActions,
    showingPrimaryActions,
  } = sectionVisibilityToggles;

  return (
    <>
      <PrimaryActionSection
        accountInfo={accountInfo}
        tableHeader={tableHeaders}
        sectionIsShowing={showingPrimaryActions}
        setSectionIsShowing={setShowingPrimaryActions}
      />
      <MaxImpact
        accountInfo={accountInfo}
        tableHeader={tableHeaders}
        sectionIsShowing={showingAllActions}
        setSectionIsShowing={setShowingAllActions}
      />
    </>
  );
};
