import { AccountInfoProps } from '../types/AccountInfoProps';
import { SAV_WINFLUENCE } from 'app/src/constants/routes';
import _clamp from 'lodash/clamp';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

export const getWinfluencePriorityDetails = ({
  accountInfo,
}: AccountInfoProps) => {
  if (!accountInfo) {
    return {
      title: '',
      subText: '',
      priorityLift: 0,
      nonPriorityLift: 0,
    };
  }

  const priorityLift = accountInfo?.rankPriority || 0;
  const nonPriorityLift = accountInfo?.rankAll || 0;
  const nonPriorityValueDifference = Math.max(
    0,
    nonPriorityLift - priorityLift,
  );
  const title =
    (
      getAppDefinitionsItem(
        SAV_WINFLUENCE.name,
        `title_${_clamp(priorityLift + nonPriorityValueDifference, 5)}`,
      ) as TextDefinitionType
    )?.text || '';
  const subText =
    (
      getAppDefinitionsItem(
        SAV_WINFLUENCE.name,
        `subtext_${priorityLift}_${nonPriorityValueDifference}`,
      ) as TextDefinitionType
    )?.subtext || '';

  return {
    title,
    subText,
    priorityLift,
    nonPriorityLift,
  };
};
