import {useInput, UseInputHookReturnType, UseInputHookStringValueTypes,} from 'shared/hooks/inputHook';
import {calendarFilterFormatOptions} from '../../../constants/calendarFilterItemOptions';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInYears from 'date-fns/differenceInYears';
import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';
import isDateValid from 'date-fns/isValid';
import {useCallback, useEffect} from 'react';
import {usePrevious} from 'app/src/hooks/previousHook';
import _isNull from 'lodash/isNull';
import {Input, Select} from '../../../../../../../shared/components/Core';
import {CalendarDateType, CalendarRangeFilterItemProps,} from '../../../types/calendarTypes';
import {OnChangeSelectChangeEventType} from '../../../../../../../shared/components/Core/Select';
import {OptionsType} from 'shared/types/coreTypes.d';

export const CalendarRangePreviousFilterItem = ({
  endDate,
  setEndDate,
  setStartDate,
  startDate,
}: CalendarRangeFilterItemProps) => {
  const DEFAULT_FORMAT_VALUE = 'days';
  const {
    bind: bindFormat,
    setValue: setFormatValue,
    value: format,
  } = useInput(DEFAULT_FORMAT_VALUE, {
    defaultValue: DEFAULT_FORMAT_VALUE,
    id: 'format',
    name: 'format',
    options: calendarFilterFormatOptions,
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindInput,
    reset: resetInputValue,
    setValue: setInputValue,
    value: input,
  } = useInput(0, {
    defaultValue: 0,
    id: 'number-input',
    name: 'number-input',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  const cleanValues = useCallback(
    (startDate: CalendarDateType, endDate: CalendarDateType) => {
      const inputValue =
        startDate && endDate
          ? (format as string) === 'days'
            ? differenceInDays(endDate, startDate)
            : (format as string) === 'weeks'
            ? differenceInWeeks(endDate, startDate)
            : (format as string) === 'months'
            ? differenceInMonths(endDate, startDate)
            : differenceInYears(endDate, startDate)
          : 0;
      setFormatValue(DEFAULT_FORMAT_VALUE);
      setInputValue(inputValue);
    },
    [format, setFormatValue, setInputValue],
  );

  const onChangeValues = (newFormat: string, newInput: number) => {
    const newEndDate = new Date();
    const newStartDate =
      newFormat === 'days'
        ? subDays(newEndDate, newInput)
        : newFormat === 'weeks'
        ? subWeeks(newEndDate, newInput)
        : newFormat === 'months'
        ? subMonths(newEndDate, newInput)
        : subYears(newEndDate, newInput);
    if (isDateValid(newStartDate) && isDateValid(newEndDate)) {
      setEndDate(newEndDate);
      setStartDate(newStartDate);
      setInputValue(newInput);
      setFormatValue(newFormat);
    }
  };

  useEffect(() => {
    cleanValues(startDate, endDate);
  }, [startDate, endDate, cleanValues]);

  const previousStartDate = usePrevious(startDate);
  const previousEndDate = usePrevious(endDate);
  useEffect(() => {
    if (
      previousEndDate &&
      _isNull(endDate) &&
      previousStartDate &&
      _isNull(startDate)
    ) {
      resetInputValue();
    }
  }, [endDate, previousEndDate, previousStartDate, resetInputValue, startDate]);

  return (
    <div className={'filter-bar-wrapper'}>
      <Input
        {...bindInput}
        className={'h-mt-2xs h-pb-none h-width-full'}
        onChange={(ev) => onChangeValues(format, parseInt(ev.target.value))}
        type={'number'}
      />
      <Select
        {...bindFormat}
        analytics-attr={'between-or-previous-calendar-range'}
        className={'h-pb-none h-width-full'}
        displayPlaceholder={false}
        onChange={(ev) =>
          onChangeValues(
            (ev as OnChangeSelectChangeEventType).target.value,
            parseInt(input),
          )
        }
        options={bindFormat.options as OptionsType[]}
      />
    </div>
  );
};
