import {Text} from '@chakra-ui/react';
import {
    ApiAccountCustomerStatus,
    ApiPageInfo,
    ApiSort,
    GetAccountsListDocument,
} from 'shared/graphql/generatedApiTypes';
import SectionHeader from '../../../../../shared/components/Core/SectionHeader';
import {Card} from '../../../../../shared/components/Core';
import {TableComponentProps, TableLegend as TableLegendValueType} from 'shared/types/companyAppStructureTypes';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {mapColumnNamesToSorts} from '../helpers/mapColumnNamesToSorts';
import {TableLegend} from './TableLegend';
import {AbstractedTable} from './AbstractedTable';
import {IconRendererIcons} from 'shared/renderers/IconRenderer';

type TableLegendType = { leftIcon: IconRendererIcons; value: string };

export const getTableLegendValues = (
  legendValue: TableLegendValueType,
): TableLegendType[] | undefined => {
  switch (legendValue) {
    case TableLegendValueType.customerStatus:
      return [
        {
          leftIcon: IconRendererIcons.STATUS_CIRCLE,
          value: ApiAccountCustomerStatus.Customer,
        },
        {
          leftIcon: IconRendererIcons.STATUS_CIRCLE,
          value: ApiAccountCustomerStatus.PastCustomer,
        },
        {
          leftIcon: IconRendererIcons.STATUS_CIRCLE,
          value: ApiAccountCustomerStatus.Prospect,
        },
      ];
    case TableLegendValueType.none:
      return undefined;
    default:
      return undefined;
  }
};

export const AbstractedTableSection = ({
  analyticsAttr,
  columns,
  // dataSource,
  defaultSort,
  // tablePerPage,
  legendType,
  tableSubTitle,
  tableTitle,
  tableTooltip,
}: TableComponentProps) => {
  //  We need to keep the table from resetting the pageIndex when we update data; keep track of that with a ref
  const skipResetRef = useRef(false);
  const apolloClient = useApolloClient();
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any[] | null>(null);
  const [pageInfo, setPageInfo] = useState<ApiPageInfo | null>(null);

  const fetchData = useCallback(
    async ({ pageIndex, pageSize, sortBy }) => {
      skipResetRef.current = true;
      setError(null);
      try {
        const sort = sortBy.map((selectedSort) => ({
          field: mapColumnNamesToSorts(selectedSort.id),
          direction: selectedSort.desc ? ApiSort.Desc : ApiSort.Asc,
        }));
        const { data: queryData } = await apolloClient.query({
          query: GetAccountsListDocument,
          variables: {
            page: pageIndex,
            limit: pageSize,
            sort,
          },
        });
        if (queryData) {
          setData(queryData?.accounts?.accounts ?? null);
          setPageInfo(queryData?.accounts?.pageInfo ?? null);
        }
      } catch (err) {
        setError('Error loading data; please refresh & try again.');
      }
    },
    // Needs global filter dependency b/c that's one in the apollo query
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloClient],
  );

  useEffect(() => {
    skipResetRef.current = false;
  }, [data]);

  const legendContent = getTableLegendValues(legendType);

  return (
    <Card isLoading={false}>
      <SectionHeader
        addHeaderLeftMargin
        showBackgroundColor={false}
        title={tableTitle}
        subtitle={tableSubTitle}
        tooltip={tableTooltip}
      />
      {!!error && (
        <Text color={'brand.crimson'} size={'lg'} m={4}>
          {error}
        </Text>
      )}
      <AbstractedTable
        analyticsAttr={analyticsAttr}
        columns={columns}
        data={data}
        defaultSort={defaultSort}
        error={error}
        fetchData={fetchData}
        legend={
          legendContent ? <TableLegend legendContent={legendContent} /> : null
        }
        pageInfo={pageInfo ?? { totalPages: 0, totalEntries: 0 }}
      />
    </Card>
  );
};
