import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { signalDirectionOptions } from 'app/src/hooks/useSignalDirectionButtons';
import { useParams } from 'react-router-dom';
import { useGetAccountSignalsLazyQuery } from 'shared/graphql/generatedApiTypes';
import {
  Granularity,
  SignalSectionUnitType,
  SingleAccountSignalsTimePeriod,
} from '../../../types/SingleAccountSignals';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { mapDropdownTimeInputToDates } from '../../../helpers/singleAccountSignalHelpers';
import { ComponentDataType } from 'shared/types/companyAppStructureTypes';
import SectionHeader from 'shared/components/Core/SectionHeader';
import {
  Box,
  ButtonGroup,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Select,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Button, Card } from 'shared/components/Core';
import { OptionsType } from 'shared/types/coreTypes.d';
import {
  initialSingleAccountSignalReducerState,
  SingleAccountSignalActionType,
  singleAccountSignalReducer,
} from '../../../reducers/singleAccountSignalReducer';
import { singleAccountSignalHistoryTimeOptions } from '../../../constants/singleAccountSignalHistoryTimeOptions';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { SingleAccountSignalSummaryTable } from './SingleAccountSignalSummaryTable';
import { SingleAccountSignalHistoryChart } from './SingleAccountSignalHistoryChart';
import {
  SignalDirections,
  SignalSources,
} from '../../../../Home/types/homeTypes';
import { signalSourceOptions } from 'app/src/hooks/useSignalSourceButtons';
import { FilterIcon } from 'shared/components/Core/Icon/filter';
import { SingleAccountSignalDetailsTable } from './SingleAccountSignalDetailsTable';
import {
  cleanStringCase,
  formatDateString,
} from 'shared/helpers/formatHelpers';

export enum ActivityTableOptions {
  summary = 'summary',
  details = 'details',
}

type SingleAccountSignalHistorySectionProps = {
  structureData: ComponentDataType;
};

export const SingleAccountSignalHistorySection = ({
  structureData,
}: SingleAccountSignalHistorySectionProps) => {
  const { structure } = useContext(AppNavigationalStructureContext);
  const fiscalYearStartingMonth = structure?.fiscalYearStartingMonth ?? 0;
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(
    singleAccountSignalReducer,
    initialSingleAccountSignalReducerState,
  );
  const [selectedSignalTab, setSelectedSignalTab] =
    useState<ActivityTableOptions>(ActivityTableOptions.summary);

  const [selectedTimePeriod, setSelectedTimePeriod] =
    useState<SingleAccountSignalsTimePeriod>(
      SingleAccountSignalsTimePeriod.last5Qtrs,
    );

  const [isCurrentlyZoomedIn, setIsCurrentlyZoomedIn] =
    useState<boolean>(false);

  const {
    onOpen: drawerOnOpen,
    onClose: drawerOnClose,
    isOpen: drawerIsOpen,
  } = useDisclosure();

  const [getAccountSignals, { loading }] = useGetAccountSignalsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (newData) =>
      dispatch({
        type: SingleAccountSignalActionType.SET_FULL_DATA,
        payload: { data: newData.accountSignals, fiscalYearStartingMonth },
      }),
  });

  useEffect(() => {
    if (fiscalYearStartingMonth && id && getAccountSignals && !loading) {
      const minAndMaxDates = mapDropdownTimeInputToDates(
        SingleAccountSignalsTimePeriod.last5Qtrs,
        fiscalYearStartingMonth,
      );
      getAccountSignals({
        variables: {
          accountId: parseInt(id),
          startDate: minAndMaxDates.startDate,
          endDate: minAndMaxDates.endDate,
        },
      });
    }
  }, [id, fiscalYearStartingMonth, getAccountSignals]);

  const typeOptions = useMemo(() => {
    const options = state.fullData?.reduce((accum, item) => {
      if (
        item &&
        item.type &&
        !accum.find((accumItem) => accumItem.value === item.type)
      ) {
        accum.push({
          value: item.type,
          label: cleanStringCase(item.type),
        });
      }
      return accum;
    }, [] as any[]);
    options?.unshift({
      value: '',
      label: 'All',
    });
    return options;
  }, [state.fullData]);

  const typeDetailOptions = useMemo(() => {
    const options = state.fullData?.reduce((accum, item) => {
      if (
        item &&
        item.typeDetail &&
        // filter out return type detail since we don't want to show it here
        item.typeDetail !== 'return' &&
        !accum.find((accumItem) => accumItem.value === item.typeDetail)
      ) {
        accum.push({
          value: item.typeDetail,
          label: cleanStringCase(item.typeDetail),
        });
      }
      return accum;
    }, [] as any[]);
    options?.unshift({
      value: '',
      label: 'All',
    });
    return options;
  }, [state.fullData]);

  return (
    <Card isLoading={loading}>
      <Stack px={6}>
        <SectionHeader
          title={structureData.title ?? 'Signals'}
          subtitle={
            'Signals represent outbound activities from marketing and sales, as well as inbound responses from prospects and customers.'
          }
          tooltip={structureData.tooltip}
          showBackgroundColor={false}
          addHeaderLeftMargin
          rightComponent={
            <ButtonGroup>
              <Button
                text={'Manage Section Filters'}
                onClick={drawerOnOpen}
                leftIcon={<FilterIcon />}
              />
              <Button
                text={'Reset Filters'}
                onClick={() => {
                  dispatch({
                    type: SingleAccountSignalActionType.RESET,
                    payload: null,
                  });
                  setIsCurrentlyZoomedIn(false);
                }}
                variant={'activeOutline'}
              />
            </ButtonGroup>
          }
        />
        <Box pl={8}>
          <Text fontWeight={'bold'}>Selected filters:&nbsp;</Text>
          <Flex>
            <Text ml={2}>
              <i>Source:</i> {cleanStringCase(state.currentSource)}
              ,&nbsp;
            </Text>
            <Text>
              <i>Direction:</i>{' '}
              {cleanStringCase(
                state.currentDirection === SignalDirections.Inbound
                  ? 'inbound'
                  : state.currentDirection === SignalDirections.Outbound
                  ? 'outbound'
                  : 'all',
              )}
              ,&nbsp;
            </Text>
            <Text>
              <i>Type:</i>{' '}
              {state.currentType ? cleanStringCase(state.currentType) : 'All'}
              ,&nbsp;
            </Text>
            <Text>
              <i>Type Detail:</i>{' '}
              {state.currentTypeDetail
                ? cleanStringCase(state.currentTypeDetail)
                : 'All'}
              ,&nbsp;
            </Text>
            <Text>
              <i>Summarize by:</i> {cleanStringCase(state.currentUnitType)}
              ,&nbsp;
            </Text>
            <Text>
              <i>Granularity:</i> {cleanStringCase(state.currentGranularity)}
              ,&nbsp;
            </Text>
            <Text>
              <i>Time Period:</i>{' '}
              {state.currentStartDate &&
                formatDateString(state.currentStartDate, 'MM/dd/yyyy')}
              -
              {state.currentEndDate &&
                formatDateString(state.currentEndDate, 'MM/dd/yyyy')}
            </Text>
          </Flex>
        </Box>
        {state.chartData && (
          <SingleAccountSignalHistoryChart
            chartData={state.chartData}
            isCurrentlyZoomedIn={isCurrentlyZoomedIn}
            setIsCurrentlyZoomedIn={setIsCurrentlyZoomedIn}
            updateStartAndEndDates={(newStartDate, newEndDate) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_START_AND_END_DATES,
                payload: { newStartDate, newEndDate },
              })
            }
            updateGranularity={(newGranularity) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_GRANULARITY,
                payload: { newGranularity },
              })
            }
            currentTimePeriod={selectedTimePeriod}
            currentGranularity={state.currentGranularity}
            zoomOut={() => {
              const { startDate, endDate } = mapDropdownTimeInputToDates(
                selectedTimePeriod,
                fiscalYearStartingMonth,
              );
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_START_AND_END_DATES,
                payload: { newStartDate: startDate, newEndDate: endDate },
              });
            }}
          />
        )}
        <ButtonGroup>
          <Button
            analyticsAttr={`activity-explorer-totals-table`}
            onClick={() => setSelectedSignalTab(ActivityTableOptions.summary)}
            size={'sm'}
            variant={
              selectedSignalTab === ActivityTableOptions.summary
                ? 'actionPurple'
                : 'transparent'
            }
            borderWidth={1}
            borderColor={'brand.purple'}
            text={'Signal Summary'}
          />
          <Button
            analyticsAttr={`activity-explorer-details-table`}
            onClick={() => setSelectedSignalTab(ActivityTableOptions.details)}
            size={'sm'}
            variant={
              selectedSignalTab === ActivityTableOptions.details
                ? 'actionPurple'
                : 'transparent'
            }
            borderWidth={1}
            borderColor={'brand.purple'}
            text={'Signal Detail'}
          />
        </ButtonGroup>
        {state.tableData &&
          (selectedSignalTab === ActivityTableOptions.details ? (
            <SingleAccountSignalDetailsTable
              currentGranularity={state.currentGranularity}
              currentUnitType={state.currentUnitType}
              tableData={state.tableData}
            />
          ) : (
            <SingleAccountSignalSummaryTable
              currentUnitType={state.currentUnitType}
              tableData={state.tableData}
            />
          ))}
      </Stack>
      <Drawer isOpen={drawerIsOpen} placement='right' onClose={drawerOnClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Signals section filters
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing='24px'>
              <Box>
                <FormLabel>TIME PERIOD</FormLabel>
                <Select
                  analytics-attr={`activity-explorer-time-select`}
                  onChange={(e) => {
                    const { startDate, endDate } = mapDropdownTimeInputToDates(
                      e.target.value as SingleAccountSignalsTimePeriod,
                      fiscalYearStartingMonth,
                    );
                    setSelectedTimePeriod(
                      e.target.value as SingleAccountSignalsTimePeriod,
                    );
                    dispatch({
                      type: SingleAccountSignalActionType.UPDATE_START_AND_END_DATES,
                      payload: {
                        newStartDate: startDate,
                        newEndDate: endDate,
                      },
                    });
                  }}
                  value={selectedTimePeriod}
                >
                  {singleAccountSignalHistoryTimeOptions.map((item) => (
                    <option
                      analytics-attr={cleanAnalyticsStringList([
                        'activity-explorer-time-select',
                        item.label,
                      ])}
                      key={item.value}
                      label={item.label}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box>
                <FormLabel>GRANULARITY</FormLabel>
                <Select
                  analytics-attr={`signals-section-granularity-select`}
                  onChange={(e) =>
                    dispatch({
                      type: SingleAccountSignalActionType.UPDATE_GRANULARITY,
                      payload: {
                        newGranularity: e.target.value as Granularity,
                      },
                    })
                  }
                  value={state.currentGranularity}
                  color={'brand.black'}
                >
                  {Object.values(Granularity)
                    .map((item) => ({
                      value: item,
                      label: cleanStringCase(item),
                    }))
                    .map((item) => (
                      <option
                        analytics-attr={`signals-section-granularity-select-${item.label}`}
                        style={{ color: 'brand.black' }}
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      >
                        {item.label}
                      </option>
                    ))}
                </Select>
              </Box>
              <Box>
                <FormLabel>SUMMARIZE BY</FormLabel>
                <Select
                  analytics-attr={`signals-section-unit-select`}
                  onChange={(e) =>
                    dispatch({
                      type: SingleAccountSignalActionType.UPDATE_UNIT_TYPE,
                      payload: {
                        newUnitType: e.target.value as SignalSectionUnitType,
                      },
                    })
                  }
                  value={state.currentUnitType}
                  color={'brand.black'}
                >
                  {Object.values(SignalSectionUnitType)
                    .map((item) => ({
                      value: item,
                      label: cleanStringCase(item),
                    }))
                    .map((item) => (
                      <option
                        analytics-attr={`signals-section-unit-select-${item.label}`}
                        style={{ color: 'brand.black' }}
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      >
                        {item.label}
                      </option>
                    ))}
                </Select>
              </Box>
              <StackDivider />
              <Divider borderColor={'brand.gray-600'} />
              <StackDivider />
              <Box>
                <FormLabel>SOURCE</FormLabel>
                <Select
                  analytics-attr={`signal-section-signal-source-select`}
                  onChange={(e) =>
                    dispatch({
                      type: SingleAccountSignalActionType.UPDATE_SOURCE,
                      payload: { newSource: e.target.value as SignalSources },
                    })
                  }
                  value={state.currentSource}
                  color={'brand.black'}
                >
                  {signalSourceOptions.map((item) => (
                    <option
                      analytics-attr={`signal-section-signal-source-select-${item.label}`}
                      style={{ color: 'brand.black' }}
                      key={item.value}
                      label={item.label}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box>
                <FormLabel>DIRECTION</FormLabel>
                <Select
                  analytics-attr={`$signal-section-signal-direction-select`}
                  onChange={(e) =>
                    dispatch({
                      type: SingleAccountSignalActionType.UPDATE_DIRECTION,
                      payload: {
                        newDirection: e.target.value as SignalDirections,
                      },
                    })
                  }
                  value={state.currentDirection}
                >
                  {signalDirectionOptions.map((item) => (
                    <option
                      analytics-attr={`$signal-section--signal-direction-select-${item.label}`}
                      key={item.value}
                      label={item.label}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box>
                <FormLabel>TYPE DETAIL</FormLabel>
                <Select
                  analytics-attr={`signals-section-type-detail-select`}
                  onChange={(e) =>
                    dispatch({
                      type: SingleAccountSignalActionType.UPDATE_TYPE_DETAIL,
                      payload: {
                        newTypeDetail: e.target.value,
                      },
                    })
                  }
                  value={state.currentTypeDetail}
                  color={'brand.black'}
                >
                  {typeDetailOptions &&
                    typeDetailOptions.map((item: OptionsType) => (
                      <option
                        analytics-attr={`signals-section-type-detail-select-${item.label}`}
                        style={{ color: 'brand.black' }}
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      >
                        {item.label}
                      </option>
                    ))}
                </Select>
              </Box>
              <Box>
                <FormLabel>TYPE</FormLabel>
                <Select
                  analytics-attr={`signals-section-type-select`}
                  onChange={(e) =>
                    dispatch({
                      type: SingleAccountSignalActionType.UPDATE_TYPE,
                      payload: {
                        newType: e.target.value,
                      },
                    })
                  }
                  value={state.currentType}
                  color={'brand.black'}
                >
                  {typeOptions &&
                    typeOptions.map((item: OptionsType) => (
                      <option
                        analytics-attr={`signals-section-type-detail-select-${item.label}`}
                        style={{ color: 'brand.black' }}
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      >
                        {item.label}
                      </option>
                    ))}
                </Select>
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Card>
  );
};
