import {useInput, UseInputHookReturnType, UseInputHookStringValueTypes,} from 'shared/hooks/inputHook';
import {useApolloClient} from '@apollo/client';
import {
    GetAccountsDocument,
    GetAllAccountGroupsDocument,
    useGetAllAccountGroupsQuery,
} from 'shared/graphql/generatedApiTypes';
import {groupAccountGroups} from '../../../helpers/groupAccountGroups';
import {ADD_ACCOUNT_GROUP} from 'shared/graphql/gqlMutations';
import {useFirebase} from '../../../../Firebase';
import {KlearlyUserType} from '../../../../Firebase/firebase';
import {adaptDictAsString} from '../../../helpers/adaptFilterForQuery';
import {useContext, useState} from 'react';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import _map from 'lodash/map';
import {globalFilterAppDefinitions} from '../../../helpers/globalFilterAppDefinitions';
import {AccordionPanelWrapper, Button, Inline, Input, Txt,} from '../../../../../../../shared/components/Core';
import {AnalyticsAttrType} from 'shared/types/coreTypes.d';
import {useToast} from '@chakra-ui/react';

type SaveCurrentSelectionAccordionState = {
  errorMessage: string;
  isSaving: boolean;
};

const initialState: SaveCurrentSelectionAccordionState = {
  errorMessage: '',
  isSaving: false,
};

export const SaveCurrentSelectionAccordion = ({
  analyticsAttr,
}: AnalyticsAttrType) => {
  const apolloClient = useApolloClient();
  const [{ errorMessage, isSaving }, setState] =
    useState<SaveCurrentSelectionAccordionState>(initialState);
  const firebase = useFirebase();
  const klearlyUser: KlearlyUserType | null = firebase!.klearlyUser || null;
  const { globalFilter } = useContext(AggregateAccountsDataContext);
  const appDefinitions =
    globalFilterAppDefinitions.getSegmentSaveAccordionText();
  const toast = useToast();

  const { data } = useGetAllAccountGroupsQuery();
  const { groups: accountSegments } = groupAccountGroups({
    accountGroups: data?.accountGroups,
    klearlyUser,
  });

  const {
    bind: bindAccountGroupTitle,
    setValue: setAccountGroupTitle,
    value: accountGroupTitle,
  } = useInput('', {
    label: appDefinitions.segmentSaveNameLabel.text,
    placeholder: appDefinitions.segmentSaveNamePlaceholder.text,
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindAccountGroupDescription,
    value: accountGroupDescription,
    setValue: setAccountGroupDescription,
  } = useInput('', {
    label: appDefinitions.segmentSaveDescriptionLabel.text,
    placeholder: appDefinitions.segmentSaveDescriptionPlaceholder.text,
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  const saveSegment = async () => {
    setState({ errorMessage: '', isSaving: true });
    if (
      accountSegments?.find(
        (segment) => segment?.title === accountGroupTitle.trim(),
      ) ||
      !globalFilter
    ) {
      setState({
        errorMessage: appDefinitions.segmentSaveDuplicateError.text,
        isSaving: false,
      });
    } else {
      const newAccountGroup = {
        description: accountGroupDescription.trim(),
        filterString: adaptDictAsString(globalFilter),
        title: accountGroupTitle.trim(),
      };
      try {
        const res = await apolloClient.query({
          query: GetAccountsDocument,
          variables: { filterString: adaptDictAsString(globalFilter) },
        });
        // get the up to date list of account ids
        await apolloClient.mutate({
          mutation: ADD_ACCOUNT_GROUP,
          variables: {
            input: {
              ...newAccountGroup,
              accountIds: _map(res.data.accountsWithPredictions, 'id'),
            },
          },
        });
        // merge the new one into the cache to avoid the full refetch call
        apolloClient.cache.writeQuery({
          query: GetAllAccountGroupsDocument,
          data: {
            accountGroups: [
              {
                ...newAccountGroup,
                accountIds: _map(res.data.accountsWithPredictions, 'id'),
              },
            ],
          },
        });
        setState(initialState);
        setAccountGroupTitle('');
        setAccountGroupDescription('');
        toast({
          title: `Saved successfully!`,
          status: 'success',
          position: 'top',
        });
      } catch (err) {
        setState({
          errorMessage: 'Error with save',
          isSaving: false,
        });
      }
    }
  };

  return (
    <AccordionPanelWrapper
      title={appDefinitions.accordionLabel.text}
      analyticsAttr={analyticsAttr}
      render={() => (
        <>
          <Inline display={'flex'} alignItems={'center'}>
            <div className={'filter-bar-wrapper__save-input-wrapper'}>
              <Input
                {...bindAccountGroupTitle}
                className={'h-pb-sm'}
                disabled={isSaving}
              />
              <Input {...bindAccountGroupDescription} disabled={isSaving} />
            </div>
            <Button
              isDisabled={!accountGroupTitle.trim()}
              isLoading={isSaving}
              onClick={saveSegment}
              text={'Save'}
              variant={'action'}
            />
          </Inline>
          {!!errorMessage && <Txt theme={'active'}>{errorMessage}</Txt>}
        </>
      )}
    />
  );
};
