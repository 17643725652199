import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { QueryResult, useReactiveVar } from '@apollo/client';
import {
  ApiGetAggregateAccountsDataQuery,
  ApiGetAggregateAccountsDataQueryVariables,
  ApiGetAggregateAccountsDataWithFilterVarQuery,
  ApiGetAggregateAccountsDataWithFilterVarQueryVariables,
  useGetAggregateAccountsDataLazyQuery,
  useGetAggregateAccountsDataWithFilterVarQuery,
} from 'shared/graphql/generatedApiTypes';
import {
  getInitialApolloState,
  globalFilterVar,
  InitialApolloState,
  initialApolloState,
} from '../apollo/rootReactiveVariables';
import {
  GlobalFilterActionType,
  globalFilterReducer,
  GlobalFilterReducerState,
} from '../components/GlobalFilters/reducers/globalFilterReducer';
import _size from 'lodash/size';
import { adaptDictAsString } from '../components/GlobalFilters/helpers/adaptFilterForQuery';
import { FilterIndices } from '../components/GlobalFilters/types/filterTypes';
import { useFirebase } from '../components/Firebase';
import { merzGlobalFiltersStructure } from '../constants/merzGlobalFiltersStructure';
import { crownpeakGlobalFiltersStructure } from '../constants/crownpeakGlobalFiltersStructure';
import { getAccountGroupStats } from 'app/src/context/helpers/getAccountGroupStats';

type AccountGroupStats = {
  title: string;
  value: string;
};

export const AggregateAccountsDataContext = createContext<
  Pick<
    QueryResult<
      ApiGetAggregateAccountsDataQuery,
      ApiGetAggregateAccountsDataQueryVariables
    >,
    'data' | 'loading' | 'error'
  > & {
    accountGroupStats: AccountGroupStats[];
    fullAggregateData: QueryResult<
      ApiGetAggregateAccountsDataWithFilterVarQuery,
      ApiGetAggregateAccountsDataWithFilterVarQueryVariables
    >['data'];
    fullAggregateError: QueryResult<
      ApiGetAggregateAccountsDataWithFilterVarQuery,
      ApiGetAggregateAccountsDataWithFilterVarQueryVariables
    >['error'];
    fullAggregateLoading: QueryResult<
      ApiGetAggregateAccountsDataWithFilterVarQuery,
      ApiGetAggregateAccountsDataWithFilterVarQueryVariables
    >['loading'];
    globalFilter: InitialApolloState['globalFilter'];
    overrideFilter: (payload: InitialApolloState['globalFilter']) => void;
    resetFilter: () => void;
    updateFilter: (payload: { index: FilterIndices; value: any }) => void;
  }
>({
  accountGroupStats: [],
  data: {
    aggregateAccountsData: null,
    filterString: null,
  },
  error: undefined,
  fullAggregateData: {
    aggregateAccountsData: null,
  },
  fullAggregateError: undefined,
  fullAggregateLoading: false,
  globalFilter: initialApolloState.globalFilter,
  loading: false,
  overrideFilter: () => {},
  resetFilter: () => {},
  updateFilter: () => {},
});

export default function AggregateAccountsDataContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const globalFilter = useReactiveVar(globalFilterVar);
  const { klearlyUser } = useFirebase()!;
  const companyName = klearlyUser?.companyName;
  const globalFiltersStructure =
    companyName === 'merz'
      ? merzGlobalFiltersStructure
      : crownpeakGlobalFiltersStructure;
  const apolloState = getInitialApolloState(
    globalFiltersStructure.defaultAppliedFilter,
  );
  const [state, dispatch] = useReducer(
    globalFilterReducer,
    apolloState.globalFilter,
  );

  // use a lazy query so that we wait for the initial global filter to be set before querying
  const [getAggregateAccountsData, { data, error, loading }] =
    useGetAggregateAccountsDataLazyQuery({
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    if (globalFilter) {
      getAggregateAccountsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  // Get all the data so that we can do the filter comparisions (# of # accounts)
  const {
    data: fullAggregateData,
    loading: fullAggregateLoading,
    error: fullAggregateError,
  } = useGetAggregateAccountsDataWithFilterVarQuery({
    variables: {
      filterString:
        adaptDictAsString({
          accountFilterType: FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
        }) ?? '',
    },
    notifyOnNetworkStatusChange: true,
  });

  const accountGroupStats = getAccountGroupStats(globalFilter);

  useEffect(() => {
    if (state) {
      const newFilter: any = {};
      Object.keys(state).forEach((key) => {
        if (_size(state[key])) {
          newFilter[key] = state[key];
        }
      });
      globalFilterVar(newFilter);
    }
  }, [state]);

  return (
    <AggregateAccountsDataContext.Provider
      value={{
        accountGroupStats,
        data,
        error,
        fullAggregateData,
        fullAggregateError,
        fullAggregateLoading,
        globalFilter: state,
        loading,
        overrideFilter: (payload: GlobalFilterReducerState) =>
          dispatch({
            type: GlobalFilterActionType.OVERRIDE,
            payload,
          }),
        resetFilter: () =>
          dispatch({
            type: GlobalFilterActionType.RESET,
            payload: apolloState.globalFilter,
          }),
        updateFilter: (payload: { index: FilterIndices; value: any }) =>
          dispatch({
            type: GlobalFilterActionType.UPDATE_OR_ADD,
            payload,
          }),
      }}
    >
      {children}
    </AggregateAccountsDataContext.Provider>
  );
}
