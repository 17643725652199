import { useState } from 'react';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _flatten from 'lodash/flatten';
import _indexOf from 'lodash/indexOf';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _round from 'lodash/round';
import _size from 'lodash/size';

import { MAV_WINFLUENCE_SCATTER_PLOT } from 'app/src/constants/routes';

import { HeatMap } from '../../../../../../shared/components/Core';

import SectionHeader from '../../../../../../shared/components/Core/SectionHeader';
import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { FilterIndices } from '../../../GlobalFilters/types/filterTypes';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { HEADER_INDEX } from 'app/src/constants/headerIndex';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';
import { filterAccountByPriorityAndRank } from 'app/src/components/Account/helpers/filterAccountByPriorityAndRank';

export type AccountsDataType = Array<ApiGetAccountQuery['account']>;

type MultiAccountWinfluenceType = {
  accountsData: AccountsDataType;
  isFilterApplied: boolean;
  onChartSelection: (values) => void;
};

type HeatMapStackType = {
  data: AccountsDataType;
  onChartSelection: (value: SelectedChartValuesType | undefined) => void;
};

export type SelectedChartValuesType = Array<{
  current: Array<number>;
  winfluence: number | Array<number>;
}>;

const MultiAccountWinfluence = ({
  accountsData = [],
  isFilterApplied = false,
  onChartSelection = () => {},
}: MultiAccountWinfluenceType) => {
  const {
    subtext: headerSubtext,
    text: headerText,
    tooltip: headerTooltip,
  } = getAppDefinitionsItem(
    MAV_WINFLUENCE_SCATTER_PLOT.name,
    HEADER_INDEX,
  ) as TextDefinitionType;

  return (
    <>
      <SectionHeader
        addHeaderLeftMargin
        showBackgroundColor={false}
        showFilterIcon={isFilterApplied}
        subtitle={headerSubtext}
        title={headerText}
        tooltip={headerTooltip}
      />
      <HeatMapStack data={accountsData} onChartSelection={onChartSelection} />
    </>
  );
};

export default MultiAccountWinfluence;

const HeatMapStack = ({
  data = [],
  onChartSelection = () => {},
}: HeatMapStackType) => {
  // setup state
  const [selectedChartValues, setSelectedChartValues] =
    useState<SelectedChartValuesType>([]);
  // setup static text
  const { text: winfluenceText } = getAppDefinitionsItem(
    MAV_WINFLUENCE_SCATTER_PLOT.name,
    FilterIndices.LIFT,
  ) as TextDefinitionType;
  const { text: currentScoreText } = getAppDefinitionsItem(
    MAV_WINFLUENCE_SCATTER_PLOT.name,
    FilterIndices.CURRENT_ACCOUNT_SCORE,
  ) as TextDefinitionType;
  const dataBucketLengths = _round(data.length / 2);
  const getColor = (value = 0) =>
    value >= 0 && value <= 1
      ? '#F3ECF6'
      : value > 1 && value <= dataBucketLengths
      ? '#B98BC7'
      : '#7E2A98';
  const getFontColor = (value = 0) =>
    value >= 0 && value <= dataBucketLengths
      ? 'var(--color-black)'
      : 'var(--color-white)';
  const events = {
    dataPlotClick: function ({ columnId, rowId }) {
      const selectedRowIndex = _indexOf(rowAxisMapping, rowId);
      const selectedColumnIndex = _indexOf(columnAxisMapping, columnId);
      const currentValues = rowRange[selectedRowIndex];
      const winfluenceValues = columnRange[selectedColumnIndex];
      const indexOfItem = _findIndex(selectedChartValues, {
        current: currentValues,
        winfluence: winfluenceValues,
      });
      const newValue =
        indexOfItem > -1
          ? _filter(selectedChartValues, (val, idx) => idx !== indexOfItem)
          : _concat(selectedChartValues, [
              { current: currentValues, winfluence: winfluenceValues },
            ]);
      const chartSelectionValue = _size(newValue) === 0 ? undefined : newValue;
      setSelectedChartValues(newValue);
      onChartSelection(chartSelectionValue);
    },
  };
  // setup data - column = Winfluence, row = current account score
  const columnRange = [5, 4, 3, 2, [0, 1]];
  const rowRange = [
    [0, 20],
    [21, 40],
    [41, 60],
    [61, 80],
    [81, 100],
  ];
  const columnAxisMapping = [
    'Very High',
    'High',
    'Moderate',
    'Low',
    'Very Low',
  ];
  const rowAxisMapping = [
    'Very Unlikely',
    'Unlikely',
    'Tossup',
    'Likely',
    'Very Likely',
  ];
  const heatMapDataset = _flatten(
    _map(rowRange, (xRangeItem, xRangeIndex) =>
      _map(columnRange, (yRangeItem, yRangeIndex) => {
        const filteredAccounts = _filter(data, (d) => {
          const { probabilityCheck, rankCheck } =
            filterAccountByPriorityAndRank(
              d,
              undefined,
              xRangeItem,
              yRangeItem,
            );
          return probabilityCheck && rankCheck;
        });
        const count = _size(filteredAccounts);
        const isSelected = !!_find(
          selectedChartValues,
          (val) =>
            _isEqual(val.current, xRangeItem) &&
            _isEqual(val.winfluence, yRangeItem),
        );
        return {
          clickMeta: filteredAccounts,
          color: getColor(count),
          columnId: columnAxisMapping[yRangeIndex],
          fontColor: getFontColor(count),
          isSelected,
          label: count.toLocaleString(),
          rowId: rowAxisMapping[xRangeIndex],
          value: count,
        };
      }),
    ),
  );
  const dataColumns = _map(columnAxisMapping, (label) => ({
    id: label,
    label,
  }));
  const dataRows = _map(rowAxisMapping, (label) => ({ id: label, label }));
  // render
  return (
    <div analytics-attr={'heat-map-chart'}>
      <HeatMap
        columns={dataColumns}
        data={heatMapDataset}
        gridHeight={75}
        onClick={(props) =>
          events.dataPlotClick({
            columnId: props.columnId,
            rowId: props.rowId,
          })
        }
        onClickMessage={'Click one or more cells to focus on those accounts'}
        rows={dataRows}
        tooltipFormatter={(props) =>
          `${currentScoreText}: ${props.rowId}; ${winfluenceText}: ${
            props.columnId
          }; Accounts: ${_size(props.clickMeta)}`
        }
        wrapperWidth={'75%'}
        xAxisLabel={currentScoreText}
        yAxisLabel={winfluenceText}
      />
    </div>
  );
};
