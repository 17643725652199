import {
  ApiAccountGroup,
  ApiGetAllAccountGroupsQuery,
  GetAllAccountGroupsDocument,
  UpdateAccountGroupAccountsMutationOptions,
  UpdateAccountGroupDescriptorsMutationOptions,
  UpdateAccountGroupIsHiddenMutationOptions,
} from 'shared/graphql/generatedApiTypes';
import { ApolloCache } from '@apollo/client';

export const updateAccountGroupIsHiddenCache: UpdateAccountGroupIsHiddenMutationOptions =
  {
    update(cache, { data }) {
      const updatedGroup = data?.updateAccountGroupIsHidden?.accountGroup;
      if (updatedGroup) {
        updateAccountGroupCache(updatedGroup, cache, true);
      }
    },
  };

export const updateAccountGroupDescriptorsCache: UpdateAccountGroupDescriptorsMutationOptions =
  {
    update(cache, { data }) {
      const updatedGroup = data?.updateAccountGroupDescriptors?.accountGroup;
      if (updatedGroup) {
        updateAccountGroupCache(updatedGroup, cache);
      }
    },
  };

export const updateAccountGroupAccountsCache: UpdateAccountGroupAccountsMutationOptions =
  {
    update(cache, { data }) {
      const updatedGroup = data?.updateAccountGroupAccounts?.accountGroup;
      if (updatedGroup) {
        updateAccountGroupCache(updatedGroup, cache);
      }
    },
  };

const updateAccountGroupCache = (
  updatedGroup: Pick<ApiAccountGroup, 'title' | 'id'>,
  cache: ApolloCache<any>,
  remove?: boolean,
) => {
  const existingGroups = cache.readQuery<ApiGetAllAccountGroupsQuery>({
    query: GetAllAccountGroupsDocument,
  });
  if (existingGroups && existingGroups.accountGroups && !remove) {
    cache.writeQuery({
      query: GetAllAccountGroupsDocument,
      data: {
        accountGroups: [
          ...existingGroups.accountGroups.filter(
            (group) => group?.id !== updatedGroup.id,
          ),
          updatedGroup,
        ],
      },
    });
  } else if (existingGroups && existingGroups.accountGroups) {
    cache.writeQuery({
      query: GetAllAccountGroupsDocument,
      data: {
        accountGroups: [
          ...existingGroups.accountGroups.filter(
            (group) => group?.id !== updatedGroup.id,
          ),
        ],
      },
    });
  }
};
