import { Box, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { Container } from '../../../../../../../shared/components/Core';
import { CustomerBadge } from './CustomerBadge';
import { getFormattedAddressString } from '../../../helpers/getFormattedAddressString';
import { LocationLink } from './LocationLink';
import { AccountHeaderItem } from './AccountHeaderItem';
import { cleanStringCase, numberFormatter } from 'shared/helpers/formatHelpers';
import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';

type AccountHeaderContentProps = {
  accountInfo: ApiGetAccountQuery['account'];
  dataProcessedAt: string;
  formattedEmployeeCount: string;
  googleMapsLink: string;
};

export const AccountHeaderContent = ({
  accountInfo,
  dataProcessedAt,
  formattedEmployeeCount,
  googleMapsLink,
}: AccountHeaderContentProps) => {
  return (
    <Box
      bg={'brand.gray-800'}
      color={'brand.white'}
      p={6}
      borderBottomWidth={1}
      borderBottomStyle={'solid'}
      borderBottomColor={'brand.gray-300'}
    >
      <Container ph={'xl'} width={'full'}>
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          direction={['column', null, null, null, 'row']}
          gridColumnGap={8}
        >
          <Flex mr={8} flexWrap={'nowrap'} mb={[4, null, null, null, 0]}>
            <Text as={'h1'} fontSize={'2xl'} fontWeight={'bold'}>
              {accountInfo?.accountName ?? ''}
            </Text>
            {accountInfo?.customerStatus && (
              <CustomerBadge customerStatus={accountInfo.customerStatus} />
            )}
          </Flex>
          <SimpleGrid
            columns={[1, 2, null, 5, null]}
            gap={6}
            alignItems={'flex-start'}
          >
            <AccountHeaderItem
              labelText={'SHIP TO PARTY'}
              itemValue={accountInfo?.crmAccountId ?? ''}
            />
            <AccountHeaderItem
              labelText={'DNB REVENUE'}
              itemValue={
                accountInfo?.annualRevenue
                  ? numberFormatter(accountInfo.annualRevenue)
                  : '-'
              }
            />
            <AccountHeaderItem
              labelText={'DNB INDUSTRY'}
              itemValue={cleanStringCase(accountInfo?.industry) ?? '-'}
            />
            <AccountHeaderItem
              labelText={'DNB SIZE'}
              itemValue={formattedEmployeeCount}
            />
            <Stack spacing={1}>
              <LocationLink
                googleMapsLink={googleMapsLink}
                labelText={'LOCATION'}
              />
              <Text>{accountInfo?.addressStreet ?? '-'}</Text>
              <Text>
                {getFormattedAddressString({
                  addressCity: accountInfo?.addressCity,
                  addressRegion: accountInfo?.addressRegion,
                  addressZipCode: accountInfo?.addressZipCode,
                })}
              </Text>
            </Stack>
          </SimpleGrid>
        </Flex>
        <Text color={'brand.white'} mt={2} fontSize={'xs'}>
          Data was last updated on {dataProcessedAt}.
        </Text>
      </Container>
    </Box>
  );
};
