import {createIcon} from '@chakra-ui/icon';

export const ZoomOutIcon = createIcon({
  displayName: 'ZoomOut',
  viewBox: '0 0 24 24',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      width='24'
      viewBox='0 0 24 24'
    >
      <title>zoom out</title>
      <g
        strokeLinecap='square'
        strokeWidth='2'
        fill='none'
        stroke='#ffffff'
        strokeLinejoin='miter'
        className='nc-icon-wrapper'
        strokeMiterlimit='10'
      >
        <line x1='13' y1='10' x2='7' y2='10' stroke='#ffffff'></line>
        <line x1='22' y1='22' x2='15.656' y2='15.656'></line>
        <circle cx='10' cy='10' r='8'></circle>
      </g>
    </svg>
  ),
});
