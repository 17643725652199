import { useContext, useEffect, useState } from 'react';
import { FilterIndices } from '../../../types/filterTypes';
import { Icon } from '../../../../../../../shared/components/Core';
import { AggregateAccountsDataContext } from 'app/src/context/AggregateAccountsDataContext';
import { OptionsType } from 'shared/types/coreTypes.d';
import classnames from 'classnames';
import { Text } from '@chakra-ui/react';
import { pluralizeAccountsString } from 'shared/helpers/formatHelpers';

export const SelectedAccountItems = ({
  isVertical = false,
  filterText = '',
}: {
  isVertical?: boolean;
  filterText?: string;
}) => {
  const { globalFilter } = useContext(AggregateAccountsDataContext);
  const [filteredAccounts, setFilteredAccounts] = useState<OptionsType[]>([]);

  useEffect(() => {
    setFilteredAccounts(
      globalFilter?.accounts?.filter((acct) => {
        const valToCompare = acct.label.slice(0, filterText.length);
        return valToCompare.toLowerCase() === filterText.toLowerCase();
      }),
    );
  }, [filterText, globalFilter?.accounts]);
  const filteredLengthDiff =
    globalFilter?.accounts.length - filteredAccounts.length;

  const wrapperClassnames = classnames({
    'h-flex': true,
    'h-flex-wrap': true,
    'h-flex-column': isVertical,
    'filter-bar-wrapper__accounts-wrapper': true,
  });

  return (
    <div className={wrapperClassnames}>
      {!!filteredAccounts?.length &&
        filteredAccounts.map((account) => (
          <AccountItem account={account} key={account.value} />
        ))}
      {!!filteredLengthDiff && isVertical && (
        <Text ml={2} fontSize={'13px'}>
          {`+ ${filteredLengthDiff} ${pluralizeAccountsString(
            filteredLengthDiff,
          )}`}
        </Text>
      )}
    </div>
  );
};

const AccountItem = ({ account }: { account: OptionsType }) => {
  const [isHoveringOverRemove, setIsHoveringOverRemove] =
    useState<boolean>(false);
  const { globalFilter, updateFilter } = useContext(
    AggregateAccountsDataContext,
  );

  const removeAccount = (accountId) => {
    updateFilter({
      index: FilterIndices.ACCOUNTS_FULL_FILTER,
      value: globalFilter?.accounts?.filter((acct) => acct.value !== accountId),
    });
    updateFilter({
      index: FilterIndices.ACCOUNTS_FILTER,
      value: globalFilter?.accountIds?.filter(
        (id) => id.toString() !== accountId.toString(),
      ),
    });
  };

  return (
    <span
      className={'filter-bar-wrapper__account-item-wrapper'}
      key={account.value}
    >
      <span
        className={'h-ml-md filter-bar-wrapper__account-item-text'}
        style={{ fontSize: '13px' }}
      >
        {account.label}
      </span>
      <span
        className={'h-p-xs filter-bar-wrapper__account-item-icon'}
        onClick={() => removeAccount(account.value)}
        onMouseEnter={() => {
          setIsHoveringOverRemove(true);
        }}
        onMouseLeave={() => setIsHoveringOverRemove(false)}
      >
        <Icon
          // Don't use hoverColor prop so that we can have some hit slop padding for mobile
          color={isHoveringOverRemove ? 'red' : 'gray-800'}
          name={'io-x'}
          size={'md'}
        />
      </span>
    </span>
  );
};
