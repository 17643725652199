import { appDefinitionsDataVar } from '../graphql';
import _find from 'lodash/find';

type AppDefinitionType = {
  _field: Array<{ type: string; label: string }>;
  id: string;
  items: Array<object>;
};
type AppDefinitionsDataType = Array<AppDefinitionType>;
export const getAppDefinitionsItem = (id: string, index?: string) => {
  const data: AppDefinitionsDataType = appDefinitionsDataVar() || [];
  const document: AppDefinitionType | undefined = _find(data, ['id', id]);
  const items: AppDefinitionType['items'] = document?.items ?? [];
  return !data || !document
    ? {}
    : index
    ? _find(items, ['index', index])
    : items;
};
