import { AccountStatSummaryItem } from 'shared/types/companyAppStructureTypes';
import { AccountsStatsProps } from 'app/src/components/GlobalFilters/components/filterBar/AccountsStats';
import {
  pluralizeAccountsString,
  pluralizeIndustriesString,
} from 'shared/helpers/formatHelpers';
import { convertWinfluenceRankToText } from 'app/src/helpers/convertWinfluenceRankToText';

export const accountStatSummaryItemRenderer = (
  item: AccountStatSummaryItem,
  accountsData: NonNullable<AccountsStatsProps['accountsData']>,
) => {
  const {
    avgWinfluence,
    accountsWithClosedOpps,
    totalIndustryCount,
    totalAccounts,
  } = accountsData;
  switch (item) {
    case AccountStatSummaryItem.totalAccounts:
      return {
        addOnText: '',
        count: totalAccounts?.toLocaleString() || '',
        forceShow: true,
        typeFunction: (count) => pluralizeAccountsString(count),
      };
    case AccountStatSummaryItem.avgWinfluence:
      return {
        addOnText: 'Avg. Winfluence',
        typeFunction: () => convertWinfluenceRankToText(avgWinfluence),
        forceShow: !!totalAccounts,
      };
    case AccountStatSummaryItem.totalAccountsWithClosedOpps:
      return {
        addOnText: ' w/ Closed Opps',
        count: accountsWithClosedOpps?.toLocaleString() || '',
        typeFunction: (count) => pluralizeAccountsString(count),
      };
    case AccountStatSummaryItem.totalAccountsWithoutClosedOpps:
      return {
        addOnText: ' w/o Closed Opps',
        count: totalAccounts?.toLocaleString() || '',
        forceShow: true,
        typeFunction: (count) => pluralizeAccountsString(count),
      };
    case AccountStatSummaryItem.totalIndustries:
      return {
        addOnText: '',
        count: totalIndustryCount?.toLocaleString() || '',
        typeFunction: (count) => pluralizeIndustriesString(count),
      };
    default:
      return null;
  }
};
