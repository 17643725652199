import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _map from 'lodash/map';
import _size from 'lodash/size';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { Avatar, Inline, Txt } from '../../../../../shared/components/Core';
import { useFirestoreQuery } from 'shared/hooks/firestoreHook';
import { useFirebase } from '../../Firebase';
import { KlearlyUserType } from '../../Firebase/firebase';
import { formatDateString, truncateString } from 'shared/helpers/formatHelpers';

const SendIcon = require('../../../assets/images/send_button.svg');

export type CommentType = {
  comment: string;
  createdAt: string;
  id: string;
  user: {
    firstName: string;
    isAdminOrSuperUser: boolean;
    lastName: string;
    uid: string;
  };
};

type LabCardCommentsType = {
  id: string;
};

type CommentsType = {
  id: string;
};

type CommentHeaderType = {
  createdAt: string | Date;
  firstName: string;
  isAdminOrSuperUser: boolean;
  isLoggedInUser: boolean;
  lastName: string;
};

type SenderType = {
  id: string;
  klearlyUser: KlearlyUserType;
};

const LabCardComments = ({ id = '' }: LabCardCommentsType) => {
  const firebase = useFirebase();
  const klearlyUser = firebase!.klearlyUser || {};
  return (
    <div className={'comments-wrapper h-height-full'}>
      <Header />
      <Comments id={id} />
      <Sender id={id} klearlyUser={klearlyUser as KlearlyUserType} />
    </div>
  );
};

export default LabCardComments;

const Header = () => (
  <div className={'comments-header'}>
    <Txt>{'Comments'}</Txt>
  </div>
);

const Comments = ({ id = '' }: CommentsType) => {
  const commentsEndRef = useRef<HTMLDivElement>(null);

  const firebase = useFirebase();
  const firebaseUserUID = firebase!.auth.currentUser.uid;
  const { data } = useFirestoreQuery(
    firebase!.getFirestoreCollection('feedback').doc(id),
  );

  const comments = useMemo(() => data?.comments || [], [data]);

  const scrollToBottom = () => {
    if (commentsEndRef.current && commentsEndRef.current.parentElement) {
      commentsEndRef.current.parentElement.scrollTop =
        commentsEndRef.current.offsetTop;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  return (
    <div className={'comments-body h-border-bottom'}>
      <div className={'comments-body-wrapper'}>
        {_map(comments, (comment) => {
          const {
            comment: commentComment,
            createdAt,
            id,
            user,
          }: CommentType = {
            ...comment,
          };
          const {
            firstName,
            isAdminOrSuperUser,
            lastName,
            uid,
          }: CommentType['user'] = { ...user };
          const isLoggedInUser = uid === firebaseUserUID;
          const commentClasses = classnames({
            'comments-comment': true,
            'comments-comment__active': isLoggedInUser,
          });
          return (
            <div className={commentClasses} key={id} ref={commentsEndRef}>
              <CommentHeader
                createdAt={createdAt}
                firstName={firstName.trim()}
                isAdminOrSuperUser={isAdminOrSuperUser}
                isLoggedInUser={isLoggedInUser}
                lastName={lastName.trim()}
              />
              <Txt
                align={isLoggedInUser ? 'right' : 'left'}
                className={'h-mt-2xs comments-comment-text'}
              >
                {commentComment}
              </Txt>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CommentHeader = ({
  createdAt = new Date(),
  firstName = '',
  isAdminOrSuperUser = false,
  isLoggedInUser = false,
  lastName = '',
}: CommentHeaderType) => (
  <Inline
    alignItems={'center'}
    gap={'md'}
    justifyContent={isLoggedInUser ? 'end' : 'start'}
  >
    {!isLoggedInUser && <Avatar text={`${firstName} ${lastName}`} />}
    <div>
      <Txt align={isLoggedInUser ? 'right' : 'left'} weight={'bold'}>
        {`${truncateString(`${firstName} ${lastName}`, 18)}${
          isAdminOrSuperUser && ' (Admin)'
        }`}
      </Txt>
      <Txt
        align={isLoggedInUser ? 'right' : 'left'}
        as={'i'}
        className={'h-mt-2xs'}
        size={'xs'}
      >
        {formatDateString(new Date(createdAt), 'PPpp')}
      </Txt>
    </div>
    {isLoggedInUser && <Avatar text={`${firstName} ${lastName}`} />}
  </Inline>
);

const Sender = ({ id = '', klearlyUser }: SenderType) => {
  const firebase = useFirebase();
  const currentUser = firebase!.auth.currentUser;
  const uid = currentUser.uid;
  const email = currentUser.email;
  const [value, setValue] = useState('');

  const onSubmit = useCallback(() => {
    const isKlearlyAdminOrCustomerAdminUser =
      klearlyUser?.isKlearlyAdmin || klearlyUser?.isAdmin;
    const commentObj = {
      comment: value,
      createdAt: new Date().toISOString(),
      id: uuidv4(),
      user: {
        email,
        firstName: klearlyUser?.firstName,
        isAdminOrSuperUser: isKlearlyAdminOrCustomerAdminUser,
        lastName: klearlyUser?.lastName,
        uid,
      },
    };
    firebase!.updateFeedbackComment(id, commentObj);
    setValue('');
  }, [
    email,
    firebase,
    id,
    klearlyUser?.firstName,
    klearlyUser?.isAdmin,
    klearlyUser?.isKlearlyAdmin,
    klearlyUser?.lastName,
    uid,
    value,
  ]);
  const keyPressFunction = useCallback(
    (event) => {
      if (event.which === 13 && _size(value) > 0) {
        onSubmit();
      }
    },
    [onSubmit, value],
  );
  // setup effects on keyboard press
  useEffect(() => {
    document.addEventListener('keydown', keyPressFunction, false);
    return () =>
      document.removeEventListener('keydown', keyPressFunction, false);
  }, [keyPressFunction]);

  return (
    <div className={'comments-sender'}>
      <input
        autoComplete={'off'}
        className={'comments-new-message'}
        name={'message'}
        onChange={(ev) => setValue(ev.target.value)}
        placeholder={'Type a message...'}
        type={'text'}
        value={value}
      />
      <button
        className={'comments-send'}
        disabled={_size(value) === 0}
        onClick={onSubmit}
        type={'button'}
      >
        <img alt={'Send'} className={'comments-send-icon'} src={SendIcon} />
      </button>
    </div>
  );
};
