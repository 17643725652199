import { Logo } from './index';
import { useReactiveVar } from '@apollo/client';
import { appliedFiltersHeightVar } from 'app/src/apollo/rootReactiveVariables';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

const Circles = require('shared/assets/images/footer-circles.svg');

const Footer = () => {
  const appliedFiltersBarHeight = useReactiveVar(appliedFiltersHeightVar);
  return (
    <Box pb={6} top={appliedFiltersBarHeight}>
      <Flex direction={'column'} align={'center'}>
        <Logo color={'gray-400'} />
        <Text
          fontSize={'xs'}
          fontWeight={'bold'}
          color={'brand.gray-400'}
          pt={4}
          ml={2}
        >
          The Smarter Path to Growth &#8480;
        </Text>
        {/*<Text*/}
        {/*  as={'a'}*/}
        {/*  color={'brand.gray-400'}*/}
        {/*  pt={4}*/}
        {/*  ml={2}*/}
        {/*  href={'https://www.klearly.com/privacy-policy'}*/}
        {/*  rel={'noopener noreferrer'}*/}
        {/*  target={'_blank'}*/}
        {/*>*/}
        {/*  Privacy Policy*/}
        {/*</Text>*/}
      </Flex>
      <Image
        alt={'logo'}
        aria-hidden={'true'}
        position={'absolute'}
        bottom={0}
        right={0}
        src={Circles}
      />
    </Box>
  );
};

export default Footer;
