import { Button, Container } from 'shared/components/Core';
import { Stack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export const Login = () => {
  const history = useHistory();
  return (
    <Container ph={'xs'} pv={'4xl'} width={'xs'}>
      <Stack spacing={10}>
        <Text textAlign={'center'} fontSize={'3xl'} fontWeight={'extrabold'}>
          {'Welcome to Klearly Demo'}
        </Text>
        <Button
          text={'Demo Basic Tier'}
          variant={'active'}
          onClick={() => history.push('/signals')}
        />
        <Button
          text={'Demo Plus Tier'}
          variant={'active'}
          onClick={() => history.push('/signals')}
        />
      </Stack>
    </Container>
  );
};
