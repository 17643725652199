import {CalendarDateType, CalendarRangeFilterItemProps,} from '../../../types/calendarTypes';
import {CalendarRange} from '../../../../../../../shared/components/Core';

export const CalendarRangeBetweenFilterItem = ({
  endDate,
  setEndDate,
  setStartDate,
  startDate,
}: CalendarRangeFilterItemProps) => (
  <div className={'calendar-filter-item-wrapper'}>
    <CalendarRange
      analytics-attr={'calendar-range-select'}
      endDate={endDate || undefined}
      setEndDate={(date: CalendarDateType) => setEndDate(date)}
      setStartDate={(date: CalendarDateType) => setStartDate(date)}
      startDate={startDate || undefined}
    />
  </div>
);
