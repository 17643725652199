import { ComponentType, ReactNode } from 'react';
import {
  components,
  GroupTypeBase,
  InputProps,
  OptionProps,
} from 'react-select';
import _kebabCase from 'lodash/kebabCase';

type AnalyticsAttrType = {
  'analytics-attr': string;
};

export const cleanAnalyticsStringList = (
  listOfStrings: Array<string | number | null | undefined>,
): string =>
  listOfStrings.reduce((accum: string, currentString) => {
    const newString =
      currentString !== null && currentString !== undefined
        ? _kebabCase(currentString.toString().trim())
        : '';
    if (!newString) {
      return accum;
    }
    // If we already have something in the accum,
    // then add a dash to the beginning of the new string
    // otherwise just return the new string
    return accum ? `${accum}-${newString}` : newString;
  }, '');

export const addInputComponentDataAcceptance =
  (attr: string): ReactNode =>
  (props: InputProps & AnalyticsAttrType) =>
    (
      <components.Input
        {...props}
        analytics-attr={cleanAnalyticsStringList([attr])}
      />
    );

export const addOptionComponentDataAcceptance =
  (
    attr: string,
  ): ComponentType<OptionProps<any, boolean, GroupTypeBase<any>>> | undefined =>
  (props: any) =>
    (
      <div analytics-attr={cleanAnalyticsStringList([attr, props.value])}>
        <components.Option {...props}>
          <div className={'h-flex h-flex-align-items-center'}>
            {props.data.countryCode && (
              <img
                alt={props.data.countryCode}
                className={'h-mr-sm'}
                src={
                  require(`../assets/images/flags/${props.data.countryCode.toLowerCase()}.svg`)
                    ?.default
                }
                style={{ height: '100%', width: 'var(--spacing-2xl)' }}
              />
            )}
            {props.label}
          </div>
        </components.Option>
      </div>
    );

export const addControlComponentDataAcceptance =
  (attr: string): ReactNode =>
  (props) =>
    (
      <div analytics-attr={cleanAnalyticsStringList([attr])}>
        <components.Control {...props} />
      </div>
    );
