import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import * as ROUTES from '../../../../../../constants/routes';

export const WinfluenceChangeText = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <Flex>
      <CheckCircleIcon boxSize={5} ml={4} mr={2} color={'brand.green'} />
      <Box>
        <Text as={'span'}>
          Nice work! You were at <strong>Medium</strong> last week and have
          improved to <strong>High</strong> since then!&nbsp;
        </Text>
        <Text
          as={Link}
          textDecoration={'underline'}
          cursor={'pointer'}
          to={{
            pathname: `${ROUTES.ACCOUNT[0]}/${id}`,
            hash: '#recommendations',
          }}
        >
          Learn more about your recommended actions.
        </Text>
      </Box>
    </Flex>
  );
};
