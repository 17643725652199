import { ApiGetAccountsQuery } from 'shared/graphql/generatedApiTypes';
import _filter from 'lodash/filter';
import _isNull from 'lodash/isNull';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _meanBy from 'lodash/meanBy';
import _size from 'lodash/size';
import _round from 'lodash/round';
import _countBy from 'lodash/countBy';

export function getMultipleAccountsHeaderValues(
  accounts: ApiGetAccountsQuery['accountsWithPredictions'],
) {
  if (!accounts || !accounts.length) {
    return {
      accountsWithClosedOpps: [],
      accountsWithoutSignals: [],
      averagePriorityWinfluence: '0',
      numberOfAccountsWithClosedOpps: '0',
      numberOfAccountsWithoutSignals: '0',
      numberOfIndustries: '0',
      numberOfOpenAccounts: '0',
      openAccounts: [],
      possibleWins: '0',
    };
  }

  // filter out closed or no prediction accounts
  const openAccounts =
    _filter(
      accounts,
      (a) => _isNull(a?.firstClosedOpportunityAt) && !_isNull(a?.currentValue),
    ) ?? [];
  const accountsWithClosedOpps =
    _filter(accounts, (a) => !_isNull(a?.firstClosedOpportunityAt)) ?? [];
  const accountsWithoutSignals =
    _filter(
      accounts,
      (a) => _isNull(a?.firstClosedOpportunityAt) && _isNull(a?.currentValue),
    ) ?? [];
  const priorityGoalValues = _map(openAccounts, (a) =>
    _find(a?.goalValues, ['isPriority', true]),
  );
  const averagePriorityWinfluence = _meanBy(priorityGoalValues, 'lift');
  const possibleWins = _size(openAccounts) * (averagePriorityWinfluence / 100);
  return {
    accountsWithClosedOpps,
    accountsWithoutSignals,
    averagePriorityWinfluence:
      _round(averagePriorityWinfluence).toLocaleString() || '0',
    numberOfAccountsWithClosedOpps: _size(
      accountsWithClosedOpps,
    ).toLocaleString(),
    numberOfAccountsWithoutSignals: _size(
      accountsWithoutSignals,
    ).toLocaleString(),
    numberOfIndustries: _size(
      _countBy(openAccounts, 'industry'),
    ).toLocaleString(),
    numberOfOpenAccounts: _size(openAccounts).toLocaleString(),
    openAccounts,
    possibleWins:
      _round(possibleWins) > 0 ? _round(possibleWins).toLocaleString() : '0',
  };
}
