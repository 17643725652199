import { useEffect, useRef } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Card, Txt } from '../../../../../shared/components/Core';
import { useFetchLabCardData } from 'app/src/hooks/useFetchLabCardData';
import { LabFirestoreValuesType } from '../types/firestoreValuesTypes';
import { LabCardPageHeader } from './LabCardPageHeader';
import { LabsHeroDisplay } from '../types/labApiTypes';
import { LabCardPageContent } from './LabCardPageContent';
import * as ROUTES from '../../../constants/routes';
import { Box } from '@chakra-ui/react';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';

export const LabCardDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { data: labFromApi, loading, error } = useFetchLabCardData(id);
  const componentRef = useRef<HTMLDivElement>(null);
  const tableValues = getAppDefinitionsItem(
    ROUTES.LABS_TAB.name,
  ) as LabFirestoreValuesType[];
  const tableValue = tableValues.find((value) => value?.id === labFromApi?.id);
  const pageId = `lab-page-${id}`;

  useEffect(() => {
    if (labFromApi?.question) {
      updateAppHeaders(labFromApi.question);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // setup redirect if card is hidden
  if (tableValue && !tableValue.showCard) {
    return <Redirect to={ROUTES.LABS_TAB.path} />;
  }
  return (
    <Card isLoading={loading} loadingText={'Loading...'}>
      {!!error && (
        <Txt align={'center'} theme={'active'}>
          {'We encountered an error. Please refresh and try again.'}
        </Txt>
      )}
      <Box id={pageId} ref={componentRef}>
        {labFromApi && tableValue && (
          <>
            <LabCardPageHeader
              componentRef={componentRef}
              context={labFromApi.hero.context}
              heroNumber={labFromApi.hero.value}
              id={labFromApi.id}
              question={labFromApi.question}
              totalNumberType={
                labFromApi.hero.displayType === LabsHeroDisplay.integer
                  ? 'number'
                  : labFromApi.hero.displayType === LabsHeroDisplay.percent
                  ? 'percent'
                  : 'currency'
              }
            />

            <LabCardPageContent
              labFromApi={labFromApi}
              tableValue={tableValue}
            />
          </>
        )}
      </Box>
    </Card>
  );
};
