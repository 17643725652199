import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceArea,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Text } from '@chakra-ui/react';
import {
  Button,
  RechartsTooltip,
} from '../../../../../../../shared/components/Core';
import useComponentSize from '@rehooks/component-size';
import {
  Granularity,
  SingleAccountSignalItem,
  SingleAccountSignalsTimePeriod,
} from '../../../types/SingleAccountSignals';
import { ZoomOutIcon } from '../../../../../../../shared/components/Core/Icon/zoomOut';
import { addMonths, isBefore, lastDayOfMonth } from 'date-fns';
import { capitalize } from 'lodash';
import { formatDateString } from 'shared/helpers/formatHelpers';

type SingleAccountSignalHistoryChartState = {
  left: string;
  right: string;
  refAreaLeft: string;
  refAreaRight: string;
};

type SingleAccountSignalHistoryChartProps = {
  chartData: SingleAccountSignalItem[];
  currentGranularity: Granularity;
  currentTimePeriod: SingleAccountSignalsTimePeriod;
  updateGranularity: (newGranularity: Granularity) => void;
  isCurrentlyZoomedIn: boolean;
  setIsCurrentlyZoomedIn: Dispatch<SetStateAction<boolean>>;
  updateStartAndEndDates: (newStartDate: string, newEndDate: string) => void;
  zoomOut: () => void;
};

export const SingleAccountSignalHistoryChart = ({
  chartData,
  currentGranularity,
  currentTimePeriod,
  isCurrentlyZoomedIn,
  setIsCurrentlyZoomedIn,
  updateGranularity,
  updateStartAndEndDates,
  zoomOut,
}: SingleAccountSignalHistoryChartProps) => {
  const initialState: SingleAccountSignalHistoryChartState = {
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
  };

  const [{ left, right, refAreaLeft, refAreaRight }, setState] =
    useState<SingleAccountSignalHistoryChartState>(initialState);
  const chartWrapperRef = useRef(null);
  const { width } = useComponentSize(chartWrapperRef);

  useEffect(() => {
    setIsCurrentlyZoomedIn(false);
  }, [currentTimePeriod, setIsCurrentlyZoomedIn]);

  const zoom = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setState((prevState) => ({
        ...prevState,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    const bottomItem = chartData?.find(
      (item) => item.occurredAt === refAreaLeft,
    );
    const topItem = chartData?.find((item) => item.occurredAt === refAreaRight);

    if (chartData?.length) {
      let leftDate = '';
      let rightDate = '';
      if (bottomItem && topItem) {
        leftDate = isBefore(
          new Date(bottomItem.originalDate),
          new Date(topItem.originalDate),
        )
          ? bottomItem.originalDate
          : topItem.originalDate;
        rightDate = isBefore(
          new Date(bottomItem.originalDate),
          new Date(topItem.originalDate),
        )
          ? topItem.originalDate
          : bottomItem.originalDate;
      }
      if (currentGranularity === Granularity.monthly) {
        rightDate = formatDateString(
          lastDayOfMonth(new Date(rightDate)),
          'M-d-yyyy',
        );
      }
      if (currentGranularity === Granularity.quarterly) {
        rightDate = formatDateString(
          lastDayOfMonth(addMonths(new Date(rightDate), 2)),
          'M-d-yyyy',
        );
      }
      updateStartAndEndDates(leftDate, rightDate);
    }

    setState((prevState) => ({
      ...prevState,
      refAreaLeft: '',
      refAreaRight: '',
      left: refAreaLeft,
      right: refAreaRight,
    }));
    setIsCurrentlyZoomedIn(true);
  };

  const handleBarClick = (data) => {
    if (currentGranularity === Granularity.daily) {
      return;
    }
    const bottomItem = chartData?.find(
      (item) => item.occurredAt === data.occurredAt,
    );
    const leftDate = bottomItem?.originalDate ?? '';
    let rightDate = '';
    if (currentGranularity === Granularity.monthly) {
      rightDate = formatDateString(
        lastDayOfMonth(new Date(leftDate)),
        'M-d-yyyy',
      );
      updateGranularity(Granularity.daily);
    }
    if (currentGranularity === Granularity.quarterly) {
      rightDate = formatDateString(
        lastDayOfMonth(addMonths(new Date(leftDate), 2)),
        'M-d-yyyy',
      );
      updateGranularity(Granularity.monthly);
    }
    updateStartAndEndDates(leftDate, rightDate);
    setIsCurrentlyZoomedIn(true);
  };

  return (
    <>
      <Box ref={chartWrapperRef} analytics-attr={'activity-explorer-chart'}>
        <Button
          analyticsAttr={'activity-explorer-zoom-out'}
          isDisabled={!isCurrentlyZoomedIn}
          leftIcon={<ZoomOutIcon boxSize={6} />}
          mb={4}
          onClick={() => {
            zoomOut();
            setIsCurrentlyZoomedIn(false);
          }}
          variant={'actionPurple'}
          text={'Zoom Out'}
        />
        <BarChart
          width={width}
          height={400}
          data={chartData}
          onMouseDown={(e) =>
            e?.activeLabel &&
            setState((prevState) => ({
              ...prevState,
              refAreaLeft: e.activeLabel,
            }))
          }
          onMouseMove={(e) =>
            refAreaLeft &&
            e?.activeLabel &&
            setState((prevState) => ({
              ...prevState,
              refAreaRight: e.activeLabel,
            }))
          }
          onMouseUp={zoom}
        >
          <Legend
            formatter={(dataPayload: any) => capitalize(dataPayload)}
            iconType={'circle'}
            verticalAlign={'top'}
            wrapperStyle={{ top: -20 }}
          />
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            allowDataOverflow
            dataKey='occurredAt'
            domain={[left, right]}
            type='category'
            style={{ userSelect: 'none' }}
            tickMargin={6}
            tickFormatter={(value) =>
              value && value !== 'dataMax' && value !== 'dataMin' ? value : ''
            }
          />
          <YAxis
            allowDecimals={false}
            domain={[0, 'dataMax+1']}
            type='number'
            yAxisId='1'
            style={{ userSelect: 'none' }}
            tickFormatter={(value) => value.toLocaleString()}
          />
          <Tooltip
            content={
              <RechartsTooltip
                lightBackground
                renderContent={renderTooltipContent}
              />
            }
          />
          <Bar
            yAxisId='1'
            dataKey='inbound'
            fill={'#489ade'}
            stackId={'a'}
            animationDuration={300}
            onClick={handleBarClick}
          />
          <Bar
            yAxisId='1'
            dataKey='outbound'
            fill={'#62d1a6'}
            stackId={'a'}
            animationDuration={300}
            onClick={handleBarClick}
          />
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea
              yAxisId='1'
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}
        </BarChart>
      </Box>
    </>
  );
};

const renderTooltipContent = (dataPayload: any) => {
  const inbound = dataPayload?.inbound;
  const outbound = dataPayload?.outbound;
  const occurredAt = dataPayload?.occurredAt;
  return (
    <>
      <Text color={'brand.black'}>{`Date: ${occurredAt}`}</Text>
      <Text
        color={'brand.black'}
      >{`Inbound: ${inbound.toLocaleString()}`}</Text>
      <Text
        color={'brand.black'}
      >{`Outbound: ${outbound.toLocaleString()}`}</Text>
    </>
  );
};
