import { ApiSignalHistoryItemsFragment } from 'shared/graphql/generatedApiTypes';

export enum SectionHeaderIndices {
  RECOMMENDED_ACTIONS = 'recommendedActionsSectionHeader',
  ENGAGEMENT_STATUS = 'engagementStatusSectionHeader',
  NOTES = 'notesSectionHeader',
  ENGAGEMENT = 'engagementSectionHeader',
}

// ENGAGEMENT LINE CHART
export type EngagementLineChartType = {
  data: ApiSignalHistoryItemsFragment[];
  maxYLeftValue: number;
  maxYRightValue: number;
};

// ENGAGEMENT STATUS
export type EngagementStatusItemType = {
  label: string;
  maxYValue: number;
  x: string;
  y: number;
  tooltip: string;
};

// RECOMMENDED ACTIONS TABLE
export enum RecommendationTableIndices {
  ACCOUNTS_HEADER = 'accounts',
  ACTUAL_HEADER = 'actual',
  AVERAGE_WINFLUENCE_HEADER = 'averageWinfluence',
  CHANGE_HEADER = 'change',
  GOAL_HEADER = 'goal',
  SIGNAL_TYPE_HEADER = 'signalType',
}

export enum RecommendedActionFilterOptions {
  PAUSE = 'pause',
  PURSUE = 'pursue',
}

export interface RecommendationsTableDataTypeWithFilter {
  [RecommendationTableIndices.ACCOUNTS_HEADER]: number;
  [RecommendationTableIndices.ACTUAL_HEADER]: number;
  [RecommendationTableIndices.AVERAGE_WINFLUENCE_HEADER]: number;
  [RecommendationTableIndices.CHANGE_HEADER]: number;
  [RecommendationTableIndices.GOAL_HEADER]: number;
  [RecommendationTableIndices.SIGNAL_TYPE_HEADER]: string;
  filterType: RecommendedActionFilterOptions;
}

export type RecommendedActionsTableText = {
  accountsHeaderText: string;
  accountsHeaderTooltip?: string;
  actionHeaderText: string;
  actionHeaderTooltip?: string;
  averageWinfluenceText: string;
  averageWinfluenceTooltip?: string;
  changeHeaderText: string;
  changeHeaderTooltip?: string;
  goalHeaderText: string;
  goalHeaderTooltip?: string;
  sectionHeaderSubtext: string;
  sectionHeaderText: string;
  sectionHeaderTooltip?: string;
  signalTypeHeaderText: string;
  signalTypeHeaderTooltip?: string;
};

export enum SignalDirections {
  All = 'All',
  Outbound = 'Activity',
  Inbound = 'Response',
}

export enum SignalSources {
  'All' = 'all',
  'marketing' = 'marketing',
  'sales' = 'sales',
}
