import { useContext, useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import { recommendationsTableRadioButtonOptions } from '../constants/homeConstants';
import { Button, Card, RadioInput, Table } from '../../../../../shared/components/Core';
import {
  filterRecommendationsTableData,
  mapRecommendationsTableData,
} from '../helpers/recommendationsTableHelpers';
import {
  RecommendationsTableDataTypeWithFilter,
  RecommendationTableIndices,
  RecommendedActionFilterOptions,
} from '../types/homeTypes';
import { useGetRecommendedActionsQuery } from 'shared/graphql/generatedApiTypes';
import SectionHeader from '../../../../../shared/components/Core/SectionHeader';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { useRecommendationsTableAppDefinitions } from '../hooks/useRecommendationsTableAppDefinitions';
import { useRecommendationsTableColumns } from '../hooks/useRecommendationsTableColumns';
import { ACCOUNT } from 'app/src/constants/routes';
import { useHistory } from 'react-router-dom';
import { AggregateAccountsDataContext } from 'app/src/context/AggregateAccountsDataContext';
import { FilterIndices } from '../../GlobalFilters/types/filterTypes';
import { Box, Flex, Text } from '@chakra-ui/react';

type PrioritySignalRecommendationsState = {
  selectedRowSignals: string[];
  selectedTableFilter: RecommendedActionFilterOptions;
  filterIsDisabled: boolean;
  mappedRecommendationTableData: RecommendationsTableDataTypeWithFilter[];
  filteredTableData: RecommendationsTableDataTypeWithFilter[];
  selectedFilterString: string;
};

const initialPrioritySignalRecommendationsState: PrioritySignalRecommendationsState =
  {
    selectedRowSignals: [],
    selectedTableFilter: RecommendedActionFilterOptions.PURSUE,
    filterIsDisabled: false,
    mappedRecommendationTableData: [],
    filteredTableData: [],
    selectedFilterString: '',
  };

export const HomeRecommendationsTable = () => {
  const [state, setState] = useState<PrioritySignalRecommendationsState>(
    initialPrioritySignalRecommendationsState,
  );
  const history = useHistory();
  const { globalFilter, updateFilter } = useContext(
    AggregateAccountsDataContext,
  );

  const { data, loading } = useGetRecommendedActionsQuery({
    ...defaultQueryFetchPolicy,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data) {
      const mappedData = mapRecommendationsTableData(data);
      setState((prevState) => ({
        ...prevState,
        mappedRecommendationTableData: mappedData,
        filteredTableData: filterRecommendationsTableData({
          data: mappedData,
          filterType: prevState.selectedTableFilter,
        }),
      }));
    }
  }, [data]);

  // listen to the overall home filter && lock the table filter
  // when the top level has an action filter selected
  useEffect(() => {
    if (globalFilter && globalFilter[FilterIndices.RECOMMENDED_ACTION]) {
      setState((prevState) => ({
        ...prevState,
        selectedTableFilter: globalFilter[
          FilterIndices.RECOMMENDED_ACTION
        ] as RecommendedActionFilterOptions,
        filterIsDisabled: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filterIsDisabled: false,
      }));
    }
  }, [globalFilter]);

  // when the filter changes, filter the existing data
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      filteredTableData: filterRecommendationsTableData({
        data: state.mappedRecommendationTableData,
        filterType: state.selectedTableFilter,
      }),
    }));
  }, [state.selectedTableFilter, state.mappedRecommendationTableData]);

  const text = useRecommendationsTableAppDefinitions();
  const tableColumns = useRecommendationsTableColumns(text);

  const showAccounts = () => {
    // add in any selected table rows by their signal type
    if (state.selectedRowSignals.length) {
      updateFilter({
        index: FilterIndices.SIGNAL_TYPE_FILTER,
        value: state.selectedRowSignals,
      });
    }
    // account for if the pause or pursue filter is selected
    updateFilter({
      index: FilterIndices.RECOMMENDED_ACTION,
      value: state.selectedTableFilter,
    });
    // force it to queue up to ensure the filter is updated before the accounts page loads
    setTimeout(() => {
      history.push(ACCOUNT[0]);
      window.scrollTo(0, 0);
    }, 200);
  };

  return (
    <Card isLoading={loading}>
      <Box p={4}>
        <SectionHeader
          showBackgroundColor={false}
          subtitle={text.sectionHeaderSubtext}
          title={text.sectionHeaderText}
          tooltip={text.sectionHeaderTooltip}
        />
        <Flex justify={'space-between'}>
          <Flex pt={2}>
            <Text fontWeight={'bold'} mr={4}>
              Filter Recommended Actions:
            </Text>
            <RadioInput
              horizontal
              disabled={state.filterIsDisabled}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  selectedTableFilter: e.target
                    .value as RecommendedActionFilterOptions,
                }))
              }
              options={recommendationsTableRadioButtonOptions}
              value={state.selectedTableFilter}
            />
          </Flex>
          <Button
            analyticsAttr={'recommendations-table-view-accounts'}
            isDisabled={!state.selectedRowSignals.length}
            onClick={showAccounts}
            text={'View Accounts'}
            fontProps={{ fontSize: 'sm' }}
            variant={'action'}
          />
        </Flex>
        {state.filteredTableData && (
          <Table
            analyticsAttr={'recommendations'}
            columns={tableColumns}
            data={state.filteredTableData}
            initialDefaultSortByColumnDesc={true}
            initialDefaultSortByColumnId={'accounts'}
            selectedRowIndex={RecommendationTableIndices.SIGNAL_TYPE_HEADER}
            setSelectedRows={(signalTypes) => {
              if (
                !_isEqual(
                  _sortBy(signalTypes),
                  _sortBy(state.selectedRowSignals),
                )
              ) {
                setState((prevState) => ({
                  ...prevState,
                  selectedRowSignals: signalTypes,
                }));
              }
            }}
          />
        )}
      </Box>
    </Card>
  );
};
