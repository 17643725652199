import { useGetAllAccountGroupsQuery } from 'shared/graphql/generatedApiTypes';
import { SegmentsOrLists } from './SegmentsOrLists';
import { groupAccountGroups } from '../../../helpers/groupAccountGroups';
import { useFirebase } from '../../../../Firebase';
import { SegmentsAndListsIndices } from '../../../constants/segmentsAndListsIndices';
import { globalFilterAppDefinitions } from '../../../helpers/globalFilterAppDefinitions';

export const TeamListsTab = () => {
  const { data, loading } = useGetAllAccountGroupsQuery();
  const firebase = useFirebase();
  const klearlyUser = firebase!.klearlyUser ?? null;
  const accountGroups = data?.accountGroups ?? [];
  const { lists } = groupAccountGroups({
    accountGroups,
    klearlyUser,
  });

  const appDefinitions = globalFilterAppDefinitions.getTeamListsTabText();

  return (
    <div
      key={SegmentsAndListsIndices.LISTS_INDEX}
      label={SegmentsAndListsIndices.LISTS_INDEX}
    >
      <SegmentsOrLists
        data={lists ?? []}
        emptyListString={appDefinitions.empty.text}
        index={SegmentsAndListsIndices.LISTS_INDEX}
        loading={loading}
        segmentOrListType={'list'}
        subtext={appDefinitions.tabTitle.subtext}
        title={appDefinitions.tabTitle.text}
      />
    </div>
  );
};
