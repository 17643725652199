import {useContext, useMemo} from 'react';
import {useInput, UseInputHookReturnType} from 'shared/hooks/inputHook';
import MultiSelectCheckbox from '../../../../../../shared/components/Core/MultiSelectCheckbox';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import {FilterItemProps} from '../../types/filterItemProps';
import {sortSelectFilterOptions} from '../../helpers/sortSelectFilterOptions';
import {getFilterItemConsts} from '../../helpers/getFilterItemConsts';
import {useGetAggregateAccountsDataWithFilterVarQuery} from 'shared/graphql/generatedApiTypes';
import {adaptDictAsString} from '../../helpers/adaptFilterForQuery';
import _omit from 'lodash/omit';
import {OptionsType} from 'shared/types/coreTypes.d';

const CheckboxFilterItem = ({
  analyticsAttr = undefined,
  initialItems = [],
  filterIndex,
  label = '',
  labelStringTooltip = '',
  tableAccessor = '',
}: FilterItemProps) => {
  const { globalFilter } = useContext(AggregateAccountsDataContext);

  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
  } = useContext(AggregateAccountsDataContext);

  const {
    data: filterItemAggregatedAccountsData,
    loading: filterItemAggregatedAccountsDataLoading,
  } = useGetAggregateAccountsDataWithFilterVarQuery({
    variables: {
      filterString: adaptDictAsString(_omit(globalFilter, filterIndex)) ?? '',
    },
  });

  const { aggregatedItemsAccountData, areFiltersApplied, isSelectedDisabled } =
    getFilterItemConsts({
      filterIndex,
      filterItemAggregatedAccountsData,
      filterItemAggregatedAccountsDataLoading,
      globalFilter,
      initialAggregatedAccountsData,
      initialAggregatedAccountsDataLoading,
    });

  const itemsSorted = sortSelectFilterOptions({
    aggregatedItemsAccountData,
    areFiltersApplied,
    filterIndex,
    initialItems,
    tableAccessor,
  });

  const defaultValue = useMemo(() => [], []);
  const { bind: bindItems } = useInput(
    globalFilter?.[filterIndex] || defaultValue,
    {
      defaultValue: defaultValue,
      label: label,
      labelTooltip: labelStringTooltip,
      options: itemsSorted,
    },
  ) as UseInputHookReturnType;

  return (
    <MultiSelectCheckbox
      {...bindItems}
      analyticsAttr={analyticsAttr}
      disabled={isSelectedDisabled}
      extraFieldSetStyles={{ marginTop: 0 }}
      filterIndex={filterIndex}
      isFilterBar={true}
      options={bindItems.options as OptionsType[]}
    />
  );
};

export default CheckboxFilterItem;
