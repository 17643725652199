export type CalendarDateType = Date | null;

export type CalendarRangeFilterItemProps = {
  endDate: CalendarDateType;
  setEndDate: (date: CalendarDateType) => void;
  setStartDate: (date: CalendarDateType) => void;
  startDate: CalendarDateType;
};

export enum CalendarRangeState {
  BETWEEN = 'between',
  PREVIOUS = 'previous',
}

export enum CalendarFilterFormat {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years',
}
