import {Inline} from '../../../../../../shared/components/Core';
import {useContext, useEffect, useRef} from 'react';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import {appliedFiltersHeightVar} from 'app/src/apollo/rootReactiveVariables';
import {FilterBarDivider} from './FilterBarDivider';
import {Text} from '@chakra-ui/react';

export const FilterStatsSummaryBar = () => {
  const appliedFiltersBarRef = useRef<HTMLDivElement>(null);

  const { accountGroupStats, globalFilter } = useContext(
    AggregateAccountsDataContext,
  );

  // Set the height of this bar in order to adjust the container top when things take >1 line
  useEffect(() => {
    if (appliedFiltersBarRef.current) {
      appliedFiltersHeightVar(appliedFiltersBarRef.current.clientHeight);
    }
  }, [globalFilter, appliedFiltersBarRef.current?.clientHeight]);

  return (
    <>
      <div className={'c-header c-header__account-stats h-pv-2xs'}>
        <div className={'c-header__container'}>
          <Inline
            className={'h-bg-white'}
            height={'auto'}
            display={'grid'}
            gap={'xs'}
          >
            <Text color={'brand.gray-600'} fontSize={'md'} fontWeight={'bold'}>
              Selected filters:
            </Text>
            <div
              className={'h-flex h-flex-wrap h-width-full'}
              ref={appliedFiltersBarRef}
            >
              {accountGroupStats.length ? (
                accountGroupStats.map((stat, idx) => (
                  <div className={'h-flex h-flex-wrap'} key={idx}>
                    <Inline className={'h-color-gray-600 h-mr-sm'}>
                      <Text fontStyle={'italic'}>{`${stat.title}:`}</Text>
                      <Text>{`${stat.value}`}</Text>
                    </Inline>
                  </div>
                ))
              ) : (
                <Text color={'brand.gray-600'} fontSize={'md'}>
                  None
                </Text>
              )}
            </div>
          </Inline>
        </div>
      </div>
      <FilterBarDivider />
    </>
  );
};
