import { Box, Text } from '@chakra-ui/react';
import { BarGraphBackgroundBarsType } from '../../../helpers/rangerFilterHelpers';

type RangerBarAndLabelProps = {
  bars: { values: BarGraphBackgroundBarsType; selectedBarsIndex: number[] };
  currentValueIndices: number[];
  index: number;
  label: string | undefined;
};

export const RangerBarAndLabel = ({
  bars,
  currentValueIndices,
  index,
  label,
}: RangerBarAndLabelProps) => (
  <Box>
    <Box
      mb={-4}
      w={`98%`}
      mx={'1%'}
      ml={'49%'}
      h={bars.values[index].height}
      bg={
        index >= currentValueIndices[0] && index <= currentValueIndices[1] - 1
          ? 'brand.gray-400'
          : 'brand.gray-200'
      }
      transform={`translateY(-${bars.values[index].height})`}
    />
    <RangerLabel bars={bars} index={index} label={label} />
  </Box>
);

type RangerLabelProps = Omit<RangerBarAndLabelProps, 'currentValueIndices'>;

export const RangerLabel = ({ bars, index, label }: RangerLabelProps) => (
  <Text
    fontSize={'xs'}
    mt={8}
    mr={2}
    transform={
      bars.values[index].height
        ? `translateY(-${bars.values[index].height})`
        : undefined
    }
  >
    {label ?? ''}
  </Text>
);
