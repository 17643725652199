import { useRef } from 'react';
import { LabFirestoreValuesType } from '../types/firestoreValuesTypes';
import { useFirestoreQuery } from 'shared/hooks/firestoreHook';
import { useFirebase } from '../../Firebase';
import {
  Card,
  IconBadge,
  Tooltip,
} from '../../../../../shared/components/Core';
import { useFetchLabCardData } from 'app/src/hooks/useFetchLabCardData';
import { LabChart } from './LabCharts';
import { LabCardLikeFavoriteIcons, LabCardShareIcons } from './LabCardFeedback';
import { LabsHeroDisplay } from '../types/labApiTypes';
import IoMagnifyingGlass from '../../../../../shared/components/Core/Icon/io-magnifying-glass';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { Flex, Text } from '@chakra-ui/react';
import { cleanStringCase } from 'shared/helpers/formatHelpers';
import { styleNumber } from 'app/src/components/Lab/helpers/styleNumber';

type LabCardProps = {
  isFavorited: boolean;
  lab: LabFirestoreValuesType;
  section: string;
};

const LabCard = ({ lab, isFavorited = false, section = '' }: LabCardProps) => {
  const { id } = lab;
  const firebase = useFirebase()!;
  const { data: labFromApi, loading, error } = useFetchLabCardData(id);
  const componentRef = useRef<HTMLDivElement>(null);
  // setup firebase items
  const { data } = useFirestoreQuery(
    firebase!.getFirestoreCollection('feedback').doc(id),
  );
  // setup consts
  const comments = data?.comments ?? [];
  const cardIdPrefix = 'lab-card-';
  const cardId = `${cardIdPrefix}${id}`;
  const contentWrapperId = 'content-wrapper';
  const contentHeaderWrapperId = 'content-header-wrapper';
  const contentChartWrapperId = 'content-chart-wrapper';

  return (
    <Card isLoading={loading} loadingText={'Loading...'} minH={600}>
      <div className={'lab-card'} id={cardId}>
        {isFavorited && section === 'Favorites' && (
          <div className={'h-p-md h-border-bottom lab-card-header'}>
            <Text as={'i'}>{cleanStringCase(lab.LabsTags)}</Text>
          </div>
        )}
        <div
          className={'h-p-md h-border-bottom lab-card-body'}
          id={contentWrapperId}
          ref={componentRef}
        >
          <div id={contentHeaderWrapperId}>
            <Flex justify={'space-between'}>
              <Text color={'brand.black'} fontSize={'lg'} fontWeight={'bold'}>
                {labFromApi?.question ?? ''}
              </Text>
              <Link to={`${ROUTES.LABS_TAB.path}/${id}`}>
                <Tooltip content={'Magnify'} hideIcon={true}>
                  <IconBadge
                    badgeProps={{ position: 'relative' }}
                    iconComponent={
                      <IoMagnifyingGlass boxSize={6} ml={-3} mt={2} />
                    }
                    text={comments.length.toString()}
                  />
                </Tooltip>
              </Link>
            </Flex>
            {labFromApi?.hero.value && (
              <Text color={'brand.black'} mt={'md'} fontSize={'3xl'}>
                {styleNumber(
                  labFromApi.hero.value,
                  labFromApi.hero.displayType === LabsHeroDisplay.currency,
                  labFromApi.hero.displayType === LabsHeroDisplay.percent,
                )}
              </Text>
            )}
            {!!labFromApi?.hero.context.length && (
              <Text color={'brand.black'} mt={'2xs'}>
                {labFromApi.hero.context}
              </Text>
            )}
          </div>
          <div id={contentChartWrapperId}>
            {labFromApi && lab && (
              <LabChart
                columns={labFromApi.columns}
                context={labFromApi.hero.context}
                displayType={labFromApi.chartType.default}
                isAnswerTypePercent={
                  labFromApi.hero.displayType === LabsHeroDisplay.percent
                }
                isChartClickable={false}
                isDollars={
                  labFromApi.hero.displayType === LabsHeroDisplay.currency
                }
                isSmallerViewport={true}
                monoColor={lab.monoColor}
                rows={labFromApi.rows}
                sortOrder={'asc'}
                totalNumber={labFromApi.hero.value}
              />
            )}
            {!!error && (
              <Text align={'center'} color={'brand.crimson'}>
                {'Error loading this chart. Please refresh and try again.'}
              </Text>
            )}
          </div>
        </div>
        <div className={'h-p-md lab-card-footer'}>
          <LabCardLikeFavoriteIcons
            id={id}
            middleComponent={
              <LabCardShareIcons
                componentRef={componentRef}
                customContentIdPrefix={cardIdPrefix}
                customURLPrefix={window.location.href}
                id={id}
                question={labFromApi?.question ?? ''}
              />
            }
          />
        </div>
      </div>
    </Card>
  );
};

export { LabCard };
