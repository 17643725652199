export enum SectionComponents {
  EngagementStatus = 'engagementStatus',
  AggregateSignalHistory = 'aggregateSignalHistory',
  RecommendedActionsTable = 'recommendedActionsTable',
  PossibleAccountScoreSankey = 'possibleAccountScoreSankey',
  WinfluenceAndPrimaryActionFilterableList = 'winfluenceAndPrimaryActionFilterableList',
  Labs = 'labs',
  LabsDetail = 'labsDetail',
  SingleAccountRecommendations = 'singleAccountRecommendations',
  SingleAccountEngagementSummary = 'singleAccountEngagementSummary',
  SingleAccountEngagementAnalysis = 'singleAccountEngagementAnalysis',
  SingleAccountSignalHistory = 'singleAccountSignalHistory',
  Orders = 'orders',
  TableSection = 'tableSection',
  Winfluence = 'winfluence',
  SingleAccountHeader = 'singleAccountHeader',
  SingleAccountHeaderAlternate = 'singleAccountHeaderAlternate',
  SingleAccountQuickStat = 'singleAccountQuickStat',
  InsightsFeed = 'insightsFeed',
  GaugeChart = 'gaugeChart',
}
