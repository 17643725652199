/* Number Formatter */
import _startCase from 'lodash/startCase';
import _lowerCase from 'lodash/lowerCase';
import { addMinutes, format } from 'date-fns';
import _isDate from 'lodash/isDate';
import _round from 'lodash/round';
import _size from 'lodash/size';

const THOUSAND = 1000;
const MILLION = THOUSAND * THOUSAND;
const BILLION = MILLION * THOUSAND;

function _billions(value: number | string) {
  // Times and divided by 10 gives an extra decimal place
  return Math.round((10 * (value as number)) / BILLION) / 10;
}

function _commaFormatter(value: number) {
  if (value === null) return '-';
  return value.toLocaleString();
}

function _millions(value: number | string) {
  // Times and divided by 10 gives an extra decimal place
  return Math.round((10 * (value as number)) / MILLION) / 10;
}

function _thousands(value: number | string) {
  // Times and divided by 10 gives an extra decimal place
  return Math.round((10 * (value as number)) / THOUSAND) / 10;
}

export function numberFormatter(
  value: number | string | null | undefined,
  isDollars: boolean = true,
) {
  const addOnText = isDollars ? '$' : '';
  if (!value && value !== 0) return '-';
  if (_thousands(value) < 1) {
    return `${addOnText}${value}`;
  }
  const numMillions = _millions(value);
  if (numMillions >= 1000) {
    return `${addOnText}${_commaFormatter(_billions(value))}B`;
  }
  if (numMillions < 1) {
    return `${addOnText}${_commaFormatter(_thousands(value))}K`;
  }
  return `${addOnText}${_commaFormatter(_millions(value))}M`;
}

export function cleanStringCase(string: string | null | undefined) {
  return string ? _startCase(_lowerCase(string)) : '';
}

export function cleanNumberRangeStringCase(string: string) {
  const strings = string.split(' - ');
  const firstString = strings[0].replace('$', '').replace(/,/g, '');
  const secondString = strings[1].replace('$', '').replace(/,/g, '');
  const isFirstStringDollars = !!strings[0].indexOf('$');
  const isSecondStringDollars = !!strings[1].indexOf('$');
  return `${numberFormatter(
    firstString,
    isFirstStringDollars,
  )} - ${numberFormatter(secondString, isSecondStringDollars)}`;
}

export function formatDateForTable(dateString: string | null = null) {
  return dateString ? formatDateString(new Date(dateString + 'Z'), 'PP') : '';
}

export function pluralizeAccountsString(count: number | string = 0) {
  const castCount = count as string;
  if ((parseInt(castCount) === 1 && castCount.length === 1) || count === 1) {
    return 'Account';
  }
  return 'Accounts';
}

export function pluralizeContactsString(count: number | string = 0) {
  return `Contact${parseInt(count as string) === 1 ? '' : 's'}`;
}

export function pluralizeDaysString(count: number | string = 0) {
  return `day${parseInt(count as string) === 1 ? '' : 's'}`;
}

export function pluralizeIndustriesString(count: number | string = 0) {
  return `Industr${parseInt(count as string) === 1 ? 'y' : 'ies'}`;
}

export function formatDateString(
  date: Date | string,
  formatString = 'yyyy-MM-dd',
) {
  // If the date is a string, we need to account for the browser's time offset
  const convertToOffsetDate = (dateString: string): Date => {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const dateWithOffset = addMinutes(date, offset);
    return new Date(dateWithOffset);
  };

  const cleanedDate = _isDate(date) ? date : convertToOffsetDate(date);
  return format(cleanedDate, formatString);
}

export function formatAsPercentage(
  num: number,
  {
    precision = 2,
    includeSign = false,
  }: { precision?: number; includeSign?: boolean },
) {
  const percentage = num * 100;
  if (!includeSign || percentage <= 0)
    return percentage.toFixed(precision) + '%';
  return `+${percentage.toFixed(precision)}%`;
}

export function roundNumber(
  number: number | string | null = null,
  isPercent: boolean = false,
  precision: number | null = null,
) {
  if (!number && number !== 0) {
    return '-';
  }
  const numberToMultiplyBy = isPercent ? 100 : 1;
  const percisionValue = isPercent ? precision! + 2 : precision;
  const percentString = isPercent ? '%' : '';
  return percisionValue || percisionValue === 0
    ? `${(
        _round(parseFloat(number as string), percisionValue) *
        numberToMultiplyBy
      ).toLocaleString()}${percentString}`
    : `${_round(
        parseFloat(number as string) * numberToMultiplyBy,
      ).toLocaleString()}${percentString}`;
}

export function truncateString(str: string = '', length: number = 12) {
  if (_size(str) > 0) {
    return `${str.substring(0, length)}${str.length > length ? '...' : ''}`;
  }
  return '';
}

export function styleNumber(
  number: number = 0,
  isCurrency: boolean = false,
  isPercent: boolean = false,
) {
  if (isCurrency) {
    return `$${number.toLocaleString()}`;
  }
  if (isPercent) {
    return formatAsPercentage(number, {});
  }
  return number.toLocaleString();
}
