import {FilterIndices} from '../components/GlobalFilters/types/filterTypes';
import {FilterPanelTabValues} from '../components/GlobalFilters/constants/filterPanelTabOptions';
import {AccountStatSummaryItem, FilterItemOption, GlobalFiltersStructure,} from 'shared/types/companyAppStructureTypes';

export const crownpeakGlobalFiltersStructure: GlobalFiltersStructure = {
  accountStatSummaryConfig: [
    AccountStatSummaryItem.totalAccountsWithoutClosedOpps,
    AccountStatSummaryItem.totalAccountsWithClosedOpps,
    AccountStatSummaryItem.totalIndustries,
    AccountStatSummaryItem.avgWinfluence,
  ],
  defaultAppliedFilter: JSON.stringify({
    [FilterIndices.ENGAGEMENT_STATUS]: ['active'],
  }),
  panel: {
    showTabs: [
      FilterPanelTabValues.BUILD,
      FilterPanelTabValues.TEAM_SEGMENTS,
      FilterPanelTabValues.TEAM_LISTS,
    ],
    segmentBuilderSections: [
      {
        priority: 1,
        label: 'Accounts',
        filters: [
          {
            field: FilterIndices.ACCOUNTS_FILTER,
            title: '',
            type: FilterItemOption.searchableDropdown,
          },
        ],
      },
      {
        priority: 2,
        label: 'Firmographics',
        filters: [
          {
            field: FilterIndices.COUNTRY,
            title: 'Countries',
            type: FilterItemOption.checkboxDropdown,
          },
          {
            field: FilterIndices.INDUSTRY,
            title: 'Industries',
            type: FilterItemOption.checkboxDropdown,
          },
          {
            field: FilterIndices.REVENUES_FILTER,
            title: 'Revenue',
            type: FilterItemOption.dollarRanger,
          },
          {
            field: FilterIndices.SIZES_FILTER,
            title: 'Size',
            type: FilterItemOption.ranger,
          },
        ],
      },
      {
        priority: 3,
        label: 'Signals',
        filters: [
          {
            field: FilterIndices.ENGAGEMENT_STATUS,
            title: 'Engagement Status',
            tooltip:
              'An account\'s engagement status based on the recency of its last inbound response (e.g., email click, form submission, etc.), including "Active" (at least one response in the last 90 days), "Lapsing" (at least one response in the last 180 days, but not in the last 90 days), "Lapsed" (at least one response in the last 365 days, but not in the last 180 days), "Inactive" (at least one response, but not in the last 365 days), and "Pending" (no responses, ever).',
            type: FilterItemOption.checkboxes,
          },
          {
            field: FilterIndices.ENGAGEMENT_INTENSITY,
            title: 'Engagement Intensity',
            tooltip:
              'An account’s engagement intensity indicates when recent engagement (i.e., inbound responses) is much more (or less) than normal compared to historical engagement benchmarks for that account.',
            type: FilterItemOption.checkboxes,
          },
          {
            field: FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER,
            title: 'Last Inbound Signal Date',
            tooltip:
              'Date range since an account was last engaged as indicated by an inbound signal response (e.g., email click, form submission, etc.).',
            type: FilterItemOption.calendar,
          },
          {
            field: FilterIndices.OUTREACH_INTENSITY,
            title: 'Outreach Intensity',
            type: FilterItemOption.checkboxes,
          },
          {
            field: FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER,
            title: 'Last Outbound Signal Date',
            tooltip:
              'Date range since an account was last reached as indicated by an outbound signal activity (e.g., email send, phone call, etc.).',
            type: FilterItemOption.calendar,
          },
        ],
      },
      {
        priority: 4,
        label: 'Predictions',
        filters: [
          {
            field: FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE,
            title: 'Current Account Score',
            tooltip:
              'Current probability of an account ever becoming a customer, on a scale from 0 to 100.',
            type: FilterItemOption.hundredPointScaleRanger,
          },
          {
            field: FilterIndices.WINFLUENCE_ACTUAL,
            title: 'Winfluence',
            tooltip:
              "Ranking of the possible influence recommended signal actions could have on an account's current win probability of ever becoming a customer.",
            type: FilterItemOption.checkboxes,
          },
        ],
      },
      {
        priority: 5,
        label: 'Recommendations',
        filters: [
          {
            field: FilterIndices.SIGNAL_TYPE_FILTER,
            title: 'Signal Type',
            tooltip:
              'Either an outbound activity targeting an account or an inbound response from an account.',
            type: FilterItemOption.checkboxes,
          },
          {
            field: FilterIndices.RECOMMENDED_ACTION,
            title: 'Recommended Action',
            tooltip:
              'Recommended action (e.g., pause or pursue) based on a comparison between expected signal performance and recommended signal performance.',
            type: FilterItemOption.radio,
          },
        ],
      },
    ],
  },
  quickAccessBarFilterItems: [
    {
      type: FilterItemOption.searchableDropdown,
      field: FilterIndices.ACCOUNTS_FILTER,
      title: 'Accounts',
    },
    {
      type: FilterItemOption.checkboxDropdown,
      field: FilterIndices.ENGAGEMENT_STATUS,
      title: 'Engagement Status',
    },
    {
      type: FilterItemOption.checkboxDropdown,
      field: FilterIndices.WINFLUENCE_ACTUAL,
      title: 'Winfluence',
    },
  ],
};
