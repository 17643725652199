import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import _isEqual from 'lodash/isEqual';
import { ApiGetKlearlyUserQuery } from 'shared/graphql/generatedApiTypes';
import {
  CompanyAppStructure,
  StructuredPage,
} from 'shared/types/companyAppStructureTypes';
import { QuerySnapshot } from '@firebase/firestore-types';
import { typeOptionsLiteral } from 'shared/types/index.d';

type ItemType = {
  id?: string;
  index?: string;
  value: string;
};

export type ItemsType = Array<ItemType>;

export type DocumentsDataPropsType = {
  _fields?: FieldsType;
  id?: string;
  items?: ItemsType;
};

export type DocumentsPropsType = {
  data?: Array<DocumentsDataPropsType>;
};

export type FieldType = {
  label: string;
  type: typeOptionsLiteral;
};

export type FieldsType = Array<FieldType>;

export type KlearlyUserType = ApiGetKlearlyUserQuery['me'];

export type FirebaseType = {
  appDefinitionsCollection: Promise<any>;
  auth: {
    currentUser: any;
    onAuthStateChanged: (newUser: any) => void;
  };
  doConfirmPasswordReset: (oobCode: string, password: string) => Promise<{}>;
  doSendPasswordResetEmail: (email: string) => Promise<{}>;
  doSignInWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<{}>;
  doSignOut: () => Promise<{}>;
  getCompanyAppStructure: () => Promise<any>;
  getCompanyAppStructureByName: (args: {
    companyName: string;
    env?: string;
  }) => Promise<CompanyAppStructure>;
  getConfigDetails: (user?: any) => void;
  getFirestoreCollection: (type: string) => { doc: (id: string) => void };
  getAllCompanyAppStructureIds: () => Promise<Array<{ id: string }>>;
  klearlyUser: KlearlyUserType;
  createSavedConfig: (
    newConfig: { pages: StructuredPage[] },
    newConfigName: string,
  ) => void;
  getSavedConfig: (configName: string) => Promise<{ pages: StructuredPage[] }>;
  getAllSavedConfigs: () => Promise<
    Array<{ pages: StructuredPage[]; id: string }>
  >;
  updateSavedConfig: (
    newConfigName: string,
    newConfig: { pages: StructuredPage[] },
  ) => void;
  deleteSavedConfig: (
    newConfig: StructuredPage[],
    newConfigName: string,
  ) => void;
  setKlearlyUser: (user: KlearlyUserType) => void;
  toggleFavoriteFlag: (
    id: string,
    firebaseUserUID: string,
    isFavorited: boolean,
  ) => void;
  toggleFeedbackLikeFlag: (
    id: string,
    firebaseUserUID: string,
    isLiked: boolean,
  ) => void;
  uiConfigCollection: CompanyAppStructure;
  updateAppDefinitionDocumentFields: (
    fieldsValues: FieldsType | undefined,
    id: string | undefined,
  ) => void;
  updateAppDefinitionDocumentItems: (
    items: Array<object> | undefined,
    id: string | undefined,
  ) => void;
  updateCompanyGlobalConfiguration: (companyName: string, updates: any) => void;
  updateFeedbackComment: (id: string, object?: {}) => void;
};

export type FirestoreQueryType = {
  data?: Array<DocumentsDataPropsType>;
  error: string | undefined;
  status: string | undefined;
};

export type FirebaseErrorType = { code: string; message: string };

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

class Firebase {
  public auth: any;
  public klearlyUser: KlearlyUserType | null;
  public firestore: any;
  public uiConfigCollection: any;
  public appDefinitionsCollection: any;

  constructor() {
    firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.klearlyUser = null;

    this.firestore = firebase.firestore();
    this.uiConfigCollection = this.firestore.collection(
      `${process.env.REACT_APP_NODE_ENV}_ui_config`,
    );
    this.appDefinitionsCollection =
      this.firestore.collection('app_definitions');
  }

  // Auth API

  setKlearlyUser = (user = null) =>
    _isEqual(user, this.klearlyUser) && !this.klearlyUser
      ? null
      : (this.klearlyUser = user);

  // doCreateUserWithEmailAndPassword = (email, password) =>
  //   this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.setKlearlyUser(null);
    return this.auth.signOut();
  };

  doSendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  doConfirmPasswordReset = (code, password) =>
    this.auth.confirmPasswordReset(code, password);

  // firestore API

  getConfigDetails = (companyName: string | null = null) => {
    if (!this.klearlyUser || !this.klearlyUser?.companyName) {
      return false;
    }
    return this.uiConfigCollection.doc(
      companyName || this.klearlyUser.companyName,
    );
  };

  getCompanyAppStructure = async () => {
    if (this.getConfigDetails().get) {
      let document = await this.getConfigDetails().get();
      return document.data();
    }
  };

  getCompanyAppStructureByName = async ({
    companyName,
  }: {
    companyName: string;
  }) => {
    try {
      const document = await this.firestore
        .collection(`${process.env.REACT_APP_NODE_ENV}_ui_config`)
        .doc(companyName)
        .get();
      return document.data();
    } catch (err) {
      console.log(err);
    }
  };

  getSavedConfig = async (configName: string) => {
    try {
      const document = await this.firestore
        .collection(`saved_ui_configs`)
        .doc(configName)
        .get();
      return document.data();
    } catch (err) {
      console.log(err);
    }
  };

  getFirestoreCollection = (type = 'favorite') => {
    const spacer = '_';
    const stringCleanup = (str = '', isFirstItem = false) =>
      str && str.length > 0 ? `${isFirstItem ? '' : spacer}${str}` : '';
    const collectionId = `${stringCleanup(
      process.env.REACT_APP_NODE_ENV,
      true,
    )}${stringCleanup(this.klearlyUser!.companyName ?? '')}${stringCleanup(
      type,
    )}`;
    return this.firestore.collection(collectionId);
  };

  toggleFavoriteFlag = async (
    id = '',
    firebaseUserUID = '',
    isFavorited = false,
  ) => {
    let document = await this.getFirestoreCollection('favorite')
      .doc(firebaseUserUID)
      .get();
    if (document && document.exists) {
      await document.ref.update({
        labCardIds: isFavorited
          ? firebase.firestore.FieldValue.arrayRemove(id)
          : firebase.firestore.FieldValue.arrayUnion(id),
      });
    } else {
      await document.ref.set(
        {
          labCardIds: [id],
        },
        { merge: true },
      );
    }
  };

  toggleFeedbackLikeFlag = async (
    id = '',
    firebaseUserUID = '',
    isLiked = false,
  ) => {
    let document = await this.getFirestoreCollection('feedback').doc(id).get();
    if (document && document.exists) {
      await document.ref.update({
        likes: isLiked
          ? firebase.firestore.FieldValue.arrayRemove(firebaseUserUID)
          : firebase.firestore.FieldValue.arrayUnion(firebaseUserUID),
      });
    } else {
      await document.ref.set(
        {
          likes: [firebaseUserUID],
        },
        { merge: true },
      );
    }
  };

  updateFeedbackComment = async (id = '', commentObj = null) => {
    if (!commentObj) {
      return;
    }
    let document = await this.getFirestoreCollection('feedback').doc(id).get();
    if (document && document.exists) {
      await document.ref.update({
        comments: firebase.firestore.FieldValue.arrayUnion(commentObj),
      });
    } else {
      await document.ref.set(
        {
          comments: [commentObj],
        },
        { merge: true },
      );
    }
  };

  updateAppDefinitionDocumentFields = async (_fields = [], id = '') => {
    let document = await this.appDefinitionsCollection.doc(id).get();
    if (document && document.exists) {
      await document.ref.update({ _fields });
    } else {
      await document.ref.set({ _fields }, { merge: true });
    }
  };

  updateAppDefinitionDocumentItems = async (items = [], id = '') => {
    let document = await this.appDefinitionsCollection.doc(id).get();
    if (document && document.exists) {
      await document.ref.update({ items });
    } else {
      await document.ref.set({ items }, { merge: true });
    }
  };

  updateCompanyGlobalConfiguration = async (companyName, updates) => {
    try {
      const document = await this.firestore
        .collection(`${process.env.REACT_APP_NODE_ENV}_ui_config`)
        .doc(companyName);
      document.update(updates);
    } catch (err) {
      console.log(err);
    }
  };

  createSavedConfig = async (
    newConfig: { pages: StructuredPage[] },
    newConfigName,
  ) => {
    try {
      await this.firestore
        .collection('saved_ui_configs')
        .doc(newConfigName)
        .set(newConfig);
    } catch (err) {
      console.log(err);
    }
  };

  getAllSavedConfigs = async () => {
    const ref = await this.firestore.collection('saved_ui_configs');

    const querySnapshot = (await ref.get()) as QuerySnapshot<{
      pages: StructuredPage[];
    }>;

    return querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return { ...data, id: doc.id };
    });
  };

  getAllCompanyAppStructureIds = async () => {
    const querySnapshot =
      (await this.uiConfigCollection.get()) as QuerySnapshot<{
        pages: StructuredPage[];
      }>;

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
    }));
  };

  updateSavedConfig = async (
    configName,
    updates: { pages: Partial<StructuredPage>[] },
  ) => {
    try {
      const document = await this.firestore
        .collection('saved_ui_configs')
        .doc(configName);
      document.update(updates);
    } catch (err) {
      console.log(err);
    }
  };

  deleteSavedConfig = async () => {};
}

export default Firebase;
