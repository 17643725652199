import { createRef, Fragment, ReactNode, useEffect, useState } from 'react';
import { useExpanded, usePagination, useTable } from 'react-table';
import _isString from 'lodash/isString';
import {
  Box,
  ButtonGroup,
  Flex,
  FormLabel,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table as StyledTable,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import classnames from 'classnames';
import { ApiPageInfo, ApiSort } from 'shared/graphql/generatedApiTypes';
import { ServerPagination } from './ServerPagination';
import {
  Button,
  RadioInput,
  Select,
} from '../../../../../shared/components/Core';
import { ChevronDownIcon, CloseIcon, SmallAddIcon } from '@chakra-ui/icons';
import { OptionsType } from 'shared/types/coreTypes.d';
import { OnChangeSelectChangeEventType } from '../../../../../shared/components/Core/Select';
import { useReactiveVar } from '@apollo/client';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';

type TableProps = {
  analyticsAttr?: string;
  columns: any[];
  data: any[];
  defaultSort: string;
  error: string | null;
  fetchData: (p: { pageIndex: number; pageSize: number; sortBy: any }) => any;
  legend: ReactNode | null;
  pageInfo: ApiPageInfo;
  renderRowSubComponent: (row) => ReactNode;
  sortableColumnOptions: OptionsType[];
};

const ServerPaginatedTable = ({
  analyticsAttr = undefined,
  columns,
  data,
  defaultSort,
  error,
  fetchData,
  legend,
  pageInfo,
  renderRowSubComponent,
  sortableColumnOptions,
}: TableProps) => {
  const popoverTriggerRef = createRef();
  const [currentSorts, setCurrentSorts] = useState([
    { desc: false, id: defaultSort },
  ]);
  const [currentSortsInForm, setCurrentSortsInForm] = useState([
    { desc: false, id: defaultSort },
  ]);
  const { onClose: popoverOnClose, isOpen, onOpen } = useDisclosure();
  const globalFilter = useReactiveVar(globalFilterVar);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      //@ts-ignore
      data: data ?? [],
      autoResetExpanded: false,
      autoResetPage: false,
      autoResetRowState: false,
      initialState: {
        pageIndex: 0,
        pageSize: 30,
      },
      manualPagination: true,
      pageCount: pageInfo.totalPages,
    },
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    gotoPage(0);
  }, [currentSorts, gotoPage]);

  useEffect(() => {
    fetchData({
      pageIndex: pageIndex + 1,
      pageSize,
      sortBy: currentSorts,
    });
  }, [fetchData, pageIndex, pageSize, currentSorts]);

  useEffect(() => {
    if (globalFilter) {
      if (pageIndex === 0) {
        fetchData({
          pageIndex: pageIndex + 1,
          pageSize,
          sortBy: currentSorts,
        });
      } else {
        gotoPage(0);
      }
    }
    // we only want this to happen when the filter changes, otherwise the page change will trigger it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, gotoPage]);

  const tableClass = classnames({
    'c-table': true,
  });

  const applySorts = () => {
    setCurrentSorts(currentSortsInForm.filter((sort) => sort.id));
    popoverOnClose();
  };

  const onClose = () => {
    popoverOnClose();
    setCurrentSortsInForm(currentSorts);
  };

  const popoverTop = popoverTriggerRef?.current
    ? ((popoverTriggerRef?.current as HTMLButtonElement)?.offsetTop as number)
    : 0;

  // disable this if the same column is selected twice
  const disableSortApply = !!currentSortsInForm.find(
    (sort1, index1) =>
      sort1.id &&
      currentSortsInForm.find(
        (sort2, index2) => sort2 && sort2.id === sort1.id && index1 !== index2,
      ),
  );

  return (
    <>
      <Popover onClose={onClose} isOpen={isOpen}>
        <Flex alignItems={'center'} justify={'space-between'} mb={2}>
          <Flex>{legend}</Flex>
          <PopoverTrigger>
            <Button
              onClick={onOpen}
              ref={popoverTriggerRef}
              rightIcon={<ChevronDownIcon boxSize={6} />}
              text={`Multi-sort`}
            />
          </PopoverTrigger>
        </Flex>
        <PopoverContent right={16} top={popoverTop} w={500}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            {currentSortsInForm.map((sort, index) => (
              <Flex
                alignItems={'center'}
                h={20}
                justify={'space-between'}
                key={index}
                mt={4}
              >
                <IconButton
                  aria-label={'remove-sort'}
                  icon={<CloseIcon boxSize={3} />}
                  mx={2}
                  mb={6}
                  onClick={() =>
                    setCurrentSortsInForm(
                      currentSortsInForm.filter(
                        (_, indexToFilter) => indexToFilter !== index,
                      ),
                    )
                  }
                  size={'sm'}
                  variant={'activeOutline'}
                />
                <Flex mx={4} alignItems={'center'}>
                  <FormLabel fontSize={'sm'} mb={6}>
                    {index === 0 ? 'Sort by:' : 'Then by:'}
                  </FormLabel>
                  <Select
                    extraFieldSetStyles={{
                      fontSize: '12px',
                      minWidth: '200px',
                    }}
                    options={sortableColumnOptions}
                    //@ts-ignore
                    onChange={(e: OnChangeSelectChangeEventType) => {
                      const copy = [...currentSortsInForm];
                      copy[index].id = e.target.value;
                      setCurrentSortsInForm(copy);
                    }}
                    //@ts-ignore
                    value={sort.id}
                  />
                </Flex>
                <Box pt={4}>
                  <RadioInput
                    onChange={(e) => {
                      const copy = [...currentSortsInForm];
                      copy[index].desc = e.target.value === ApiSort.Desc;
                      setCurrentSortsInForm(copy);
                    }}
                    options={[
                      { label: 'A-Z', value: ApiSort.Asc },
                      { label: 'Z-A', value: ApiSort.Desc },
                    ]}
                    value={sort.desc ? ApiSort.Desc : ApiSort.Asc}
                  />
                </Box>
              </Flex>
            ))}
            <Box>
              <Button
                leftIcon={<SmallAddIcon />}
                text={'Add Sort'}
                p={2}
                m={2}
                maxH={8}
                onClick={() =>
                  setCurrentSortsInForm([
                    ...currentSortsInForm,
                    { desc: false, id: '' },
                  ])
                }
              />
            </Box>
            <ButtonGroup
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'flex-end'}
            >
              <Button
                text={'Cancel'}
                onClick={onClose}
                variant={'actionOutline'}
              />
              <Button
                text={'Apply'}
                isDisabled={disableSortApply}
                onClick={applySorts}
              />
            </ButtonGroup>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <StyledTable
        {...getTableProps()}
        analytics-attr={cleanAnalyticsStringList([analyticsAttr, 'table'])}
        className={tableClass}
      >
        {!!error ? (
          <Text color={'brand.crimson'} fontWeight={'bold'}>
            {error}
          </Text>
        ) : (
          <>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    let inlineHeaderProps = {
                      alignItems: 'center' as const,
                      className: 'h-flex-nowrap',
                    };
                    // @ts-ignore
                    if (column.inlineHeaderProps) {
                      inlineHeaderProps = {
                        ...inlineHeaderProps,
                        // @ts-ignore
                        ...column.inlineHeaderProps,
                      };
                    }
                    const columnHeaderText = column.render('Header') as string;
                    return (
                      <Th {...column.getHeaderProps()}>
                        <Flex
                          {...inlineHeaderProps}
                          analytics-attr={cleanAnalyticsStringList([
                            columnHeaderText === ''
                              ? 'expander'
                              : columnHeaderText,
                            analyticsAttr,
                            'table header',
                          ])}
                        >
                          {_isString(columnHeaderText) ? (
                            <Text mr={1}>{columnHeaderText}</Text>
                          ) : (
                            columnHeaderText
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page?.map((row) => {
                prepareRow(row);
                const rowProps = { ...row.getRowProps() };
                delete rowProps.role;
                return (
                  <Fragment {...rowProps}>
                    <Tr>
                      {row.cells.map((cell) => {
                        const headerText =
                          // @ts-ignore
                          cell.column.Header?.props?.text || cell.column.Header;
                        return (
                          <Td
                            {...cell.getCellProps()}
                            analytics-attr={cleanAnalyticsStringList([
                              headerText ? headerText : 'expander',
                              analyticsAttr,
                            ])}
                          >
                            {cell.render('Cell')}
                          </Td>
                        );
                      })}
                    </Tr>
                    {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                    {row.isExpanded && renderRowSubComponent ? (
                      <Tr>
                        <Td colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </Td>
                      </Tr>
                    ) : null}
                  </Fragment>
                );
              })}
            </Tbody>
          </>
        )}
      </StyledTable>
      {!error && !!data?.length && (
        <ServerPagination
          analyticsAttr={analyticsAttr}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageIndex={pageIndex}
          pageInfo={pageInfo}
          pageLength={page.length}
          pageSize={pageSize}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      )}
    </>
  );
};

export { ServerPaginatedTable };
