import { SAV_ACCOUNT_SCORE_LINE_CHART } from 'app/src/constants/routes';
import _round from 'lodash/round';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { HEADER_INDEX } from 'app/src/constants/headerIndex';
import { TextDefinitionType } from 'app/src/@types/textDefinitionType';

const tableAccessors = {
  currentScore: 'current_score',
  expectedScore: 'expected_score',
  possibleScore: 'possible_score',
};

export const getAccountScoreSectionText = () => {
  const pageId = SAV_ACCOUNT_SCORE_LINE_CHART.name;
  const {
    subtext: currentScoreSubtext,
    text: currentScoreText,
    tooltip: currentScoreTooltip,
  } = getAppDefinitionsItem(
    pageId,
    tableAccessors.currentScore,
  ) as TextDefinitionType;
  const {
    subtext: expectedScoreSubtext,
    text: expectedScoreText,
    tooltip: expectedScoreTooltip,
  } = getAppDefinitionsItem(
    pageId,
    tableAccessors.expectedScore,
  ) as TextDefinitionType;
  const {
    subtext: possibleScoreSubText,
    text: possibleScoreText,
    tooltip: possibleScoreTooltip,
  } = getAppDefinitionsItem(
    pageId,
    tableAccessors.possibleScore,
  ) as TextDefinitionType;
  const { subtext: accountScoreHeaderSubText, text: accountScoreHeaderText } =
    getAppDefinitionsItem(pageId, HEADER_INDEX) as TextDefinitionType;
  const { text: prioritySignalButtonLabel } = getAppDefinitionsItem(
    pageId,
    'priority_signal_button_label',
  ) as TextDefinitionType;
  const { text: maxImpactButtonLabel } = getAppDefinitionsItem(
    pageId,
    'max_impact_button_label',
  ) as TextDefinitionType;

  return {
    accountScore: {
      subtext: accountScoreHeaderSubText,

      text: accountScoreHeaderText,
    },
    currentScore: {
      subText: currentScoreSubtext,
      text: currentScoreText,
      tooltip: currentScoreTooltip,
    },
    expectedScore: {
      subText: expectedScoreSubtext,
      text: expectedScoreText,
      tooltip: expectedScoreTooltip,
    },
    maxImpactButtonLabel,
    possibleScore: {
      subText: possibleScoreSubText,
      text: possibleScoreText,
      tooltip: possibleScoreTooltip,
    },
    prioritySignalButtonLabel,
  };
};

type AppDefinitionsText = {
  text?: string;
  subText?: string;
  tooltip?: string;
};

type GetScoresCircleBaseParams = {
  currentScore: number | null | undefined;
  currentScoreText: AppDefinitionsText;
  expectedScoreLow: number | null | undefined;
  expectedScoreHigh: number | null | undefined;
  expectedScoreText: AppDefinitionsText;
};

export const getScoresCircleBase = ({
  currentScore,
  currentScoreText,
  expectedScoreLow,
  expectedScoreHigh,
  expectedScoreText,
}: GetScoresCircleBaseParams) => [
  {
    backgroundColor: 'brand.gray-800',
    score: currentScore ?? 0,
    subtitle: currentScoreText.subText ?? '',
    title: currentScoreText.text ?? '',
    tooltip: currentScoreText.tooltip,
  },
  {
    backgroundColor: 'brand.gray-400',
    score: `${_round(expectedScoreLow ?? 0)}-${_round(expectedScoreHigh ?? 0)}`,
    subtitle: expectedScoreText.subText ?? '',
    title: expectedScoreText.text ?? '',
    tooltip: expectedScoreText.tooltip,
  },
];

type GetPossibleScoreCircleParams = {
  possibleScore: number | null | undefined;
  possibleScoreText: AppDefinitionsText;
  isPriority: boolean;
};

export const getPossibleScoreCircleBase = ({
  possibleScore = 0,
  possibleScoreText,
  isPriority = true,
}: GetPossibleScoreCircleParams) => [
  {
    backgroundColor: isPriority ? 'brand.blue' : 'brand.green',
    score: possibleScore ?? 0,
    subtitle: possibleScoreText.subText ?? '',
    title: possibleScoreText.text ?? '',
    tooltip: possibleScoreText.tooltip ?? '',
  },
];

export const getLineChartBaseData = ({ currentValues, expectedValues }) => ({
  currentLine: {
    dataStyles: { color: 'brand.gray-800' },
    values: currentValues,
  },
  expectedLine: {
    dataStyles: { color: 'brand.gray-400' },
    values: expectedValues,
  },
});

export const getPrioritySignalGoalLine = (values) => ({
  prioritySignalGoalLine: {
    dataStyles: { color: 'brand.blue' },
    values,
  },
});

export const getMaxImpactGoalLine = (values) => ({
  maxImpactGoalLine: {
    dataStyles: { color: 'brand.green' },
    values,
  },
});
