import {useContext, useEffect} from 'react';
import _isEqual from 'lodash/isEqual';
import {useInput, UseInputHookReturnType, UseInputHookStringNumberValueTypes,} from 'shared/hooks/inputHook';
import {usePrevious} from 'app/src/hooks/previousHook';
import {AnalyticsAttrType, OptionsType} from 'shared/types/coreTypes.d';
import {filterItemsLoadingVar} from 'app/src/apollo/rootReactiveVariables';
import {RadioInput} from '../../../../../../shared/components/Core';
import {AggregateAccountsDataContext} from 'app/src/context/AggregateAccountsDataContext';
import {FilterItemProps} from '../../types/filterItemProps';
import {sortSelectFilterOptions} from '../../helpers/sortSelectFilterOptions';
import {getFilterItemConsts} from '../../helpers/getFilterItemConsts';
import {useGetAggregateAccountsDataWithFilterVarQuery} from 'shared/graphql/generatedApiTypes';
import {adaptDictAsString} from '../../helpers/adaptFilterForQuery';
import _omit from 'lodash/omit';

type RadioInputFilterItemProps = AnalyticsAttrType &
  FilterItemProps & {
    defaultValue: string | number;
  };

const RadioInputFilterItem = ({
  analyticsAttr = undefined,
  defaultValue,
  initialItems = [],
  filterIndex,
  label = '',
  labelStringTooltip = '',
  tableAccessor = '',
}: RadioInputFilterItemProps) => {
  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
    globalFilter,
    updateFilter,
  } = useContext(AggregateAccountsDataContext);

  const {
    data: filterItemAggregatedAccountsData,
    loading: filterItemAggregatedAccountsDataLoading,
  } = useGetAggregateAccountsDataWithFilterVarQuery({
    variables: {
      filterString: adaptDictAsString(_omit(globalFilter, filterIndex)) ?? '',
    },
  });

  const { aggregatedItemsAccountData, areFiltersApplied, isSelectedDisabled } =
    getFilterItemConsts({
      filterIndex,
      filterItemAggregatedAccountsData,
      filterItemAggregatedAccountsDataLoading,
      globalFilter,
      initialAggregatedAccountsData,
      initialAggregatedAccountsDataLoading,
    });

  const itemsSorted = sortSelectFilterOptions({
    aggregatedItemsAccountData,
    areFiltersApplied,
    filterIndex,
    initialItems,
    tableAccessor,
  });
  const totalAccounts = itemsSorted.reduce(
    (accum, current) => accum + current.count,
    0,
  );
  const {
    bind: bindItems,
    setValue: setItems,
    value,
  } = useInput(globalFilter?.[filterIndex] || defaultValue, {
    defaultValue: defaultValue,
    label: label,
    labelTooltip: labelStringTooltip,
    options: [
      {
        accountsCount: totalAccounts,
        count: totalAccounts,
        label: 'All',
        value: 'all',
        analyticsAttr: 'all',
      },
      ...itemsSorted,
    ],
  }) as UseInputHookReturnType & UseInputHookStringNumberValueTypes;

  // setup previous filter dict
  const previousFilterDict = usePrevious(globalFilter);
  const previousLoadingState = usePrevious(isSelectedDisabled);

  useEffect(() => {
    if (!_isEqual(previousFilterDict, globalFilter)) {
      setItems(globalFilter?.[filterIndex] || defaultValue);
    }
  }, [defaultValue, globalFilter, filterIndex, previousFilterDict, setItems]);
  useEffect(() => {
    if (!_isEqual(previousLoadingState, isSelectedDisabled)) {
      filterItemsLoadingVar(isSelectedDisabled);
    }
  }, [isSelectedDisabled, previousLoadingState]);

  const updateSelection = (selectedOption: string) => {
    if (selectedOption === 'all') {
      updateFilter({
        index: filterIndex,
        value: undefined,
      });
    } else {
      updateFilter({
        index: filterIndex,
        value: selectedOption,
      });
    }
  };

  return (
    <div className={'h-mv-lg'} style={{ minHeight: '100px' }}>
      <RadioInput
        {...bindItems}
        analytics-attr={analyticsAttr}
        className={'h-grid h-grid-column-gap-lg'}
        disabled={isSelectedDisabled}
        horizontal
        onChange={(e) => updateSelection(e.target.value)}
        options={bindItems.options as OptionsType[]}
        value={value}
      />
    </div>
  );
};

export default RadioInputFilterItem;
