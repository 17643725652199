import { ApiGetAllAccountGroupsQuery } from 'shared/graphql/generatedApiTypes';
import { KlearlyUserType } from '../../Firebase/firebase';
import _filter from 'lodash/filter';
import _map from 'lodash/map';

export type GroupAccountGroupsParams = {
  accountGroups: ApiGetAllAccountGroupsQuery['accountGroups'];
  klearlyUser: KlearlyUserType;
  showOnlyLoggedInUsersGroups?: boolean;
};

export function groupAccountGroups({
  accountGroups = [],
  klearlyUser,
  showOnlyLoggedInUsersGroups = false,
}: GroupAccountGroupsParams): {
  deleted: ApiGetAllAccountGroupsQuery['accountGroups'];
  groups: ApiGetAllAccountGroupsQuery['accountGroups'];
  lists: ApiGetAllAccountGroupsQuery['accountGroups'];
} {
  // the check here allows us to assert non nullable
  const nonNullableAccountGroups = accountGroups
    ? (accountGroups as NonNullable<
        ApiGetAllAccountGroupsQuery['accountGroups']
      >)
    : [];
  const filteredDeletedGroups = _filter(
    nonNullableAccountGroups,
    (g) => g?.isHidden && g?.companyUser?.id === klearlyUser?.id,
  ) as NonNullable<ApiGetAllAccountGroupsQuery['accountGroups']>;
  const filteredGroupGroups = _filter(
    nonNullableAccountGroups,
    (g) => !g?.isHidden && g?.filterString,
  ) as NonNullable<ApiGetAllAccountGroupsQuery['accountGroups']>;
  const filteredListGroups = _filter(
    nonNullableAccountGroups,
    (g) => !g?.isHidden && !g?.filterString,
  );
  if (showOnlyLoggedInUsersGroups) {
    return {
      deleted: filteredDeletedGroups,
      groups: _filter(
        filteredGroupGroups,
        (g) => g?.companyUser?.id === klearlyUser?.id,
      ),
      lists: _filter(
        filteredListGroups,
        (g) => g?.companyUser?.id === klearlyUser?.id,
      ),
    };
  }

  return {
    deleted: filteredDeletedGroups,
    groups: _map(filteredGroupGroups, (g) => ({
      ...g,
      isLoggedInUsers: g?.companyUser?.id === klearlyUser?.id,
    })) as NonNullable<ApiGetAllAccountGroupsQuery['accountGroups']>,
    lists: _map(filteredListGroups, (l) => ({
      ...l,
      isLoggedInUsers: l?.companyUser?.id === klearlyUser?.id,
    })) as NonNullable<ApiGetAllAccountGroupsQuery['accountGroups']>,
  };
}
