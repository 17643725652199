import {createIcon} from '@chakra-ui/icon';

export const DottedGrayCircle = createIcon({
  displayName: 'DottedGrayCircle',
  viewBox: '0 0 12 12',
  path: (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='6'
        cy='6'
        r='4.5'
        fill='transparent'
        stroke='#707F8A'
        strokeWidth='3'
        strokeDasharray='2 2'
      />
    </svg>
  ),
});
