import _filter from 'lodash/filter';
import { Accordion, SimpleGrid } from '@chakra-ui/react';
import { LabFirestoreValuesType } from '../types/firestoreValuesTypes';
import { useFirestoreQuery } from 'shared/hooks/firestoreHook';
import {
  AccordionPanelWrapper,
  Card,
  Container,
  Loading,
  Txt,
} from '../../../../../shared/components/Core';
import { useFirebase } from '../../Firebase';
import { buildLabAccordionSections } from '../helpers/buildLabAccordionSections';
import { handleFeedbackFavoriteForLabCard } from '../helpers/labCardInteractions';
import { LABS_TAB } from 'app/src/constants/routes';
import { LabCard } from './LabCard';
import { getAppDefinitionsItem } from 'shared/helpers/getAppDefinitionsItem';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

export const LabsAccordions = () => {
  const tableValues: LabFirestoreValuesType[] = _filter(
    getAppDefinitionsItem(LABS_TAB.name),
    (value: { showCard: boolean }) => value.showCard,
  );
  const tagsValues = getAppDefinitionsItem(LABS_TAB.tag);
  const firebase = useFirebase()!;
  const firebaseUserUID = firebase!.auth.currentUser.uid;
  const favoriteProps = useFirestoreQuery(
    firebase!.getFirestoreCollection('favorite').doc(firebaseUserUID),
  );

  const { favoriteData } = handleFeedbackFavoriteForLabCard(
    favoriteProps,
    undefined,
    firebaseUserUID,
  );

  const { accordionSections, orderedTags } = buildLabAccordionSections({
    favoriteData,
    tableValues,
    tagsValues,
  });

  return (
    <Container ph={'xl'} pv={'xl'} width={'full'}>
      {favoriteProps.data || favoriteProps.status === 'success' ? (
        <Accordion allowToggle allowMultiple defaultIndex={[0]} reduceMotion>
          {orderedTags.map((tag, index) => {
            return !!accordionSections[tag.index].length ? (
              <AccordionPanelWrapper
                key={index}
                render={(isExpanded) => (
                  <SimpleGrid columns={[1, null, null, 2, 3, null]} gap={3}>
                    {accordionSections[tag.index].map((lab) =>
                      isExpanded ? (
                        <LabCard
                          isFavorited={tag.index === 'Favorites'}
                          key={lab.id}
                          lab={lab}
                          section={tag.index}
                        />
                      ) : (
                        <Card key={lab.id}>
                          <div className={'lab-card'} id={lab.id}>
                            <Loading />
                          </div>
                        </Card>
                      ),
                    )}
                  </SimpleGrid>
                )}
                title={`${cleanStringCase(tag.index)} (${accordionSections[
                  tag.index
                ].length.toLocaleString()})`}
              />
            ) : null;
          })}
        </Accordion>
      ) : !!favoriteProps.error ? (
        <Txt align={'center'} theme={'active'}>
          {'We encountered an error. Please refresh and try again'}
        </Txt>
      ) : (
        <div style={{ minHeight: '450px' }}>
          <Loading
            dotColor={'color'}
            flexDirection={'column'}
            maxW={'94vw'}
            text={'Experiments are afoot...'}
          />
        </div>
      )}
    </Container>
  );
};
