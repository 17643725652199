type ArrayRouteType = Array<string>;

type StringRouteType = string;

type ObjectNameRouteType = {
  name: string;
};

type ObjectNamePathRouteType = {
  name: string;
  path: string;
};

type ObjectNamePathTagRouteType = {
  name: string;
  path: string;
  tag: string;
};

// pages
export const ACCOUNT: ArrayRouteType = ['/account', '/account/:id'];
export const ADMIN: ArrayRouteType = ['/admin?page=user_list', '/admin'];
export const BASE: StringRouteType = '/';
export const CRM_CREDENTIALS_PAGE: ObjectNamePathRouteType = {
  name: 'CRMCredentials',
  path: '/credentials/:company_name',
};
export const HOME: StringRouteType = '/';
export const LOG_IN: StringRouteType = '/login';
export const PASSWORD_FORGOT: StringRouteType = '/forgot';
export const PASSWORD_RESET: StringRouteType = '/reset';
// widgets
export const ACCOUNT_LIST: ObjectNameRouteType = { name: 'AccountList' };
export const ACCOUNT_FILTER_BAR: ObjectNameRouteType = {
  name: 'AccountFilterBar',
};
export const ADMIN_USER_LIST_USER_ROLES: ObjectNameRouteType = {
  name: 'UserRoles',
};
export const GLOBAL_FILTER_PANEL: ObjectNameRouteType = {
  name: 'GlobalFilterPanel',
};
export const HOME_TITLE: ObjectNameRouteType = {
  name: 'Home',
};
export const HOME_ENGAGEMENT_STATUS: ObjectNameRouteType = {
  name: 'HomeEngagementStatus',
};
export const HOME_HEADERS: ObjectNameRouteType = {
  name: 'HomeHeaders',
};
export const HOME_RECOMMENDATIONS_LIST: ObjectNameRouteType = {
  name: 'HomeRecommendationsList',
};
export const MAV_PRIORITY_SIGNAL_RECOMMENDATIONS_LIST: ObjectNameRouteType = {
  name: 'MAVPrioritySignalRecommendationsList',
};
export const MAV_WINFLUENCE_SCATTER_PLOT: ObjectNameRouteType = {
  name: 'MAVWinfluenceScatterPlot',
};
export const PLAN_TITLE: ObjectNameRouteType = {
  name: 'Plan',
};
export const SAV_ACCOUNT_SCORE_LINE_CHART: ObjectNameRouteType = {
  name: 'SAVAccountScoreLineChart',
};
export const SAV_WINFLUENCE: ObjectNameRouteType = { name: 'SAVWinfluence' };
export const SAV_RECOMMENDATIONS_LIST: ObjectNameRouteType = {
  name: 'SAVRecommendationsList',
};
export const GROUPS_VIEW: ObjectNameRouteType = { name: 'Groups' };
// tabs
export const ADMIN_USER_LIST_TAB: StringRouteType = 'user_list';
export const APP_DEFINITIONS_TAB: StringRouteType = 'app_definitions';
export const DATA_ASSESSMENT_TAB: StringRouteType = 'data_assessment';
export const ADMIN_COMPANY_LIST_TAB: StringRouteType = 'company_list';
export const ADMIN_LABS_LIST_TAB: StringRouteType = 'labs_stats';
export const SAVED_UI_CONFIGS_TAB: StringRouteType = 'saved_ui_configs';
export const LABS_TAB: ObjectNamePathTagRouteType = {
  name: 'Labs',
  path: '/labs',
  tag: 'LabsTags',
};
