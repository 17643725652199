import { ReactNode } from 'react';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { Flex, Tooltip } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';

type TooltipProps = AnalyticsAttrType & {
  children?: ReactNode;
  content: ReactNode;
  hideIcon?: boolean;
  text?: string;
};

const StyledTooltip = ({
  analyticsAttr = undefined,
  children,
  content,
  hideIcon = false,
  text = undefined,
}: TooltipProps) => {
  return (
    <Tooltip
      analytics-attr={cleanAnalyticsStringList([analyticsAttr, 'tooltip'])}
      bg='components.tooltip'
      borderRadius={8}
      hasArrow
      label={<>{content}</>}
      m={2}
      p={4}
      shouldWrapChildren
      zIndex={15000}
    >
      <Flex alignItems={'center'} zIndex={15000}>
        {text ? text : children}
        {!hideIcon && <QuestionIcon color={'brand.gray-400'} ml={2} />}
      </Flex>
    </Tooltip>
  );
};

export default StyledTooltip;
