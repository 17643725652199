import { Flex, Select, Text } from '@chakra-ui/react';
import { Button } from '../../../../../shared/components/Core';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { ApiPageInfo } from 'shared/graphql/generatedApiTypes';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';

type ServerPaginationProps = AnalyticsAttrType & {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (newPage: number) => void;
  nextPage: () => void;
  pageIndex: number;
  pageInfo: ApiPageInfo;
  pageLength: number;
  pageSize: number;
  previousPage: () => void;
  setPageSize: (newSize: number) => void;
};

export const ServerPagination = ({
  analyticsAttr,
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageIndex,
  pageInfo,
  pageLength,
  pageSize,
  previousPage,
  setPageSize,
}: ServerPaginationProps) => {
  const totalPages = pageInfo.totalPages;
  const totalEntries = pageInfo.totalEntries ?? 0;
  const showingText = `Showing ${pageSize * pageIndex + 1}-${
    pageSize * pageIndex + pageLength
  } of ${totalEntries} results`;
  const pageOfPagesText = `${pageIndex + 1} of ${totalPages ?? 0}`;

  return (
    <Flex justify={'space-between'} mt={4}>
      <Text>{showingText}</Text>
      <Flex align={'center'}>
        <Button
          analyticsAttr={cleanAnalyticsStringList([
            'First page',
            analyticsAttr,
          ])}
          text={'<<'}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          mr={2}
          variant={'transparent'}
          fontProps={{ fontSize: 'xl' }}
        />
        <Button
          analyticsAttr={cleanAnalyticsStringList([
            'Previous page',
            analyticsAttr,
          ])}
          text={'<'}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          mr={2}
          variant={'transparent'}
          fontProps={{ fontSize: 'xl' }}
        />
        <Text mr={2}>
          Page <strong>{pageOfPagesText}</strong>
        </Text>
        <Button
          analyticsAttr={cleanAnalyticsStringList(['Next page', analyticsAttr])}
          text={'>'}
          onClick={() => nextPage()}
          disabled={!canNextPage}
          mr={2}
          variant={'transparent'}
          fontProps={{ fontSize: 'xl' }}
        />
        <Button
          analyticsAttr={cleanAnalyticsStringList(['Last page', analyticsAttr])}
          text={'>>'}
          onClick={() => {
            gotoPage(totalPages - 1);
          }}
          disabled={!canNextPage}
          mr={2}
          variant={'transparent'}
          fontProps={{ fontSize: 'xl' }}
        />
      </Flex>
      <Select
        maxW={36}
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
        analytics-attr={cleanAnalyticsStringList([
          'Page size',
          analyticsAttr,
          'select',
        ])}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option
            key={pageSize}
            value={pageSize}
            analytics-attr={cleanAnalyticsStringList([
              `page size`,
              pageSize,
              analyticsAttr,
              'select option',
            ])}
          >
            Show {pageSize}
          </option>
        ))}
      </Select>
    </Flex>
  );
};
