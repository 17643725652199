import {GlobalFilterReducerState} from '../reducers/globalFilterReducer';
import {FilterIndices} from '../types/filterTypes';
import {SegmentBuilderSection} from 'shared/types/companyAppStructureTypes';

export const getFilterNumberSelected = (
  globalFilter: GlobalFilterReducerState,
  section: SegmentBuilderSection,
) => {
  return section.filters
    .map((item) => item.field)
    .reduce((accum, current) => {
      return accum + numberSelected(globalFilter)[current] ?? 0;
    }, 0);
};

const numberSelected = (filter: GlobalFilterReducerState) => ({
  [FilterIndices.COUNTRY]:
    filter &&
    filter[FilterIndices.COUNTRY] &&
    filter[FilterIndices.COUNTRY].length
      ? 1
      : 0,
  [FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE]:
    filter &&
    filter[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE] &&
    !(
      filter[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE].min ===
        0 &&
      filter[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE].max === 100
    )
      ? 1
      : 0,
  [FilterIndices.ENGAGEMENT_STATUS]:
    filter &&
    filter[FilterIndices.ENGAGEMENT_STATUS] &&
    !!filter[FilterIndices.ENGAGEMENT_STATUS].length
      ? 1
      : 0,
  [FilterIndices.ENGAGEMENT_INTENSITY]:
    filter &&
    filter[FilterIndices.ENGAGEMENT_INTENSITY] &&
    !!filter[FilterIndices.ENGAGEMENT_INTENSITY].length
      ? 1
      : 0,
  [FilterIndices.INDUSTRY]:
    filter &&
    filter[FilterIndices.INDUSTRY] &&
    filter[FilterIndices.INDUSTRY].length
      ? 1
      : 0,
  [FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER]:
    filter && filter[FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER] ? 1 : 0,
  [FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER]:
    filter && filter[FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER] ? 1 : 0,
  [FilterIndices.OUTREACH_INTENSITY]:
    filter &&
    filter[FilterIndices.OUTREACH_INTENSITY] &&
    !!filter[FilterIndices.OUTREACH_INTENSITY].length
      ? 1
      : 0,
  [FilterIndices.RECOMMENDED_ACTION]:
    filter && filter[FilterIndices.RECOMMENDED_ACTION] ? 1 : 0,
  [FilterIndices.REVENUES_FILTER]:
    filter &&
    filter[FilterIndices.REVENUES_FILTER] &&
    !!Object.keys(filter[FilterIndices.REVENUES_FILTER]).length
      ? 1
      : 0,
  [FilterIndices.SIGNAL_TYPE_FILTER]:
    filter &&
    filter[FilterIndices.SIGNAL_TYPE_FILTER] &&
    !!filter[FilterIndices.SIGNAL_TYPE_FILTER].length
      ? 1
      : 0,
  [FilterIndices.SIZES_FILTER]:
    filter &&
    filter[FilterIndices.SIZES_FILTER] &&
    !!Object.keys(filter[FilterIndices.SIZES_FILTER]).length
      ? 1
      : 0,
  [FilterIndices.WINFLUENCE_ACTUAL]:
    filter &&
    filter[FilterIndices.WINFLUENCE_ACTUAL] &&
    !!filter[FilterIndices.WINFLUENCE_ACTUAL].length
      ? 1
      : 0,
});
