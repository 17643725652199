import _find from 'lodash/find';
import _map from 'lodash/map';
import {
  ApiAggregateValueCounts,
  Maybe,
} from 'shared/graphql/generatedApiTypes';
import { BindMinMaxOptionsType } from '../types/minToMaxFilterItemProps';
import _size from 'lodash/size';
import _max from 'lodash/max';
import _round from 'lodash/round';
import {
  numberFormatter,
  pluralizeAccountsString,
} from 'shared/helpers/formatHelpers';

export const getLabelAddOn = (
  aggregatedInitialData: BindMinMaxOptionsType,
  values: Array<number | 'Max'>,
  isDollars: boolean,
) => {
  const minValue = values[0];
  const maxValue = values[1];
  const selectedMinOption = _find(aggregatedInitialData, ['value', minValue]);
  const selectedMaxOption = _find(aggregatedInitialData, ['value', maxValue]);
  const minTextValue = numberFormatter(minValue, isDollars);
  let maxTextValue = numberFormatter(maxValue, isDollars);
  if (values[1] === 'Max') {
    maxTextValue = 'Max';
  }
  const numberOfAccounts = selectedMaxOption
    ? Number.isNaN(
        selectedMinOption?.eq! +
          selectedMinOption?.gt! -
          selectedMaxOption?.gt!,
      )
      ? 'Loading'
      : selectedMinOption?.eq! + selectedMinOption?.gt! - selectedMaxOption?.gt!
    : Number.isNaN(selectedMinOption?.gt! + selectedMinOption?.eq!)
    ? 'Loading'
    : selectedMinOption?.gt! + selectedMinOption?.eq!;
  const maxValueText =
    maxValue === undefined
      ? minValue || minValue === 0
        ? ' - Max'
        : ''
      : ` - ${maxTextValue}`;
  const accountsStrings = ` ${pluralizeAccountsString(numberOfAccounts)}`;
  return `: ${minTextValue}${maxValueText} (${numberOfAccounts.toLocaleString()} ${accountsStrings})`;
};

export function createAggregateMinMaxFromItems(items: any[]) {
  return _map(items, (item) => ({
    index: item.value,
    label: item.value.toString(),
    value: item.value.toString(),
  }));
}

export function createRangeMinMaxFromItems(
  items: Maybe<Pick<ApiAggregateValueCounts, 'value' | 'eq' | 'gt' | 'lt'>>[],
  isDollars: boolean,
) {
  return _map(items, (item) => {
    const numberFormatted = numberFormatter(item?.value, isDollars);
    return {
      index: item?.value,
      label: numberFormatted,
      value: numberFormatted,
    };
  });
}

export type BarGraphBackgroundBarsType = Array<{
  height: string;
  left: string | number;
  value: string | number;
  width: string | number;
}>;

export function createRangerBars(numberOfValuesInRange: Array<any>) {
  const width = (1 / _size(numberOfValuesInRange)) * 100;
  const maxValue: number = _max(numberOfValuesInRange);
  const bars: BarGraphBackgroundBarsType = _map(
    numberOfValuesInRange,
    (v, idx) => {
      return {
        height: `${Math.floor((v / maxValue) * 60)}px`,
        left: `${_round(width * idx, 2)}%`,
        value: v,
        width: _round(width),
      };
    },
  );
  return bars;
}
