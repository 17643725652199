import {FilterIndices} from '../components/GlobalFilters/types/filterTypes';
import {AccountStatSummaryItem, FilterItemOption, GlobalFiltersStructure,} from 'shared/types/companyAppStructureTypes';
import {FilterPanelTabValues} from '../components/GlobalFilters/constants/filterPanelTabOptions';

export const merzGlobalFiltersStructure: GlobalFiltersStructure = {
  accountStatSummaryConfig: [AccountStatSummaryItem.totalAccounts],
  defaultAppliedFilter: '{}',
  panel: {
    showTabs: [FilterPanelTabValues.BUILD],
    segmentBuilderSections: [
      {
        priority: 1,
        label: 'Accounts',
        filters: [
          {
            field: FilterIndices.ACCOUNTS_FILTER,
            title: '',
            type: FilterItemOption.searchableDropdown,
          },
        ],
      },
      {
        priority: 2,
        label: 'Firmographics',
        filters: [
          {
            field: FilterIndices.INDUSTRY,
            title: 'Industries',
            type: FilterItemOption.checkboxDropdown,
          },
        ],
      },
    ],
  },
  quickAccessBarFilterItems: [
    {
      type: FilterItemOption.checkboxDropdown,
      field: FilterIndices.XEOMIN_PREDICTION_SUMMARY,
      title: 'Prediction Summary',
      removeValues: [],
    },
  ],
};
