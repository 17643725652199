import _omit from 'lodash/omit';
import {FilterDictType} from '../types/filterDict';
import {FilterIndices} from '../types/filterTypes';
import {OptionsType} from 'shared/types/coreTypes.d';

export const cleanFilterDict = ({
  filterDict,
  filterIndex,
}: {
  filterDict: FilterDictType;
  filterIndex: string;
}) =>
  _omit(
    filterDict,
    filterDict?.[FilterIndices.ACCOUNTS_FILTER_TYPE] ===
      FilterIndices.ACCOUNTS_FILTER_TYPE_EXCLUSIVE
      ? [
          filterIndex,
          FilterIndices.ACCOUNTS_FILTER,
          FilterIndices.ACCOUNTS_FULL_FILTER,
        ]
      : [filterIndex],
  );

export function filterStringFromBackendToDict(
  filterString: string = '',
): FilterDictType & { accounts: OptionsType[] } {
  if (!filterString.trim()) {
    return {
      [FilterIndices.ACCOUNTS_FILTER_TYPE]: [
        FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
      ],
      accounts: [],
    };
  }
  const parsedFilter = JSON.parse(filterString);
  if (parsedFilter.accounts) {
    return parsedFilter;
  }
  return { ...parsedFilter, accounts: [] };
}
